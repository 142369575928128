import { IonCol } from "@ionic/react";
import { ReactNode, useState } from "react";
import { generateRandomString } from "services/util";
import Card from "./Card"
import TournamentDisplay from "./TournamentDisplay"

const TournamentsList: React.FC<{
	tournaments: Tournament[],
	qualifier?: string
	user_id: string | null
	organizing_status: string | null
}> = ({tournaments, qualifier, user_id,organizing_status}) => {

	const [tournamentsCache, setTournamentsCache] = useState<Tournament[]>([]);
	const [tournamentsNodes, setTournamentsNodes] = useState<ReactNode[]>([]);

	if (tournaments && tournaments.length <= 0){
		return null;
	}

	const getNodes = () => {
		if (JSON.stringify(tournaments) !== JSON.stringify(tournamentsCache)){
			let newNodes = tournaments.map((tournament: Tournament) => {
				return <TournamentDisplay key={generateRandomString()} tournament={tournament} user_id={user_id} organizing_status={organizing_status} />
			});
			setTournamentsCache(tournaments);
			setTournamentsNodes(newNodes);
		} else {
			return tournamentsNodes;
		}
	}

	return (
		<IonCol>
			<Card title={(() => {
				if (tournaments && tournaments.length > 0){
					return (qualifier) ? qualifier + " ":"" + "Tournaments (" + tournaments.length + ")";
				} else {
					return (qualifier) ? qualifier + " ":"" + "Tournaments";
				}
			})()}>
				{getNodes()}
			</Card>
		</IonCol>
	)
}

export default TournamentsList;