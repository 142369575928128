import styled from "styled-components";
import { useSpring, animated } from 'react-spring';
import { ReactNode, useContext, useEffect, useState } from "react";
import { getLinearGradient } from "../services/util";
import { useHistory, useParams } from "react-router";
import { FilterContext } from "State/FilterContext";
import { isPlatform } from '@ionic/react';

interface WaveTabProps {
	leftTitle: string
	rightTitle: string
	centerTitle: string
	leftComponent: React.ReactNode
	rightComponent: React.ReactNode
	centerComponent: React.ReactNode
	color: string
}

interface TabsProps {
	path: string
	color: string
	zIndex?: number
}

interface TargetProps {
	top: string
	left?: string
	right?: string
	colorActive?: boolean
	fontWeight?:string
}

const WaveTabs: React.FC<WaveTabProps> = ({ children, leftTitle, rightTitle, centerTitle, leftComponent, rightComponent, centerComponent, color }) => {
	let { tab } = useParams<SearchTypeParams>();
	
	const { state, dispatch } = useContext(FilterContext);
	const [leftActive, setLeftActive] = useState(() => {
		return (tab !== 'tournament');
	});
	
	const [activeStatus, setActiveStatus] = useState(()=>{
		if(tab==='match'){
			return 1;
		}else if(tab==='tournament'){
			return 2;
		}else{
			return 3;
		}
	});
	
	
	const { x } = useSpring({ config: { duration: 300 }, x: leftActive ? 1 : 0 });

	const provideFallback = (path: ReactNode, fallback_paths: string[], leftActive: boolean) => {
		
		if (isPlatform('ios')) {
			return <path d={
				(leftActive) ? fallback_paths[1] : fallback_paths[0]
			} 
			/>
		} else {
			return path;
		}
	}


	return (
		<div>
			<Container>
				<ChildrenContainer>
					{children}
				</ChildrenContainer>
				<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{ position: 'absolute' }}>
					<clipPath id="path" clipPathUnits="objectBoundingBox">
						{
							provideFallback(<animated.path
								d={x.to({
									range: [0, 1],
									output: [
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.15 0.5 0.5 T 0 0.5",
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.85 0.5 0.5 T 0 0.5",
									],
								})}
								style={{
									fill: x.to({
										range: [0, 1],
										output: ["#fdeae7", "#eae7fd"],
									}),
								}}
							/>, [
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.15 0.5 0.5 T 0 0.5",
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.85 0.5 0.5 T 0 0.5"
							], (activeStatus==1)? true : false)
						}
					</clipPath >
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{ position: 'absolute' }}>
					<clipPath id="pathBack" clipPathUnits="objectBoundingBox">
						{
							provideFallback(<animated.path
								d={x.to({
									range: [0, 1],
									output: [
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.07 0.5 0.5 T 0 0.5",
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.93 0.5 0.5 T 0 0.5",
									],
								})}
								style={{
									fill: x.to({
										range: [0, 1],
										output: ["#fdeae7", "#eae7fd"],
									}),
								}}
							/>, [
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.07 0.5 0.5 T 0 0.5",
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.93 0.5 0.5 T 0 0.5"
							], (activeStatus==2)? true : false)
						}
					</clipPath >
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{ position: 'absolute' }}>
					<clipPath id="pathBackEvent" clipPathUnits="objectBoundingBox">
						{
							provideFallback(<animated.path
								d={x.to({
									range: [0, 1],
									output: [
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.07 0.5 0.5 T 0 0.5",
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.93 0.5 0.5 T 0 0.5",
									],
								})}
								style={{
									fill: x.to({
										range: [0, 1],
										output: ["#fdeae7", "#eae7fd"],
									}),
								}}
							/>, [
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.07 0.5 0.5 T 0 0.5",
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.93 0.5 0.5 T 0 0.5"
							], (activeStatus==3)? true : false)
						}
					</clipPath >
				</svg>
				<Tabs path="#pathBack" color="bg-shadow" />
				<Tabs path="#path" color={color} />
				<Tabs path="#pathBackEvent" color={color} />

				<Target
					className="color-text-transition"
					top="50%"
					left="-30px"
					fontWeight={(activeStatus==1)? "bold" : "normal"}
					colorActive={(activeStatus==1)? true : false}
					onClick={() => {
						window.history.pushState({}, "Match Search", "/search/match");
						dispatch({ type: 'setSearchType', payload: 'match' });
						setActiveStatus(1)
					}}
				>{leftTitle}</Target>

				<Target
					className={(isPlatform('ios')) ? "" : "color-text-transition"}
					top="50%"
					left="25%"
					fontWeight={(activeStatus==2)? "bold" : "normal"}
					colorActive={(activeStatus==2)? true : false}
					onClick={() => {
						window.history.pushState({}, "Tournament Search", "/search/tournament");
						dispatch({ type: 'setSearchType', payload: 'tournament' });
						setActiveStatus(2)
					}}
				>{rightTitle}
				</Target>


				<Target
					className={(isPlatform('ios')) ? "" : "color-text-transition"}
					top="50%"
					right="-30px"
					fontWeight={(activeStatus==3)? "bold" : "normal"}
					colorActive={(activeStatus==3)? true : false}
					onClick={() => {
						window.history.pushState({}, "Event Search", "/search/event");
						dispatch({ type: 'setSearchType', payload: 'event' });
						setActiveStatus(3)
					}}
				>{centerTitle}</Target>
			</Container>
			{(activeStatus == 1) ? leftComponent : (activeStatus == 2) ? rightComponent : centerComponent}
		</div>
	)
}

export default WaveTabs;

const Target = styled.div<TargetProps>`
  position: absolute;
  width: 50%;
  height: 50px;
  top: ${props => `${props.top}`};
  left: ${props => `${props.left}`};
  right: ${props => `${props.right}`};
	font-weight: ${props => `${props.fontWeight}`};
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${({ colorActive }) => {
		if (colorActive) {
			return 'var(--ion-color-text)';
		} else {
			return `var(--ion-color-main)`;
		}
	}};
`;

const ChildrenContainer = styled.div`
	background: transparent;
	position: absolute;
	z-index: 1;
`;

const Container = styled.div`
	color: white;
	font-size: 30px;
	height: 155px;
	width: 100%;
	position: relative;
`;

const Tabs = styled.div<TabsProps>`
  width: 100%;
  height: 200px;
  background: ${props => `var(--ion-color-${props.color})`};
	background: ${props => getLinearGradient(props.color)};
  clip-path: ${props => `url(${props.path})`};
  position: absolute;
  z-index: ${props => `${(props.zIndex !== undefined) ? props.zIndex : 0}`};
`;