import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonPage } from "@ionic/react";
import CommonHeader from "components/CommonHeader";
import TitleCardSimple from "components/TitleCardSimple";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { interval } from "rxjs";
import { Users } from "services/api";
import { AuthenticationService } from "services/auth";
import styled from "styled-components";
import './SetupPage.css';


const SetupPage: React.FC = () => {
	const {token} = useAuth();
	const [toast] = useToast("");
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const sendVerificationEmail = () => {
		let response;
		try {
			response = Users.requestVerificationEmail(token ?? "");
			if (response){
				response.then(function(data){
					if (data && data.success){
						toast({message: "Verification Email Sent"});
						setError(null);
					} else {
						toast({message: "Error Sending"}, true);
						setError("Verification email could not be sent")
					}
				});
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			setError("Verification email could not be sent");
		}
	}

	return (
		<IonPage>
			<IonContent className="ion-padding setup-content">
				<div style={{padding: "50px 20px 20px"}}>
					<TitleCardSimple showSubtitle={true} />
				</div>
			</IonContent>
			<PageContainer>
				<IonCard style={{width: "100%",}}>
					<IonCardHeader>
            {/* <IonCardSubtitle style={{color: 'red'}}>Required to proceed</IonCardSubtitle> */}
            <IonCardTitle>Thank you for registering with us. To log in, please verify your email by clicking the verification link in the email we've sent you.</IonCardTitle>
          </IonCardHeader>
					<IonCardContent>
						{(error) ? <Error>{error}</Error>:null}
						<Buttons>
							{/* <IonButton
								color="main"
								expand="full"
								onClick={sendVerificationEmail}
							>
								Send Verification
							</IonButton> */}
							<IonButton
								color="accent-two"
								expand="full"
								onClick={() => AuthenticationService.logout()}
							>Log Out</IonButton>
						</Buttons>
					</IonCardContent>
				</IonCard>
			</PageContainer>
		</IonPage>
	)
}

export default SetupPage;

const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0;
	width: 100%;
`;

const Buttons = styled.div`
	display: flex;
	justify-content: space-between;
	& ion-button {
		flex: 1;
	}
`;

const Error = styled.div`

`;

const TipContainer = styled.div`
	padding: 10px 16px;
	margin: 10px 0;
	background: var(--ion-color-primary-bg-shade);
	color: var(--ion-color-primary-bg-contrast);
`;

const TipTextContainer = styled.div`
	padding: 0 0 10px 0;
`;

const TipTitle = styled.div`
	padding: 0 0 10px;
	font-size: 16px;
`;

const TipDetails = styled.div`
	font-size: 13px;
	padding-bottom: 5px;
`;