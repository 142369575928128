import React, { MutableRefObject, ReactNode } from "react";
import styled from "styled-components";

interface TitleProps {
	color?: string;
	size?: string;
}

const Title = styled.h1<TitleProps>`
	font-size: ${props => props.size};
	font-family: var(--heading-font) !important;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${props => `var(--ion-color-${props.color})`};
	padding: var(--horizontal-gutter);
	margin: 0;
`;

const PageTitle: React.FC<TitleProps> = React.forwardRef(
	({color, size = '30px', children }, ref: any) => <Title ref={ref} color={color} size={size}>{children}</Title>
);

PageTitle.displayName = "PageTitle";

export default PageTitle;