import { IonButton, IonCol, IonCard, IonContent, IonItem, IonLabel, IonToggle, IonFab, IonFabButton, IonIcon, IonModal, IonPage, IonRow, IonSpinner, IonText, useIonAlert, IonSelect, IonSelectOption } from '@ionic/react';
import StarRatingComponent from 'react-star-rating-component';
import WaveHeader from '../components/WaveHeader';
import { person, location, star, diamond, storefrontSharp, checkmarkCircleOutline, starHalf, arrowForwardCircle, barbell } from 'ionicons/icons';
import useUser from '../hooks/useUser';
import PageTitle from '../components/PageTitle';
import Tag from '../components/Tag';
import { generateRandomString } from '../services/util';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import ProfileImage from '../components/ProfileImage';
import { useContext, useEffect, useState, useReducer } from 'react';
import ChallengeForm from '../components/ChallengeForm';
import Card from '../components/Card';
import ChallengesList from 'components/ChallengesList';
import useChallenges from 'hooks/useChallenges';
import VariableText from 'components/VariableText';
import WelcomeCard from 'components/WelcomeCard';
import styled from 'styled-components';
import AbsoluteSpinner from 'components/AbsoluteSpinner';
import { Users, Team,Challenges } from 'services/api';
import useToast from "hooks/useToast";
//import SportsAddPage from './SportsAddPage';
import SportsAddSettingPage from './SportsAddPage';
import CommonHeader from "../components/CommonHeader";


interface ProfilePageProps extends RouteComponentProps<{
	id?: string;
}> { }

const ProfilePage: React.FC<ProfilePageProps> = ({ match }, props) => {
	const dev = false;
	const { id } = match.params;
	const { userID } = useAuth();
	const { token } = useAuth();
	const [toast] = useToast("Hello");
	const { past } = useChallenges(id);
	const { user, userLoading } = useUser(id);
	const [showPill, setShowPill] = useState<boolean>(false);
	const [showProfileImage, setShowProfileImage] = useState<boolean>(false);
	const [showMainContent, setShowMainContent] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState(false);
	const detailsSize = "12px";
	const [sportData, setSportData] = useState<any>([]);
  const [teamData, setTeamData] = useState<any>([]);
  const history = useHistory();

	const[presentAlert] = useIonAlert();
	const [handlerMessage, setHandlerMessage] = useState('');
	const [roleMessage, setRoleMessage] = useState('');
	// let teamListResponse = Team.teamList(token ?? "");
	// console.log("Team list response", teamListResponse);
	const [creditsvalue, setUserCredit] = useState<any>(0);
  
  const getTeamDetails = (val:any) =>{
		 //console.log("Selected value", val);
		 history.push(`/team/edit/${val}`);
		 
	}

	const getSportsTags = () => {
		let sports: any = [];
		if (user && user?.sports.length > 0) {
			sports = user.sports;
		}

		// debugging
		let sports_display = [];
		if (sports.map !== undefined) {
			sports_display = sports
				.filter((sport_obj: any) => sport_obj._joinData.active)
				.map((sport_obj: any) => (
					<Tag
						key={generateRandomString()}
						textColor="main-text"
						color="main-text"
						onClick={() => {
							//dispatch({type: 'match_setSelectedSports', payload: [sport_obj.name]});
							//history.push('/results');
						}}
					>
						{sport_obj.name}
					</Tag>
				))
		}
		return sports_display;
	}


	async function sportActiveDeactive(id: any, check: any) {

		try {
			let post = { sport_id: id, active: check, user_id: user?.id };
			let response = await Users.updateSportsActiveDeactive(token ?? "", post);
			if (response) {
				toast({ message: response.message });
				getSportsDetails();
			}
		}
		catch {
			toast({ message: "Something went wrong" }, true);
		}
	}


	const getSportsRatings = () => {
		let sports: any = [];
		if (user && user?.sports.length > 0) {
			sports = user.sports;
		}
		let sports_arr = [];
		if (sports.map !== undefined) {
			sports_arr = sports
				.map((sport_obj: any) => `${sport_obj.name} ${sport_obj._joinData.rank}`);
		}
		let sports_str = sports_arr.join(' | ');
		return sports_str;
	}

	const isUserProfile = () => {
		if (userID === null) return false;
		return (id === undefined || id == userID);
	}

	const getSportsDetails = async () => {

		try {
			let response = await Users.getAllUserSportList(token ?? "");
			//let response = await Users.getSingleAllSportList(token ?? "");
			let sports = response.payload;
			setSportData(sports);
			return sports;

		} catch (error) {
			//alert("hello");
			return [];
		}
	}

  


	const teamList = async() => {
		  try {
           let teamListResponse = await Team.teamList(token ?? "");
					 //if (teamListResponse !== '') {
					 let team = teamListResponse.payload;
					 setTeamData(teamListResponse.payload);
					 //console.log("Team Data Value", teamListResponse.payload.length);
					 return team;
					// }					 
			}
			catch(error){
           return [];
			}
	}

	/*
		start at top on first render, 
		for example: when this component is used for a new user
	*/
	useEffect(() => {
		document.body.scrollTop = 0;
	}, [])



	useEffect(() => {
		setShowPill(false);
		setShowProfileImage(false);
		setShowMainContent(false);
		setUserCredit(user?.credits);

		setTimeout(() => {
			let shouldShowPill = !!(
				(user && user.preference && (user.preference.age_range || user.preference.gender)) ||
				(user && user.preference && user.preference.location) ||
				(user && user.preference && user.preference.verified_email)
			);
			setShowPill(shouldShowPill);
			setShowProfileImage(true);
			setShowMainContent(true);
			
		}, 200);
		// userRefresh();
		getSportsDetails();
		teamList();
	}, [user, id]);



  //console.log("User credit", user?.credits);
	console.log("User credits", user?.credits);
  console.log("Creditd value", creditsvalue);
	console.log("Get value type", Math.sign(creditsvalue));

	return (
		<IonPage>
			<CommonHeader title="My Profile" defaultHref="/user/profile/view" logout={true} />
			<IonContent fullscreen>
				{(userLoading) ? <AbsoluteSpinner /> : null}

				{/*
					WAVE HEADER
				*/}
				<WaveHeader color="accent-two" gradient={true} loading={userLoading}>
					<IonRow>
						<ProfileDetails>
							<PageTitle
								color="main"
							>
								<VariableText text={(user && user.short_name) ? user.full_name : ""} />
							</PageTitle>

							<DetailsPill show={showPill && !userLoading} style={{paddingTop: "14px",paddingBottom: "14px", paddingLeft: "11px", paddingRight: "11px"}}>

								{/* Only show this row if age or gender has been set*/}
								{(user && user.preference && (user.preference.age_range || user.preference.gender)) ? (
									<IonRow style={{ fontSize: detailsSize }} className="ion-align-items-center horizontal-gutter">
										<IonIcon style={{ marginRight: "5px" }} color="main" icon={person} />
										<IonText color="main" style={{fontWeight:600}}>
											{(user && user.preference && user.preference.age_range) ? user.preference.age_range : null}
											{
												(
													(user && user.preference && user.preference.age_range) &&
													(user && user.preference && user.preference.gender)
												) ? ', ' : null
											}
											{(user && user.preference && user.preference.gender) ? user.preference.gender.name : null}
										</IonText>
									</IonRow>
								) : null}

								{/* Only show this row if the location is set */}
								{(user && user.preference && user.preference.location) ?
									(
										<IonRow style={{ fontSize: detailsSize }} className="ion-align-items-center horizontal-gutter">
											<IonIcon style={{ marginRight: "5px" }} color="main" icon={location} />
											<IonText color="main" style={{fontWeight:600}}>{user && user.preference?.location}</IonText>
										</IonRow>
									) : null}

								{/* Only show this row if the users email is verified */}
								{(user && user.preference && user.preference.verified_email) ?
									(
										<IonRow style={{ fontSize: detailsSize }} className="ion-align-items-center horizontal-gutter">
											<IonIcon style={{ marginRight: "5px" }} color="main" icon={checkmarkCircleOutline} />
											<IonText color="main" style={{fontWeight:600}}>Verified Email</IonText>
										</IonRow>
									) : null}

							</DetailsPill>

						</ProfileDetails>
						<ProfileContainer show={(showProfileImage || showPill) && !userLoading}>
							{
								(user) ? (
									<ProfileImage
										icon_color="accent-two"
										icon_type={(isUserProfile()) ? "edit" : "none"}
										filename={user?.preference?.image}
										size="47%"
										minSize="50px"
										maxSize="175px"
										icon_offset="-7px"
									/>) : null
							}
						</ProfileContainer>
					</IonRow>
				</WaveHeader>




				{/*
					MAIN PROFILE BODY
				*/}
				<MainContent show={showMainContent && !userLoading}>
					<div className="horizontal-gutter" style={{ marginTop: '25px' }}>
						<IonCol className="ion-justify-content-between">
							<div>
								{/*
								THREE ROWS FOR THINGS THAT AREN'T GOING ON A CARD
							*/}
								<div className="horizontal-gutter">

									{/*
									SPORTS TAGS
								*/}
									{(!isUserProfile()) ? 
								(
									<IonRow className="ion-wrap ion-align-items-center ion-nowrap" style={{
										margin: "25px 0 12px", 
										overflowX: "scroll",
										flexWrap: 'nowrap',
										whiteSpace: 'nowrap'
									}}>
										{getSportsTags()}
									</IonRow>
								):null}

									{/*
									SPORTS RATINGS
								*/}
									{(!isUserProfile()) ? 
								(
									<IonRow className="ion-align-items-center" style={{ 
										fontSize: "15px", 
										marginBottom: "5px",
										overflowX: "scroll",
										flexWrap: 'nowrap',
										whiteSpace: 'nowrap'
									}}>
										<IonText color="main-text">
											{getSportsRatings()}
										</IonText>
									</IonRow>
								):null} 

									{/*
									USER RATING AND CREDITS
								*/}
									<IonRow style={{ fontSize: "15px" }} className="ion-align-items-center ion-justify-content-between">
										{(isUserProfile())
											?
											<IonButton
												expand="block"
												onClick={() => setModalOpen(!modalOpen)}
												style={{ margin: 14 }}
											>
												Add Sports
											</IonButton>
											: []
										}

										<IonModal isOpen={modalOpen}>
											<SportsAddSettingPage
												usertoken={token}
												getSportsDetails={getSportsDetails}
												onCloseClick={() => setModalOpen(false)}
											/>
										</IonModal>
										<StarRatingComponent
											name="user-rating"
											value={(user && user.rating) ?? 0}
											editing={false}
											starColor={"var(--ion-color-text)"}
											emptyStarColor="#777"
											renderStarIcon={() => <IonIcon icon={star} />}
											renderStarIconHalf={() => <IonIcon icon={starHalf} />}
										/>

										
										{
											(isUserProfile() && user && user.preference) ? (
												<IonRow className="ion-align-items-center" style={{ fontSize: "20px" }}>
													{/* <IonButton
														shape="round"
														color="main"
														style={{ fontSize: "12px" }}
														routerLink="/store"
													>
														<IonIcon style={{ marginRight: "15px", fontSize: "16px" }} color="constant-light" icon={diamond} />
														<IonText style={{ fontSize: "11px" }}>Buy Credits</IonText>
													</IonButton> */}
													
                           {(user?.credits==0 || (Math.sign(creditsvalue) == -1))?
                           (<IonButton
													  shape="round"
														color="main"
														style={{fontSize:"12px"}}
                            onClick={async () => {
															presentAlert({
																header: "Are you sure want to add 10 credits?",
																buttons: [
																	{
																		text: "Yes",
																		role: "Confirm",
																		handler: () => {
																	    	let userid = user.id;
																				 if(userid) {
																				     const creditresponse = Users.addcredit(token ?? "",userid);
																				     if(creditresponse){
																				      creditresponse.then(function(data:any) { 
																				          //console.log("data.success");
																				          if(data.success){
																				              toast({message: "Credits added to player account successfully."});
																											
																				          }else{
																				               toast({message: data.message});
																				          }    
																								
																				      })
																				     }
																			  }
								
																		},
																	},
																	{
																		text: "No",
																		role: "Cancel",
																		handler: () => {
																			setHandlerMessage("Alert canceled");
																		},
																	},
																],
																onDidDismiss: (e: CustomEvent) =>
																	setRoleMessage(`Dismissed with role: ${e.detail.role}`),
															})
								
																
													}}>
													<IonText style={{fontSize:"11px"}}>Add 10 Credits</IonText>
													</IonButton>): null}

													<IonIcon style={{ margin: "0 5px 0 10px", fontSize: "16px" }} color="main-text" icon={diamond} />
													<IonText style={{ fontSize: "15px" }}>{user?.credits}</IonText>
												</IonRow>
											) : null
										}
									</IonRow>
									
								
								{/* <IonItem className="form-input" data-name="title">
									<IonSelect  placeholder="Select Team" onIonChange={(e) => getTeamDetails(e.detail.value)}>
									{
									(teamData?.length > 0) ?
										(
											teamData.map((team_item: any) => (
										     <IonSelectOption value={team_item.id}>{team_item.name}</IonSelectOption>
												 ))
												 ) :<IonLabel >No teams found.</IonLabel>
									}
									</IonSelect>
                
								 </IonItem> */}
                 
								 <IonRow style={{ fontSize: "15px" }} className="ion-align-items-center ion-justify-content-between">
								 <IonButton
												expand="block"
												onClick={() => history.push('/teams')}
												style={{ margin: 14 }}
												disabled={true}
											>
												Create / View Team
									</IonButton>
	               </IonRow>

								</div>

								{/*
								WELCOME CARD
							*/}
							  
								{(
									isUserProfile() && (
										((user && user.preference) && !user.preference.verified_email)) ||
									(user && user.preference && user.preference.addresses && user.preference.addresses.length <= 0)
								) ?
									(
										<WelcomeCard user={user} />
									) : null}

								{/*
								BIO
							*/}
								{(!isUserProfile()) ?
									(
										<Card title={`Bio`}>
											<IonRow style={{ fontSize: "15px", paddingTop: '10px' }} className="ion-align-items-center">
												{user && user.preference.bio}
											</IonRow>
										</Card>
									) : null}
							</div>
							{
								(isUserProfile()) ?
								<IonCard>
								<IonItem>
									<IonLabel><b>Sports</b></IonLabel>
									<IonLabel style={{paddingLeft:"605px"}}><b>Rank</b></IonLabel>
									<IonLabel style={{ textAlign: 'right' }}><b>Inactive / Active</b></IonLabel>
								</IonItem>
								{
									(sportData && sportData) ?
										(
											sportData.map((sport_obj: any) => (
												<IonItem>
													<IonLabel>{sport_obj.sport.name}</IonLabel>
													<RankName style={{width: '31%'}}>{sport_obj.rank}</RankName>
													<IonToggle color="success"  checked={sport_obj.active} onIonChange={e => sportActiveDeactive(sport_obj.sport_id, e.detail.checked)} />
												</IonItem>
											))
										) : [null]
								}
							</IonCard>
								: null
							}
							


							{/* <IonItem>
						
              <IonLabel>Open Invite</IonLabel> 
              {<IonToggle checked={open_invite} onIonChange={e => setOpenInvite(e.detail.checked)} />
            </IonItem> */}


							{/*
							PAST CHALLENGES
						*/}
							{(!isUserProfile()) ? (
						<ChallengesList
							title="Past Challenges"
							challenges={past}
							contextual_user_id={id}
							searchPrompt={isUserProfile()}
							hideTitleOnNoResults={!isUserProfile()}
							actions={[]}
							allowViewChallenge={isUserProfile()}
							showActions={isUserProfile()}
							{...props}
						/>
						):null}

							{/*
							CHALLENGE BUTTON
						*/}
							{
								(isUserProfile() || user?.sports[0]?.name === undefined) ? null : (
									<div>

										<IonButton
											expand="block"
											onClick={() => setModalOpen(!modalOpen)}
											style={{ margin: 14 }}
										>
											Challenge
										</IonButton>

										<IonModal isOpen={modalOpen}>
											<ChallengeForm onCloseClick={() => setModalOpen(false)} user={user} />
										</IonModal>
									</div>
								)
							}

							{/*
							ACCOUNT SETTINGS BUTTON
						*/}
							{
								isUserProfile() && (
									<IonButton
										expand="block"
										routerLink="/user/profile/edit"
										style={{ margin: 14 }}
									>
										Account Settings
									</IonButton>

									
								)
							}

						</IonCol>
					</div>
				</MainContent>
				{(dev) ? (<div style={{ padding: "var(--horizontal-gutter)", height: "400px" }}>
					<IonRow><IonText>Primary Address</IonText></IonRow>
					<IonRow><IonText>{user?.preference?.primary_address?.address_line_1}</IonText></IonRow>
					<IonRow><IonText>{user?.preference?.primary_address?.address_line_2}</IonText></IonRow>
					<IonRow><IonText>{user?.preference?.primary_address?.city}, {user?.preference?.primary_address?.state} {user?.preference?.primary_address?.postal_code}</IonText></IonRow>
					<IonRow><IonText>{user?.preference?.primary_address?.lat}, {user?.preference?.primary_address?.lon}</IonText></IonRow>
				</div>) : null}
			</IonContent>
		</IonPage>

	);
};

export default ProfilePage
const RankName = styled.div`
	width:53%;
`;
const ProfileDetails = styled(IonCol)`
	flex: 2;
`;

const DetailsPill = styled.div<{ show: boolean }>`
	background: var(--ion-color-accent-one);
	letter-spacing: 0.4px;
	padding: 10px;
	padding-left: 0;
	width: max-content;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	box-shadow: 0 0 4px rgba(13,13,13,0.3);
	transition: margin-left 0.3s;
	margin-left: ${props => `${(props.show) ? "0" : "-100%"}`};
	font-family: var(--heading-font);
	& * {
		font-size: 12px;
	}
`;

const ProfileContainer = styled.div<{ show: boolean }>`
	flex: 1;
	padding-right: 3%;
	transition: margin-right 0.3s;
	margin-right: ${props => `${(props.show) ? "0" : "-100%"}`};
`;

const MainContent = styled.div<{ show: boolean }>`
	transition: margin-top 0.3s;
	margin-top: ${props => `${(props.show) ? "0" : "150%"}`}
`;

