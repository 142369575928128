import { useIonToast } from "@ionic/react";
import { close } from "ionicons/icons";

function useToast(message: string = "Hello"){
	const [present, dismiss] = useIonToast();

	/*
		TOAST OPTIONS INTERFACE

		header?: string;
		message?: string | IonicSafeString;
		cssClass?: string | string[];
		duration?: number;
		buttons?: (ToastButton | string)[];
		position?: 'top' | 'bottom' | 'middle';
		translucent?: boolean;
		animated?: boolean;

		color?: Color;
		mode?: Mode;
		keyboardClose?: boolean;
		id?: string;

		enterAnimation?: AnimationBuilder;
		leaveAnimation?: AnimationBuilder;
	*/
	const default_options = {
		message: message,
		cssClass: "standard-toast",
		duration: 3000,
		position: 'bottom',
		translucent: false,
		animated: true,
		keyboardClose: false,
		
		/*
			ION DEFAULTS APPLIED
			mode?: Mode;
			enterAnimation?: AnimationBuilder;
			leaveAnimation?: AnimationBuilder;
			id?: string;
		*/
	}

	const toast = (options_override?: any, error: boolean = false) => {
		const options: any = {
			...default_options,
			color: (error) ? 'danger':'accent-two',
			...options_override
		}
		present({
			...options,
			buttons: [{ icon: 'close', handler: () => dismiss() }],
		});
	}
	return [toast, dismiss];
}

export default useToast;