import { IonButton, IonTitle, IonInput, IonItem, IonLabel, IonList, IonRange, IonRow, IonText, IonToggle, IonTextarea, IonDatetime,IonCol } from "@ionic/react";
import { useContext, useEffect } from "react";
import useToast from "hooks/useToast";
import styled from 'styled-components';
import { FilterContext } from "../State/FilterContext";
import { useState } from "react";
import { getDateISO, joinISODateAndTime } from "services/time";
import { useHistory } from "react-router-dom";
import { Users } from 'services/api';
import useAuth from 'hooks/useAuth';
import SportsDropdown from "./SportsDropdown";
import GenderCheckboxes from "./GenderCheckboxes";
import { useParams } from "react-router";

  const AddChallengeSearch: React.FC = (id:any) => {
  //console.log("challenge id",id);
  const { token } = useAuth();
  const { state, dispatch } = useContext(FilterContext);
  const history = useHistory();
  const [event_name, setTitle] = useState<string>("");
  const [event_start, setStart] = useState<string>("");
  const [event_start_date, setStartDate] = useState<string>("");
  const [event_start_time, setStartTime] = useState<string>("");
  const [event_end_date, setEndDate] = useState<string>("");
  const [event_end_time, setEndTime] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [venue, setVenue] = useState<string>("");
  const [open_invite, setOpenInvite] = useState<boolean>(false);
  const [rules, setRules] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [toast] = useToast("");
  const [friendData,setfriendData]=useState<any>([]);


  const INITIAL = "";
  const [value, setValue] = useState(INITIAL);
  
  const newDate = new Date()
  const date = newDate.getDate();
  let dateValue:any;
  if(date < 10){
    dateValue= '0'+date;
  }else{
    dateValue = date;
  }
  const month = newDate.getMonth() + 1;
  let monthValue:any;
  if(month<10){
    monthValue = '0'+month;
  }else{
    monthValue = month;
  }
  let year = newDate.getFullYear();
  let getCurrentDate = year+'-'+monthValue+'-'+dateValue;

  // event handler for new sports selectiong
	const setSelectedSports = (selectedSports: string[]) => {
		if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sports)) {
			dispatch({ type: "match_setSelectedSports", payload: selectedSports });
		}
	}
  	// event handler for new gender selection
	const updateGenderSelection = (checked: boolean, gender_change: string) => {
		let selectedGenders = state.matchFilters.genders;
		const newGenderArr: string[] = selectedGenders;
		if (checked){
			if (newGenderArr.indexOf(gender_change) < 0){
				newGenderArr.push(gender_change);
			}
		} else {
			const index = newGenderArr.indexOf(gender_change);
			if (index > -1) {
				newGenderArr.splice(index, 1);
			}
		}
		if (JSON.stringify(selectedGenders) !== JSON.stringify(state.matchFilters.genders)){
			dispatch({type: 'match_setGenders', payload: selectedGenders});
		}
  }
  const updateAge = (e: any) => {
		dispatch({type: 'match_setAgeRange', payload: e.target.value});
	}
  
  const getFriendList = async()=> {
      try {
          let response = await Users.getFriendList(token??"");
          setfriendData(response.success);
        
      } catch (error) {
        console.log(error);
      }
     
   }

  useEffect(()=>{
    getFriendList();
    //console.log("user id cutrrent",id)
  },[friendData]);
  
  //console.log("Default sport", state.matchFilters.sports);
  //console.log("Sports value", value);
 
  return (
    <IonList lines="none">
      <SportsDropdown
				multiple={false}
				onChange={(sports:any) => {
          //console.log("challenge sports click", sports);
          setValue(sports);
					setSelectedSports(sports);
          localStorage.setItem('sportsintiallySel', sports);

				}}
				selectedSports={state.matchFilters.sports}
         />
      <TimeRow>

      </TimeRow>


      <IonItem className="form-input" data-name="location">
        <IonLabel position="stacked" style={{fontSize:"25px"}}>Location</IonLabel>
        <IonInput
          type="text"
					onIonChange={(e) => {
						dispatch({type: 'match_setOrigin', payload: e.detail.value})
					}}
					name="text"
					placeholder="(City, State) or Zip Code"
					value={(typeof state.matchFilters.origin === 'string') ? state.matchFilters.origin:""}
        />
      </IonItem>
      <IonItem>
				<RowLabel position="floating">
					<IonText style={{fontSize:"21px"}}>Distance</IonText>
					<LabelValue>({state.matchFilters.distance} miles)</LabelValue>
				</RowLabel>
				<IonRange
					pin={true}
					dualKnobs={false}
					min={0}
					max={300}
					name="distance"
					value={state.matchFilters.distance}
					onIonChange={(e) => {
						dispatch({ type: 'match_setDistance', payload: e.detail.value })
					}}
				>
					<IonText slot="start">0</IonText>
					<IonText slot="end">300</IonText>
				</IonRange>
			</IonItem>

      <IonItem>
				<RowLabel position="floating">
					<IonText style={{fontSize:"21px"}}>Age</IonText>
					<LabelValue>{(
						state.matchFilters.ageRange &&
						state.matchFilters.ageRange.lower &&
						state.matchFilters.ageRange.upper) ? 
						`(${state.matchFilters.ageRange.lower} - ${state.matchFilters.ageRange.upper})`:null}</LabelValue>
				</RowLabel>
				<IonRange pin={true} dualKnobs={true} min={18} max={100} name="age" value={state.matchFilters.ageRange} onIonChange={updateAge}>
					<IonText slot="start">18</IonText>
					<IonText slot="end">100</IonText>
				</IonRange>
			</IonItem>
      <GenderCheckboxes
				selectedGenders={state.matchFilters.genders}
				onChange={updateGenderSelection}
			/>
      
      <IonRow>
        <IonCol size="2"  >
        </IonCol>
        <IonCol size="3"  >
        <IonButton
        color="main"
        expand="block"
        style={{ margin: 14,width:"125px"}}
				//routerLink="/results"
				onClick={(e) => {
          e.preventDefault();
          //console.log("value", value); return;
          if(value==''){
              toast({message:"A sport needs to be chosen."});  
              return;
          }
          else{
            history.push('/results');
          }
          
          //console.log("Sport value",localStorage.getItem('sportsintiallySel')); return;
          //console.log("match_create_data",state.matchFilters);
					dispatch({type: 'setSearchType', payload: 'match_create'});
				}}
      >
        OPPONENT
      </IonButton>
        </IonCol>
        <IonCol size="2"  >
        </IonCol>
        <IonCol size="3">

    <IonButton
        color="main"
        expand="block"
        style={{ margin: 14, width:"125px"}}
        onClick={(e) => {
          e.preventDefault();
          //console.log("Sports Value", value);
          if(value==''){
              toast({message: "A sport needs to be chosen."});
              return;
          }
          else
          {
            history.push("/createopenchallenge");
          }
          
        }}
      >
        OPEN
      </IonButton>
        </IonCol>
        <IonCol size="2"  >
        </IonCol>
      </IonRow>
      
    </IonList>
  )
}

export default AddChallengeSearch;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	--width: 100% !important;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;
