import { IonPage, IonContent, IonSpinner, IonRow } from "@ionic/react";
import LoadingContent from "components/LoadingContent";
import './LoadingPage.css';

const LoadingPage: React.FC = () => {
  return (
		<IonPage>
			<LoadingContent />
    </IonPage>
  )
}

export default LoadingPage;