const VariableText: React.FC<{ 
  text: string,
  min?: number,
  max?: number,
  charsAtMin?: number,
  charsAtMax?: number
}> = ({text = "", min = 12, max = 28, charsAtMin = 15, charsAtMax = 5}) => {
  const calculateFontSize = (
    text: string, 
    min: number,
    max: number,
    charsAtMin: number,
    charsAtMax: number,
  ) => {
    let text_arr = text.trim().split('');
    let len      = text_arr.length;
    let sizeDiff = max - min;
    let lenDiff  = charsAtMin - charsAtMax;
    let multiplier = len / lenDiff;
    let offsetFromMax = (sizeDiff * multiplier - min);
    let size = max - offsetFromMax;
    if (size < min) size = min;
    if (size > max) size = max;
    return size;
  }
  return (
    <div style={{fontSize: `${calculateFontSize(text, min, max, charsAtMin, charsAtMax)}px`}}>
      {text}  
    </div>
  )  
}
export default VariableText;