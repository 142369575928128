import { IonList, IonItem, IonThumbnail, IonSkeletonText, IonLabel } from "@ionic/react"
import { generateRandomString } from "services/util";
import styled from 'styled-components';

const SkeletonList: React.FC<{num: number}> = ({num}) => {
	return (
		<IonList>
			{(Array(num).fill(null).map(each => <SkeletonItem key={generateRandomString()} />))}
		</IonList>
	)
}

export default SkeletonList;

const SkeletonItem: React.FC = () => {
	return (
		<IonItem lines="none">
				<Icon slot="start">
					<IonSkeletonText animated />
				</Icon>
				<IonLabel>
					<h3>
						<IonSkeletonText animated style={{ width: '50%', height: "20px" }} />
					</h3>
				</IonLabel>
		</IonItem>
	)
}

const Icon = styled(IonThumbnail)`
	width: 40px;
	height: 40px;
	--border-radius: 100px;
`;