import { IonButton, IonIcon, IonRouterLink, IonRow, IonSpinner, IonText} from "@ionic/react";
import useAuth from "hooks/useAuth";
import useDarkTheme from "hooks/useDarkTheme";
import useStateWithPromise from "hooks/useStateWithPromise";
import { location, map } from "ionicons/icons";
import { ReactNode, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Challenges,Event } from "services/api";
import styled, { css } from "styled-components";
import useAsyncEffect from "use-async-effect";
import { getTimeParts } from "../services/time";
import { generateRandomString, truncate } from "../services/util";
import useToast from "hooks/useToast";

interface EventsProps {
	event: Event
	contextual_user_id: string
	actions?: ActionButtonProps[]
	issuer: User | undefined
	recipients: User[]
	isContextUserIssuer: boolean
	boxType: FillType
	boxTextColorOverride?: string
	boxColorOverride?: string
	dotColorOverride?: string
	allowViewChallenge: boolean
	refreshChallenges: () => void
	showActions?: boolean
}

const Events: React.FC<EventsProps> = ({
	event,
	contextual_user_id,
	isContextUserIssuer,
	boxType,
	refreshChallenges,
	showActions = true
  }) => {

	let history = useHistory();
	const { token, userID } = useAuth();
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useStateWithPromise<boolean>(true);
	const [actionNodes, setActionNodes] = useState<ReactNode[]>();
	const [events_id, setEventsId] = useState<string>("");
	const [toast] = useToast("Hello");
	const [data, setData] = useState<any>([]);
	const [timeObj, setTimeObj] = useState({
		weekday_name: "Day",
		month_name: "Mon",
		month_day: "00",
		time_12: "00:00 AM"
	});

	const getActionButtons = (): ReactNode[] => {

		let buttons: ReactNode[] = [];
		if (event.status === "Pending") {

			// buttons.push(makeActionButton({
			// 	name: "Modify",
			// 	function: (events_id) => {
			// 		history.push({
			// 			pathname: `/event/edit/${events_id}`,
			// 			state: {
			// 				events_id
			// 			}
			// 		});
			// 	},
			// 	type: 'solid' as FillType, color: "main", textColor: 'constant-light'
			// }))

		} else if (event.status === 'Upcoming') {

			// buttons.push(makeActionButton({
			// 	name: "Cancel",
			// 	function: async (events_id) => {
			// 		let response = await Event.cancelEvent(token ?? "", events_id);
			// 		console.log('response_data',response.success);
			// 		if(response.success===true){
			// 		toast({ message: "Tournaments cancel successfully." });
      //     }
			// 		refreshChallenges()
			// 	},
			// 	type: 'solid' as FillType,
			// 	color: "danger" as ColorType,
			// 	textColor: 'constant-light' as ColorType
			// }))

		} else if (event.status === 'Cancelled') {

			buttons.push(makeActionButton({
				name: "Cancelled",
				type: 'solid' as FillType, color: 'danger', textColor: 'constant-light'
			}))

		} else if (event.status === 'Dismissed') {

			buttons.push(makeActionButton({
				name: "Dismissed",
				type: 'solid' as FillType, color: 'danger', textColor: 'constant-light'
			}))

		} else if (event.status === 'Reporting') {

			let found_user = event.users.find(user => user.id === contextual_user_id);
			let already_reported = (found_user && found_user?._joinData?.reported_winner_id !== null);
			if (!already_reported) {
				buttons.push(makeActionButton({
					name: "Report",
					function: () => {
						setModalOpen(true);
					},
					type: 'solid' as FillType, color: "main", textColor: 'constant-light'
				}))
			} else {
				buttons.push(makeActionButton({
					name: "Reported",
					function: () => { },
					type: 'solid' as FillType, color: "main", textColor: 'constant-light',
					disabled: true
				}))
			}

		} else if (event.status === 'Complete') {

			buttons.push(makeActionButton({
				name: "Complete",
				function: (id) => {
					history.push({
						pathname: `/event/view/${id}`
					});
				},
				type: 'solid' as FillType, color: "success", textColor: 'constant-light'
			}))

		}

		return buttons;
	}

	const makeActionButton = (action: ActionButtonProps) => (
		<ActionButton
			key={generateRandomString()}
			onClick={() => { action.function && action.function(event.id) }}
			routerLink={action.href}
			fill={action.type}
			color={action.color}
			size="small"
			disabled={action.disabled || !action.function}
		>
			{action.name}
		</ActionButton>
	);
	useEffect(() => {
		if (JSON.stringify(event)) {
			setTimeObj(getTimeParts(event.start_date));
			setEventsId(event.id);
			setIsLoading(false);
		}
		setActionNodes(getActionButtons());
	}, [event,token])

	useAsyncEffect(async (isMounted) => {


	}, [event, contextual_user_id, isContextUserIssuer,token])

	return (isLoading) ? (
		<IonRow style={{ padding: '15px', justifyContent: 'center' }}>
			<IonSpinner name="dots" color="accent-two" />
		</IonRow>
	) : (
					<Col style={{ padding: '10px 0 0' }}>
			<Row style={{ fontSize: '15px' }}>
				<Col>
					<Dot
						size={7}
						//color={"danger"}
						color=""
						style={{backgroundColor:"#FCB83E"}}
					/>
				</Col>
				
				<Col width="65px" align="center" style={{ padding: "0 6px 0 0", fontSize: "12px", textAlign: "center", letterSpacing: "0.2px" }}>
					<Row justify="center"><strong>{timeObj.weekday_name}</strong></Row>
					<Row justify="center">{timeObj.month_name}<span style={{ width: "5px" }}></span><strong>{timeObj.month_day}</strong></Row>
					<Row justify="center" style={{ "whiteSpace": "nowrap" }}>{timeObj.time_12}</Row>
				</Col>

				<Col flex={1}>
					<ColoredBox
						type={boxType}
						color=""
						style={{backgroundColor:"#FCB83E"}}
						textColor={"black"}
						onClick={() => {
							if (event && event.id) {
								history.push({
									pathname: `/event/view/${event?.id}`
								});
							}
						}}
					>


						<Row style={{ paddingTop: "21px" }}>
							<Col>
							<Row>
									<IonText style={{marginLeft: "10px" }}><strong>Event Name</strong></IonText>
							</Row>
							<Row>
									<IonText style={{marginLeft: "10px" }}><strong>{event?.event_name ?? ""}</strong></IonText>
								</Row>
								{(event.venue) ?
									<Row>
										<IonText style={{marginLeft: "10px" }}><b>Venue:</b></IonText>
										<IonText style={{ fontSize: "0.8rem" }}>{event.venue}</IonText>
									</Row>
									: null
								}

							</Col>
							{/* <AbsoluteBox>
								{(event.venue) ? (
									<Pill>Ranked</Pill>
								) : null}

							</AbsoluteBox> */}

						</Row>
					</ColoredBox>
				</Col>
			</Row>
			<Row justify="flex-end">
				{(showActions) ? actionNodes : null}
			</Row>
		</Col>

		

		 
	)
}

export default Events;

const Row = styled.div<{ justify?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;

const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
	width: ${(props: any) => (props.width) ? props.width : 'auto'};
	flex: ${(props: any) => (props.flex) ? props.flex : 'unset'};
	align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;

const Dot = styled.div<{ size: number, color: string | undefined }>`
	height: ${(props: any) => `${props.size}px`};
	width: ${(props: any) => `${props.size}px`};
	background: var(--ion-color-${props => props.color});
	border-radius: 50px;
	border: 2px solid var(--ion-color-${props => props.color});
	margin: 5px;
`;

const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: ${props => `var(--ion-color-${props.textColor})`};
	${props => (props.type === 'outline') ? css` 
		border: 2.5px solid var(--ion-color-${props.color});` : css`
		background: var(--ion-color-${props.color});
	`}
`;


const ActionButton = styled(IonButton)`
	--padding-top: 0;
	--padding-right: 0;
	--padding-bottom: 0;
	--padding-left: 0;
`;

