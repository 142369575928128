import { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { DynamicInputs } from "../services/api";
import useAuth from "./useAuth";

function useDynamicInputs(id = null){
	const {token} = useAuth();
	const [sports, setSports] = useState<Sport[]>([]);
	const [genders, setGenders] = useState<Gender[]>([]);
	const [addresses, setAddresses] = useState<Address[]>();
	const [gendersStringsToObjects, setGendersStringsToObjects] = useState<(genders: string[]) => Gender[]>(() => (genders_strs: string[]) => {
		return _genderStringsToObjects(genders_strs, genders);
	});
	const [sportsStringsToObjects, setSportsStringsToObjects] = useState<(sports: string[]) => Sport[]>(() => (sports_strs: string[]) => {
		return _sportsStringsToObjects(sports_strs, sports);
	})


	useAsyncEffect(async () => {
		refresh();
		return () => {
			DynamicInputs.getSportsCancel();
			DynamicInputs.getGendersCancel();
			DynamicInputs.getUsersAddressesCancel();
		}
	}, [token])

	const _sportsStringsToObjects = (strings: string[], sports: Sport[]): Sport[] => {
		if (strings.map !== undefined){
			let converted: Sport[] = [];
			strings.forEach((sport_str: string) => {
				let sport_obj = sports.find((sport_obj: Sport) => sport_obj.name === sport_str);
				if (sport_obj){
					converted.push(sport_obj);
				}
			});
			return converted;
		} else {
			return [];
		}
	}

	const _genderStringsToObjects = (strings: string[], genders: Gender[]): Gender[] => {
		if (strings.map !== undefined){
			let converted: Gender[] = [];
			strings.forEach((gender_str: string) => {
				let gender_obj = genders.find((gender_obj: Gender) => gender_obj.name === gender_str);
				if (gender_obj){
					converted.push(gender_obj);
				}
			});
			return converted;
		} else {
			return []
		}
	}

	const refresh = async () => {
		if (token){

			// sports
			let sports = await DynamicInputs.getSports(token ?? "");
			if (sports){
				setSports(sports);
				setSportsStringsToObjects(() => (sports_strs: string[]) => {
					return _sportsStringsToObjects(sports_strs, sports);
				});
			}

			// genders
			let genders = await DynamicInputs.getGenders(token ?? "");
			if (genders){
				setGenders(genders);
				setGendersStringsToObjects(() => (genders_strs: string[]) => {
					return _genderStringsToObjects(genders_strs, genders);
				})
			}

			// addresses
			let addresses = await DynamicInputs.getUserAddresses(token ?? "");
			if (addresses){
				setAddresses(addresses.payload);
			}

		}
	}

	const DynamicInputInformation = {
		sports,
		genders,
		addresses,
		refreshInputs: refresh,
		sportsStringsToObjects,
		gendersStringsToObjects
	};
	
	return DynamicInputInformation;
}

export default useDynamicInputs;