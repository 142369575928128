import { IonButton, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel,IonText, IonList, IonPage, IonTextarea, IonTitle, IonToggle, IonToolbar } from "@ionic/react";
import PillRadios from "components/PillRadios";
import UsersSelect from "components/UsersSelect";
import useAuth from "hooks/useAuth";
import useDynamicInputs from "hooks/useDynamicInputs";
import useToast from "hooks/useToast";
import useUser from "hooks/useUser";
import { handRight } from "ionicons/icons";
import { useState } from "react";
import { Tournaments } from "services/api";
import { getDateISO, joinISODateAndTime } from "services/time";
import styled from "styled-components";

import TournamentPromptButton from "components/TournamentPromptButton";
import { useHistory } from "react-router-dom";
import useTournaments from "hooks/useTournaments";
import PageTitle from "components/PageTitle";
import TournamentsList from "components/TournamentsList";
import CenteredSpinner from "components/CenteredSpinner";
import ErrorMessage from "components/ErrorMessage";

const TournamentCreate: React.FC<{
  onCloseClick: () => void
}> = ({ onCloseClick }) => {
  const history = useHistory();
  const { user } = useUser();
  const { token } = useAuth();
  const { sports } = useDynamicInputs();
  const [title, setTitle] = useState<string>("");
  const [sport, setSport] = useState<string>(user && user.sports[0]?.name || "");
  const [tournament_start, setStart] = useState<string>("");
  const [tournament_start_date, setStartDate] = useState<string>("");
  const [tournament_start_time, setStartTime] = useState<string>("");
  const [tournament_end_date, setEndDate] = useState<string>("");
  const [tournament_end_time, setEndTime] = useState<string>("");
  const [tournament_end, setEnd] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [venue, setVenue] = useState<string>("");
  const [open_invite, setOpenInvite] = useState<boolean>(false);
  const [selected, setSelected] = useState<User[]>([]);
  const [slots, setSlots] = useState<string>("3");
  const [rules, setRules] = useState<string>("");
  const [toast] = useToast("Hello");
  const[max,  setMax] = useState(500);
  const[min, setMin] = useState(500);
  const {
		tournaments, tournamentsLoading, tournamentsError,
		organizing, organizingLoading, organizingError,
		refreshTournaments, pollTournaments} = useTournaments();
    const {userID} = useAuth();

  
  const newDate = new Date()
  const date = newDate.getDate();
  let dateValue:any;
  if(date < 10){
    dateValue= '0'+date;
  }else{
    dateValue = date;
  }
  const month = newDate.getMonth() + 1;
  let monthValue:any;
  if(month<10){
    monthValue = '0'+month;
  }else{
    monthValue = month;
  }
  let year = newDate.getFullYear();
  let getCurrentDate = year+'-'+monthValue+'-'+dateValue;
  //console.log("Current date value", getCurrentDate);


  const getMaxAsNumber = () => {
    if (!isNaN(parseInt(slots))) {
      return parseInt(slots);
    } else {
      return null;
    }
  }

  // Function is called everytime increment button is clicked
	const increment = () => {
		// Counter state is incremented
		setMax(max + 10)
	}

	// Function is called everytime decrement button is clicked
	const decrement = () => {
		// Counter state is decremented
		setMin(min - 10)
	}


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Create Tournament
          </IonTitle>
          <IonIcon
            size="large"
            style={{
              marginRight: "var(--toolbar-icon-right-margin)"
            }}
            icon="close"
            slot="end"
            onClick={onCloseClick}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem className="form-input" data-name="sport">
            <IonLabel>Sport</IonLabel>
            <PillRadios
              options={(sports.map((each: Sport) => each.name)) ?? []}
              value={sport}
              onChange={(selectedSport: string) => setSport(selectedSport)}
            />
          </IonItem>
          <IonItem className="form-input" data-name="title">
            <IonLabel position="stacked">Title</IonLabel>
            <IonInput
              name="title"
              type="text"
              placeholder="Tournament Title"
              value={title}
              onIonChange={(e) => {
                setTitle(e.detail.value as string);
              }}
            />
          </IonItem>
          <TimeRow>
            <IonItem className="form-input" data-name="tournament_start_date">
              <IonLabel position="stacked">Start Date</IonLabel>
              <IonDatetime
                name="tournament_start_date"
                min={getCurrentDate}
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                value={tournament_start_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({ localDate });
                  setStartDate(localDate);
                  setEndDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="tournament_start_time">
              <IonLabel position="stacked">Start Time</IonLabel>
              <IonDatetime
                minuteValues="0,10,20,30,40,50"
                name="tournament_start_time"
                displayFormat="h:mm A"
                placeholder="Select Start Time"
                value={tournament_start_time}
                onIonChange={e => {
                  let localTime = e.detail.value!;
                  console.log({ localTime });
                  setStartTime(localTime);
                }}
              ></IonDatetime>
            </IonItem>
          </TimeRow>
          <TimeRow>
            <IonItem className="form-input" data-name="tournament_end_date">
              <IonLabel position="stacked">End Date</IonLabel>
              <IonDatetime
                name="tournament_end_date"
                min={tournament_start_date}
                displayFormat="MMM DD YYYY"
                placeholder="Select End Date"
                value={tournament_end_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  setEndDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="tournament_end_time">
                <IonLabel position="stacked">End Time</IonLabel>
                <IonDatetime
                  name="tournament_end_time"
                  displayFormat="h:mm A"
                  placeholder="Select End Time"
                  value={tournament_end_time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setEndTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
          </TimeRow>
          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked">Location</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="City, State, or Zip Code"
              value={location}
              onIonChange={(e) => {
                setLocation(e.detail.value as string);
              }}
            />
          </IonItem>
          <IonItem className="form-input" data-name="title">
            <IonLabel position="stacked">Venue</IonLabel>
            <IonInput
              name="title"
              type="text"
              placeholder="Venue"
              value={venue}
              onIonChange={(e) => {
                setVenue(e.detail.value as string);
              }}
            />
          </IonItem>

        <IonItem>
          <IonLabel>Rating</IonLabel>
          <IonText>{max}</IonText>
          <IonButton color="main" fill="clear" onClick={() => { increment() }}>+</IonButton>/
          <IonButton color="main" fill="clear" onClick={() => { decrement() }}>-</IonButton>
          <IonText>{min}</IonText>
        </IonItem>

          <IonItem>
            <IonLabel>Open Invite</IonLabel>
            <IonToggle checked={open_invite} onIonChange={e => setOpenInvite(e.detail.checked)} />
          </IonItem>
          <IonItem>
            <IonLabel>Slots</IonLabel>
            <IonInput
              slot="end"
              name="slots"
              min='1'
              type="number"
              value={slots}
              onIonChange={(e) => {
                if(e.detail.value=='0'){
                  setSlots('1');
                }else{
                  setSlots(e.detail.value as string);
                }
              }}
              style={{ textAlign: 'right', marginRight: '25px' }}
            ></IonInput>
          </IonItem>

          <IonItem className="form-input" data-name="rules">
            <IonLabel position="stacked">Rules</IonLabel>
            <IonTextarea 
             name="rules"
             placeholder="Rules"
             value={rules}
             auto-grow={false}
             rows={3}
             spellCheck={true}
             onIonChange={(e) =>{
               setRules(e.detail.value as string);
             }}
            
            ></IonTextarea>
          </IonItem>

          <IonItem className="form-input" data-name="message">
            <IonLabel position="stacked">Message</IonLabel>
            <IonTextarea
              name="message"
              placeholder="Message"
              value={message}
              auto-grow={false}
              rows={6}
              spellCheck={true}
              onIonChange={e => setMessage(e.detail.value!)}
            ></IonTextarea>
          </IonItem>
          
          <UsersSelect selected={selected} setSelected={setSelected} max={getMaxAsNumber()} />
          <IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (!e.currentTarget) return;
              e.preventDefault();

              if (!sport) {
                toast({ message: 'Select a sport' }, true);
                return;
              }

              if (!title) {
                toast({ message: 'Set a title' }, true);
                return;
              }

              if (!location) {
                toast({ message: 'Add a location' }, true);
                return;
              }

              if (!venue) {
                toast({ message: 'Add a venue' }, true);
                return;
              }

              if(!rules){
                toast({ message: 'Add rule'}, true);
                return;
             }


              if (!(tournament_start_date && tournament_start_time)) {
                toast({ message: 'Start data & time is required' }, true);
                return;
              }
              const tournament_start = joinISODateAndTime(tournament_start_date, tournament_start_time);

              if (!(tournament_end_date && tournament_end_time)){
                toast({message: 'End data & time is required'}, true);
                return;
              }
              const tournament_end = joinISODateAndTime(tournament_end_date, tournament_end_time);
            
              let args = {
                title,
                open_invite,
                sport,
                location,
                venue,
                tournament_start,
                tournament_end,
                message,
                selected,
                slots,
                rules
              }
              //console.log('tournament create args', args);
              let response = Tournaments.create(token ?? "", args);
              if (response) {
                response.then(function (data) {
                  if (data && data.success) {
                    <div>
                    {
                      (!organizingError && !organizingLoading && organizing.length > 0) ?
                      <div>
                        <PageTitle size="15px">Organizing</PageTitle>
                        <TournamentsList tournaments={organizing} user_id={userID} organizing_status={"organizing"}/>
                      </div>:null
                    }
                    {
                      (tournamentsLoading) ? (
                        <CenteredSpinner />
                      ):((tournamentsError) ? (
                        <ErrorMessage retryMethod={refreshTournaments} >{tournamentsError}</ErrorMessage>
                      ):(tournaments && tournaments.length > 0) ? (
                        <div>
                          <PageTitle size="15px">Participating</PageTitle>
                          <TournamentsList tournaments={tournaments} user_id={userID} organizing_status={"participating"}/>
                        </div>
                      ):null)
                    }
          
                    {
                      (
                        !(!organizingError && !organizingLoading && organizing.length > 0) &&
                        !(tournamentsLoading) &&
                        !(tournamentsError) &&
                        !(tournaments && tournaments.length > 0)
                      ) ? (
                        <NoneMessage>
                          <h1>No Tournaments</h1>
                          <div>
                            Search your area for<br />open tournaments to join
                          </div>
                          <TournamentPromptButton />	
                        </NoneMessage>
                      ):null
                    }
                  </div>
                    toast({ message: "Tournament Created" });
                    onCloseClick();

                  } else {
                    toast({ message: "Problem Creating Tournament" }, true);
                  }
                })
              }
            }}
          >
            Submit
          </IonButton>
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export default TournamentCreate;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;

const NoneMessage = styled.div`
	text-align: center;
	padding: var(--horizontal-gutter);	
	& h1 {
		font-size: 30px;
		font-family: var(--heading-font);
	}
	& div {
		padding-bottom: 20px;
	}
`;