import { IonButton, IonTitle, IonInput, IonItem, IonLabel, IonList, IonRange, IonRow, IonText, IonToggle, IonTextarea, IonDatetime,IonCol,IonIcon,IonRadioGroup,IonRadio,IonSelect,IonSelectOption } from "@ionic/react";
import { useContext, useEffect } from "react";
import useToast from "hooks/useToast";
import styled, { css } from "styled-components";
import { FilterContext } from "../State/FilterContext";
import { useState } from "react";
import { getDateISO, joinISODateAndTime } from "services/time";
import { useHistory } from "react-router-dom";
import { Users,DynamicInputs } from 'services/api';
import useAuth from 'hooks/useAuth';
import SportsRadio from "components/SportsRadio";
import ProfileImage from "components/ProfileImage";
import StarRatingComponent from 'react-star-rating-component';
import {star,starHalf,person, reloadOutline } from "ionicons/icons";
import { Challenges } from "services/api";
import SportsDropdown from "./SportsDropdown";

import { generateRandomString } from "../services/util";
import ReportTournament from "./ReportTournament";

interface AddChallengeProps {
  id:number;
}

 const AddChallenge: React.FC<AddChallengeProps> = ({ id }) => {
  const { token } = useAuth();
  const { state, dispatch } = useContext(FilterContext);
  const history = useHistory();
  const [challenge_name, setTitle] = useState<string>("");
  const [challenge_start, setStart] = useState<string>("");
  const [challenge_start_date, setStartDate] = useState<string>("");
  const [challenge_start_time, setStartTime] = useState<string>("");
  const [challenge_end_date, setEndDate] = useState<string>("");
  const [challenge_end_time, setEndTime] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [locstate, setLocationState] = useState<string>("");
  const [zip, setLocationZip] = useState<string>("");

  const [venue, setVenue] = useState<string>("");
  const [open_invite, setOpenInvite] = useState<boolean>(false);
  const [rules, setRules] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [toast] = useToast("Hello");
  const [challengeUserData,setChallengeUserData]=useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [ranked, setRanked] = useState<boolean>(false);
  //const [sportFinal, setSports] = useState<any>(state.matchFilters.sports);
  //console.log("selected open sports", state.matchFilters.sports);

  const [sports, setSports] = useState<string>("");
  const [activesports, setActiveSport] = useState<any>([]);

  const newDate = new Date()
  const date = newDate.getDate();
  let dateValue:any;
  if(date < 10){
    dateValue= '0'+date;
  }else{
    dateValue = date;
  }
  const month = newDate.getMonth() + 1;
  let monthValue:any;
  if(month<10){
    monthValue = '0'+month;
  }else{
    monthValue = month;
  }
  let year = newDate.getFullYear();
  let getCurrentDate = year+'-'+monthValue+'-'+dateValue;

  // event handler for new sports selectiong
	// const setSelectedSports = (selectedSports: string[]) => {
	// 	if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sportsFinal)) {
	// 		dispatch({ type: "match_setSelectedSportsFinal", payload: selectedSports });
  //     setSports(state.matchFilters.sportsFinal)
	// 	}
	// }
  
  const getChallengeUserdata = async()=> {
      try {
          let response = await Users.getUser(token??"",id);
          setChallengeUserData(response.payload);
      } catch (error) {
        console.log(error);
      }
     
  }

  useEffect(()=>{
    if(id!=undefined){
      getChallengeUserdata();
    }
    
  },[token,id]);


 var getAllSportValue = challengeUserData?.sports;

 const getSportList = async() => {
     try{
        let sportList = DynamicInputs.getSports(token??"");
        sportList.then(function(data) {
          if(data){
            console.log("Active sports", data);
            setActiveSport(data);

          }
        })
         
     }catch(error) {
          console.log(error);
     }
 }

 useEffect(() => {
     getSportList();
 }, [token])


 //get sports intially selected from local storage
 const getSportsIntailValue = localStorage.getItem('sportsintiallySel');
 //console.log("Intail value", getSportsIntailValue);

 return (
    <IonList lines="none">
      {(challengeUserData?.id) ?
      (
      <IonCol size="6">
      <Col style={{ padding: '10px 0 0' }} >
                  <Row style={{ fontSize: '15px' }}>
                    <Col flex={1} style={{background: '#caee6f'}}>
                      <ColoredBox
                        type={'outline'}
                        color={"#caee6f"}
                        textColor={"dark"}
                        onClick={() => {
                          if (challengeUserData && challengeUserData?.id) {
                            history.push({
                              pathname: `/user/friendlist/view/${challengeUserData?.id}`
                            });
                          }
                        }}
                      >
                    <ProfileImage
                        icon_type="none"
                        size="75px"
                        filename={challengeUserData?.preference?.image ?? ""}
                        style={{ marginRight: '15px' }}
                        />
                        <Row style={{ paddingTop: "21px" }}>
                          <Col>
                            <Row>
                              <StarRatingComponent
                                name="user-rating"
                                value={challengeUserData?.rating ?? ""}
                                editing={false}
                                starColor={"var(--ion-color-text)"}
                                emptyStarColor="#777"
                                renderStarIcon={() => <IonIcon icon={star} />}
                                renderStarIconHalf={() => <IonIcon icon={starHalf} />}
                              />
                            </Row>
                            <Row>
                              <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={person} />
                              <IonText><strong>{challengeUserData?.full_name ?? ""}</strong></IonText>
                            </Row>
                            
                            {/* {(challengeUserData.preference?.distance_miles) ?
                            (<IonRow style={{ paddingTop: '1px' }}>
                              <IonText style={{ fontSize: '15px' }}>{challengeUserData.preference?.distance_miles} miles away</IonText>
                            </IonRow>)
                            : null} */}

                          </Col>

                        </Row>
                      </ColoredBox>
                    </Col>
                  </Row>

                </Col>
                </IonCol>
           ): null}
             
             {/* <SportsDropdown
                multiple={false}
                onChange={(sports) => {
                  setSelectedSports(sports);
                }}
                selectedSports={sportFinal}
              /> */}

        {/* <IonItem>
              <SportsRadio
                selectedSports={sports}
                onChange={setSports}
              />
        </IonItem> */}

       {(id) ?(
        <IonItem>
          {/* <IonLabel position="stacked">Sports</IonLabel>
          <IonRadioGroup name="sport" value={getSportsIntailValue}  onIonChange={(e) => {
            setSports(e.detail.value as string);
          }}>

          {getAllSportValue?.map((user: any, index: any) => (
            <IonItem key={generateRandomString()}>
              <IonLabel>{user.name}</IonLabel>
              <IonRadio slot="end" value={user.name} name="sport"/>
            </IonItem>

          ))}
          </IonRadioGroup> */}

          <IonLabel position="stacked" style={{fontSize:"21px"}}>Sports</IonLabel>
          <IonItem className="form-input"  data-name="title" style={{width:'200px'}}>
							<IonSelect value={getSportsIntailValue} placeholder={getSportsIntailValue?getSportsIntailValue:"Select Sports"}  onIonChange={(e) => {
                  setSports(e.detail.value as string);
              }}>
										{getAllSportValue?.map((value:any) => (
										    <IonSelectOption  value={value.name}>{value.name}</IonSelectOption>
										))}
							</IonSelect>
					</IonItem>
        {/* <IonLabel position="stacked">Sports111</IonLabel>
        <IonItem className="form-input" data-name="title">			
              <select>
                <option>Select</option>
                {getAllSportValue?.map((value:any) => (
                    <option selected={getSportsIntailValue==value.name}>{value.name}</option>
                ))}
              </select>
              </IonItem> */}
          

        </IonItem>
       ): 
       <IonItem>
       {/* <IonLabel position="stacked">Sports</IonLabel>
         <IonRadioGroup name="sport" value={getSportsIntailValue}  onIonChange={(e) => {
             setSports(e.detail.value as string);
          }}>
          {activesports?.map((user: any, index: any) => (
            <IonItem key={generateRandomString()}>
              <IonLabel>{user.name}</IonLabel>
              <IonRadio slot="end" value={user.name} name="sport"/>
            </IonItem>

          ))}
       </IonRadioGroup> */}
       <IonLabel position="stacked" style={{fontSize:"21px"}}>Sports</IonLabel>
          <IonItem className="form-input" data-name="title">
							<IonSelect value={getSportsIntailValue} placeholder={getSportsIntailValue?getSportsIntailValue:'Select Sports'}  onIonChange={(e) => {
                 setSports(e.detail.value as string);
          }}>
										{activesports.map((value:any) => (
										    <IonSelectOption value={value.name}>{value.name}</IonSelectOption>
										))}
							</IonSelect>
					</IonItem>

     </IonItem>}

      
      <TimeRow>
        <IonItem className="form-input" >
          <IonLabel position="stacked" style={{fontSize:"22px"}}>Start Date</IonLabel>
          <IonDatetime
                name="date"
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                min={getCurrentDate}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  setStartDate(localDate);
                  setEndDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
        </IonItem>
        <IonItem className="form-input" >
          <IonLabel position="stacked" style={{fontSize:"22px"}}>Start Time</IonLabel>
          <IonDatetime
            minuteValues="0,10,20,30,40,50"
            name="challenge_set_time"
            displayFormat="h:mm A"
            placeholder="Select Start Time"
            value={challenge_start_time}
            onIonChange={e => {
              let localTime = e.detail.value!;
              setStartTime(localTime);
              //setEndTime(localTime);
            }}
          ></IonDatetime>
        </IonItem>
      </TimeRow>
      
      <TimeRow style={{display:"none"}}>
        <IonItem className="form-input" >
          <IonLabel position="stacked" style={{fontSize:"18px"}}>End Date</IonLabel>
          <IonDatetime
            name="challenge_end_date"
            min={challenge_start_date}
            displayFormat="MMM DD YYYY"
            placeholder="Select Start Date"
            value={challenge_end_date}
            onIonChange={e => {
              let localDate = e.detail.value!;
              setEndDate(localDate);
            }}
            max={(() => {
              let d = new Date();
              let year = d.getFullYear();
              let next_year = year + 1;
              return String(next_year);
            })()}
          />
        </IonItem>
        <IonItem className="form-input" >
          <IonLabel position="stacked" style={{fontSize:"18px"}}>End Time</IonLabel>
          <IonDatetime
            minuteValues="0,10,20,30,40,50"
            name="challenge_end_time"
            displayFormat="h:mm A"
            placeholder="Select Start Time"
            value={challenge_end_time}
            onIonChange={e => {
              let localTime = e.detail.value!;
              setEndTime(localTime);
            }}
          ></IonDatetime>
        </IonItem>
      </TimeRow>


      {/* <IonItem className="form-input" data-name="location">
        <IonLabel position="stacked" style={{fontSize:"18px"}}>Location</IonLabel>
        <IonInput
              name="location"
              type="text"
              placeholder="City, State, or Zip Code"
              value={location}
              // onIonChange={(e) => {
              //     setLocation(e.detail.value as string);
              // }}
            	onKeyUp={(event:any) => {
                let location = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(location)){
                     event.target.value = location.slice(0, -1);
                }
                setLocation(event.target.value);
              }}
            
              onKeyPress={(event:any) => {
                let location = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(location)){
                    event.target.value = location.slice(0, -1);
                }
                setLocation(event.target.value);
              }}

            />
      </IonItem> */}



    <IonItem className="form-input" data-name="location">
        <IonLabel position="stacked" style={{fontSize:"22px"}}>City</IonLabel>
        <IonInput
              name="location"
              type="text"
              placeholder="City"
              value={location}
            	onKeyUp={(event:any) => {
                let location = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(location)){
                     event.target.value = location.slice(0, -1);
                }
                setLocation(event.target.value);
              }}
            
              onKeyPress={(event:any) => {
                let location = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(location)){
                    event.target.value = location.slice(0, -1);
                }
                setLocation(event.target.value);
              }}

            />
      </IonItem>


      <IonItem className="form-input" data-name="location">
        <IonLabel position="stacked" style={{fontSize:"22px"}}>State</IonLabel>
        <IonInput
              name="locstate"
              type="text"
              placeholder="State"
              value={locstate}
            	onKeyUp={(event:any) => {
                let location = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(location)){
                     event.target.value = location.slice(0, -1);
                }
                setLocationState(event.target.value);
              }}
            
              onKeyPress={(event:any) => {
                let location = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(location)){
                    event.target.value = location.slice(0, -1);
                }
                setLocationState(event.target.value);
              }}

            />
      </IonItem>



      <IonItem className="form-input" data-name="location">
        <IonLabel position="stacked" style={{fontSize:"22px"}}>Zip</IonLabel>
        <IonInput
              name="zip"
              type="text"
              placeholder="Zip"
              value={zip}
            	onKeyUp={(event:any) => {
                let location = event.target.value;
                let regExp = new RegExp('^[0-9]*$');
                if(!regExp.test(location)){
                     event.target.value = location.slice(0, -1);
                }
                setLocationZip(event.target.value);
              }}
            
              onKeyPress={(event:any) => {
                let location = event.target.value;
                let regExp = new RegExp('^[0-9]*$');
                if(!regExp.test(location)){
                    event.target.value = location.slice(0, -1);
                }
                setLocationZip(event.target.value);
              }}

            />
      </IonItem>




      <IonItem className="form-input" data-name="title">
      <IonLabel position="stacked" style={{fontSize:"22px"}}>Venue</IonLabel>
        <IonInput
          name="venue"
          type="text"
          placeholder="Venue"
          value={venue}
          onIonChange={(e) => {
            setVenue(e.detail.value as string);
          }}

        />
      </IonItem>
      <IonItem>
        <IonLabel style={{fontSize:"19px"}}>Ranked</IonLabel>
        <IonToggle color="success" checked={ranked} onIonChange={e => setRanked(e.detail.checked)} />
      </IonItem>

    
      <IonItem className="form-input" data-name="location">
      <IonLabel position="stacked" style={{fontSize:"22px"}}>Rules</IonLabel>
      {/* <IonTitle >Rules</IonTitle> */}
        <IonInput
          name="location"
          type="text"
          value={rules}
          onIonChange={(e) => {
            setRules(e.detail.value as string);
          }}

        />
      </IonItem>

      <IonItem className="form-input" data-name="message">
        <IonLabel position="stacked" style={{fontSize:"22px"}}>Message</IonLabel>
        <IonTextarea
          name="message"
          placeholder="Message"
          value={message}
          auto-grow={false}
          rows={2}
          spellCheck={true}
          onIonChange={e => setMessage(e.detail.value!)}
        ></IonTextarea>
      </IonItem>
      
      <IonRow>
        <IonCol size="12">
        <IonButton
        color="main"
        expand="block"
        style={{ margin: 14 }}
        
        onClick={(e) => {
          e.preventDefault();
          if (state.matchFilters.sports.length==0) {
            toast({ message: 'Please select sport' }, true);
            return;
          }
          if (!(challenge_start_date && challenge_start_time)) {
            toast({ message: 'Please select Start date & time' }, true);
            return;
          }

          // if(!(challenge_end_date && challenge_end_time)){
          //     toast({message: 'End date time is required'}, true);
          //     return;
          // }

          // if(challenge_start_date > challenge_end_date){
          //     toast({message: 'Challenge end date should be greater than start date'}, true);
          //     return;
          // }

          // if(challenge_start_time > challenge_end_time){
          //   toast({message: 'Event end time should be greater than start time'}, true);
          //   return;
          // }

          const challenge_end = "";

          if (!location) {
            toast({ message: 'Please enter city' }, true);
            return;
          }

          if (!locstate) {
            toast({ message: 'Please enter state' }, true);
            return;
          }

          if (!zip) {
            toast({ message: 'Please enter zip code' }, true);
            return;
          }

          if (!venue) {
            toast({ message: 'Please enter venue' }, true);
            return;
          }

          let args = {
            //sport:sportFinal,
            sport: getSportsIntailValue,
            location,
            locstate,
            zip,
            venue,
            date: joinISODateAndTime(challenge_start_date, challenge_start_time),
            //challenge_end: joinISODateAndTime(challenge_end_date, challenge_end_time),
            challenge_end,
            message,
            rules,
            ranked,
            //ids: [challengeUserData?.id?challengeUserData?.id:'']
            ids: challengeUserData?.id?[challengeUserData?.id]:''
          }
          //console.log("Args", args); return;

          
          let response;
          try {
            response = Challenges.issue(token ?? "", args);
            //console.log("Response", response); return;
            if (response){
              response.then(function(data:any){
                if (data && data.success){
                  history.push('/mychallenge');
                  toast({message: "Challenge created successfully."});
                } else {
                  toast({message: "Problem Submitting Challenge"}, true);
                }
              })
            }
          } catch (e) {
            console.log(e);
            setLoading(false);
            setError('Problem Issueing Challenge');
          }
        }}
      >
        CREATE
      </IonButton>
        </IonCol>

      </IonRow>
      
    </IonList>
  )
}

export default AddChallenge;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	--width: 100% !important;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;
const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
width: ${(props: any) => (props.width) ? props.width : 'auto'};
flex: ${(props: any) => (props.flex) ? props.flex : 'unset'};
align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;

const Row = styled.div<{ justify?: string }>`
display: flex;
align-items: center;
justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;

const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
position: relative;
display: flex;
align-items: center;
border-radius: 10px;
color: ${props => `var(--ion-color-${props.textColor})`};
${props => (props.type === 'outline') ? css` 
  border: 2.5px solid var(--ion-color-${props.color});` : css`
  background: var(--ion-color-${props.color});
`}
`;

const AbsoluteBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 2px;
`;

const Pill = styled.div`
	background: white;
	color: black;
	box-shadow: 0 0 0 1px #333;
	border-radius: 10px;
	padding: 1px 10px 1px;
	margin: 4px 2px;
	font-size: 11px;
	text-align: center;
	width: min-content;
`;



  
