import styled from "styled-components";

interface CardProps {
	title?: string | null
}

const StyledCard = styled.div`
	background: var(--ion-background-color-tint);
	border-radius: var(--radius);
	margin-left: var(--horizontal-gutter);
	margin-right: var(--horizontal-gutter);
	padding: 10px;
	margin-top: 5px;
	margin-bottom: 5px;
`;

const SmallTitle = styled.div`
	font-size: 12px;
`;

const Card: React.FC<CardProps> = ({ children, title }) => (
	<StyledCard>
		{(title) ? <SmallTitle>{title}</SmallTitle>:null}
		{children}
	</StyledCard>
)

export default Card;