import { IonCol, IonSpinner, IonText } from "@ionic/react"
import useUser from "hooks/useUser";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ProfileImage from "./ProfileImage";

const LoggedInUser: React.FC = () => {
	const {user, userLoading} = useUser();

	return (
		<Container>
				<IonText style={{fontSize: "26px",marginRight: "8px"}}>Account:</IonText>
				<IonText style={{fontSize: "15px", fontWeight: "600", color: "#000"}}>{user?.full_name}</IonText>
		</Container>
	);
/*
	return (userLoading) ? (
		<IonSpinner
			style={{
				width: "54px",
				padding: "var(--horizontal-gutter)",
				position: "relative"
			}}
		/>
	):(
		(user && user.preference && user.preference.image) ? (
			
				<Link to='/user/profile/view'>
					<ProfileImage
						icon_color="accent-two"
						icon_type={"none"}
						filename={user.preference.image}
						size="15%"
						minSize="50px"
						maxSize="175px"
						icon_offset="-10px"
					/>
				</Link>
		):(
			<IonCol style={{
				display: "flex",
				flexDirection: "column",
				alignSelf: "end",
				
				marginRight: "10px",
				textAlign: "right"
			}}>
					<IonText style={{fontSize: "11px", fontWeight: 'bold'}}>Signed In:</IonText>
					<IonText style={{fontSize: "15px"}}>{user?.full_name}</IonText>
			</IonCol>
		)
	)
	*/
}

export default LoggedInUser;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: end;
	padding: 10px 0 0 15px;

	& > ion-text:first-of-type {
		margin-right: 5px;
		font-size: 13px;
		font-weight: bold;
	}

	& > ion-text:last-of-type {
		font-size: 15px;
	}
`;