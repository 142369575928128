import { IonList, IonItem, IonLabel, IonInput, IonRange, IonText, IonButton, IonDatetime, IonModal } from "@ionic/react";
import { useState, useContext } from "react";
import styled from 'styled-components';
import { FilterContext } from '../State/FilterContext';

const EventSearch: React.FC = () => {
	const {state, dispatch} = useContext(FilterContext);
	return (
		<IonList lines="none">
			<IonItem>
			<div className="emaillab">
				<IonLabel position="stacked">Location</IonLabel>
			 </div>
				<IonInput
					type="email"
					onIonChange={(e) => {
						dispatch({type: 'event_setOrigin', payload: e.detail.value})
					}}
					name="text"
					placeholder="(City, State) or Zip Code"
					value={(typeof state.eventFilters.origin === 'string') ? state.eventFilters.origin:""}
				/>
			</IonItem>
			<IonItem>
				<RowLabel position="floating">
					<IonText style={{fontSize:"20px"}}>Distance</IonText>
					<LabelValue>({state.eventFilters.distance} miles)</LabelValue>
				</RowLabel>
				<IonRange
					pin={true}
					dualKnobs={false}
					min={0}
					max={300}
					name="distance"
					value={state.eventFilters.distance}
					onIonChange={(e) => {
						dispatch({type: 'event_setDistance', payload: e.detail.value})
					}}
				>
					<IonText slot="start">0</IonText>
					<IonText slot="end">300</IonText>
				</IonRange>
			</IonItem>
			{/* <IonItem>
				<IonLabel style={{fontSize:"20px"}}>Start Date</IonLabel>
				<IonDatetime
					displayFormat="MM DD YY"
					placeholder="Select Date"
					value={state.eventFilters.startDate}
					onIonChange={e => {
						dispatch({type: 'event_setStartDate', payload: e.detail.value})
					}}
				></IonDatetime>
			</IonItem> */}

        <IonItem className="form-input">
				<div className="emaillab">
          <IonLabel position="stacked">Start Date</IonLabel>
				 </div>
          <IonDatetime
                name="date"
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                //min={getCurrentDate}
                value={state.eventFilters.startDate}
								onIonChange={e => {
									dispatch({type: 'event_setStartDate', payload: e.detail.value})
								}}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
        </IonItem>

			{/* <IonItem>
				<IonLabel style={{fontSize:"20px"}}>End Date</IonLabel>
				<IonDatetime
					displayFormat="MM DD YY"
					placeholder="Select Date"
					value={state.eventFilters.endDate}
					onIonChange={e => {
						dispatch({type: 'event_setEndDate', payload: e.detail.value})
					}}
				></IonDatetime>
			</IonItem> */}

      <IonItem className="form-input" >
				<div className="emaillab">
          <IonLabel position="stacked">End Date</IonLabel>
				</div>
          <IonDatetime
            name="challenge_end_date"
           // min={getCurrentDate}
            displayFormat="MMM DD YYYY"
            placeholder="Select End Date"
            value={state.eventFilters.endDate}
						onIonChange={e => {
							dispatch({type: 'event_setEndDate', payload: e.detail.value})
						}}
            max={(() => {
              let d = new Date();
              let year = d.getFullYear();
              let next_year = year + 1;
              return String(next_year);
            })()}
          />
        </IonItem>


			<IonButton
				color="main"
				expand="block"
				style={{ margin: 14 }}
				routerLink="/results"
				onClick={() => {
					dispatch({type: 'setSearchType', payload: 'event'});
				}}
			>
				Search
			</IonButton>
		</IonList>
	)
}

export default EventSearch;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	width: 100%;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;