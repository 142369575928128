import {
	IonPage,
	IonContent
} from "@ionic/react";
import useAsyncEffect from "use-async-effect";
import { Cms } from "../../services/api";
import TitleCardSimple from "components/TitleCardSimple";
import { useState } from "react";
import AuthNav from "components/AuthNav";

//import SwiperCore from 'swiper';
//import { Swiper, SwiperSlide } from "swiper/react";


//SwiperCore.use([]);

const FaqPage = () => {

	const [faqContent,setFaqContent]=useState<any>("");
	useAsyncEffect(async () => {
		const data = { "page_key": "faq" };
		let result = await Cms.getCmsContent(data);
		console.log(result);
		setFaqContent(result[0].content);
	}, [])

	return (
		<IonPage>
			<IonContent className="ion-padding basic-bg basic-bg-pad ">
				<div style={{ padding: "50px 0 250px" }}>
					<TitleCardSimple />
					<p
            className="Features"
            dangerouslySetInnerHTML={{ __html: faqContent }}
						style={{color:"#fff"}}
          />
				</div>
			
				<AuthNav/>
			</IonContent>
		</IonPage>
	);
};

export default FaqPage