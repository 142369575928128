import { IonCol, IonIcon, IonItem, IonLabel, IonRow, IonText } from "@ionic/react"
import ProfileImage from "./ProfileImage"
import { fitnessOutline, flameOutline, podium } from 'ionicons/icons';
import styled from 'styled-components';
import StarRatingComponent from 'react-star-rating-component';
import { location, star, walk, starHalf } from 'ionicons/icons';

type LinesType = "full" | "inset" | "none" | undefined;
interface RankCardProps {
  user: any
  filters?: any
  lines?: LinesType
  style?: any
  textColor?: string
}

const RankCard: React.FC<RankCardProps> = ({ user, lines, style, textColor = 'main-text' }) => {
  return (
    <IonItem routerLink={`/user/friendlist/view/${user.id}`} lines={lines} style={style} detail={false}>
      <div>{user.rank}</div>
      <ProfileImage
        icon_type="none"
        icon_color="primary"
        size="75px"
        filename={user.preference.image}
      />
      <RankDetails>
        <IonRow className="ion-justify-content-between ion-align-items-center">
          <div style={{ display: 'flex', flexDirection: "column", marginLeft: '15px' }}>
            <IonRow class="ion-align-items-center" style={{ paddingBottom: '3px' }}>
              <StarRatingComponent
                name="user-rating"
                value={(user && user.rating) ?? 0}
                editing={false}
                starColor={"var(--ion-color-text)"}
                emptyStarColor="#777"
                renderStarIcon={() => <IonIcon icon={star} />}
                renderStarIconHalf={() => <IonIcon icon={starHalf} />}
              />
            </IonRow>
            <IonRow class="ion-align-items-center" style={{ paddingBottom: '3px' }}>
              <IonText color={textColor}>{user.short_name}</IonText>
            </IonRow>
            <IonRow class="ion-align-items-center">
              <IonIcon style={{ marginRight: "5px", fontSize: '13px' }} color={textColor} icon={flameOutline} />
              <IonText color={textColor} style={{ fontSize: "13px" }}>{user._matchingData.Sports.name}</IonText>
            </IonRow>
            {(user && user.preference && user.preference.distance_miles !== undefined && user.distance_miles !== null) ?
              (<IonRow class="ion-align-items-center">
                <IonIcon style={{ marginRight: "5px", fontSize: '13px' }} color={textColor} icon={location} />
                <IonText color={textColor} style={{ fontSize: "13px" }}>{user.preference.distance_miles?.toFixed(1)} miles away</IonText>
              </IonRow>) : null}
          </div>
          <IonRow class="ion-align-items-center">
            <IonIcon style={{ marginRight: "5px" }} color={textColor} icon={podium} />
            <IonText color={textColor}>{user._matchingData.UsersSports.rank}</IonText>
          </IonRow>
        </IonRow>
      </RankDetails>
    </IonItem>
  )
}

export default RankCard;

const RankDetails = styled(IonCol)`
  font-family: var(--ion-font-family);
`;