import { IonButton, IonRouterLink, IonRow, IonText } from "@ionic/react";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Users } from "services/api";
import styled from "styled-components";
import useToast from "../hooks/useToast";
import Card from "./Card"

const WelcomeCard: React.FC<{ user: User }> = ({user}) => {
	const [toast] = useToast("Hello");
	const {token} = useAuth();
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const sendVerificationEmail = () => {
		let response;
		try {
			response = Users.requestVerificationEmail(token ?? "");
			if (response){
				response.then(function(data){
					if (data && data.success){
						toast({message: "Verification Email Sent"});
						setError(null);
					} else {
						toast({message: "Error Sending"}, true);
						setError("Verification email could not be sent")
					}
				});
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			setError("Verification email could not be sent");
		}
	}
	return (
		<Card title={`Getting Started`}>
				<GettingStartedTitle>
					<div>Here are a few tips</div><div>to help you get started</div>
				</GettingStartedTitle>
				{(user.preference.addresses && user.preference.addresses.length <= 0) ? (
					<TipContainer>
						<TipTextContainer>
							<TipTitle>Add Sports</TipTitle>
							<TipDetails>- Click the "Add Sports" above to select the sports you would like to compete in</TipDetails>
							<TipDetails>- Team Sports will be added in future updates</TipDetails>
              <TipTitle>Add Profile Information - For Match and Tournament Searches</TipTitle>
							<TipDetails> - Click the "Edit Profile" below to add your profile information, Save</TipDetails>
							<TipDetails>- Add a Picture by selecting the icon next to the Image</TipDetails>
							<TipTitle>For more information getting started and navigating YAG, go to the About Page by selecting the Document Icon at the bottom of the page.</TipTitle>

						</TipTextContainer>
						<IonButton
							routerLink={`/user/profile/edit`}
							color="main"
							expand="full"
						>
							Edit Profile
						</IonButton>
					</TipContainer>
				):null}

				{(!user.preference.verified_email) ? (
					<TipContainer>
						<TipTextContainer>
							<TipTitle>Verify your email</TipTitle>
							<TipDetails>- This will ensure you get notified of new challenge and tournament invites</TipDetails>
						</TipTextContainer>
						<IonButton
							color="main"
							expand="full"
							onClick={sendVerificationEmail}
						>
							Send Verification
						</IonButton>
						
					</TipContainer>
				):null}
		</Card>
	)
}

export default WelcomeCard;

const GettingStartedTitle = styled.div`
	text-align: center;
	padding: 10px;
	margin: 10px 0;
	font-size: 20px;
	background: var(--ion-color-accent-one);
	color: var(--ion-color-constant-dark);
`;

const TipContainer = styled.div`
	padding: 10px 16px;
	margin: 10px 0;
	background: var(--ion-color-primary-bg-shade);
	color: var(--ion-color-primary-bg-contrast);
`;

const TipTextContainer = styled.div`
	padding: 0 0 10px 0;
`;

const TipTitle = styled.div`
	padding: 0 0 10px;
	font-size: 16px;
`;

const TipDetails = styled.div`
	font-size: 13px;
	padding-bottom: 5px;
`;