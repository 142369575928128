import { useHistory } from "react-router-dom";
import styled from "styled-components";

import siteLogo from "../images/logo-chartreuse.png";

const TitleCardSimple: React.FC<{
	showSubtitle?: boolean
}> = ({showSubtitle = false}) => {
	const history = useHistory();
	return (
		<TitleContainer
			onClick={() => history.push('/')}
		>
			<Logo>
				<img src={siteLogo} alt="" />
			</Logo>
			{/* <Title><div>Your Active Games</div></Title> */}
			{/* {showSubtitle && <><Subtitle>Play in Matches and Tournaments by challenging fellow players.</Subtitle><Subtitle>Ratings are affected by wins and losses so your play matters.</Subtitle><Subtitle>So Compete, Have Fun, Play Fair & Get Active</Subtitle></>} */}
			{showSubtitle && <>
			<Ul>
				<Listing>Play in Matches and Tournaments by challenging fellow players.</Listing>
				<Listing>Ratings are affected by wins and losses so your play matters.</Listing>
				<Listing>So Compete, Have Fun, Play Fair & Get Active.</Listing>
			</Ul>
			</>}
		</TitleContainer>
	);
}

export default TitleCardSimple;

const Logo = styled.div`
& img{
	width:450px;
	display: block;
  margin: auto;
}
`;

const TitleContainer = styled.div`
	color: var(--ion-color-accent-two);
	font-family: var(--heading-font);
	
`;

// const Title = styled.div`
// 	font-size: clamp(30px, 20vw, 35px);
// 	font-weight: 600;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: start;
// 	justify-content: start;
// 	margin: 0 0 8px;
// 	& div {
// 		margin: -4px 0;
// 	}
// `;
const Title = styled.div`
	font-size: clamp(20px, 20vw, 20px);
	font-weight: 600;
	width:100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	margin: 0 0 8px;
	& div {
		text-align:center;
		width:100%;
		text-transform:uppercase;
		letter-spacing:12px;
	}
`;

const Subtitle = styled.div`
	font-size: clamp(14px, 5vw, 14px);
	font-weight: normal;
	text-align:center;
	margin-top:30px;
`;
// const Subtitle = styled.div`
// 	font-size: clamp(20px, 5vw, 20px);
// 	font-weight: normal;
// `;
const Ul = styled.ul`
display: block;
margin: 25px auto;
width: 500px;
`;
const Listing = styled.li`
	font-size: clamp(14px, 5vw, 14px);
	font-weight: normal;
	text-align:start;
	margin-bottom:10px;
`;