import { IonButton, IonCol, IonInput, IonItem, IonLabel, IonList, IonRange, IonRow, IonText, IonToggle, IonTextarea, IonDatetime } from "@ionic/react";
import { useContext, useEffect } from "react";
import useToast from "hooks/useToast";
import styled from 'styled-components';
import { FilterContext } from "../State/FilterContext";
import { useState } from "react";
import { getDateISO, joinISODateAndTime } from "services/time";
import { useHistory } from "react-router-dom";
import { Users } from 'services/api';
import useAuth from 'hooks/useAuth';
import {usePosition} from 'hooks/usePosition';


const AddEvent: React.FC = (userData) => {
  const { token } = useAuth();
  const { state, dispatch } = useContext(FilterContext);
  const history = useHistory();
  const [event_name, setTitle] = useState<string>("");
  const [event_start, setStart] = useState<string>("");
  const [event_start_date, setStartDate] = useState<string>("");
  const [event_start_time, setStartTime] = useState<string>("");
  const [event_end_date, setEndDate] = useState<string>("");
  const [event_end_time, setEndTime] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [locationState, setLocationState] = useState<string>("");
  const [locationZip, setLocationZip] = useState<string>("");
  const [venue, setVenue] = useState<string>("");
  const [open_invite, setOpenInvite] = useState<boolean>(false);
  const [slots, setSlots] = useState<string>("3");
  const [message, setMessage] = useState<string>("");
  const [toast] = useToast("Hello");
  const [friendData,setfriendData]=useState<any>([]);
  
  const newDate = new Date()
  const date = newDate.getDate();
  let dateValue:any;
  if(date < 10){
    dateValue= '0'+date;
  }else{
    dateValue = date;
  }
  const month = newDate.getMonth() + 1;
  let monthValue:any;
  if(month<10){
    monthValue = '0'+month;
  }else{
    monthValue = month;
  }
  let year = newDate.getFullYear();
  let getCurrentDate = year+'-'+monthValue+'-'+dateValue;
  
  
  const getFriendList = async()=> {
      try {
          let response = await Users.getFriendList(token??"");
          setfriendData(response.success);
        
      } catch (error) {
        console.log(error);
      }
     
   }

  useEffect(()=>{
    getFriendList();
  },[friendData]);
  
  
 
 return (
    <IonList lines="none">
      <IonItem className="form-input" data-name="title">
        <IonLabel position="stacked" style={{fontSize:"24px"}}>Title</IonLabel>
        <IonRow style={{ width: "100%" }}>
          <IonCol size-md="9" size-sm="8">
            <IonInput
              name="title"
              type="text"
              placeholder="Event Title"
              value={event_name}
              onIonChange={(e) => {
                setTitle(e.detail.value as string);
              }}
            />
          </IonCol>
        </IonRow>
      </IonItem>
      <TimeRow>
        <IonItem className="form-input" data-name="event_start_date">
          <IonLabel position="stacked" style={{fontSize:"24px"}}>Start Date</IonLabel>
          <IonDatetime
            name="event_start_date"
            min={getCurrentDate}
            displayFormat="MMM DD YYYY"
            placeholder="Select Start Date"
            value={event_start_date}
            onIonChange={e => {
              let localDate = e.detail.value!;
              console.log({ localDate });
              setStartDate(localDate);
              setEndDate(localDate);
            }}
            max={(() => {
              let d = new Date();
              let year = d.getFullYear();
              let next_year = year + 1;
              return String(next_year);
            })()}
          />
        </IonItem>
        <IonItem className="form-input" data-name="event_start_time">
          <IonLabel position="stacked" style={{fontSize:"24px"}}>Start Time</IonLabel>
          <IonDatetime
            minuteValues="0,10,20,30,40,50"
            name="event_start_time"
            displayFormat="h:mm A"
            placeholder="Select Start Time"
            value={event_start_time}
            onIonChange={e => {
              let localTime = e.detail.value!;
              console.log({ localTime });
              setStartTime(localTime);
              setEndTime(localTime);
            }}
          ></IonDatetime>
        </IonItem>
      </TimeRow>

      <TimeRow style={{display:"none"}}>
            <IonItem className="form-input" data-name="event_end_date">
              <IonLabel position="stacked">End Date</IonLabel>
              <IonDatetime
                name="event_end_date"
                min={event_start_date}
                displayFormat="MMM DD YYYY"
                placeholder="Select End Date"
                value={event_end_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({localDate});
                  setEndDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="event_end_time">
                <IonLabel position="stacked">End Time</IonLabel>
                <IonDatetime
                  minuteValues="0,10,20,30,40,50"
                  name="event_end_time"
                  displayFormat="h:mm A"
                  placeholder="Select End Time"
                  value={event_end_time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setEndTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
      </TimeRow> 

      {/* <IonItem className="form-input" data-name="location">
        <IonLabel position="stacked">Location</IonLabel>
        <IonInput
          name="location"
          type="text"
          placeholder="City, State, or Zip Code"
          value={location}
          onIonChange={(e) => {
            setLocation(e.detail.value as string);
          }}
        />
      </IonItem> */}


      <IonItem className="form-input" data-name="location">
        <IonLabel position="stacked" style={{fontSize:"24px"}}>City</IonLabel>
        <IonInput
          name="location"
          type="text"
          placeholder="City"
          value={location}
          // onIonChange={(e) => {
          //   setLocation(e.detail.value as string);
          // }}
          
          onKeyUp={(event:any) => {
            let cityVal = event.target.value;
            let regExp = new RegExp('^[A-Za-z? ]+$');
            if(!regExp.test(cityVal)){
                 event.target.value = cityVal.slice(0, -1);
            }
            setLocation(event.target.value);
          }}
        
          onKeyPress={(event:any) => {
            let cityVal = event.target.value;
            let regExp = new RegExp('^[A-Za-z? ]+$');
            if(!regExp.test(cityVal)){
                event.target.value = cityVal.slice(0, -1);
            }
            setLocation(event.target.value);
          }}
      

        />
      </IonItem>

      <IonItem className="form-input" data-name="state">
        <IonLabel position="stacked" style={{fontSize:"24px"}}>State</IonLabel>
        <IonInput
          name="state"
          type="text"
          placeholder="State"
          value={locationState}
          // onIonChange={(e) => {
          //   setLocationState(e.detail.value as string);
          // }}
          
          onKeyUp={(event:any) => {
            let stateVal = event.target.value;
            let regExp = new RegExp('^[A-Za-z? ]+$');
            if(!regExp.test(stateVal)){
                 event.target.value = stateVal.slice(0, -1);
            }
            setLocationState(event.target.value);
          }}
        
          onKeyPress={(event:any) => {
            let stateVal = event.target.value;
            let regExp = new RegExp('^[A-Za-z? ]+$');
            if(!regExp.test(stateVal)){
                event.target.value = stateVal.slice(0, -1);
            }
            setLocationState(event.target.value);
          }}

        />
      </IonItem>

      <IonItem className="form-input" data-name="location">
        <IonLabel position="stacked" style={{fontSize:"24px"}}>Zip</IonLabel>
        <IonInput
          name="zip"
          type="text"
          placeholder="Zip"
          value={locationZip}
          // onIonChange={(e) => {
          //   setLocationZip(e.detail.value as string);
          // }}
        
          onKeyUp={(event:any) => {
            let zip = event.target.value;
            let regExp = new RegExp('^[0-9]*$');
            if(!regExp.test(zip)){
                 event.target.value = zip.slice(0, -1);
            }
            setLocationZip(event.target.value);
          }}

          onKeyPress={(event:any) => {
            let zip = event.target.value;
            let regExp = new RegExp('^[0-9]*$');
            if(!regExp.test(zip)){
                event.target.value = zip.slice(0, -1);
            }
            setLocationZip(event.target.value);
          }}
       

        />
      </IonItem>


      <IonItem className="form-input" data-name="title">
        <IonLabel position="stacked" style={{fontSize:"24px"}}>Venue</IonLabel>
        <IonInput
          name="title"
          type="text"
          placeholder="Venue"
          value={venue}
          onIonChange={(e) => {
            setVenue(e.detail.value as string);
          }}
        />
      </IonItem>
      <IonItem>
        <IonLabel style={{fontSize:"18px"}}>Open Invite</IonLabel>
        <IonToggle color="success" checked={open_invite} onIonChange={e => setOpenInvite(e.detail.checked)} />
      </IonItem>
      <IonItem>
        <IonLabel style={{fontSize:"18px"}}>Slots</IonLabel>
        <IonInput
          slot="end"
          name="slots"
          type="number"
          min="1"
          value={Number(slots)}
          onIonChange={(e) => {
            if(e.detail.value=='0'){
              setSlots('1');
            }else{
              setSlots(e.detail.value as string);
            }
          }}
          style={{ textAlign: 'right', marginRight: '25px' }}
        ></IonInput>
      </IonItem>
      <IonItem className="form-input" data-name="message">
        <IonLabel position="stacked" style={{fontSize:"24px"}}>Message</IonLabel>
        <IonTextarea
          name="message"
          placeholder="Message"
          value={message}
          auto-grow={false}
          rows={6}
          spellCheck={true}
          onIonChange={e => setMessage(e.detail.value!)}
        ></IonTextarea>
      </IonItem>

      <IonButton
        color="main"
        expand="block"
        style={{ margin: 14 }}
        onClick={(e) => {
          e.preventDefault();
          if (!event_name) {
            toast({ message: 'Please enter event title.' }, true);
            return;
          }

          if (!(event_start_date && event_start_time)) {
            toast({ message: 'Please select Start date & time' }, true);
            return;
          }
          const event_start = joinISODateAndTime(event_start_date, event_start_time);
          //console.log('Event Start date Value', event_start);

          // if(!(event_end_date && event_end_time)){
          //     toast({message: 'End date time is required'}, true);
          //     return;
          // }
          // const event_end = joinISODateAndTime(event_end_date, event_end_time);
          

          // if(event_start_date > event_end_date){
          //     toast({message: 'Event end date should be greater than start date'}, true);
          //     return;
          // }

          // if(event_start_time > event_end_time){
          //   toast({message: 'Event end time should be greater than start time'}, true);
          //   return;
          // }
          const event_end = '';

          if (!location) {
            toast({ message: 'Please enter city' }, true);
            return;
          }

          if (!locationState) {
            toast({ message: 'Please enter state' }, true);
            return;
          }

          if (!locationZip) {
            toast({ message: 'Please enter zip' }, true);
            return;
          }

          if (!venue) {
            toast({ message: 'Please enter venue' }, true);
            return;
          }

          if(friendData==false){
             toast({message: 'Please add friend from friend list.'}, true);
             return false;
          }
         
          let args = {
            event_name,
            event_start,
            event_end,
            location,
            locationState,
            locationZip,
            venue,
            slots,
            open_invite,
            message,
            selected:[]
          }
          //console.log('event create args', args); return;
          dispatch({ type: 'eventDetails_setEventDetails', payload: args });
          history.push("/addfriend");
        }}
      >
        Create Event
      </IonButton>
    </IonList>
  )
}

export default AddEvent;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	--width: 100% !important;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;