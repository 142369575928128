import { IonButton, IonInput, IonItem, IonLabel, IonList, IonRange, IonText,IonDatetime } from "@ionic/react";
import { useState, useContext } from "react";
import GenderCheckboxes from "./GenderCheckboxes";
import SportsDropdown from "./SportsDropdown";
import styled from 'styled-components';
import { FilterContext } from "../State/FilterContext";
import SearchDropdown from "./SearchDropdown";
import useAuth from "hooks/useAuth";
import { Users } from "services/api";


  const newDate = new Date()
  const date = newDate.getDate();
  let dateValue:any;
  if(date < 10){
    dateValue= '0'+date;
  }else{
    dateValue = date;
  }
  const month = newDate.getMonth() + 1;
  let monthValue:any;
  if(month<10){
    monthValue = '0'+month;
  }else{
    monthValue = month;
  }
  let year = newDate.getFullYear();
  let getCurrentDate = year+'-'+monthValue+'-'+dateValue;


const MatchSearch: React.FC = () => {
	const {state, dispatch} = useContext(FilterContext);
	const [ratingMinus, setMin] = useState(100);
	const [ratingPlus, setMax] = useState(500)

	const { token, userID } = useAuth();
	const [rankvalue, setRankvalue] = useState("");

 
	// event handler for new gender selection
	const updateGenderSelection = (checked: boolean, gender_change: string) => {
		let selectedGenders = state.matchFilters.genders;
		const newGenderArr: string[] = selectedGenders;
		if (checked){
			if (newGenderArr.indexOf(gender_change) < 0){
				newGenderArr.push(gender_change);
			}
		} else {
			const index = newGenderArr.indexOf(gender_change);
			if (index > -1) {
				newGenderArr.splice(index, 1);
			}
		}
		if (JSON.stringify(selectedGenders) !== JSON.stringify(state.matchFilters.genders)){
			dispatch({type: 'match_setGenders', payload: selectedGenders});
		}
  }


	// event handler for new sports selectiong
	const setSelectedSports = (selectedSports: string[]) => { 
		  //console.log("selected sports",selectedSports);
			if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sports)){
				dispatch({type: "match_setSelectedSports", payload: selectedSports});
			}

			let sport_id = selectedSports;
			try {
				let response = Users.getSportRank(token ?? "", sport_id);
				if (response) {
					response.then(function (data) {
						//console.log("Response data", data.payload[0].rank);
						if (data && data.success) {
							setRankvalue(data.payload[0].rank);
							//console.log("Show rank value", data.payload[0].rank);
							//console.log("Spread Value", ratingMinus);
							dispatch({type: "match_setRatingPlus", payload:data.payload[0].rank});
              dispatch({ type: "match_setRatingMinus", payload: ratingMinus });
				

						} 
					})
				}
			}
			catch (error) {
				console.log(error);
				
			}
      
      
	}


	
	const updateAge = (e: any) => {
		dispatch({type: 'match_setAgeRange', payload: e.target.value});
	}

  
	//Function is called everytime increment button is clicked
	const increment = () => {
		// Counter state is incremented
		setMax(ratingPlus + 10);
		dispatch({ type: "match_setRatingPlus", payload: ratingPlus });
	}

	//Function is called everytime decrement button is clicked
	const decrement = () => {
		// Counter state is decremented
		setMin(ratingMinus - 10);
		dispatch({ type: "match_setRatingMinus", payload: ratingMinus });
	}


	const spreadIncr = () =>{
		   //console.log("Rating MInus Value", ratingMinus);
			 setMin(ratingMinus + 10);
	}

 
	//console.log("Sports data list", state.matchFilters.sports);

	return (
		<IonList lines="none">
			<SportsDropdown
				multiple={false}
				onChange={(sports) => {
					setSelectedSports(sports);
					
				}}
				selectedSports={state.matchFilters.sports}
				
			/>
			<IonItem>
			<div className="emaillab">
				<IonLabel position="stacked">Location</IonLabel>
			</div>
				<IonInput
					type="text"
					onIonChange={(e) => {
						dispatch({type: 'match_setOrigin', payload: e.detail.value})
					}}
					name="text"
					//placeholder="City, State, or Zip Code"
					placeholder="(City, State) or Zip Code"
					value={(typeof state.matchFilters.origin === 'string') ? state.matchFilters.origin:""}
				/>
			</IonItem>
			<IonItem>
				<RowLabel position="floating">
					<IonText style={{fontSize:"18px"}}>Distance</IonText>
					<LabelValue>({state.matchFilters.distance} miles)</LabelValue>
				</RowLabel>
				<IonRange
					pin={true}
					dualKnobs={false}
					min={0}
					max={300}
					name="distance"
					value={state.matchFilters.distance}
					onIonChange={(e) => {
						dispatch({type: 'match_setDistance', payload: e.detail.value})
					}}
				>
					<IonText slot="start">0</IonText>
					<IonText slot="end">300</IonText>
				</IonRange>
			</IonItem>
		
			{/* <IonItem>
				<RowLabel position="floating">
					<IonText>Age</IonText>
					<LabelValue>{(
						state.matchFilters.ageRange &&
						state.matchFilters.ageRange.lower &&
						state.matchFilters.ageRange.upper) ? 
						`(${state.matchFilters.ageRange.lower} - ${state.matchFilters.ageRange.upper})`:null}</LabelValue>
				</RowLabel>
				<IonRange pin={true} dualKnobs={true} min={18} max={100} name="age" value={state.matchFilters.ageRange} onIonChange={updateAge}>
					<IonText slot="start">18</IonText>
					<IonText slot="end">100</IonText>
				</IonRange>
			</IonItem>
			<GenderCheckboxes
				selectedGenders={state.matchFilters.genders}
				onChange={updateGenderSelection}
			/> */}
      

			{/* <IonItem>
				<IonLabel>Start Date</IonLabel>
				<IonDatetime
					displayFormat="MM DD YY"
					min={getCurrentDate}
					placeholder="Select Date"
					value={state.matchFilters.startDate}  
					onIonChange={e => {
						dispatch({type: 'match_setStartDate', payload: e.detail.value})
					}}
					max={(() => {
						let d = new Date();
						let year = d.getFullYear();
						let next_year = year + 1;
						return String(next_year);
					})()}
				></IonDatetime>
			</IonItem> */}


        <IonItem className="form-input">
				<div className="emaillab">
          <IonLabel position="stacked">Start Date</IonLabel>
				 </div>
          <IonDatetime
                name="date"
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                min={getCurrentDate}
                // onIonChange={e => {
                //   let localDate = e.detail.value!;
                //   setStartDate(localDate);
                //   setEndDate(localDate);
                // }}
								onIonChange={e => {
									dispatch({type: 'match_setStartDate', payload: e.detail.value})
								}}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
        </IonItem>

			{/* <IonItem>
				<IonLabel>End Date</IonLabel>
				<IonDatetime
					displayFormat="MM DD YY"
					min={getCurrentDate}
					placeholder="Select Date"
					value={state.matchFilters.endDate}  
					onIonChange={e => {
						dispatch({type: 'match_setEndDate', payload: e.detail.value})
					}}

					max={(() => {
						let d = new Date();
						let year = d.getFullYear();
						let next_year = year + 1;
						return String(next_year);
					})()}
				></IonDatetime>
			</IonItem> */}


        <IonItem className="form-input" >
				<div className="emaillab">
          <IonLabel position="stacked">End Date</IonLabel>
				</div>
          <IonDatetime
            name="challenge_end_date"
            min={getCurrentDate}
            displayFormat="MMM DD YYYY"
            placeholder="Select End Date"
            value={state.matchFilters.endDate}  
            onIonChange={e => {
							dispatch({type: 'match_setEndDate', payload: e.detail.value})
						}}
            max={(() => {
              let d = new Date();
              let year = d.getFullYear();
              let next_year = year + 1;
              return String(next_year);
            })()}
          />
        </IonItem>


			{/* <IonItem>
				<IonLabel>Rating</IonLabel>
				<IonText>{ratingPlus}</IonText>
				<IonButton color="main" fill="clear" onClick={() => { increment() }}>+</IonButton>/
				<IonButton color="main" fill="clear" onClick={() => { decrement() }}>-</IonButton>
				<IonText>{ratingMinus}</IonText>
			</IonItem> */}

			
      <IonItem>
				<IonLabel style={{fontSize:"18px"}}>Rating</IonLabel>
				<IonText>{rankvalue}</IonText>
			</IonItem>

			
			<IonItem>
				<IonLabel style={{fontSize:"18px"}}>Spread</IonLabel>
				<IonButton color="main" fill="clear" onClick={() => { spreadIncr() }}>+</IonButton>/
				<IonButton color="main" fill="clear" onClick={() => { decrement() }}>-</IonButton>
				<IonText>{ratingMinus}</IonText>
			</IonItem>



			<IonButton
			  color="main"
			  expand="block"
			  style={{ margin: 14 }}
				routerLink="/results"
				onClick={() => {
					dispatch({type: 'setSearchType', payload: 'match'});
				}}
			>
				Search
			</IonButton>
		</IonList>
	)
}

export default MatchSearch;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	--width: 100% !important;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;