import { IonButton, IonIcon } from "@ionic/react";
import { search } from "ionicons/icons";
import { generateRandomString } from "services/util";
import ChallengePromptButton from "./ChallengePromptButton";

const ChallengePrompt: React.FC<{msg:string}> = ({msg}) => {
	return (
		<div>
			<div style={{textAlign: "center", padding: "20px 0px 10px"}}>
				{msg.split('\n').map(each => {
					return (<div key={generateRandomString()}>{each}</div>);
				})}
			</div>
			<ChallengePromptButton />
		</div>
	)
}

export default ChallengePrompt;