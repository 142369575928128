import { useState, useEffect } from 'react';
import { availableFeatures, useWatchPosition } from '@capacitor-community/react-hooks/geolocation';
import { Geolocation, Position } from '@capacitor/geolocation';
import useAsyncEffect from 'use-async-effect';


interface PositionError {
	message: string
}

interface Coords {
	lat: number,
	lon: number
	timestamp?: number,
}

interface CallbackPosition {
	coords: Coords
	timestamp: number
}

interface StatePosition {
	coords: Coords
	timestamp?: number
	error?: string | undefined
}

const defaultSettings = {
	enableHighAccuracy: false,
	timeout: Infinity,
	maximumAge: 0,
};

export const usePosition = (watch = false, settings = defaultSettings) => {
	const {currentPosition, startWatch, clearWatch } = useWatchPosition();
	const [browserPosition, setPosition] = useState<StatePosition | null>(null);
	const [error, setError] = useState<string>();

	const onSuccess: (pos: Position) => void = function ({coords, timestamp}): void {
		setPosition({
			coords: { lat: coords.latitude, lon: coords.longitude },
			timestamp: timestamp,
		});
	}

	const onError = (error: PositionError) => {
		setError(error.message);
	};

	useEffect(() => {
		if (availableFeatures.watchPosition){
			startWatch(defaultSettings);
			return clearWatch;
		} else if (!(!navigator || !navigator.geolocation)){
			let watcher: any = null;
			if (watch) {
				watcher = navigator.geolocation.watchPosition(onSuccess, onError, settings);
			} else {
				navigator.geolocation.getCurrentPosition(onSuccess, onError, settings);
			}
			return () => watcher && navigator.geolocation.clearWatch(watcher);
		}
	}, [watch, settings]);

	const prompt = async () => {
		let newState = null;
		if (availableFeatures.getCurrentPosition){
			console.log('using capacitor Geolocation');
			const response = await Geolocation.getCurrentPosition();
			newState = {
				coords: {
					lat: response.coords.latitude,
					lon: response.coords.longitude,
					timestamp: response.timestamp
				}
			}
			setPosition(newState);
		} else if (!(!navigator || !navigator.geolocation)){
			console.log('using navigator');
			const permissions_response = await navigator.permissions.query({name:'geolocation'});
			console.log('permission response', permissions_response);
			if (permissions_response.state === 'granted' || permissions_response.state === 'prompt'){
				try {
					const navigator_response: GeolocationPosition = await new Promise((resolve, reject) => {
						navigator.geolocation.getCurrentPosition(resolve, reject, defaultSettings);
					});
					console.log("navigator response", navigator_response);
					if (navigator_response){
						console.log('navigator response', navigator_response);
						newState = {
							coords: {
								lat: navigator_response.coords.latitude,
								lon: navigator_response.coords.longitude,
							},
							timestamp: navigator_response.timestamp
						}
						setPosition(newState);
					}
				} catch (e){
					console.log('caught error', e);
				}
			}
		}
		return newState;
	}

	let position = null;
	if (availableFeatures.watchPosition){
		position = currentPosition;
	} else if (!(!navigator || !navigator.geolocation)){
		position = { coords: browserPosition}
	}
	
	return {
		position, 
		prompt, 
		error,
	};
};