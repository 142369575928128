import {
	IonPage,
	IonContent,
	IonCardTitle,
	IonItem,
	IonLabel,
	IonInput,
	IonTextarea,
	IonSelect,
	IonSelectOption,
	IonButton,
	useIonAlert,
	IonSpinner
} from "@ionic/react";
import TitleText from "../../components/TitleText";
import TitleCard from "../../components/TitleCard";
import useAsyncEffect from "use-async-effect";
import { Contact } from "../../services/api";
import { getPlatforms } from '@ionic/react';
import 'swiper/swiper-bundle.min.css';
import TitleCardSimple from "components/TitleCardSimple";
import useToast from "../../hooks/useToast";
import { useContext, useEffect, useState } from "react";
import AuthNav from "components/AuthNav";



const ContactPage = () => {
	const [present] = useIonAlert();
	const [toast] = useToast("Hello");
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [reason, setReason] = useState<string>("Select");
	const [message, setMessage] = useState<string>("");
	const [loader,setLoader]=useState<boolean>(false);


	const postContactForm = async () => {
		
		try {
			let args={
				first_name:firstName,
				last_name:lastName,
				email:email,
				reason:reason,
				message:message
			};

			//console.log("contact form data", args); return;
			if(firstName==""){
				toast({message: "First Name Required"}, true);
				return;
			}

			if(lastName==""){
				toast({message: "Last Name Required"}, true);
				return;
			}

			if(email==""){
				toast({message: "Email Required"}, true);
				return;
			}

			if(reason==""){
				toast({message: "Reason Required"}, true);
				return;
			}

			if(message==""){
				toast({message: "Message Required"}, true);
				return;
			}
			setLoader(true);
			const response = await Contact.contactDataPost(args);
			console.log("Response", response);

			if(response.success==true){
				setFirstName("");
				setLastName("");
				setEmail("");
				setReason("");
				setMessage("");
				toast({message: response.message});
			}else{
				toast({message: response.message}, true);
			}
			setLoader(false);
		} catch (error) {
			toast({message: error}, true);
			setLoader(false);
		}
	}
	useAsyncEffect(async () => {
	
	}, [])

	return (
		<IonPage>
			<IonContent fullscreen className="ion-padding basic-bg basic-bg-pad">
				<div style={{ padding: "50px 0 30px" }}>
					<TitleCardSimple />
				</div>
				{/* <div>
					...contact info
				</div> */}
				<IonItem>
					<IonLabel position="stacked">First Name</IonLabel>
					<IonInput
						type="text"
						name="firstName"
						placeholder="First Name"
						value={firstName}
              onIonChange={(e) => {
								setFirstName(e.detail.value as string);
              }}

					/>
				</IonItem>
				<IonItem>
					<IonLabel position="stacked">Last Name</IonLabel>
					<IonInput
						type="text"
						name="firstName"
						placeholder="Last Name"
						value={lastName}
						onIonChange={(e)=>{
							setLastName(e.detail.value as string);
						}}
					/>
				</IonItem>
				<IonItem>
					<IonLabel position="stacked">Email Address</IonLabel>
					<IonInput
						type="email"
						name="email"
						placeholder="Email"
						value={email}
						onIonChange={(e)=>{
							setEmail(e.detail.value as string);
						}}
					/>
				</IonItem>
				<IonItem>
					<IonLabel position="stacked">Reason For Contacting</IonLabel>
					<IonSelect 
					value={reason} 
					placeholder="Select One" 
					name="reason"
					onIonChange={(e)=>{
						setReason(e.detail.value as string);
					}}
					>
						<IonSelectOption value="request sports">Request Sports</IonSelectOption>
						<IonSelectOption value="Feedback Ideas">Feedback Ideas</IonSelectOption>
						<IonSelectOption value="Dispute">Dispute</IonSelectOption>
						<IonSelectOption value="Other">Other</IonSelectOption>
					</IonSelect>
				</IonItem>
				<IonItem>
					<IonLabel position="stacked">Message</IonLabel>
					<IonTextarea
						name="message"
						placeholder="Message"
						value={message}
						onIonChange={(e)=>{
							setMessage(e.detail.value as string);
						}}
					/>
				</IonItem>
				<IonButton
					expand="block"
					// onClick={() => {
					// 	present({
					// 		header: 'Are you sure?',
					// 		message: 'Do you want to submit the form? This action cannot be undone',
					// 		buttons: [
					// 			{ text: 'Yes', handler: postContactForm },
					// 			{ text: 'No', handler: () => console.log('cancelled') }
					// 		]
					// 	});
					// }}
					onClick={postContactForm}
					>
					Submit 
					{(loader)?(<IonSpinner />):""}
				</IonButton>
				<AuthNav/>
			</IonContent>
		
		</IonPage>
	);
};

export default ContactPage