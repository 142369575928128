const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = [
	"January","February","March","April","May","June","July",
	"August","September","October","November","December"
];

const getTimeParts = (datestr: string) => {
	var d = localDate(datestr);
	var time_parts = {
		hour_12: (d.getHours() % 12 !== 0) ? (d.getHours() % 12):"12",
		hour_24: d.getHours(),
		minutes: String(d.getMinutes()).padStart(2, '0'),
		am_pm: (d.getHours() > 11)  ? "PM":"AM",
	}
	return {
		weekday_name: weekdays[d.getDay()].slice(0, 3),
		month_name: months[d.getMonth()].slice(0, 3),
		month_day: d.getDate().toString(),
		day: d.getDate(),
		time_12: time_parts.hour_12 + ":" + time_parts.minutes + " " + time_parts.am_pm,
		time_24: time_parts.hour_24 + ":" + time_parts.minutes,
		time_parts
	}
}
const localDate = (datestr: string) => {
	var d = new Date(datestr);
	if (d.toString() === "Invalid Date"){
		d = new Date();
	}
	return d;
}
const toAppFormat = (datestr: string) => {
	let d = localDate(datestr);
	return d.toLocaleString('en-US', {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
  	minute: 'numeric',
	});
}
const toAppDateFormat = (datestr: string) => {
	let d = localDate(datestr);
	return d.toLocaleDateString('en-US', {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
}
const toAppTimeFormat = (datestr: string) => {
	let d = localDate(datestr);
	return d.toLocaleTimeString('en-US', {
		hour: 'numeric',
  	minute: 'numeric',
	});
}


const timeAgo = (datestr: string) => {
	const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'long' });

	const date = new Date(datestr);
	const compare_date = new Date();
	
	// vars for readability
	const millisecondsInSecond = 1000;
	const secondsInMinute = 60;
	const minutesInHour = 60;
	const secondsInHour = 3600;
	const hoursInDay = 24;

	// get deltas
	const deltaDays    = (date.getTime() - compare_date.getTime()) / (millisecondsInSecond * secondsInHour * hoursInDay);
	const deltaHours   = (date.getTime() - compare_date.getTime()) / (millisecondsInSecond * secondsInHour);
	const deltaMinutes = (date.getTime() - compare_date.getTime()) / (millisecondsInSecond * secondsInMinute);
	const deltaSeconds = (date.getTime() - compare_date.getTime()) / millisecondsInSecond;

	// determine best relative time and set string
	let agoString = null;

	const round = (num: number) => {
		let negative = (Math.sign(num) < 0);
		let floored = Math.floor(Math.abs(num));
		if (negative){
			floored *= -1;
		}
		return floored;
	}

	if (Math.abs(deltaSeconds) < secondsInMinute){
		agoString = rtf1.format(round(deltaSeconds), 'seconds');
	} else if (Math.abs(deltaMinutes) < minutesInHour){
		agoString = rtf1.format(round(deltaMinutes), 'minutes');
	} else if (Math.abs(deltaHours) < hoursInDay) {
		agoString = rtf1.format(round(deltaHours), 'hours');
	} else {
		agoString = rtf1.format(round(deltaDays), 'days');
	}

	let payload = {
		deltaDays,
		deltaHours,
		deltaMinutes,
		deltaSeconds,
		agoString
	};
	return payload;
}

const getDateISO = () => {
	// get local time in ISO 8601 format
	var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
	var localISOTime = (new Date(Date.now() - tzoffset)).toISOString();
	return localISOTime;
}

const joinISODateAndTime = (date: string, time: string) => {
	const date_split = date.split('T');
	const time_split = time.split('T');
	return [date_split[0], time_split[1]].join('T');
}

export {
	getTimeParts,
	localDate,
	toAppFormat,
	toAppDateFormat,
	toAppTimeFormat,
	timeAgo,
	getDateISO,
	joinISODateAndTime
}
