import { IonButton, IonIcon, IonModal, IonRouterLink, IonRow, IonSpinner, IonText } from "@ionic/react";
import useAuth from "hooks/useAuth";
import useDarkTheme from "hooks/useDarkTheme";
import useStateWithPromise from "hooks/useStateWithPromise";
import { person,star,starHalf, location, alertCircleOutline, alertCircle, constructOutline, calendar, map } from "ionicons/icons";
import { ReactNode, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Challenges } from "services/api";
import styled, { css } from "styled-components";
import useAsyncEffect from "use-async-effect";
import { getTimeParts } from "../services/time";
import { generateRandomString, truncate } from "../services/util";
import ProfileImage from "./ProfileImage";
import ReportChallenge from "./ReportChallenge";
import StarRatingComponent from 'react-star-rating-component';



interface ChallengeProps {
 
	challenge: Challenge
	contextual_user_id: string
	actions?: ActionButtonProps[]
	issuer: User | undefined
	recipients: User[]
	isContextUserIssuer: boolean
	boxType: FillType
	boxTextColorOverride?: string
	boxColorOverride?: string
	dotColorOverride?: string
	allowViewChallenge: boolean
	refreshChallenges: () => void
	showActions?: boolean,
	
}


const Challenge: React.FC<ChallengeProps> = ({
	challenge,
	contextual_user_id,
	isContextUserIssuer,
	boxType,
	boxColorOverride,
	dotColorOverride,
	boxTextColorOverride,
	refreshChallenges,
	allowViewChallenge,
	showActions = true
}) => {

	let history = useHistory();
	const { token, userID } = useAuth();
	const [isLoading, setIsLoading] = useStateWithPromise<boolean>(true);
	const [displayUser, setDisplayUser] = useStateWithPromise<User | undefined>();
	const [dotColor, setDotColor] = useStateWithPromise<string>("danger");
	const [boxColor, setBoxColor] = useStateWithPromise<string>("main");
	const [boxTextColor, setBoxTextColor] = useStateWithPromise<string>("main-text");
	const [participantNodes, setParticipantNodes] = useState<ReactNode[]>();
	const [actionNodes, setActionNodes] = useState<ReactNode[]>();
	const [timeObj, setTimeObj] = useState({
		weekday_name: "Day",
		month_name: "Mon",
		month_day: "00",
		time_12: "00:00 AM"
	});
	const [modalOpen, setModalOpen] = useState(false);
	const { prefersDark } = useDarkTheme();
	const [challengeRef, setChallengeRef] = useState<Challenge | null>();

	const[openchallenge, setOpenChallenge] = useState(challenge?.open_challenge);

  //console.log("challege data", challenge);
	//console.log("Contectal user id", contextual_user_id);
  //console.log("challenge Name", challenge?.open_challenge)
	//console.log("check open", openchallenge);
	console.log("challenge Issue id", challenge.issuer?.id);
	console.log("Context user issue", isContextUserIssuer);
	console.log("Context user id", contextual_user_id);


	// const getProfileImage = (user = challenge.issuer, size = '75px') => {
	// 	if (user && user.preference.image) {
	// 		return (

	// 			<Link to="">
	// 			{/* <IonItem routerLink={`/user/view/${user.id}`} detail={true}> */}
	// 				<ProfileImage
	// 					icon_type="none"
	// 					size={size}
	// 					filename={user.preference.image}
	// 				/>
	// 			{/* </IonItem> */}
	// 			</Link>
	// 		)
	// 	} else {
	// 		return (
	// 			<ProfileImage
	// 				icon_type="none"
	// 				size={size}
	// 				filename=""
	// 			/>
	// 		)
	// 	}
	// }


	const getProfileImage = (user = challenge.recipients[0], size = '75px') => {
		if (user && user.preference.image) {
			return (

				<Link to="">
				{/* <IonItem routerLink={`/user/view/${user.id}`} detail={true}> */}
					<ProfileImage
						icon_type="none"
						size={size}
						filename={user.preference.image}
					/>
				{/* </IonItem> */}
				</Link>
			)
		} else {
			return (
				<ProfileImage
					icon_type="none"
					size={size}
					filename=""
				/>
			)
		}
	}


	const getProfileImageIssuer = (user = challenge.issuer, size = '75px') => {
		if (user && user.preference.image) {
			return (

				<Link to="">
				{/* <IonItem routerLink={`/user/view/${user.id}`} detail={true}> */}
					<ProfileImage
						icon_type="none"
						size={size}
						filename={user.preference.image}
					/>
				{/* </IonItem> */}
				</Link>
			)
		} else {
			return (
				<ProfileImage
					icon_type="none"
					size={size}
					filename=""
				/>
			)
		}
	}

	const hasChanged = () => {
		//console.log("Contextual User", contextual_user_id);
		//console.log("Login ID", userID);
		if (contextual_user_id === userID) {
			let found_user = challenge.users.find(user => user.id === contextual_user_id);
			let hasChanged = found_user?._joinData?.hasChangedSinceLastViewed
			return hasChanged;
		}
		return false;
	}



	const getLocation = () => {
		if (challenge && challenge.location) {
			return challenge.location;
		}
		return "Location";
	}




	const getSport = () => {
		if (challenge && challenge.sport) {
			return challenge.sport.name;
		}
		return "Sport";
	}



	const getActionButtons = (): ReactNode[] => {
		let buttons: ReactNode[] = [];
		if (challenge.status === "Pending") {
			if (!isContextUserIssuer) {
				buttons.push(makeActionButton({
					name: "Accept",
					function: async (id) => {
						await Challenges.updateStatus(token ?? "", id, "accepted");
						refreshChallenges();
					},
					type: 'outline' as FillType,
					color: (prefersDark) ? "constant-light" : "main",
					textColor: (prefersDark) ? "constant-light" : 'main-text'
				}))
			}

			//if (isContextUserIssuer) {
				buttons.push(makeActionButton({
					name: "Cancel",
					function: async (id) => {
						await Challenges.cancel(token ?? "", id);
						refreshChallenges();
					},
					type: 'solid' as FillType,
					color: "danger",
					textColor: 'main-text'
				}))
			//}

			buttons.push(makeActionButton({
				name: "Modify",
				function: (id) => {
					history.push({
						pathname: `/challenge/edit/${id}`,
						state: {
							id
						}
					});
				},
				type: 'solid' as FillType, color: "main", textColor: 'constant-light'
			}))

		} 



		else if (challenge.status === 'Upcoming') {
			buttons.push(makeActionButton({
				name: "Cancel",
				function: async (id) => {
					await Challenges.updateStatus(token ?? "", id, "pending");
					refreshChallenges()
				},
				type: 'solid' as FillType,
				color: "danger" as ColorType,
				textColor: 'constant-light' as ColorType
			}))

		}
		else if (challenge.status === 'Cancelled') {
			buttons.push(makeActionButton({
				name: "Cancelled",
				type: 'solid' as FillType, color: 'danger', textColor: 'constant-light'
			}))

		} 
		else if (challenge.status === 'Dismissed') {
			buttons.push(makeActionButton({
				name: "Dismissed",
				type: 'solid' as FillType, color: 'danger', textColor: 'constant-light'
			}))

		} 
		else if (challenge.status === 'Reporting') {
			let found_user = challenge.users.find(user => user.id === contextual_user_id);
			//console.log("Found user", contextual_user_id);
			let already_reported = (found_user && found_user?._joinData?.reported_winner_id !== null);
			if (!already_reported) {
				buttons.push(makeActionButton({
					name: "Report",
					function: () => {
						setModalOpen(true);
					},
					type: 'solid' as FillType, color: "main", textColor: 'constant-light'
				}))
			} else {
				buttons.push(makeActionButton({
					name: "Reported",
					function: () => { },
					type: 'solid' as FillType, color: "main", textColor: 'constant-light',
					disabled: true
				}))
			}

		} 
		else if (challenge.status === 'Complete') {
			buttons.push(makeActionButton({
				name: "Complete",
				function: (id) => {
					history.push({
						pathname: `/challenge/issue/${id}`
					});
				},
				type: 'solid' as FillType, color: "success", textColor: 'constant-light'
			}))

		}

		return buttons;
	}

	const makeActionButton = (action: ActionButtonProps) => (
		<ActionButton
			key={generateRandomString()}
			onClick={() => { action.function && action.function(challenge.id) }}
			routerLink={action.href}
			fill={action.type}
			color={action.color}
			size="small"
			disabled={action.disabled || !action.function}
		>
			{action.name}
		</ActionButton>
	);

	const makeParticipantNodes = (users: User[]) => {
		return users
			.map((user: User) => {
				return <ProfileImage
					icon_type="none"
					filename={user.preference.image}
					size="25px"
					style={{ padding: "0" }}
				/>
			});
	}


	useEffect(() => {
		if (challengeRef === null || JSON.stringify(challengeRef) !== JSON.stringify(challenge)) {
			setChallengeRef(challenge);
			setTimeObj(getTimeParts(challenge.challenge_start));
			setParticipantNodes(makeParticipantNodes(challenge.recipients));
			
		}
		setActionNodes(getActionButtons());
	}, [challenge,token])

	useAsyncEffect(async (isMounted) => {
		const issuer = challenge.issuer;
		const recipient = challenge.recipients[0];
		const issuerColor = "klein-blue"; // colors when the contextual user is issuer of challenge
		const issuerTextColor = boxType === 'outline' ? "main-text" : "constant-light";
		const recipientColor = "accent-two"; // colors when the contextual user is recipient of challenge
		const recipientTextColor = boxType === 'outline' ? "main-text" : "constant-dark";
		if (!isMounted()) return;
		Promise.all([
			setDisplayUser((parseInt(issuer.id) === parseInt(contextual_user_id)) ? recipient : issuer),
			setDotColor(dotColorOverride ? dotColorOverride : (issuer?.id === contextual_user_id) ? issuerColor : recipientColor),
			setBoxColor(boxColorOverride ? boxColorOverride : (issuer?.id === contextual_user_id) ? issuerColor : recipientColor),
			setBoxTextColor(boxTextColorOverride ? boxTextColorOverride : (issuer?.id === contextual_user_id) ? issuerTextColor : recipientTextColor)
		]).then((values) => {
			setIsLoading(false);
		})
	}, [challenge, contextual_user_id, isContextUserIssuer,token])


	//console.log("current user id", userID);
	//console.log("challenge data", challenge);
	//console.log("All challenge data", challenge);
	//console.log("challege status", challenge);
  //console.log("challenge winner", challenge?.reported_winner_id);
	//console.log("User ID", userID);

	return (isLoading) ? (
		<IonRow style={{ padding: '15px', justifyContent: 'center' }}>
			<IonSpinner name="dots" color="accent-two" />
		</IonRow>
	) : (
		<Col style={{ padding: '10px 0 0' }}>
			<Row style={{ fontSize: '15px' }}>

				{(challenge.status==='Upcoming' || challenge.status==='Pending')?(
					  <Col>
								<Dot
									size={7}
									color=""
									style={{backgroundColor:"#caee6f"}}
								/>
					  </Col>
				):
				(challenge?.reported_winner_id==userID)?
					(<Dot
						size={7}
						color=""
						style={{backgroundColor:"#caee6f"}}
					/>):<Dot
							size={7}
							color=""
							style={{backgroundColor:"red"}}
			    	/>
				
				}

        {/* {(challenge?.reported_winner_id==userID)?
					(<Dot
						size={7}
						color=""
						style={{backgroundColor:"#caee6f"}}
					/>):<Dot
							size={7}
							color=""
							style={{backgroundColor:"red"}}
				/>}  */}
			
				


				<Col width="65px" align="center" style={{ padding: "0 6px 0 0", fontSize: "12px", textAlign: "center", letterSpacing: "0.2px" }}>
					<Row justify="center"><strong>{timeObj.weekday_name}</strong></Row>
					<Row justify="center">{timeObj.month_name}<span style={{ width: "5px" }}></span><strong>{timeObj.month_day}</strong></Row>
					<Row justify="center" style={{ "whiteSpace": "nowrap" }}>{timeObj.time_12}</Row>
				</Col>

				<Col flex={1}>
					<ColoredBox style={{backgroundColor:"#caed6b"}}
						type={boxType}
						color={boxColor}
						textColor={"black"}
						onClick={() => {
						     	history.push({
								 		pathname: `/challenge/issue/${challenge.id}`
								 	});

							// if (allowViewChallenge && challenge && challenge.id) {
							// 	history.push({
							// 		pathname: `/challenge/issue/${challenge.id}`
							// 	});
							// }
						}}
					>
				
			   
    
				 {(challenge?.recipients[0]?.id == userID) ? getProfileImageIssuer(): getProfileImage()}
					{(challenge?.recipients[0]?.id == userID)?
						<Row style={{ paddingTop: "21px" }}>
							<Col>
								<Row>
									<StarRatingComponent
										name="user-rating"
										value={(displayUser && displayUser?.rating) ?? 0}
										editing={false}
										starColor={"var(--ion-color-text)"}
										emptyStarColor="#777"
										renderStarIcon={() => <IonIcon icon={star} />}
										renderStarIconHalf={() => <IonIcon icon={starHalf} />}
									/>
								</Row>

                
								<Row>
									<IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={person} />
									<IonText><strong>{challenge?.issuer?.full_name ?? ""}</strong></IonText>
								</Row>


								<Row>
									<IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={location} />
									<IonText style={{ fontSize: "0.8rem" }}>{truncate(getLocation(), 18)}</IonText>
								</Row>
								{(challenge.venue) ?
									<Row>
										{/* <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={map} /> */}
										<IonText><b>Venue:</b></IonText>
										<IonText style={{ fontSize: "0.8rem" }}>{challenge.venue}</IonText>
									</Row>
									: null
								}

								
							</Col>
							<AbsoluteBox>
								{(challenge.ranked) ? (
									<Pill>Ranked</Pill>
								) : null}

								<Pill>{getSport()}</Pill>
							</AbsoluteBox>
							
						</Row>
           : <Row style={{ paddingTop: "21px" }}>
								<Col>
								{(openchallenge==1) ?
									<Row>
										<IonText><strong>Open</strong></IonText>
									</Row>
							 :null}
							 

							      {(openchallenge==0)?
										(<Row>
											<StarRatingComponent
												name="user-rating"
												value={(displayUser && displayUser?.rating) ?? 0}
												editing={false}
												starColor={"var(--ion-color-text)"}
												emptyStarColor="#777"
												renderStarIcon={() => <IonIcon icon={star} />}
												renderStarIconHalf={() => <IonIcon icon={starHalf} />}
											/>
										</Row>):null}
              
                   {(openchallenge==0)?(
										<Row>
											<IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={person} />
											<IonText><strong>{challenge?.recipients[0]?.full_name ?? ""}</strong></IonText>
										</Row>
									 ):<Row>
									 <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={person} />
									 <IonText><strong>{challenge?.issuer?.full_name ?? ""}</strong></IonText>
								 </Row>}



										<Row>
											<IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={location} />
											<IonText style={{ fontSize: "0.8rem" }}>{truncate(getLocation(), 18)}</IonText>
										</Row>
										{(challenge.venue) ?
											<Row>
												{/* <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={map} /> */}
												<IonText><b>Venue:</b></IonText>
												<IonText style={{ fontSize: "0.8rem" }}>{challenge.venue}</IonText>
											</Row>
											: null
										}

										
									</Col>
									<AbsoluteBox>
										{(challenge.ranked) ? (
											<Pill>Ranked</Pill>
										) : null}

										<Pill>{getSport()}</Pill>
									</AbsoluteBox>
					 
				 </Row> }
				 
					</ColoredBox>
				</Col>
			</Row>
			<Row justify="flex-end">
				{(showActions) ? actionNodes : null}
	
			</Row>
			<IonModal isOpen={modalOpen}>
				<ReportChallenge onCloseClick={() => setModalOpen(false)} challenge={challenge} />
			</IonModal>
      
		</Col>

	
	)
}

export default Challenge;

const Row = styled.div<{ justify?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;

const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
	width: ${(props: any) => (props.width) ? props.width : 'auto'};
	flex: ${(props: any) => (props.flex) ? props.flex : 'unset'};
	align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;

const Dot = styled.div<{ size: number, color: string | undefined }>`
	height: ${(props: any) => `${props.size}px`};
	width: ${(props: any) => `${props.size}px`};
	background: var(--ion-color-${props => props.color});
	border-radius: 50px;
	border: 2px solid var(--ion-color-${props => props.color});
	margin: 5px;
`;

const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: ${props => `var(--ion-color-${props.textColor})`};
	${props => (props.type === 'outline') ? css` 
		border: 2.5px solid var(--ion-color-${props.color});` : css`
		background: var(--ion-color-${props.color});
	`}
`;

const AbsoluteBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 2px;
`;

const Pill = styled.div`
	background: white;
	color: black;
	box-shadow: 0 0 0 1px #333;
	border-radius: 10px;
	padding: 1px 10px 1px;
	margin: 4px 2px;
	font-size: 11px;
	text-align: center;
	width: min-content;
`;

const ActionButton = styled(IonButton)`
	--padding-top: 0;
	--padding-right: 0;
	--padding-bottom: 0;
	--padding-left: 0;
`;
