import { IonCol, IonIcon, IonItem, IonRow, IonText } from "@ionic/react";
import { clipboardOutline, location,calendar,map } from 'ionicons/icons';
import { timeAgo, toAppDateFormat, toAppFormat, toAppTimeFormat } from "../services/time";
import { truncate } from "../services/util";

import styled, { css } from "styled-components";
import {ReactNode,useEffect,useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";

interface TournamentCardProps {
  tournament: any
}

const TournamentCard: React.FC<TournamentCardProps> = ({tournament}) => {
   
  let history = useHistory();

  const [timeObj, setTimeObj] = useState({
		weekday_name: "Day",
		month_name: "Mon",
		month_day: "00",
		time_12: "00:00 AM"
	});
  
  return (
    // <IonItem routerLink={`/tournament/view/${tournament.id}`} detail={true}>
    //   <IonCol style={{padding: '10px 8px', background: '#7bb6e9', color:'#fff'}}>
    //     <IonRow className="ion-align-items-center ion-justify-content-between" style={{paddingBottom: '5px'}}>
    //       <IonText style={{fontSize: '18px'}}>{truncate(tournament?.title ?? "", 25)}</IonText>
    //     </IonRow>
    //     <IonRow className="ion-align-items-center">
    //       <IonCol style={{maxWidth: "22px"}}>
    //         <IonIcon style={{ marginRight: "5px", fontSize: "13px", color:'#fff' }}  icon={clipboardOutline} />
    //       </IonCol>
    //       <IonCol>
    //         <IonRow>
    //          <IonText style={{ fontSize: '13px'}}>{tournament.openSlots} open slots</IonText>
    //         </IonRow>
    //       </IonCol>
    //     </IonRow>
    //     <IonRow className="ion-align-items-center">
    //       <IonCol style={{maxWidth: "22px"}}>
    //         <IonIcon style={{ marginRight: "5px", fontSize: "13px", color:'#fff' }}  icon={calendar} />
    //       </IonCol>
    //       <IonCol>
    //         <IonRow>
    //          <IonText style={{ fontSize: '13px'}}>Open Date: {toAppDateFormat(tournament.tournament_start)}</IonText>
    //         </IonRow>
    //       </IonCol>
    //     </IonRow>
    //     <IonRow className="ion-align-items-center">
    //       <IonCol style={{maxWidth: "22px"}}>
    //         <IonIcon style={{ marginRight: "5px", fontSize: "13px", color:'#fff' }}  icon={map} />
    //       </IonCol>
    //       <IonCol>
    //         <IonRow>
    //          <IonText style={{ fontSize: '13px'}}>Venue: {tournament.venue} </IonText>
    //         </IonRow>
    //       </IonCol>
    //     </IonRow>
    //     <IonRow className="ion-align-items-center">
    //       <IonCol style={{maxWidth: '22px'}}>
    //         <IonIcon style={{ marginRight: "5px", fontSize: '13px', color:'#fff' }}  icon={location} />
    //       </IonCol>
    //       <IonCol>
    //         <IonRow>
    //           <IonText style={{fontSize: '12px'}}>{tournament.location}</IonText>
    //           {(tournament && tournament.distance_miles !== undefined && tournament.distance_miles !== null) ?
    //           (<IonText style={{fontSize: '12px', marginLeft: '10px'}}>({tournament.distance_miles?.toFixed(1)} miles away)</IonText>):null}
    //         </IonRow>
    //       </IonCol>
    //     </IonRow>
    //   </IonCol>
    // </IonItem>


    

    <Col style={{ padding: '10px 0 0' }}>
    <Row style={{ fontSize: '15px' }}>

   <Col>
        <Dot
          size={7}
          color=""
          style={{backgroundColor:"#67BDFD"}}
        />
    </Col>

      <Col width="65px" align="center" style={{ padding: "0 6px 0 0", fontSize: "12px", textAlign: "center", letterSpacing: "0.2px" }}>
        {/* <Row justify="center"><strong>{timeObj.weekday_name}</strong></Row>
        <Row justify="center">{timeObj.month_name}<span style={{ width: "5px" }}></span><strong>{timeObj.month_day}</strong></Row>
        <Row justify="center" style={{ "whiteSpace": "nowrap" }}>{timeObj.time_12}</Row> */}
        
        <Row justify="center"><strong>{toAppDateFormat(tournament.tournament_start)}</strong></Row>
        <Row justify="center"><strong>{toAppTimeFormat(tournament.tournament_start)}</strong></Row>
        
      </Col>

      <Col flex={1} >
        <ColoredBox
         type={'outline'}
         color={""}
         textColor={"black"}
         style={{backgroundColor:'#67BDFD'}}
          onClick={() => {
           //if (user && user.id) {
              history.push({
                //pathname: `${checkUrl()}${user.id}`
                pathname: `/tournament/view/${tournament.id}`
              });
            //}
          }}
        >
          <Dot
           size={7}
           color="Green"
         />
          
          
      <Row style={{ paddingTop: "21px" }}>
				<Col>
          <Row>
            <IonText style={{ fontSize: "0.8rem" }}>{tournament?.title}</IonText>
          </Row>
          <Row>
              <IonText>Open Slots:&nbsp;</IonText>
              <IonText style={{fontSize: "0.8rem"}}>{tournament.openSlots}</IonText>
          </Row>

          <Row>
              <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={location} />
              <IonText style={{fontSize: "0.8rem"}}>{tournament.location}</IonText>
          </Row>
          <Row>
            <IonText>Venue:&nbsp;</IonText>
            <IonText style={{ fontSize: "0.8rem" }}>{tournament.venue}</IonText>
          </Row>
          </Col>
          </Row>
        </ColoredBox>
      </Col>
    </Row>

  </Col>


	)
}

export default TournamentCard;




const Row = styled.div<{ justify?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;

const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
	width: ${(props: any) => (props.width) ? props.width : 'auto'};
	flex: ${(props: any) => (props.flex) ? props.flex : 'unset'};
	align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;
const Dot = styled.div<{ size: number, color: string | undefined }>`
	height: ${(props: any) => `${props.size}px`};
	width: ${(props: any) => `${props.size}px`};
	background: var(--ion-color-${props => props.color});
	border-radius: 50px;
	border: 2px solid var(--ion-color-${props => props.color});
	margin: 5px;
`;
const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: ${props => `var(--ion-color-${props.textColor})`};
	${props => (props.type === 'outline') ? css` 
		border: 2.5px solid var(--ion-color-${props.color});` : css`
		background: var(--ion-color-${props.color});
	`}
`;

const AbsoluteBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 2px;
`;

const RankBox = styled.div`
	position: absolute;
	background:#fff;
	top: 28px;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 5px;
	font-weight:700;
`;
const Pill = styled.div`
	background: white;
	color: black;
	box-shadow: 0 0 0 1px #333;
	border-radius: 10px;
	padding: 1px 10px 1px;
	margin: 4px 2px;
	font-size: 11px;
	text-align: center;
	width: min-content;
`;
