import { IonIcon } from "@ionic/react";
import { refresh } from "ionicons/icons";
import styled from "styled-components";

const ErrorMessage: React.FC<{retryMethod?: () => void }> = ({retryMethod, children}) => {
	return (
		<StyledErrorMessage onClick={retryMethod}>
			{(retryMethod) ? (<IonIcon icon={refresh} style={{marginRight: '10px'}}></IonIcon>):null}
			{children}
		</StyledErrorMessage>
	)
}

export default ErrorMessage;

const StyledErrorMessage = styled.div`
	background: var(--ion-color-danger);
	color: #fff;
	padding: 8px 20px;
	border-radius: var(--radius);
	display: flex;
	justify-content: center;
	align-items: center;
	margin: var(--horizontal-gutter);
`;