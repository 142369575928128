import { IonCard, IonCardHeader, IonCardTitle, IonContent, IonPage, IonRouterLink } from '@ionic/react';
import Stats from '../components/Stats';
import TeamStats from '../components/TeamStats';
import useChallenges from '../hooks/useChallenges';
import { generateRandomString, getLinearGradient } from '../services/util';
import Podium from '../components/Podium';
import useUser from '../hooks/useUser';
import { RouteComponentProps } from 'react-router';
import useLeaders from 'hooks/useLeaders';
import styled from 'styled-components';
import CenteredSpinner from 'components/CenteredSpinner';
import { ReactNode, useEffect, useRef, useState } from 'react';
import React from 'react';
import WelcomeCard from 'components/WelcomeCard';
import RankCard from 'components/RankCard';
import WaveTitle from 'components/WaveTitle';
import LoggedInUser from 'components/LoggedInUser';
import { Challenges, Tournaments,Users } from 'services/api';
import useAuth from 'hooks/useAuth';
import useAsyncEffect from 'use-async-effect';
import ChallengesList from 'components/ChallengesList';
import { setDefaultHandler } from 'workbox-routing';


const DashboardPage: React.FC<RouteComponentProps> = (props) => {
	const  id  = localStorage.getItem('id');
	const userIdString=id?.toString();
	const { token } = useAuth();
	const { user } = useUser();
	const { nextChallenge,tournaments,events  } = useChallenges(userIdString);
	const {refreshChallenges} = useChallenges();
	const {leaders, leadersLoading} = useLeaders();
	const [leaderNodes, setLeaderNodes] = useState<ReactNode[]>([]);
	const [leadersCache, setLeadersCache] = useState<User[]>([]);
	const [challengesModified, setChallengesModified] = useState<any>('');
	const [matchesCount, setMatchesCount] = useState<number>();
	const [tournamentsModified, setTournamentsModified] = useState<number>();
	const [teamCaptainStatus, setCaptainStatus] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [pastcountvalue, setPastCount] = useState<any>('');
	const [upcomingcount, setUpcomingCount] = useState<any>('');

  //console.log("Team status", teamCaptainStatus);
 
	const isUserProfile = () => {
		if (token === null) return false;
		return (id === undefined || id == token);
	}

  // const getdata = (async () => {
	// 	let response = await Users.getCurrentUser(token ?? "");
	// 	if (response?.success){
	// 		  setIsLoading(false);
	// 	    setCaptainStatus(response.payload.team_captain);
	// 	}
	// })



	useAsyncEffect(async () => {
    let response = await Users.getCurrentUser(token ?? "");
  	if (response?.success){
	 		  setIsLoading(false);
	 	    setCaptainStatus(response.payload.team_captain);
	 	}


		let result = await Challenges.getPending(token ?? "","");
		//console.log("Total pending", result);
		// let result = await Challenges.getNumModified(token ?? "");
		if (result?.success){
				setChallengesModified(result.payload.total_count);
		}
   ///Count total past data by Rakesh on 23.12.2022
    let pastcount = await Challenges.getPast(token ?? "","");
		if(pastcount.success){
        setPastCount(pastcount.payload.new_count);
		}

		///Count upcomming data by Rakesh on 23.12.2022
		let upcommingcount = await Challenges.getUpcoming(token ??"","");
		//console.log("Upcomming", upcommingcount);
		if(upcommingcount.success){
        setUpcomingCount(upcommingcount.payload.total_count);			  
		}


		let matches_result = await Challenges.totalMatchesRequest(token ?? "");
		if (matches_result?.success){
			setMatchesCount(matches_result.payload);
		}


},[token])
	

	// useEffect(() => {
	// 	getdata();
	// },[token])

  
	let totalCountValue = pastcountvalue + upcomingcount + challengesModified;
	
	// console.log("Pastcount value", pastcountvalue);
	// console.log("Upcoming count", upcomingcount);
	// console.log("Challene modify", challengesModified);
	// console.log("Total count", totalCountValue);
  //console.log("Loading value", isLoading);

	return (
		<IonPage>
			<IonContent fullscreen>
				<WaveTitle>
					<Title>
						<h1>Your Active Games</h1>
						<h2>Dashboard</h2>
					</Title>
				</WaveTitle>

				<LoggedInUser />
				<Stats />
        {/* {(teamCaptainStatus=='false') ? <TeamStats />: null} */}


			 {/* {(!isLoading)  ? (
        <><LoggedInUser /><Stats /></>
        ) : (
        <CenteredSpinner />
      )}
			{(teamCaptainStatus=='false') ? <TeamStats />: null} */}

				<IonCard>
				<CardRow>
					<Tile
						routerLink="/challenges" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
					>
						<IonCardHeader>
							{(totalCountValue) ? <Notification>{totalCountValue}</Notification>:null}
							<TileTitle color="main" style={{textAlign:'center'}}>Your Active Games</TileTitle>
						</IonCardHeader>
					</Tile>
					<Tile
						routerLink="/leaderboard" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
						disabled={true}
					>
						<IonCardHeader>
							<TileTitle color="main" style={{textAlign:'center'}}>Leaderboard(Coming Soon)</TileTitle>
						</IonCardHeader>
					</Tile>
					
				</CardRow>

				<CardRow>
					<Tile
						routerLink="/search/friend" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
					>
						<IonCardHeader>
							<TileTitle color="main" style={{textAlign:'center'}}>Friends</TileTitle>
						</IonCardHeader>
					</Tile>
					<Tile
						routerLink="/teams" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
						//disabled={teamCaptainStatus}
						disabled={true}
					>
						<IonCardHeader>
							<TileTitle color="main" style={{textAlign:'center'}}>Teams(Coming Soon)</TileTitle>
						</IonCardHeader>
					</Tile>
				</CardRow>

				<CardRow>
			  	<Tile
						routerLink="/challengesearch" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
					>
						<IonCardHeader>
						{(matchesCount) ? <Notification>{matchesCount}</Notification>:null}
							<TileTitle color="main" style={{textAlign:'center'}}>Matches</TileTitle>
						</IonCardHeader>
					</Tile>
					<Tile
						//routerLink="/tournaments"
						routerLink="/tournamentlist" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
					>
						<IonCardHeader>
							{(tournamentsModified) ? <Notification>{tournamentsModified}</Notification>:null}
							<TileTitle color="main" style={{textAlign:'center'}}>Tournaments</TileTitle>
						</IonCardHeader>
					</Tile>
				</CardRow>

				<CardRow>
					<Tile
						routerLink="/eventlist" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
					>
						<IonCardHeader>
							<TileTitle color="main" style={{textAlign:'center'}}>Events</TileTitle>
						</IonCardHeader>
					</Tile>

					<Tile
						routerLink="/search/match" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
					>
						<IonCardHeader>
							<TileTitle color="main" style={{textAlign:'center'}}>Find Games</TileTitle>
						</IonCardHeader>
					</Tile>
				</CardRow>
      </IonCard>
				

				{(user && user.id) ? (
						<ChallengesList
						title="Next Challenges"
						tournament={tournaments}
						challenges={nextChallenge}
						event={events}
						contextual_user_id={id ?? user.id}
						searchPrompt={isUserProfile()}
						hideTitleOnNoResults={!isUserProfile()}
						allowViewChallenge={isUserProfile()}
						showActions={isUserProfile()}
						refreshChallenges={() => refreshChallenges()}
						{...props}
					/>
					):null}
					
			</IonContent>
		</IonPage>
	);
};
 
export default DashboardPage;

const CardRow = styled.div`
	display: flex;
	& ion-card {
		flex: 1;
	}
	& ion-card:first-of-type {
		margin-right: 0;
	}
`;

const Title = styled.div`
	margin: 0;
	padding: 5px;
	& h1 {
		margin: 0;
		padding: 0;
		font-size: 27px;
	}
	& h2 {
		margin: 0;
		padding: 0;
		font-size: 30px;
		font-weight: bold;
	}
	font-family: var(--heading-font);
	font-weight: 700;
	text-align: center;
`;

const TileTitle = styled.div`
	font-size: 15px;
	font-family: var(--heading-font);
	color: black;
`;

const Tile = styled(IonCard)`
	position: relative;
	overflow: visible;
`;

const Notification = styled.div`
	background: red;
	color: white;
	position: absolute;
	top: -5px;
	right: -5px;
	width: 25px;
	height: 25px;
	border-radius: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;
	z-index: 999;
	font-family: var(--heading-font);
`;