import { IonButton, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel,IonText, IonList, IonPage, IonTextarea, IonTitle, IonToggle, IonToolbar } from "@ionic/react";
import PillRadios from "components/PillRadios";
import UsersSelect from "components/UsersSelect";
import useAuth from "hooks/useAuth";
import useDynamicInputs from "hooks/useDynamicInputs";
import useToast from "hooks/useToast";
import useUser from "hooks/useUser";
import { useContext , useState } from "react";
import { Tournaments } from "services/api";
import { joinISODateAndTime } from "services/time";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import SportsDropdown from "./SportsDropdown";
import { FilterContext } from "../State/FilterContext";
import ResultsPage from "pages/ResultsPage_bac16.06";



const AddTournament: React.FC<{
}> = () => {
  const history = useHistory();
  const { user } = useUser();
  const { token } = useAuth();
  const { state, dispatch } = useContext(FilterContext);

  const { sports } = useDynamicInputs();
  const [title, setTitle] = useState<string>("");
  //const [sport, setSport] = useState<string>(user && user.sports[0]?.name || "");
  const [tournament_start, setStart] = useState<string>("");
  const [tournament_start_date, setStartDate] = useState<string>("");
  const [tournament_start_time, setStartTime] = useState<string>("");
  const [tournament_end_date, setEndDate] = useState<string>("");
  const [tournament_end_time, setEndTime] = useState<string>("");
  const [tournament_end, setEnd] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [locstate, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");


  const [venue, setVenue] = useState<string>("");
  const [open_invite, setOpenInvite] = useState<boolean>(false);
  const [selected, setSelected] = useState<User[]>([]);
  const [slots, setSlots] = useState<string>("4");
  const [rules, setRules] = useState<string>("");
  const [toast] = useToast("Hello");
  const[max,  setMax] = useState(500);
  const[min, setMin] = useState(500);

  const[maxRate, setMaxrate] = useState<number>(500);
  const[minRate, setMinrate] = useState<number>(500);

  const [maxSpread, setMaxspread] = useState(500);
  const [minSpread, setMinspread] = useState(150);
  const [ranked, setRanked] = useState<boolean>(false);

  const INITIAL = '';
  const[sportsval, setSportsValue] = useState(INITIAL);


  const newDate = new Date()
  const date = newDate.getDate();
  let dateValue:any;
  if(date < 10){
    dateValue= '0'+date;
  }else{
    dateValue = date;
  }
  const month = newDate.getMonth() + 1;
  let monthValue:any;
  if(month<10){
    monthValue = '0'+month;
  }else{
    monthValue = month;
  }
  let year = newDate.getFullYear();
  let getCurrentDate = year+'-'+monthValue+'-'+dateValue;
  // console.log("Current date value", getCurrentDate);


   // event handler for new sports selectiong
	// const setSelectedSports = (selectedSports: string[]) => {
  //   console.log("selected sports value", selectedSports);
  //   console.log("Match filter report", state.matchFilters.sports);
	// 	if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sports)) {
	// 		dispatch({ type: "tournament_setSelectedSports", payload: selectedSports });
	// 	}
	// }


  const setSelectedSports = (selectedSports: string[]) => {
		if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sports)) {
			dispatch({ type: "match_setSelectedSports", payload: selectedSports });
      
		}
	}


  const getMaxAsNumber = () => {
    if (!isNaN(parseInt(slots))) {
      return parseInt(slots);
    } else {
      return null;
    }
  }

  // Function is called everytime increment button is clicked
	const increment_rat = () => {
		// Counter state is incremented
		setMinrate(minRate + 10)
	}

	// Function is called everytime decrement button is clicked
	const decrement_rat = () => {
		// Counter state is decremented
		setMinrate(minRate - 10)
	}

  ///Added Spread Value by Rakesh
  const spreadinc = () =>{
    setMinspread(minSpread + 10);
  }

  const spreaddec = () =>{
    setMinspread(minSpread - 10);
  }

 
  return (
        <IonList>
          <IonItem className="form-input" data-name="sport">
            {/* <IonLabel>Sport</IonLabel> */}
            {/* <PillRadios
              options={(sports.map((each: Sport) => each.name)) ?? []}
              value={sport}
              onChange={(selectedSport: string) => setSport(selectedSport)}
            /> */}
            </IonItem>
          <SportsDropdown
                  multiple={false}
                  onChange={(sports:any) => {
                    setSelectedSports(sports);
                    setSportsValue(sports);
                    
                  }}
                  selectedSports={state.matchFilters.sports}
                  />
         
          <IonItem className="form-input" data-name="title">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>Title</IonLabel>
            <IonInput
              name="title"
              type="text"
              placeholder="Tournament Title"
              value={title}
              onIonChange={(e) => {
                setTitle(e.detail.value as string);
              }}
            />
          </IonItem>
          
          <TimeRow>
            <IonItem className="form-input" data-name="tournament_start_date">
              <IonLabel position="stacked" style={{fontSize:"23px"}}>Start Date</IonLabel>
              <IonDatetime
                name="tournament_start_date"
                min={getCurrentDate}
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                value={tournament_start_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({ localDate });
                  setStartDate(localDate);
                  setEndDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="tournament_start_time">
              <IonLabel position="stacked" style={{fontSize:"23px"}}>Start Time</IonLabel>
              <IonDatetime
                minuteValues="0,10,20,30,40,50"
                name="tournament_start_time"
                displayFormat="h:mm A"
                placeholder="Select Start Time"
                value={tournament_start_time}
                onIonChange={e => {
                  let localTime = e.detail.value!;
                  console.log({ localTime });
                  setStartTime(localTime);
                }}
              ></IonDatetime>
            </IonItem>
          </TimeRow>
          <TimeRow style={{display:"none"}}>
            <IonItem className="form-input" data-name="tournament_end_date">
              <IonLabel position="stacked" style={{fontSize:"23px"}}>End Date</IonLabel>
              <IonDatetime
                name="tournament_end_date"
                min={tournament_start_date}
                displayFormat="MMM DD YYYY"
                placeholder="Select End Date"
                value={tournament_end_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  setEndDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>

            <IonItem className="form-input" data-name="tournament_end_time">
                <IonLabel position="stacked" style={{fontSize:"23px"}}>End Time</IonLabel>
                <IonDatetime
                  name="tournament_end_time"
                 // min={tournament_start_time}
                  displayFormat="h:mm A"
                  placeholder="Select End Time"
                  value={tournament_end_time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setEndTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
          </TimeRow>

          {/* <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked">Location</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="City, State, or Zip Code"
              value={location}
              onIonChange={(e) => {
                setLocation(e.detail.value as string);
              }}
            />
          </IonItem> */}

          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>City</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="City"
              value={location}
              // onIonChange={(e) => {
              //   setLocation(e.detail.value as string);
              // }}
              onKeyUp={(event:any) => {
                let cityVal = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(cityVal)){
                     event.target.value = cityVal.slice(0, -1);
                }
                setLocation(event.target.value);
              }}
            
              onKeyPress={(event:any) => {
                let cityVal = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(cityVal)){
                    event.target.value = cityVal.slice(0, -1);
                }
                setLocation(event.target.value);
              }}

            />
          </IonItem>

          <IonItem className="form-input" data-name="state">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>State</IonLabel>
            <IonInput
              name="state"
              type="text"
              placeholder="State"
              value={locstate}
              // onIonChange={(e) => {
              //   setState(e.detail.value as string);
              // }}
              onKeyUp={(event:any) => {
                   let stateVal = event.target.value;
                   let regExp = new RegExp('^[A-Za-z? ]+$');
                   if(!regExp.test(stateVal)){
                      event.target.value =  stateVal.slice(0,-1);
                   }
                   setState(event.target.value);
              }}

              onKeyPress= {(event: any)=> {
                let stateVal = event.target.value;
                let regExp = new RegExp('^[A-Za-z? ]+$');
                if(!regExp.test(stateVal)){
                   event.target.value =  stateVal.slice(0,-1);
                }
                setState(event.target.value);
              }}
              

            />
          </IonItem>

          <IonItem className="form-input" data-name="zip">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>Zip Code</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="Zip Code"
              value={zip}
              // onIonChange={(e) => {
              //   setZip(e.detail.value as string);
              // }}
              onKeyUp={(event:any) => {
                let zip = event.target.value;
                let regExp = new RegExp('^[0-9]*$');
                if(!regExp.test(zip)){
                     event.target.value = zip.slice(0, -1);
                }
                setZip(event.target.value);
              }}

              onKeyPress={(event:any) => {
                let zip = event.target.value;
                let regExp = new RegExp('^[0-9]*$');
                if(!regExp.test(zip)){
                    event.target.value = zip.slice(0, -1);
                }
                setZip(event.target.value);
              }}

            />
          </IonItem>

          <IonItem className="form-input" data-name="title">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>Venue</IonLabel>
            <IonInput
              name="title"
              type="text"
              placeholder="Venue"
              value={venue}
              onIonChange={(e) => {
                setVenue(e.detail.value as string);
              }}
            />
          </IonItem>

        <IonItem>
          <IonLabel style={{fontSize:"20px"}}>Ranked</IonLabel>
          <IonToggle color="success" checked={ranked} onIonChange={e => setRanked(e.detail.checked)} />
        </IonItem>


        <IonItem>
          <IonLabel style={{fontSize:"20px"}}>Rating</IonLabel>
          {/* <IonText>{max}</IonText> */}
          <IonButton color="main" fill="clear" onClick={() => { increment_rat() }}>+</IonButton>/
          <IonButton color="main" fill="clear" onClick={() => { decrement_rat() }}>-</IonButton>
          <IonText>{minRate}</IonText>
        </IonItem>

         <IonItem>
             <IonLabel style={{fontSize:"20px"}}>Spread</IonLabel>
             {/* <IonText>{maxSpread}</IonText> */}
             <IonButton color="main" fill="clear" onClick={() => {spreadinc()}}>+</IonButton>/
             <IonButton color="main" fill="clear" onClick={() => {spreaddec()}}>-</IonButton>
             <IonText>{minSpread}</IonText>
         </IonItem>

        
          <IonItem style={{display:'none'}}>
            <IonInput onIonChange={(e) => { setMaxrate(e.detail.value as unknown as number)}} value={max} hidden></IonInput>
          </IonItem>

          <IonItem style={{display:'none'}}>
            <IonInput onIonChange={(e)=> {setMinrate(e.detail.value as unknown as number)}} value={min} hidden></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel style={{fontSize:"20px"}}>Open Invite</IonLabel>
            <IonToggle color="success"  checked={open_invite} onIonChange={e => setOpenInvite(e.detail.checked)} />
          </IonItem>
          <IonItem>
            <IonLabel style={{fontSize:"20px"}}>Slots</IonLabel>
            <IonInput
              slot="end"
              name="slots"
              min='4'
              type="number"
              value={Number(slots)}
              onIonChange={(e) => {
                if(e.detail.value=='0'){
                  setSlots('4');
                }else{
                  setSlots(e.detail.value as string);
                }
              }}
              style={{ textAlign: 'right', marginRight: '25px' }}
            ></IonInput>
          </IonItem>

          <IonItem className="form-input" data-name="rules">
            <IonLabel position="stacked" style={{fontSize:"24px"}}>Rules</IonLabel>
            <IonTextarea 
             name="rules"
             placeholder="Rules"
             value={rules}
             auto-grow={false}
             rows={3}
             spellCheck={true}
             onIonChange={(e) =>{
               setRules(e.detail.value as string);
             }}
            
            ></IonTextarea>
          </IonItem>

          <IonItem className="form-input" data-name="message">
            <IonLabel position="stacked" style={{fontSize:"24px"}}>Message</IonLabel>
            <IonTextarea
              name="message"
              placeholder="Message"
              value={message}
              auto-grow={false}
              rows={3}
              spellCheck={true}
              onIonChange={e => setMessage(e.detail.value!)}
            ></IonTextarea>
          </IonItem>

          <IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (!e.currentTarget)
              e.preventDefault();
              //console.log("Min", min);
              //console.log("Max", max); return;
              //console.log("Participants count", selected.length); return;
              //console.log("Rating Value",minRate);
              //console.log("Spread value", minSpread); return;
              //console.log(state.matchFilters.sports); return;
              //console.log("Sports value", sportsval); return;

              // if (state.matchFilters.sports.length==0) {
              //   toast({ message: 'Please select a sport' }, true);
              //   return;
              // }

             if(sportsval ==''){
                 toast({message: 'Please select sports from drop down'}, true);
                 return;
             }

              if (!title) {
                toast({ message: 'Please enter tournament title' }, true);
                return;
              }

              if (!(tournament_start_date && tournament_start_time)) {
                toast({ message: 'Start date & time is required' }, true);
                return;
              }
              const tournament_start = joinISODateAndTime(tournament_start_date, tournament_start_time);

              if (!location) {
                toast({ message: 'Please enter city' }, true);
                return;
              }

              if (!locstate) {
                toast({ message: 'Please enter state' }, true);
                return;
              }
              
              if (!zip) {
                toast({ message: 'Please enter zip code' }, true);
                return;
              }
              

              if (!venue) {
                toast({ message: 'Please enter venue' }, true);
                return;
              }

              if(!rules){
                toast({ message: 'Please enter rule'}, true);
                return;
             }

              

              // if (!(tournament_end_date && tournament_end_time)){
              //   toast({message: 'End data & time is required'}, true);
              //   return;
              // }
              // const tournament_end = joinISODateAndTime(tournament_end_date, tournament_end_time);
             
              // if(selected.length < 4){
              //     toast({message: 'Please select at least four particiapnats'}, true);
              //     return;
              // }
              

              let args = {
                title,
                open_invite,
                sport:state.matchFilters.sports,
                location,
                locstate,
                zip,
                venue,
                tournament_start,
                tournament_end,
                message,
                selected,
                slots,
                rules,
                minRate,
                minSpread,
                ranked
              }
              
              //console.log("Form value", args); return;
              let response = Tournaments.create(token ?? "", args);
              if (response) {
                response.then(function (data) {
                  if (data && data.success) {
                    toast({ message: "Tournament Created" });
                    //window.location.reload();
                    history.push('/tournamentlist');
                    setTitle("");
                    setStartDate("");
                    setStartTime("");
                    setEndDate("");
                    setEndTime("");
                    setMessage("");
                    setLocation("");
                    setState("");
                    setZip("");
                    setVenue("");
                    setOpenInvite(false);
                    setSelected([]);
                    setSlots("");
                    setRules("");
                    setRanked(false);
                    
                  } else {
                    toast({ message: "Problem Creating Tournament" }, true);
                  }
                })
              }
            }}
          >
            Submit
          </IonButton>
          
          <UsersSelect selected={selected} setSelected={setSelected} max={getMaxAsNumber()} />
          
        </IonList>
  );
}

export default AddTournament;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;

