import { IonRouterLink, IonCard, IonCardHeader, IonPage, IonContent, IonRange, IonCol, IonRow, IonSegment, IonSegmentButton, IonLabel, IonItem, IonPopover, IonInput, IonRefresher, IonRefresherContent, IonText, IonIcon } from "@ionic/react";
import { useContext, useEffect, useRef, useState,ReactNode } from "react";
import SearchResults from "../components/SearchResults";
import SportsDropdown from "../components/SportsDropdown";
import { Users } from "../services/api";
import { RefresherEventDetail } from '@ionic/core';
import useStateCallback from "../hooks/useStateCallback";
import useAuth from "../hooks/useAuth";
import useUser from "hooks/useUser";
import { RouteComponentProps } from "react-router";
import { usePosition } from "hooks/usePosition";
import useDynamicInputs from "hooks/useDynamicInputs";
import GenderCheckboxes from "components/GenderCheckboxes";
import styled from "styled-components";
import { caretDown, caretUp } from "ionicons/icons";
import CommonHeader from "components/CommonHeader";
import GroupedSearchResults from "components/GroupedSearchResults";
import { FilterContext } from "State/FilterContext";
import { convertGenderStringToGenderObject } from "services/util";
import RankCard from 'components/RankCard';
import { generateRandomString, getLinearGradient } from '../services/util';
import CenteredSpinner from 'components/CenteredSpinner';
import useLeaders from 'hooks/useLeaders';
import Podium from '../components/Podium';

const LeaderboardPage: React.FC<RouteComponentProps> = ({history}) => {
	const {token} = useAuth();
	const {user} = useUser();
	const timeoutID = useRef<number>();
	const [popoverState, setShowPopover] = useState(false);
	const [results, setResults] = useState<any>([]);
	const {genders, gendersStringsToObjects} = useDynamicInputs();
	const [zip, setZip] = useStateCallback<string>(user?.preference?.primary_address?.postal_code ?? "");
	const {position, prompt} = usePosition();
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [shouldGroup, setShouldGroup] = useState<boolean>(true);
	const {state, dispatch} = useContext(FilterContext);
	const [filtersCache, setFiltersCache] = useState<LeaderBoardFilters>(() => {
		return state.leaderboardFilters;
	})
	const { leaders, leadersLoading } = useLeaders();
	const [leaderLoadStatus, setleaderLoadStatus] = useState<number>(1);
	const [leadersCache, setLeadersCache] = useState<User[]>([]);
	const [leaderNodes, setLeaderNodes] = useState<ReactNode[]>([]);

	const getLeaders = (leaders: User[]) => {
		if (JSON.stringify(leaders) !== JSON.stringify(leadersCache)) {
			setLeadersCache(leaders);
			let newLeaderNodes = leaders.map(leader => (
				<RankCard
					key={generateRandomString()}
					user={leader}
					lines="none"
					style={{ "--background": "transparent" }}
					textColor="main"
				/>
			));
			setLeaderNodes(newLeaderNodes);
		} else {
			return leaderNodes;
		}
	}
	const checkOrigin = async (filters: any) => {
		console.log('==checkOrigin()');
		if (
				// origin not set as location string
				(filters['origin'] === null || filters['origin'] === '') &&
				// position has been aquired 
				(position?.coords?.latitude && position?.coords?.longitude)
		){
			console.log('using browser location as origin');
			const { latitude, longitude } = position.coords;
			filters['origin'] = {
				lat: latitude,
				lon: longitude
			}
		} else if (filters['origin'] !== "" && typeof filters['origin'] === 'string') {
			console.log({origin: filters['origin']});
		} else {
			console.log('prompting', filters);
			let newPosition = await prompt();
			console.log('newPosition', newPosition);
			if (newPosition !== null){
				console.log('setting new position', newPosition);
				filters['origin'] = newPosition.coords;
			} else {
				console.log('no location');
			}
		}
		return filters;
	}

	const loadLeaderboard = async (event: CustomEvent<RefresherEventDetail> | null) => {
		const filters = await getFilters();
		//console.log("LEADERBOARD FILTERS", filters);
		let response;
		if (filters['sport'] === 'all' || filters['sport'] === ''){
			response = Users.getSportsLeaders(token ?? "", filters);
		} else {
			response = Users.getLeaders(token ?? "", filters);
		}
		
		if (response){
			response.then(function(data: any){
				
				if (data.success){
					console.log('search payload', data.payload);
					setResults(data.payload);
				} else {
					console.error('failed search', data.message);
				}
				if (event){
					event.detail.complete();
				}
			});
		}
	}

	const checkGrouping = (filters: LeaderBoardFilters) => {
		if (filters['sport'] === 'all' || filters['sport'] === ''){
			setShouldGroup(true);
		} else {
			setShouldGroup(false);
		}
	}

	const getFilters = async () => {
		let filters: LeaderBoardFilters = {
			time: state.leaderboardFilters.time,
			distance: state.leaderboardFilters.distance,
			genders: state.leaderboardFilters.genders,
			ageRange: state.leaderboardFilters.ageRange,
			origin: state.leaderboardFilters.origin,
			sport: state.leaderboardFilters.sport
		}
		filters = await checkOrigin(filters);
		checkGrouping(filters);
		return filters;
	}

	const updateDistance = (e: any) => {
		dispatch({type: 'leaderboard_setDistance', payload: e.target.value});
		//setDistance(e.target.value);
		setleaderLoadStatus(3);
	}

	const updateAge = (e: any) => {
		dispatch({type: 'leaderboard_setAgeRange', payload: e.target.value});
		//setAge(e.target.value);
		setleaderLoadStatus(3);
	}

	// event handler for new gender selection
	const updateGenderSelection = (checked: boolean, gender_change: string) => {
		let selectedGenders = state.matchFilters.genders;
		const newGenderArr: string[] = selectedGenders;
		if (checked){
			if (newGenderArr.indexOf(gender_change) < 0){
				newGenderArr.push(gender_change);
			}
		} else {
			const index = newGenderArr.indexOf(gender_change);
			if (index > -1) {
				newGenderArr.splice(index, 1);
			}
		}
		let newGenderObjsArr = newGenderArr.map((genderStr) => convertGenderStringToGenderObject(genderStr, genders));
		console.log("newGenderObjsArr", {newGenderObjsArr});
		if (JSON.stringify(newGenderObjsArr) !== JSON.stringify(state.matchFilters.genders)){
			dispatch({type: 'leaderboard_setGenders', payload: newGenderObjsArr});
		}
		setleaderLoadStatus(3);
  }

	// event handler for new sports selectiong
	const setSelectedSports = (selectedSport: string) => {
		if (selectedSport === undefined){
			selectedSport = 'all';
		}
		console.log('selectedSport', selectedSport,state.leaderboardFilters.sport);
		if (selectedSport !== state.leaderboardFilters.sport){
			dispatch({type: "leaderboard_setSport", payload: selectedSport});
		}
	}

	//load leader board with default values
	useEffect(() => {
		if (JSON.stringify(state.leaderboardFilters) !== JSON.stringify(filtersCache) || true){
			loadLeaderboard(null);
			setFiltersCache(state.leaderboardFilters);
		}
	}, [
		user,
		token,
		state.leaderboardFilters
	])

	

	// setup polling interval to detect change to distance
	/*
	useEffect(() => {
		if (timeoutID.current){
			clearTimeout(timeoutID.current);
		}
		timeoutID.current = window.setTimeout(function(){
			loadLeaderboard(null);
		}, 500);
		return () => clearTimeout(timeoutID.current);
	}, [state.leaderboardFilters.distance]);
	*/
	
	const show_time_filters = false;
	const use_zip_popup = false;

	return (
		<IonPage>
			<CommonHeader title={`Leaderboard`}/>
			<IonContent fullscreen>
				<div>
					<FiltersSection>
						<FiltersHead onClick={() => setShowFilters(!showFilters)}>
							<FiltersTitle>Filters</FiltersTitle>
							<IonIcon slot="end" icon={(showFilters) ? caretUp:caretDown} />
						</FiltersHead>
						<FiltersBody show={showFilters}>
							{
								(show_time_filters) ? (
									<div style={{ margin: "0 var(--horizontal-gutter)" }}>
										<IonSegment
											onIonChange={e => {
												dispatch({type: 'leaderboard_setTime', payload: e.detail.value as LeaderBoardTime});
												//setTime(e.detail.value as LeaderBoardTime)
											}}
											value={state.leaderboardFilters.time}
											mode="ios"
										>
											<IonSegmentButton value="all-time">
												<IonLabel>All Time</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="this-month">
												<IonLabel>This Month</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="this-week">
												<IonLabel>This Week</IonLabel>
											</IonSegmentButton>
										</IonSegment>
									</div>
								):null
							}
							<SportsDropdown 
								onChange={(sport) => {
								//console.log("sports data", sport[0]);
									if (sport && sport.length && sport.length > 0){
										setSelectedSports(sport[0]);
										
									}
								}}
								selectedSports={[state.leaderboardFilters.sport]}
								prepend={[{name: "All", value: ''}]}
							/>
							<IonItem>
							<div className="emaillab">
								<IonLabel position="stacked">Location</IonLabel>
							</div>
								<IonInput
									type="text"
									onIonChange={(e) => {
										let newLocation = e.detail.value;
										dispatch({type: 'leaderboard_setLocation', payload: (typeof newLocation === 'string') ? newLocation:""});
										//setLocation((typeof newLocation === 'string') ? newLocation:"");
									}}
									name="text"
									placeholder="City, State, or Zip Code"
									value={(typeof state.leaderboardFilters.origin === 'string') ? state.leaderboardFilters.origin:""}
								/>
							</IonItem>
							<IonCol style={{padding: "var(--horizontal-gutter)"}}>
								{
									(use_zip_popup) ? (
										<IonRow style={{padding: "var(--horizontal-gutter)"}} className="ion-justify-content-end">
												<strong>{state.tournamentFilters.distance}</strong>
												<span style={{margin: '0 5px'}}>miles from</span>
													<>
														<IonPopover
															isOpen={popoverState}
															onDidDismiss={() => {
																setShowPopover(false)
																loadLeaderboard(null);
															}}
														>
															<IonItem lines="none" >
																<IonLabel position="stacked">Zip Code</IonLabel>
																<IonInput name="zip" onIonChange={(e) => setZip(e.detail.value as string, () => {})} placeholder="Enter Zip Code"/>
															</IonItem>
														</IonPopover>
														<div className="zip-popover" onClick={(e) => {setShowPopover(true)}}>
															<strong><u>{(zip) ? zip:"Zip Code"}</u></strong>
														</div>
													</>
										</IonRow>
									):null
								}
								<IonItem>
									<RowLabel position="floating">
										<IonText style={{fontSize:"21px"}}>Distance</IonText>
										<LabelValue>({state.leaderboardFilters.distance} miles)</LabelValue>
									</RowLabel>
									<IonRange pin={true} dualKnobs={false} min={0} max={300} name="distance" value={state.leaderboardFilters.distance} onIonChange={updateDistance}>
										<IonText slot="start">0</IonText>
										<IonText slot="end">300</IonText>
									</IonRange>
								</IonItem>
								<IonItem>
									<RowLabel position="floating">
										<IonText style={{fontSize:"21px"}}>Age</IonText>
										<LabelValue>{(state.leaderboardFilters.ageRange && state.leaderboardFilters.ageRange.lower && state.leaderboardFilters.ageRange.upper) ? `(${state.leaderboardFilters.ageRange.lower} - ${state.leaderboardFilters.ageRange.upper})`:null}</LabelValue>
									</RowLabel>
									<IonRange pin={true} dualKnobs={true} min={18} max={100} name="age" value={state.leaderboardFilters.ageRange} onIonChange={updateAge}>
										<IonText slot="start">18</IonText>
										<IonText slot="end">100</IonText>
									</IonRange>
								</IonItem>
								<GenderCheckboxes selectedGenders={state.leaderboardFilters.genders.map((gender: Gender) => gender.name)} onChange={updateGenderSelection}/>
							</IonCol>
						</FiltersBody>
					</FiltersSection>
					{
						(leaderLoadStatus == 1 || leaderLoadStatus == 2) ?
							<IonCard
								style={{ "--background": getLinearGradient('accent-two') }}
							>
								<IonRouterLink routerLink="/leaderboard">
									<IonCardHeader>
										<div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
											<TileTitle color="main" style={{ fontSize: '30px' }}>Leaderboard</TileTitle>
											<Podium height={45} width={17} />
										</div>
									</IonCardHeader>
								</IonRouterLink>
								{(leadersLoading) ? <CenteredSpinner /> :
									(leaders && leaders.length && leaders.length > 0) ? getLeaders(leaders) : null
								}
							</IonCard>

							: null
					}
				</div>
				<IonRefresher slot="fixed" onIonRefresh={loadLeaderboard}>
					<IonRefresherContent>
					</IonRefresherContent>
				</IonRefresher>
					
			  {
					(leaderLoadStatus == 3) ? 
				(() => {
					let filters = {
						time: state.leaderboardFilters.ageRange,
						distance: state.leaderboardFilters.distance,
						genders: state.leaderboardFilters.genders,
						ageRange: state.leaderboardFilters.ageRange,
						origin,
						sport: state.leaderboardFilters.sport
					}
					return (shouldGroup) ? (
						<GroupedSearchResults results={results} filters={filters} searchType="leaderboard" />
					):(
						<SearchResults results={results} filters={filters} searchType="leaderboard" />
					);
				})()
				: null
				}
			</IonContent>
		</IonPage>
	)
}

export default LeaderboardPage;

const FiltersSection = styled.div``;

const FiltersHead = styled(IonItem)`
	justify-content: space-between;
`;

const FiltersTitle = styled.div`
	font-size: 20px;
`;

const FiltersBody = styled.div<{show: boolean}>`
	display: ${props => (props.show) ? "block":"none"}
`;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	width: 100%;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;
const TileTitle = styled.div`
	font-size: 15px;
	font-family: var(--heading-font);
	color: black;
`;