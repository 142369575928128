import { useEffect, useState } from 'react';
import { AuthenticationService } from '../services/auth';

function useAuth(){

	// our current user in state/storage
	const [user, setUser] = useState({});

	// helper state props
	const [token, setToken] = useState(null);
	const [loggedIn, setLoggedIn] = useState(false);
	const [userID, setUserID] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);

	const restoreLoggedOutState = () => {
		setToken(null);
		setLoggedIn(false);
		setUserID(null);
	}

	const isUser = (current_user: any) => {
		if (
			(current_user === null) ||
			(current_user.token === undefined) ||
			(current_user.id === undefined) ||
			(current_user.errors)
		){
			return false;
		} else {
			return true;
		}
	}

	useEffect(() => {
		setLoading(true);
		AuthenticationService.currentUser.subscribe((current_user: any) => {
			if (!isUser(current_user)) {
				restoreLoggedOutState();
			} else {
				setUser(current_user);

				// set helper state props
				setLoggedIn(true);
				setToken(current_user.token);
				setUserID(current_user.id);
			}
			setLoading(false);
		});
		return AuthenticationService.currentUser.unsubscribe;
	}, [token])

	return {
		user,
		token,
		loggedIn,
		userID,
		loading
	};
}

export default useAuth;