import { IonButton, IonCheckbox, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonModal, IonRadio, IonRadioGroup } from "@ionic/react";
import { create, createOutline } from "ionicons/icons";
import { useState } from "react";
import styled from "styled-components";
import useDynamicInputs from "../hooks/useDynamicInputs";
import { generateRandomString, truncate } from "../services/util";
import AddAddressForm from "./AddAddressForm";
import EditAddressForm from "./EditAddressForm";

interface AddressesDropdownProps {
  selectedAddress: string | undefined
  onChange(value: string | undefined): void
  options?: any[]
  addresses?: any[]
  preferenceID?: string
  refreshUser: () => void
}

const AddressDropdown: React.FC<AddressesDropdownProps> = (props) => {
  const {addresses, refreshInputs} = useDynamicInputs();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editAddress, setEditAddress] = useState<Address>();

  const addressEdit = (id: string | undefined) => {
    if (id){
      const address = addresses?.find(addr => addr.id === id);
      setEditAddress(address);
      setEditModalOpen(true);
    }
  }

  const getAddressOptions = () => {
    let options: React.ReactNode[] = [];
    if (addresses && addresses.map !== undefined){
      options = addresses.map((address: Address) => {
        return (
          <AddressRow key={address.id}>
            <AddressEditIcon icon={createOutline} onClick={() => addressEdit(address.id)}/>
            <AddressSelect key={address.id} lines="none">
              <AddressText>{truncate(`${address.address_line_1}, ${address.city}, ${address.state}`, 35)}</AddressText>
              <IonRadio slot="end" value={address.id} />
            </AddressSelect>
          </AddressRow>
        )
      })
    }
    return options;
  }
  
  const selectAddress = (address_id: string | undefined) => {
    props.onChange(address_id);
  }
  
  return (
    <IonList>
      <IonListHeader>
      
        <IonLabel position="stacked" style={{fontSize:"22px"}}>Set Primary Address</IonLabel>
      
      </IonListHeader>
      <IonRadioGroup
        value={props.selectedAddress} 
        onIonChange={(e) => {
          selectAddress(e.detail.value);
        }}
        name="primary-address"
      >
        {getAddressOptions()}
      </IonRadioGroup>
      <IonItem lines="none">
        <IonButton  
          slot="end"
          size="small"
          onClick={() => setAddModalOpen(!addModalOpen)}
        >
          Add Address
        </IonButton>
      </IonItem>
      <IonModal isOpen={addModalOpen}>
        <AddAddressForm 
          onCloseClick={() => setAddModalOpen(false)}
          addresses={addresses}
          preferenceID={props.preferenceID}
          refreshUser={props.refreshUser}
          refreshInputs={refreshInputs}
        />
      </IonModal>
      <IonModal isOpen={editModalOpen}>
        <EditAddressForm 
          onCloseClick={() => setEditModalOpen(false)}
          addresses={addresses}
          address={editAddress}
          preferenceID={props.preferenceID}
          refreshUser={props.refreshUser}
          refreshInputs={refreshInputs}
        />
      </IonModal>
    </IonList>
  );
}

export default AddressDropdown;


const AddressRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AddressText = styled.div`
  font-size: 13px;
`;

const AddressSelect = styled(IonItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AddressEditIcon = styled(IonIcon)`
  margin-right: 14px;
  font-size: 20px;
`;