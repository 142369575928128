import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonRadioGroup, IonRouterLink, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToggle, IonToolbar } from "@ionic/react"
import ProfileImage from "components/ProfileImage";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import React, { ReactNode, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { Event, Users } from "services/api";
import useAsyncEffect from "use-async-effect";
import styled from 'styled-components';
import { generateRandomString } from "services/util";
import CenteredSpinner from "components/CenteredSpinner";
import { timeAgo, toAppDateFormat, toAppFormat, toAppTimeFormat } from "../services/time";
import { trash } from 'ionicons/icons';



interface EventPageProps extends RouteComponentProps<{
	event_view_id?: string;
}> { }

const EventPage: React.FC<EventPageProps> = ({ match }) => {
	const { event_view_id } = match.params;
	const { token, userID } = useAuth();
	const [toast] = useToast("");
	const [event, setEvent] = useState<Event>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const history = useHistory();
	const [type, setType] = useState<number>(0);
	const [data, setData] = useState<any>([]);
	const [emailData, setEmailData] = useState<any>([]);
	const [email, setEmail] = useState<string>("");

	const [selfdata, setSelfData] = useState<any>([]);

	const clickToProfilePage = (user_id: any) => {
		history.push(`/user/view/${user_id}`);
	}
	const getPlayers = ((users: User[], organizer_id: string) => {
		return (users.length > 0) ? users.map((user, index) => {
			// check if we have the user info we need
			if (user && user.id && user.preference) {
				return (
					<IonItem key={generateRandomString()} lines="none" routerDirection="forward">
						<Player
							className="ion-align-items-center"
							key={index}
							onClick={(e) => { clickToProfilePage(user.id) }}
						>
							<ProfileImage
								icon_type="none"
								icon_color="primary"
								size="75px"
								filename={user.preference.image}
							/>
							<IonCol>
								<IonText>{user.full_name}</IonText>
								<IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
									{user.preference.location}
								</IonRow>
							</IonCol>

						</Player>
						{/* {(userID==user.id) ?	 */}
						<IonButton
							size="default"
							color="danger"
							onClick={(e) => { removeSingleFriendEvent(user.id); }}>
							<IonIcon icon={trash} />
						</IonButton> 
						{/* : [] 
						} */}
					</IonItem>
				)
			}
		}) : (<IonItem><IonText>No Participants found</IonText></IonItem>)
	});


	const getData = async (id: string) => {
		if (!token) return;
		const result = await Event.view(token ?? "", id);
		if (result.success) {
			setIsLoading(false);
			setEvent(result.payload);
		} else {
			toast({ message: "Event does not exist" }, true);
		}
		return Event.viewCancel;
	}


	const eventJoin = async () => {
		try {
			let postData = {
				event_id: event_view_id
			}
			let response = await Event.joinEvent(token ?? "", postData);
			if (response.success == true) {
				getData(event_view_id ?? '');
				toast({ message: response.message });
			} else {
				toast({ message: response.message }, true);
			}
		} catch (error) {
			console.log('Event Join Error', error);
			toast({ message: error }, true);
		}
	};

	const cancelEvent = async () => {
		try {
			let postData = {
				event_id: event_view_id
			}
			let response = await Event.cancelEvent(token ?? "", postData);
			if (response.success == true) {
				toast({ message: response.message });
				history.push('/myevent');
			} else {
				toast({ message: response.message }, true);
			}
		} catch (error) {
			console.log('Event Join Error', error);
			toast({ message: error }, true);
		}
	};

	const addSingleFriendEvent = async (user_id: any) => {
		try {
			let postData = {
				event_id: event_view_id,
				user_id: user_id
			}
			let response = await Event.addSingleFriendEvent(token ?? "", postData);
			if (response.success == true) {
				getData(event_view_id ?? '');
				toast({ message: response.message });
			} else {
				toast({ message: response.message }, true);
			}

		} catch (error) {
			toast({ message: error }, true);
		}
	}

  /// Add myself to event
	const addMyselfEvent = async (user_id: any) => {
		try {
			let postData = {
				event_id: event_view_id,
				user_id: user_id
			}
			let response = await Event.addSingleFriendEvent(token ?? "", postData);
			if (response.success == true) {
				getData(event_view_id ?? '');
				toast({ message: response.message });
			} else {
				toast({ message: response.message }, true);
			}

		} catch (error) {
			toast({ message: error }, true);
		}
	}


	const validateEmail = (email: any) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const searchUserByEmail = async () => {
		try {
			if (!email) {
				toast({ message: 'Enter Email Id' }, true);
				return;
			}

			if (validateEmail(email) == null) {
				toast({ message: "Invalid Email id" }, true);
				return;
			}

			let postData = {
				email: email
			}
			let response = await Users.searchUserByEmail(token ?? "", postData);
			if (response.success == true) {
				let user = response.payload;
				var result = (
					<IonItem key={generateRandomString()} lines="none">
						<Player
							className="ion-align-items-center"
							key="0"
						>
							<ProfileImage
								icon_type="none"
								icon_color="primary"
								size="75px"
								filename={user.preference.image}
							/>
							<IonCol>
								<IonText>{user.full_name}</IonText>
								<IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
									{user.preference.location}
								</IonRow>
							</IonCol>
							<IonButton onClick={(e) => { addSingleFriendEvent(user.id) }}>Add</IonButton>
						</Player>
					</IonItem>
				);
				setEmailData(result);
			} else {
				toast({ message: response.message }, true);
				setEmailData([]);
			}

		} catch (error) {
			setEmailData([]);
			toast({ message: error }, true);
		}
	}

	
	const getFriendList = async () => {
		let response = await Users.getFriendList(token ?? "");
		let users = response.payload;

		if (response.payload) {
			var data = users.map((user: any, index: any) => (
				<IonItem key={generateRandomString()} lines="none">
					<Player
						className="ion-align-items-center"
						key={index}
					>
						<ProfileImage
							icon_type="none"
							icon_color="primary"
							size="75px"
							filename={user.preference.image}
						/>
						<IonCol>
							<IonText>{user.full_name}</IonText>
							<IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
								{user.preference.location}
							</IonRow>
						</IonCol>
						<IonButton onClick={(e) => { addSingleFriendEvent(user.id) }}>Add</IonButton>
					</Player>
				</IonItem>
			));
		} else {
			data = (<IonItem><IonText>No friend found</IonText></IonItem>);
		}
		setData(data);
		setEmailData([]);
		setEmail('');
	};



	 ///Show myself data
   const getSelfList = async() => {
    let currentuser = await Users.getCurrentUser(token ?? "")
    let users = currentuser.payload;
    console.log("current user", users);  
    var selfdata = (<IonItem key={generateRandomString()} lines="none">
                    <Player
                      className="ion-align-items-center"
                    >
                      <ProfileImage
                        icon_type="none"
                        icon_color="primary"
                        size="75px"
                        filename={users.preference.image}
                      />
                      <IonCol>
                        <IonText>{users.full_name}</IonText>
                        <IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
                          {users.preference.location}
                        </IonRow>
                      </IonCol>
                      <IonButton onClick={(e) => {addMyselfEvent(users.id)}}>JOIN</IonButton>
                    </Player>
            </IonItem>);
    setSelfData(selfdata);

  }

	const removeSingleFriendEvent = async (user_id: any) => {
		try {
			let postData = {
				event_id: event_view_id,
				user_id: user_id
			}
			let response = await Event.removeSingleFriendEvent(token ?? "", postData);
			if (response.success == true) {
				getData(event_view_id ?? '');
				toast({ message: response.message });
			} else {
				toast({ message: response.message }, true);
			}

		} catch (error) {
			console.log('Remove Event', error);
			toast({ message: error }, true);
		}
	}



	useAsyncEffect(async () => {
		if (event_view_id) {
			return getData(event_view_id);
		}
		getFriendList()
	}, [token, userID, event_view_id])

	useEffect(() => {
		if (event) {
			Event.setEventViewed(token ?? "", event.id);
		}
		getFriendList();
		getSelfList();
	}, [event, token]);


	//console.log("Event view", event);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton />
					</IonButtons>
					<IonTitle color="main-text">Event</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{(!isLoading) ? (
					<IonList>
						{(event) ?
							(<>

								<IonCard>
									<IonListHeader>
										<CardHead>Details</CardHead>
									</IonListHeader>
									<DetailsHeader>
										<IonItem routerLink={`/user/view/${event.issuer.id}`} routerDirection="forward" lines="none">
											<div style={{ paddingTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: "1" }}>
												<ProfileImage
													icon_type="none"
													icon_color="primary"
													size="90px"
													filename={event.issuer.preference.image ?? ""}
													style={{ margin: "15px 0", padding: "0", minWidth: "90px" }}
												/>
												<DetailLabel style={{ marginLeft: 'auto', marginRight: "0", padding: "0 0 0 25px" }}>
													<div style={{ paddingBottom: "10px" }}>
														<div><IonText style={{ fontSize: "13px" }}>Organizer:</IonText></div>
														<div><IonText style={{ fontSize: "18px" }}>{event.issuer.full_name}</IonText></div>
													</div>
												</DetailLabel>
											</div>
										</IonItem>
									</DetailsHeader>
									<TournamentTitle>{event.event_name}</TournamentTitle>
									<StartsIn>{timeAgo(event.start_date).agoString}</StartsIn>
									<DetailRow lines="none">
										<DetailLabel>Start Date</DetailLabel>
										<DetailValueColumn>
											<DetailValue>{toAppDateFormat(event.start_date)}</DetailValue>
										</DetailValueColumn>
									</DetailRow>
									<DetailRow lines="none">
										<DetailLabel>Start Time</DetailLabel>
										<DetailValueColumn>
											<DetailValue>{toAppTimeFormat(event.start_date)}</DetailValue>
										</DetailValueColumn>
									</DetailRow>

									{/* <DetailRow lines="none">
										<DetailLabel>End Date</DetailLabel>
										<DetailValueColumn>
											<DetailValue>{toAppDateFormat(event.end_date)}</DetailValue>
										</DetailValueColumn>
									</DetailRow>
									<DetailRow lines="none">
										<DetailLabel>End Time</DetailLabel>
										<DetailValueColumn>
											<DetailValue>{toAppTimeFormat(event.end_date)}</DetailValue>
										</DetailValueColumn>
									</DetailRow> */}

									<IonItem lines="none">
										<DetailLabel>City</DetailLabel>
										<IonText>{event.location}</IonText>
									</IonItem>

									<IonItem lines="none">
										<DetailLabel>State</DetailLabel>
										<IonText>{event.state}</IonText>
									</IonItem>

									<IonItem lines="none">
										<DetailLabel>Zip</DetailLabel>
										<IonText>{event.zip}</IonText>
									</IonItem>

									<IonItem lines="none">
										<DetailLabel>Venue</DetailLabel>
										<IonText>{event.venue}</IonText>
									</IonItem>
									<IonItem lines="none">
										<DetailLabel>Total Slots</DetailLabel>
										<IonText>{event.slots}</IonText>
									</IonItem>
								</IonCard>
							</>
							) : null}
						<IonCard>
							<IonListHeader>
								<CardHead>Message</CardHead>
							</IonListHeader>
							<IonList>
								<IonItem lines="none">{event?.message}</IonItem>
							</IonList>
						</IonCard>
						{/* Any user can join if the event is open and slot is not full */}
						{
							(event?.open_invite == 1 || event?.user_id == userID) ?
								<IonButton
									color="main"
									expand="block"
									style={{ margin: 14 }}
									onClick={eventJoin}
								>
									Join
								</IonButton>
								: []
						}

						{
							(event?.user_id == userID) ?
								<IonRow>
									<IonCol>
										<IonButton
											size="default"
											color="danger"
											expand="block"
											style={{ flex: 1 }}
											onClick={cancelEvent}
										>
											Cancel
										</IonButton>
									</IonCol>
									<IonCol>
										<IonButton
											size="default"
											color="main"
											expand="block"
											style={{ flex: 1 }}
											routerLink={`/event/edit/${event?.id}`}
										>
											Modify
										</IonButton>
									</IonCol>
								</IonRow>
								:
								[]
						}
						{
							(event?.user_id == userID) ?
								<IonItem className="form-input" data-name="title">
									<IonLabel style={{ fontWeight: "bold" }}>
										{(type == 1) ? "Add Participants Email" : ""}
										{(type == 2) ? "My Friends" : ""}
									</IonLabel>
									<IonSelect value={type} placeholder="Select Type" onIonChange={e => setType(e.detail.value)}>
								   	<IonSelectOption value="3">Add Myself</IonSelectOption>
										<IonSelectOption value="1">Participants Email</IonSelectOption>
										<IonSelectOption value="2">My Friends</IonSelectOption>
									</IonSelect>
								</IonItem>
								:
								[]

						}
						{(type == 1) ?
							<div>
								<IonItem>
									<IonInput
										name="title"
										type="text"
										placeholder="Enter Email"
										value={email}
										onIonChange={(e) => {
											setEmail(e.detail.value as string);
										}}
									/>
								</IonItem>
								<IonItem>
									<IonButton
										size="default"
										color="main"
										expand="block"
										style={{ flex: 1 }}
										onClick={searchUserByEmail}>Search</IonButton>
								</IonItem>
								{emailData}
							</div>

							: (type == 2) ?
								data
								: []

							
						}

            {(type==3) ? selfdata : []}  
						<IonCard>
							<IonListHeader>
								<CardHead>
									<IonRow className="ion-justify-content-between ion-align-items-center" style={{ paddingRight: '16px' }}>
										<IonText>Participants</IonText>
										{(event) ? (<IonText style={{ fontSize: "12px" }}>{event.openSlots} Open / {event.slots} Total</IonText>) : null}
									</IonRow>
								</CardHead>
							</IonListHeader>
							<IonRadioGroup
								name="user"
							>
								{(event?.users && event?.user_id) ? getPlayers(event?.users ?? [], event?.user_id) : null}
							</IonRadioGroup>
						</IonCard>

					</IonList>
				) : <CenteredSpinner />}
			</IonContent>
		</IonPage>
	)
}

export default EventPage;

const Player = styled.div`
  display: flex;
	width:100%;
	cursor: pointer;
  padding: 0 0 10px 0;
  &:first-of-type {
    padding-top: 5px;
  }
`

const CardHead = styled.div`
	flex: 1;
  font-size: 20px;
`;

const DetailLabel = styled.div`
  font-size: 20px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TournamentTitle = styled.h1`
	padding: 0 20px 0px;
	text-align: center;
	color: var(--ion-color-main-text);
`;

const StartsIn = styled.div`
  padding: 0 20px 10px;
  font-size: 12px;
  text-align: center;
  color: var(--ion-color-main-text);
`;

const DetailRow = styled(IonItem)`
  align-items: center;
  --padding-top: 0;
  --padding-bottom: 0;
`;

const DetailValueColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailValue = styled.div`
  text-align: right;
  padding-left: 20px;
`;


const DetailsHeader = styled.div`
  background: var(--ion-background-color);
  padding: 0px 16px;
  color: var(--ion-item-color, var(--ion-text-color, #000));
`;
