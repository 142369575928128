import { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { Users } from '../services/api';
import useAuth from "./useAuth";

function useDarkTheme(){

	const [prefersDark, setPrefersDark] = useState<boolean>(false);
	const {token, userID} = useAuth();
	
	// toggle the dark theme
	function toggleDarkTheme(prefersDark: boolean) {
		//document.body.classList.toggle('dark', prefersDark);
		setPrefersDark(prefersDark);
	}

	// checks if user prefers dark based on their system settings
	function doesUserSystemPreferDark(){
		let result = window.matchMedia('(prefers-color-scheme: dark)');
		return result.matches;
	}

	// checks if user prefers dark based on db flag
	const doesUserPreferDark = async () => {
		let preference = doesUserSystemPreferDark();
		if (userID){
			let result = await Users.getPrefersDark(token ?? "");
			if (result.success){
				return result.payload;
			} else {
				return preference;
			}
		}
	}

	const update = async () => {
		let prefersDark = await doesUserPreferDark();
		toggleDarkTheme(prefersDark);
	}

	const saveToDatabase = async (prefersDark?: boolean) => {
		const result = await Users.updateThemePreference(token ?? "", prefersDark);
	}

	useAsyncEffect(async () => {
		await update();
		// const queryList = window.matchMedia('(prefers-color-scheme: dark)');
		// queryList.addEventListener('change', (e) => {
		// 	toggleDarkTheme(e.matches);
		// })
	}, [userID, token])

	return {
		toggleDarkTheme,
		saveToDatabase,
		prefersDark: false, // force light as light/dark theming is cut
		update
	}
}

export default useDarkTheme;