import { IonButton, IonCol, IonIcon, IonItem, IonRow, IonText } from "@ionic/react";
import useAuth from "hooks/useAuth";
import { ellipse, ellipseOutline, trophy } from "ionicons/icons";
import { idCard, location, map } from "ionicons/icons";
import { timeAgo } from "services/time";
import { truncate } from "services/util";
//import styled from "styled-components";
import { Tournaments } from "services/api";
import useToast from "hooks/useToast";
import useTournaments from "hooks/useTournaments";
import PageTitle from "components/PageTitle";
import TournamentsList from "components/TournamentsList";
import CenteredSpinner from "components/CenteredSpinner";
import ErrorMessage from "components/ErrorMessage";
import TournamentPromptButton from "components/TournamentPromptButton";
import { useHistory } from "react-router-dom";
import { async } from "rxjs";
import { findByLabelText } from "@testing-library/react";

import { ReactNode, useEffect, useState } from "react";
import { getTimeParts } from "../services/time";
import styled, { css } from "styled-components";


const TournamentDisplay: React.FC<{
	tournament: Tournament
	user_id: string | null
	organizing_status: string | null
	//boxType: string | null
	
}> = ({ tournament, user_id ,organizing_status}) => {

  //console.log("tournament status", tournament.status);
	//console.log("tournament data", tournament);
	//console.log("Tournament all data", tournament);
	console.log("Show all data", tournament);
  

	const [toast] = useToast("Hello");
	const { userID, token } = useAuth();
	const history = useHistory();
  
	const [timeObj, setTimeObj] = useState({
		weekday_name: "Day",
		month_name: "Mon",
		month_day: "00",
		time_12: "00:00 AM"
	});



	const {
		tournaments, tournamentsLoading, tournamentsError,
		organizing, organizingLoading, organizingError,
		refreshTournaments, pollTournaments} = useTournaments();

	const getUser = () => {
		return tournament.users.find((user: User) => {
			return user.id === userID;
		});
	}

	const hasChanged = () => {
		if (user_id === null) return false;
		if (tournament.user_id === user_id) {
			// is organizer
			return tournament?.hasChangedSinceLastViewed;
		} else {
			let found_user = tournament.users.find(user => user.id === user_id);
			let hasChanged = found_user?._joinData?.hasChangedSinceLastViewed
			return hasChanged;
		}
	}

	const isPending = () => {
		return getStatus() === "Pending";
	}

	const getStatus = () => {
		const user = getUser();
		return user?._joinData?.status;
	}

	const cancelTournament = async (id: any) => {
		if(organizing_status=="organizing"){
		try {
			let response = await Tournaments.cancelTournament(token ?? "", id);
			if (response.success == true) {
				toast({ message: response.message });
				//window.location.reload();
				 history.push('/tournamentlist');
			} else {
				toast({ message: "Tournament already canceled." }, true);
			}
		} catch (error) {
			toast({ message: error }, true);
		}
	}
	else if(organizing_status=="participating"){
		try {
			let args = {
				tournament_id:tournament.id,
				user_id:user_id
			}
			let response = await Tournaments.cancelTournamentParticipants(token ?? "", args);
			if (response.success == true) {
				toast({ message: response.message });
				// window.location.reload();
				history.push('/tournamentlist');
			} else {
				toast({ message: "Tournament already canceled." }, true);
			}
		} catch (error) {
			console.log(error);
			toast({ message: error }, true);
		}
	}
  }


  const viewtournamentResult = async(id:any) => {
		  //console.log("tournament id", id); return;
			//console.log('Path', `/tournament/viewrank/${id}`); return;
			history.push(`/tournament/viewrank/${id}`);
			
	}


	useEffect(() => {
		if (JSON.stringify(tournament)) {
			setTimeObj(getTimeParts(tournament.tournament_start));
			
		}
		
	}, [tournament,token])

  

	return (
		<>
<AbsoluteBox11>
          <Col>
						<Dot
							size={7}
							//color={"danger"}
							color=""
							style={{ backgroundColor: "#67BDFD" }} />
					</Col>

		<Col width="65px" align="center" style={{ padding: "0 6px 0 0", fontSize: "12px", textAlign: "center", letterSpacing: "0.2px" }}>
						<Row justify="center"><strong>{timeObj.weekday_name}</strong></Row>
						<Row justify="center">{timeObj.month_name}<span style={{ width: "5px" }}></span><strong>{timeObj.month_day}</strong></Row>
						<Row justify="center" style={{ "whiteSpace": "nowrap" }}>{timeObj.time_12}</Row>
					</Col>

			<Tournament routerLink={`/tournament/view/${tournament.id}`}>
		
				{/* <Dot
					size={7}
					color={(hasChanged()) ? "danger" : "main"}
				/> */}

				{/* <IonIcon icon={trophy} style={{color: (isPending()) ? "var(--ion-color-accent-two":"unset"}} slot="start" /> */}
				
        
				<Col>
					{/* <Title style={{marginRight:"5px", marginLeft:"10px"}}>{truncate(tournament.title, 20)}</Title> */}
					<Title style={{marginRight: "5px", marginLeft:"10px"}}>{tournament.title}</Title>
					<Row>
							<IonIcon style={{ marginRight: "5px", marginLeft: "10px" }} color="boxTextColor" icon={location} />
							{/* <IonText style={{ fontSize: "0.8rem" }}>{tournament.location}</IonText> */}
							<IonText style={{fontSize: "0.8rem"}}>
								{tournament?.location} {(tournament?.state)?(tournament?.state):""}
							</IonText>
					</Row>

					{(tournament.venue) ?
										<Row>
											<IonIcon style={{ marginRight: "5px", marginLeft: "10px" }} color="boxTextColor" icon={map} />
											<IonText><b>Venue:&nbsp;</b></IonText>
											<IonText style={{ fontSize: "0.8rem" }}>{tournament.venue}</IonText>
										</Row>
										: null}

					
				</Col>

				<AbsoluteBox>
			   	{/* <Pill>{tournament.status}</Pill> */}
						{(tournament.ranked) ? (
							<Pill>Ranked</Pill>
						) : null}
					<Pill>{tournament.sport.name}</Pill>
				</AbsoluteBox>


			</Tournament>

			</AbsoluteBox11>

			<div style={{display:'flex'}}>
				  {(tournament?.status!='Complete')?(
					<CancelButton style={{marginLeft:'auto',borderRadius: '5px' ,boxShadow: '0 3px 5px rgba(0,0,0,0.5)' , background: '#eb445a', fontSize: '14px', textTransform: 'uppercase',padding: '14px 0px',maxWidth: '114px'}} onClick={(e) => cancelTournament(tournament.id)}>Cancel</CancelButton>): null}
					{(tournament.status=='Complete')?(
					<ViewButton style={{marginLeft:'auto', borderRadius: '5px' ,boxShadow: '0 3px 5px rgba(0,0,0,0.5)' , background: '#2dd36f', fontSize: '14px', textTransform: 'uppercase',padding: '14px 0px',maxWidth: '114px'
				}} onClick={(e) =>viewtournamentResult(tournament.id)}>View Result</ViewButton>): null}
			</div>
			
		</>
	);
}

export default TournamentDisplay;


const twobtn = styled.div`
		display: flex;
`;


const CancelButton = styled.div`
		padding: 20px 0px;
    display: block;
    background: #eb445a;
    border-radius: 30px;
    max-width: 100px;
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 0px;
    color: #fff;
    margin: 12px 10px 15px 0px;
`;


const ViewButton = styled.div`
			padding: 20px 0px;
			display: block;
			background: green;
			border-radius: 30px;
			max-width: 100px;
			width: 100%;
			text-align: center;
			height: 30px;
			line-height: 0px;
			color: #fff;
			margin: 12px 0px 15px 0px;
`;


// const Col = styled.div`
// 	display: flex;
// 	flex-direction: column;
// `;

const Title = styled.div`
	font-size: 16px;
`;

const Time = styled.div`
	padding-top: 2px;
	font-size: 12px;
`;

const Status = styled.div`
	font-size: 12px;
	background: var(--ion-color-accent-two);
	color: var(--ion-color-constant-dark);
	box-shadow: 0 0 0 1px #333;
	border-radius: 100px;
	padding: 3px 10px 1px;
	font-size: 11px;
	text-align: center;
	width: min-content;
	align-self: start;
	position: absolute;
	top: 4px;
	right: 4px;
`;

const NoneMessage = styled.div`
	text-align: center;
	padding: var(--horizontal-gutter);	
	& h1 {
		font-size: 30px;
		font-family: var(--heading-font);
	}
	& div {
		padding-bottom: 20px;
	}
`;
const Tournament = styled(IonItem)`
background-color:  #67BDFD;
padding: 0px 0 0;
display: flex;
justify-content: space-between;
font-size: 14px;
--background: #67BDFD ;
color: #000 !important;
border-radius: 10px;
--inner-padding-top: 15px;
--inner-padding-bottom: 10px;
--inner-padding-left: 0;
--inner-padding-right: 0;
width: 100%;
`;

const Dot = styled.div<{ size: number, color: string | undefined }>`
	height: ${(props: any) => `${props.size}px`};
	width: ${(props: any) => `${props.size}px`};
	background: var(--ion-color-${props => props.color});
	border-radius: 50px;
	border: 2px solid var(--ion-color-${props => props.color});
	margin: 5px 15px 5px 0;
`;



const AbsoluteBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 2px;
`;

const AbsoluteBox11 = styled.div`
	display: flex;
	align-items: center;
`;

const Pill = styled.div`
	background: white;
	color: black;
	box-shadow: 0 0 0 1px #333;
	border-radius: 10px;
	padding: 1px 10px 1px;
	margin: 4px 2px;
	font-size: 11px;
	text-align: center;
	width: min-content;
`;

const Row = styled.div<{ justify?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;

/***** Added on 18th Aug 2023 by Rakesh kumar */
const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: ${props => `var(--ion-color-${props.textColor})`};
	${props => (props.type === 'outline') ? css` 
		border: 2.5px solid var(--ion-color-${props.color});` : css`
		background: var(--ion-color-${props.color});
	`}
`;

const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
	width: ${(props: any) => (props.width) ? props.width : 'auto'};
	flex: ${(props: any) => (props.flex) ? props.flex : 'unset'};
	align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;