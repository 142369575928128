import { AlertOptions } from "@ionic/core";
import { IonIcon, useIonAlert } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";


interface InformationProps {
	alertOptions?: AlertOptions
	style: any
}

const Information: React.FC<InformationProps> = ({alertOptions, style}) => {
	const [present] = useIonAlert();
	return (
		<IonIcon
			style={style}
			icon={informationCircleOutline}
			onClick={() => {
				present(alertOptions ?? {});
			}}       
		/>
	)
}
Information.defaultProps = {
	alertOptions: {}
}

export default Information;