import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
`;

const Links: React.FC = ({children}) => {
	return (
		<Container>
			{children}
		</Container>
	);
};
  
export default Links;