import styled from "styled-components";

interface PodiumProps {
	slot?: string
	height?: number
	width?: number
}

const Podium: React.FC<PodiumProps> = ({slot, height = 60, width = 17}) => {
	return (
		<BarContainer slot={slot} height={height}>
			<Bar height={50} width={width} />
			<Bar height={100} width={width} />
			<Bar height={75} width={width} />
		</BarContainer>
	)
}

export default Podium;

const BarContainer = styled.div<{height: number}>`
	height: ${props => `${props.height}px`};
	padding: 0 0 6px;
	margin-right: 7px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
`;

const Bar = styled.div<{height: number, width: number}>`
	height: ${props => `${props.height}%`};
	width: ${props => `${props.width}px`};
	margin: 0 ${props => `${props.width * .15}px`};
	border-radius: 3px;
	background: var(--ion-color-main);
`;