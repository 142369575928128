import { IonRow,IonButton, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonTextarea, IonTitle, IonToggle, IonToolbar, IonCol } from "@ionic/react";
import WaveTabsTeam from '../components/WaveTabsTeam';
import PageTitle from '../components/PageTitle';
import AddTeam from 'components/AddTeam';
import TeamCard from 'components/TeamCard';
import { RouteComponentProps } from 'react-router';
import { Team } from "../services/api";
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import CenteredSpinner from "components/CenteredSpinner";
import CommonHeader from "components/CommonHeader";

const TeamsPage: React.FC<RouteComponentProps> = () => {
	const { token } = useAuth();
	const [teamData, setUserData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	
	///get team list by Rakesh on 02.06.2023
	const teamList = async () => {
		//setIsLoading(true);
		let response: any = [];
		let teamList: any = [];
		try {
		  	response = await Team.teamList(token ?? "");
				if(response.payload.length > 0){
					teamList = response.payload.map((item: any) => (
					<TeamCard team={item} />
					
				))
				setUserData(teamList);
				setIsLoading(false);
			}
			else{
				teamList = (
					<IonRow className="ion-justify-content-center">
						<h1>No Team Found</h1>
					</IonRow>
				);
				setUserData(teamList);
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
			setUserData([]);
		}

	
		return teamList;
	}

	useEffect(() => {
		teamList();
	}, [token]);

	

	return (
		<IonPage>
			<IonContent fullscreen>
				<WaveTabsTeam
					leftTitle="Create"
					rightTitle="My Teams"
					leftComponent={<AddTeam />}
					//rightComponent={teamData}
					rightComponent = {(isLoading==true) ? <CenteredSpinner/> : teamData}
					color='accent-two'
					leftRoute='addteam'
					rightRoute='myteam'
				>
					<PageTitle color="main">Teams</PageTitle>
				</WaveTabsTeam>
			</IonContent>
		</IonPage>
    
		// <IonPage>
		// 	<CommonHeader title={`All Team List`}/>
		// 	<IonContent fullscreen>
    //       {(!isLoading) ? teamData : <CenteredSpinner />}

		// 	</IonContent>
    // </IonPage>


	);
}
export default TeamsPage;