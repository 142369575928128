import { IonButton, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonTextarea, IonTitle, IonToggle, IonToolbar, IonSelectOption, IonCheckbox, IonText } from "@ionic/react";
import { Users } from 'services/api';
import { useContext, useEffect, useState } from 'react';
import useToast from "hooks/useToast";
import useAuth from '../hooks/useAuth';
import { useHistory } from "react-router-dom";
import { IonSpinner } from '@ionic/react';

const SportsAddPage: React.FC<{
	usertoken:any
	getSportsDetails: () => void
	onCloseClick: () => void
}> = ({ onCloseClick ,getSportsDetails,usertoken}) => {
	
	const [sports, setSports] = useState<any>([]);
	const { userID } = useAuth();
	const { token } = useAuth();
	const [toast] = useToast("Hello");
	const [check, setCheck] = useState<boolean>(false);


	var selectedSports: any = [];
	const handleChange = (value: any, check: any) => {
		if (check == true) {
			selectedSports.push(value);
		} else {
			const index = selectedSports.indexOf(value);
			if (index > -1) {
				selectedSports.splice(index, 1); 
			}
		}
	};

	const getAllSportList = async () => {
		//let response = await Users.getAllSportList(usertoken);
		let response = await Users.getSingleAllSportList(usertoken);  ////Showing single sports by Rakesh
		let sports = response.payload;
		
		let sportData = [];
		for (var i = 0; i < sports.length; i++) {
			sportData[i] = sports[i].name;
		}
		setSports(sportData);
		return sportData;
	}

	useEffect(() => {
		getAllSportList();
	}, [])

	const sportsAdd = async () => {
		try {
			if (selectedSports.length == 0) {
				toast({ message: "Please Select Sports" }, true);
				return false;
			}
			const postData = { sports: selectedSports, userId: userID };
			let res=await Users.addSports(token ?? "", postData);
	
			if(res.success==true){
				toast({ message: res.message });
				getSportsDetails();
				onCloseClick();
			}else{
				toast({ message: res.message }, true);
			}

		} catch (error) {
			toast({ message: error }, true);
		}
	}
	return (
		<IonPage>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonTitle style={{paddingLeft:"10px"}}>
							Add Sports
						</IonTitle>
						<IonIcon
							size="large"
							style={{
								marginRight: "var(--toolbar-icon-right-margin)"
							}}
							icon="close"
							slot="end"
							onClick={onCloseClick}
						/>
					</IonToolbar>
				</IonHeader>

				<IonList>
					{
						(sports.length > 0) ?
							sports.map((value: any, index: any) => (
								<IonItem >
									<IonLabel>{value}</IonLabel>
									<IonCheckbox slot="end" value={value} checked={check}
										onIonChange={(e) => {
											handleChange(e.detail.value, e.detail.checked);
										}}
									/>
								</IonItem>

							))
							:<IonItem>
								  <IonLabel style={{marginLeft:"200px"}}>No Sports found.</IonLabel>
							</IonItem>
					}

				</IonList>
			</IonContent>
			{ (sports.length > 0) ?
					<IonButton onClick={sportsAdd}>Submit</IonButton>
					:
					""
			}
		
		</IonPage>

	)
}

export default SportsAddPage
