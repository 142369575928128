import styled from "styled-components";

interface CardProps {
	title?: string | null
}

const StyledCard = styled.div`
	background: var(--ion-background-color-tint);
	border-radius: var(--radius);
	margin-left: var(--horizontal-gutter);
	margin-right: var(--horizontal-gutter);
	padding: 10px;
	margin-top: 5px;
	margin-bottom: 5px;
`;

const Title = styled.div`
	font-size: 20px;
	font-family: var(--heading-font);
	padding: 0 5px 5px;
`;

const GroupCard: React.FC<CardProps> = ({ children, title }) => (
	<StyledCard>
		{(title) ? <Title>{title}</Title>:null}
		{children}
	</StyledCard>
)

export default GroupCard;