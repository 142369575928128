import {
	IonPage,
	IonContent
} from "@ionic/react";
import useAsyncEffect from "use-async-effect";
import { Cms } from "../../services/api";
import TitleCardSimple from "components/TitleCardSimple";
import { useState } from "react";
import AuthNav from "components/AuthNav";

//import SwiperCore from 'swiper';
//import { Swiper, SwiperSlide } from "swiper/react";


//SwiperCore.use([]);

const AboutPage = () => {

	const [aboutContent,setAboutContent]=useState<any>("");
	useAsyncEffect(async () => {
		const data = { "page_key": "about" };
		let result = await Cms.getCmsContent(data);
		//console.log();
		setAboutContent(result[0].content);
	}, [])

	return (
		<IonPage>
			<IonContent className="ion-padding basic-bg basic-bg-pad">
				<div style={{ padding: "50px 0 200px" }}>
					<TitleCardSimple />
					<p
            className="Features"
            dangerouslySetInnerHTML={{ __html: aboutContent }}
						style={{color:"#fff"}}
          />
				</div>
				{/* <Swiper
					slidesPerView={1}
				>
          <SwiperSlide>
						<div>Find more challenging players across a wide variety of sports & games.</div>
					</SwiperSlide>
          <SwiperSlide>
						<div>Issue challenges and invite them to play with you competitively.</div>
					</SwiperSlide>
          <SwiperSlide>
						<div>Record your Win/Loss and watch your power ranking rise or fall.</div>
					</SwiperSlide>
					<SwiperSlide>
						<div>Rate other players to let others know who's a good sport</div>
					</SwiperSlide>
        </Swiper> */}
				<AuthNav/>
			</IonContent>
		</IonPage>
	);
};

export default AboutPage