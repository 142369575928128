import { IonButton, IonCard, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonTextarea, IonTitle, IonToggle, IonToolbar,IonCol,IonRow } from "@ionic/react";
import CommonHeader from "components/CommonHeader";
import PillRadios from "components/PillRadios";
import UsersSelect from "components/UsersSelect";
import useAuth from "hooks/useAuth";
import useDynamicInputs from "hooks/useDynamicInputs";
import useToast from "hooks/useToast";
import useUser from "hooks/useUser";
import { useParams } from "react-router";
import { Event } from "services/api";
import { getDateISO, joinISODateAndTime } from "services/time";
import styled from "styled-components";
import useAsyncEffect from "use-async-effect";
import { Users } from 'services/api';
import FriendCard from "components/FriendCard";
import { useEffect, useState,useContext } from "react";
import CenteredSpinner from 'components/CenteredSpinner';
import {useHistory} from "react-router-dom";

const ModifyEventPage: React.FC = () => {
  let { id } = useParams<Params>();
	const {user} = useUser();
  const {token} = useAuth();
	const [event_name, setTitle] = useState<string>("");
  const [event_start_date, setStartDate] = useState<string>("");
  const [event_start_time, setStartTime] = useState<string>("");
  const [event_end_date, setEndDate] = useState<string>("");
  const [event_end_time, setEndTime] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [locstate, setLocationState] = useState<string>("");
  const [zip, setLocationZip] = useState<string>("");

  const [venue, setVenue] = useState<string>("");
  const [open_invite, setOpenInvite] = useState<boolean>(false);
	const [slots, setSlots] = useState<string>("3");
  const [openslot, setOpenslots] = useState<string>("");

  const [toast] = useToast("Hello");
  const history = useHistory();


	
	const newDate = new Date()
  const date = newDate.getDate();
  let dateValue:any;
  if(date < 10){
    dateValue= '0'+date;
  }else{
    dateValue = date;
  }
  const month = newDate.getMonth() + 1;
  let monthValue:any;
  if(month<10){
    monthValue = '0'+month;
  }else{
    monthValue = month;
  }
  let year = newDate.getFullYear();
  let getCurrentDate = year+'-'+monthValue+'-'+dateValue;



  useAsyncEffect(async () => {
    if (id){
		  const result = await Event.view(token ?? "", id);
      if (result.success){
        //console.log('event edit response', result.payload); return;

        const { message, start_date, end_date, location,state, zip,event_name, slots, open_invite,venue,openSlots } = result.payload;
        setTitle(event_name);
        setStartDate(start_date);
        setStartTime(start_date);
        setEndDate(end_date);
        setEndTime(end_date);
        setLocation(location);
        setLocationState(state);
        setLocationZip(zip);
        setOpenInvite(open_invite);
        setSlots(slots);
        setMessage(message);
        setVenue(venue);
        setOpenslots(openSlots);
      }
    }
  }, [token])


 
  
  return (
		<IonPage>
      <CommonHeader title={`Modify Event`}/>
			<IonContent>
			<IonList>
					<IonItem className="form-input" data-name="title">
            <IonLabel position="stacked" style={{fontSize:"24px"}}>Title</IonLabel>
            <IonInput
              name="title"
              type="text"
              placeholder="Event Title"
              value={event_name}
              onIonChange={(e) => {
                  setTitle(e.detail.value as string);
              }}
            />
          </IonItem>
          <TimeRow>
            <IonItem className="form-input" data-name="event_start_date">
              <IonLabel position="stacked" style={{fontSize:"24px"}}>Start Date</IonLabel>
              <IonDatetime
                name="event_start_date"
								min= {getCurrentDate}
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                value={event_start_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({localDate});
                  setStartDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="event_start_time">
                <IonLabel position="stacked" style={{fontSize:"24px"}}>Start Time</IonLabel>
                <IonDatetime
								  minuteValues="0,10,20,30,40,50"
                  name="event_start_time"
                  displayFormat="h:mm A"
                  placeholder="Select Start Time"
                  value={event_start_time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setStartTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
          </TimeRow>
          <TimeRow style={{display:"none"}}>
            <IonItem className="form-input" data-name="event_end_date">
              <IonLabel position="stacked" style={{fontSize:"24px"}}>End Date</IonLabel>
              <IonDatetime
                name="event_end_date"
								min = {getCurrentDate}
                displayFormat="MMM DD YYYY"
                placeholder="Select End Date"
                value={event_end_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({localDate});
                  setEndDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="event_end_time">
                <IonLabel position="stacked">End Time</IonLabel>
                <IonDatetime
								  minuteValues="0,10,20,30,40,50"
                  name="event_end_time"
                  displayFormat="h:mm A"
                  placeholder="Select End Time"
                  value={event_end_time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setEndTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
          </TimeRow>
          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked" style={{fontSize:"24px"}}>City</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="City"
              value={location}
              onIonChange={(e) => {
                  setLocation(e.detail.value as string);
              }}
            />
          </IonItem>

          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked" style={{fontSize:"24px"}}>State</IonLabel>
            <IonInput
              name="locstate"
              type="text"
              placeholder="State"
              value={locstate}
              onIonChange={(e) => {
                  setLocationState(e.detail.value as string);
              }}
            />
          </IonItem>

          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked" style={{fontSize:"24px"}}>Zip</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="Zip Code"
              value={zip}
              onIonChange={(e) => {
                  setLocationZip(e.detail.value as string);
              }}
            />
          </IonItem>

          

          <IonItem>
              <IonLabel style={{fontSize:"18px"}}>Open Invite</IonLabel>
              <IonToggle color="success" checked={open_invite} onIonChange={e => setOpenInvite(e.detail.checked)} />
          </IonItem>
					{/* <IonItem>
						<IonLabel>Slots</IonLabel>
						<IonInput
							slot="end"
							name="slots"
              min="1"
							type="number"
							value={Number(slots)}
							onIonChange={(e) => {
								if(e.detail.value=='0'){
                  setSlots('4');
                }else{
                  setSlots(e.detail.value as string);
                }
							}}
              style={{textAlign: 'right', marginRight: '25px'}}
						></IonInput>
					</IonItem> */}
          <IonItem className="form-input" data-name="venue">
            <IonLabel position="stacked" style={{fontSize:"18px"}}>Venue</IonLabel>
            <IonInput
              name="venue"
              type="text"
              placeholder="Venue"
              value={venue}
              onIonChange={(e) => {
                setVenue(e.detail.value as string);
              }}
            />
          </IonItem>



          <IonItem className="form-input" data-name="message">
            <IonLabel position="stacked" style={{fontSize:"24px"}}>Message</IonLabel>
            <IonTextarea
              name="message"
              placeholder="Message"
              value={message}
              auto-grow={false}
              rows={4}
              spellCheck={true}
              onIonChange={e => setMessage(e.detail.value!)}
            ></IonTextarea>
          </IonItem>
         
            {/* <IonLabel><b>Friend list</b></IonLabel>
            {(userData.length >0) ? userData: <CenteredSpinner/>} */}
           
     

          <IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (id){
                if (!e.currentTarget) return;
                e.preventDefault();

               
                if (!event_name){
                  toast({message: 'Plesae enter title'}, true);
                  return;
                }


                if (!(event_start_date && event_start_time)){
                  toast({message: 'Plesae select Start date & time'}, true);
                  return;
                }
                const event_start = joinISODateAndTime(event_start_date, event_start_time);


                if (!location){
                  toast({message: 'Plesae enter city.'}, true);
                  return;
                }

                if (!locstate){
                  toast({message: 'Plesae enter state.'}, true);
                  return;
                }


                if (!zip){
                  toast({message: 'Plesae enter zip.'}, true);
                  return;
                }

                
                // if (!(event_end_date && event_end_time)){
                //   toast({message: 'End data & time is required'}, true);
                //   return;
                // }
                // const event_end = joinISODateAndTime(event_end_date, event_end_time);
                const event_end = "";

                let args = {
                  event_name,
                  open_invite,
                  location,
                  locstate,
                  zip,
                  event_start,
                  event_end,
                  message,
                  slots,
                  venue,
                  openslot
                }
                
                console.log('event edit args', args);
                let response = Event.edit(token ?? "", args, id);
                console.log("Event modify response", response);
                if (response){
                  response.then(function(data){
                    if (data && data.success){
                      toast({message: data.message});
                      //history.goBack();
                    } else {
                      toast({message: "Problem Updating Event"}, true);
                    }
                  })
                }
              } else {
                toast({message: "Problem Updating Event"}, true);
              }
               
            }}
          >
            Submit
          </IonButton>
        </IonList>
			</IonContent>
    </IonPage>
  );
}

export default ModifyEventPage;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;