import { IonRow, IonSpinner } from "@ionic/react"

const CenteredSpinner: React.FC<{size?: number}> = ({size = '30'}) => {
	return (
		<IonRow className="ion-justify-content-center ion-align-items-center" style={{padding: '15px'}}>
			<IonSpinner style={{
					width: "100%",
					height: `${size}px`
				}}
			/>
		</IonRow>
	)
}

export default CenteredSpinner;