import { useContext, useEffect, useState } from "react";
import { UserContext } from "State/UserContext";
import useAsyncEffect from "use-async-effect";
import { Users } from "../services/api";
import { AuthenticationService } from "../services/auth";
import useAuth from "./useAuth";
import useStateCallback from "./useStateCallback";

function useUser(id: (string | null) = null){
	const {token, loading} = useAuth();
	const [user, setUser] = useStateCallback<User | null>(null);
	const [userLoading, setUserLoading] = useState<boolean>(true);
	const [userError, setUserError] = useState<string | null>(null);
	const {state, dispatch} = useContext(UserContext);

	const getRefreshFunction = () => {
		return () => {
			fetch(id);
		}
	}

	const fetch = async (id: string | null) => {
		if (token){
			let response;
			try {
				if (id){
					response = await Users.getUser(token ?? "", id);
				} else {
					response = await Users.getCurrentUser(token ?? "");
				}
				console.log(response);
				
				if (response && response.success){
					setUser(response.payload, () => {
						setUserLoading(false);
						setUserError(null);
					});
				} else {
					setUser(null, () => {
						setUserLoading(false);
						setUserError("Problem Finding User");
					});
				}
			} catch (e) {
				console.log('problem loading user', e);
				setUserLoading(false);
			}
		} else {
			setUserLoading(false);
		}
	}

	const getUserFunction = () => {
		if (token){
			let user_id = id;
			return async () => {
				let response;
				let user = null;
				try {
					if (user_id){
						response = await Users.getUser(token ?? "", user_id);
					} else {
						response = await Users.getCurrentUser(token ?? "");
					}
					if (response.success){
						user = response.payload;
					} else {
						user = null;
					}
				} catch (e) {
					console.log(e);
				}
				return user;
			}
		}
	}

	const userRefresh = async () => {
		let response;
		let user = null;
		try {
			response = await Users.getCurrentUser(token ?? "");
			if (response.success){
				dispatch({type: 'setUser', payload: response.payload});
			}
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		fetch(id);
	}, [token, id, loading])

	return {
		user,
		token,
		userLoading,
		userError,
		userRefresh,
		refreshUser: getRefreshFunction(),
		getUser: getUserFunction()
	};
}

export default useUser;