import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { generateRandomString } from "../services/util";
import Tag from "./Tag";

interface PillRadioProps {
  options: string[]
  value: string
  onChange: (str: string) => void
}

const PillRadios: React.FC<PillRadioProps> = ({ options, value, onChange }) => {
  const [optionNodes, setOptionNodes] = useState<ReactNode[]>([]);
  const setOptions = (ops: string[]) => {
      setOptionNodes(() => ops.map(op => {
        return (
          <Tag
            key={generateRandomString()}
            value={op} 
            type="solid"
            textColor={(value === op) ? "constant-dark":"constant-light"}
            color={(value === op) ? "accent-two":"main"} 
            onClick={onChange}
          >
            {op}
          </Tag>
        );
      })
    );
  }
  useEffect(() => {
    setOptions(options);
  }, [options]);
  return (
    <PillContainer>
      {optionNodes}
    </PillContainer>
  )
}

export default PillRadios;

const PillContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: var(--horizontal-gutter);
`;