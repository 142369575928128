import { IonInput } from "@ionic/react";
import { useState } from "react";
import styled from "styled-components";

type FilterByType = 'text'|'value'|'and'|'or';

interface SearchDropdownProps {
	onChange: (selected: DropdownOption) => void
	options: DropdownOption[]
	initialOption: DropdownOption | null
	filterBy?: FilterByType
	placeholder?: string
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({onChange, options, filterBy = 'value', placeholder = 'Search...'}) => {

	const [filter, setFilter] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);
	const changeHandle = (e:any) => {
		setFilter(e.target.value)
	}

	const getOptions = () => {
		let optionsArr = options
			.filter((op: DropdownOption) => {
				if (filterBy === 'or'){
					return (
						op.text.toLowerCase().includes(filter.toLowerCase()) || filter === '' ||
						op.value.toLowerCase().includes(filter.toLowerCase()) || filter === ''
					);
				} else if (filterBy === 'and'){
					return (
						op.text.toLowerCase().includes(filter.toLowerCase()) || filter === '' &&
						op.value.toLowerCase().includes(filter.toLowerCase()) || filter === ''
					);
				} else if (filterBy === 'text'){
					return op.text.toLowerCase().includes(filter.toLowerCase()) || filter === '';
				} else {
					return op.value.toLowerCase().includes(filter.toLowerCase()) || filter === '';
				}
			}).map((op: DropdownOption) => ( 
				<Option
					onClick={() => {
						onChange(op);
						setFilter(op.text);
						setOpen(false);
					}}
					key={op.value}
				>
					{op.text}
				</Option>
			));
		if (optionsArr.length <= 0) return null;
		return (
			<OptionList>
			{ optionsArr }	
			</OptionList>
		);
	}

	return (
		<DropdownContainer>
			<IonInput
				className="native-input sc-ion-input-md"
				aria-labelledby="ion-input-6-lbl"
				autoCapitalize="none"
				autocomplete="off"
				id="filter"
				name="filter"
				type="text"
				value={filter}
				placeholder={placeholder}
				onIonInput={(e: any) => {
					changeHandle(e);
					if (!open){
						setOpen(true);
					}
				}}
				style={{ width: "100%" }}
			/>
			{open && getOptions()}
		</DropdownContainer>
	);
}

export default SearchDropdown;

const DropdownContainer = styled.div`
	width: 100%;
`;

const OptionList = styled.ul`
	list-style: none;
	max-height: 150px;
	overflow: scroll;
	padding: 0;
	margin: 0;
	width: 100%;
`;

const Option = styled.li`
	pointer: cursor;
	padding: 7px 10px;
	&:nth-child(even) {
		background: var(--ion-color-primary-bg-shade);
	}
`;