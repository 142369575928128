import { useEffect, useState } from "react";
import styled from "styled-components";
import Wave from "./Wave";

interface WaveHeaderProps {
	color: string
	gradient?: boolean
	slim?: boolean
	loading?: boolean
}

const Container = styled.div`
	color: white;
	font-size: 30px;
	height: 170px;
	position: relative;
`;

const WaveHeader: React.FC<WaveHeaderProps> = ({ children, color, gradient, slim = false, loading = false }) => {
	const path = "M 1 0 L 0 0 L 0 0.925 Q 0.263 0.523 0.495 0.829 T 0.996 0.838";

	const [stretch, setStretch] = useState(70);

	useEffect(() => {
		if (!loading){
			if (slim){
				setTimeout(() => { setStretch(70); }, 200);
			} else {
				setTimeout(() => { setStretch(170); }, 200);
			}
		}
	}, [loading]);

	return (
		<Container>
			{ children }
			<Wave color={color} stretch={stretch} path={path} top={false} gradient={gradient} />
		</Container>
	)
}

export default WaveHeader;