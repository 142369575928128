import { IonButtons, IonList, IonRow, IonSpinner,IonButton } from "@ionic/react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { FilterContext } from '../State/FilterContext'
import { generateRandomString } from "../services/util";
import RankCard from "./RankCard";
import EventCard from "./EventCard";
import TournamentCard from "./TournamentCard";
import ChallengelistCard from "./ChallengelistCard";
import UserCard from "./UserCard";
import FriendCard from "./FriendCard";
import styled from "styled-components";



const SearchResults: React.FC<SearchResultsProps> = ({results, filters, searchType = "match" }) => {
  const [resultsList, setResultsList] = useState<ReactNode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {state} = useContext(FilterContext);

  const listResults = () => {
    let list = [];
    if (results.map !== undefined){
      if (searchType === "match"){
        list = results.map((item: any) => (
          <ChallengelistCard user={item} type={searchType} key={generateRandomString()} />
        ));
      }
      else if (searchType === "match_create"){
        //console.log("Search Type Value", searchType);
        list = results.map((item: any) => (
          <UserCard user={item}  type={searchType} key={generateRandomString()}/>
        ));
      }
    
       else if (searchType === "tournament"){
        list = results.map((item: any) => (

          // <TournamentlistCard tournament={item} key={generateRandomString()} />
          <TournamentCard tournament={item} key={generateRandomString()} />
        ));
      } else if (searchType === "leaderboard"){
        list = results.map((item: any,index:any) => (
          <RankCard user={item} filters={filters} key={generateRandomString()} />
        ));
      } else if (searchType === "friend"){
        list = results.map((item: any) => (
          <FriendCard user={item} type={searchType} key={generateRandomString()}/>
          //<TournamentCard tournament={item} key={generateRandomString()} />
        ));
      }
      else if (searchType === "event"){
        list = results.map((item: any,index:any) => (
          <EventCard event={item} key={generateRandomString()} />
        ));
      }
    }
    setIsLoading(false);
    return list;
  }


  useEffect(() => {
    setResultsList(listResults());
  }, [results])

  console.log("Result count", results);

  return (
    (isLoading) ? (
    <IonRow className="ion-justify-content-center ion-align-items-center" style={{padding: '15px'}}>
      <IonSpinner
        style={{
          width: "100%",
          height: `30px`
        }}
        name="dots"
      />
    </IonRow>
  ):(
      <IonList>
        {resultsList}
      </IonList>
    )
  )
}

export default SearchResults
