import { IonButton, IonIcon } from "@ionic/react";
import { search } from "ionicons/icons";

const ChallengePromptButton = () => (
	<IonButton
			color="accent-two"
			expand="block" 
			routerLink="/search/match"
		>
		<IonIcon icon={search} style={{marginRight: '5px'}}></IonIcon>Find someone to challenge
	</IonButton>
)

export default ChallengePromptButton;