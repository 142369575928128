import { IonContent, IonHeader, IonTitle, IonIcon, IonToolbar, IonList, IonButton, useIonAlert, IonSpinner } from "@ionic/react"
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import useDynamicInputs from "../hooks/useDynamicInputs";
import useToast from "../hooks/useToast";
import useUser from "../hooks/useUser";
import { Addresses, Users } from "../services/api";
import NewAddressInputs from "./NewAddressInputs";

const EditAddressForm: React.FC<{
  onCloseClick: () => void
  addresses?: any[]
	address?: Address
  preferenceID?: string
  refreshUser: () => void
	refreshInputs: () => void
}> = ({onCloseClick, address, refreshUser, refreshInputs}) => {
  const {token} = useAuth();
  const [lineOne, setLineOne] = useState<string>(address?.address_line_1 ?? "");
  const [lineTwo, setLineTwo] = useState<string>(address?.address_line_2 ?? "");
  const [city, setCity] = useState<string>(address?.city ?? "");
  const [state, setState] = useState<string>(address?.state ?? "");
  const [zipCode, setZipCode] = useState<string>(address?.postal_code ?? "");
	const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toast] = useToast();
	const [present] = useIonAlert();

	/**
	 *  Validates any zip code
	 */
	const validateAnyZip = (zip: string) => {
		let zipUs = /^\d{5}$|^\d{5}-\d{4}$/
		let zipCa = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
		let zipUk = /^[A-Z]{1,2}[0-9R][0-9A-Z]?\s*[0-9][A-Z-[CIKMOV]]{2}/
		let zipRo = /^\d{6}$/
		let zipSj = /^\d{4}\s{0,1}[A-Za-z]{2}$/
		let zipNl = /^\d{4}$/
		let zipBe = /^\d{4}$/
		let zipIr = /^[ACDEFHKNPRTVWXY]{1}[0-9]{1}[0-9W]{1}[\ \-]?[0-9ACDEFHKNPRTVWXY]{4}$/

		return (
			zipUs.test(zip) ||
			zipCa.test(zip) ||
			zipUk.test(zip) ||
			zipRo.test(zip) ||
			zipSj.test(zip) ||
			zipNl.test(zip) ||
			zipBe.test(zip) ||
			zipIr.test(zip)
		)
	}

	const deleteAddress = async () => {
		console.log("DELETED");
		if (address){
			const response = await Addresses.delete(token ?? "", address);
			if (response && response.success){
				toast({message: 'Delete Successful'});
				refreshInputs();
				onCloseClick();
			} else {
				toast({message: 'Detail Failed'}, true);
			}
		}
	}

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle style={{marginLeft:"12px"}}>Edit Address</IonTitle>
          <IonIcon
            size="large"
            style={{
              marginRight: "var(--toolbar-icon-right-margin)"
            }}
            icon="close" 
            slot="end" 
            onClick={onCloseClick}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
				<IonList>
          <NewAddressInputs
						lineOne={lineOne}
						lineTwo={lineTwo}
						city={city}
						state={state}
						zipCode={zipCode}
						setLineOne={setLineOne}
						setLineTwo={setLineTwo}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
					/>
          <IonButton
						color="main"
						expand="block"
						style={{ margin: 14 }}
						onClick={(e) => {
							if (!e.currentTarget) return;
							e.preventDefault();

							// let's validate all the address inputs
							if (lineOne == ""){
								toast({message: "Address Line 1 missing"}, true);
								return false;
							}

							if (city == ""){
								toast({message: "City missing"}, true);
								return false;
							}

							if (state == ""){
								toast({message: "Please select a state"}, true);
								return false;
							}

							if (zipCode == ""){
								toast({message: "Please enter a zip code"}, true);
								return false;
							}

							if (!validateAnyZip(zipCode)){
								toast({message: "Please enter a valid zip code"}, true);
								return false;
							}
							if (address?.id){
								let new_address = {
									id: address.id,
									address_line_1: lineOne,
									address_line_2: lineTwo,
									city: city,
									state: state,
									postal_code: zipCode,
								};
                
								//console.log("new address", new_address);

								setIsLoading(true);
								let response = Addresses.update(token ?? "", new_address);
								//console.log("response", response); return;

								if (response){
									response.then(function(data){
										if (data && data.success){
											let obj = data.payload;
											let address = "";
											address += obj.address_line_1;
											if (obj.address_line_2){
												address += "<br />" + obj.address_line_2;
											}
											address += "<br />" + obj.city + " " + obj.state + ", " + obj.postal_code;
											//address += "<br />" + obj.lat + ", " + obj.lon;
											toast({message: address, header: "Address Updated"});
											refreshUser();
											refreshInputs();
											onCloseClick();
										} else {
											// show issues in each input
											toast({message: "Something went wrong"}, true);
											

										}
										setIsLoading(false);
									}).catch(e => {
										setIsLoading(false);
									});
								} else {
									setIsLoading(false);
								}
							}
            }}
					>
						{(isLoading) ? (
						<IonSpinner
							style={{width: '18px'}}
							color="main-text"
						/>):"Save"}
					</IonButton>
					<IonButton
						color="danger"
						expand="block"
						style={{ margin: 14 }}
						onClick={(e) => {
							present({
								header: 'Are you sure?',
								message: 'Do you want to delete this address?',
								buttons: [
									{ text: 'Cancel', handler: () => console.log('cancelled') },
									{ text: 'Delete', handler: deleteAddress }
								]
							});
						}}
					>
						Delete
					</IonButton>
        </IonList>
      </IonContent>
    </>
  )
}

export default EditAddressForm
