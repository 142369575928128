import { useEffect, useRef, useState } from "react";
import { generateRandomString, getLinearGradient, normalizePath } from "services/util";
import styled from "styled-components";

interface WavePartProps {
	color: string;
	path: string;
	height: number;
	gradient?: boolean;
}

const WavePart = styled.div<WavePartProps>`
	transition: height 0.5s;
	background: ${props => (props.gradient) ? getLinearGradient(props.color):`var(--ion-color-${props.color})`};
	clip-path: ${props => `url(${props.path})`};
	-webkit-clip-path: ${props => `url(${props.path})`};
	height: ${props => `${props.height}px`};
`

interface ColumnProps {
	top: boolean
}

const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

const WaveTitle: React.FC = ({children}) => {
	const [height, setHeight] = useState(100);
	const paths = [
		{
			path: "M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.774 0.642 0.639 0.465 T 0.368 0.483 T 0.15 0.517 T 0 0.5",
			height: 175,
			color: 'accent-one'
		},
		{
			path: "M 0 0.5 L 0 0 L 1 0 L 1.002 0.528 Q 0.784 0.202 0.649 0.445 T 0.348 0.459 T 0 0.5",
			height: 180,
			color: 'accent-two'
		}
	];

	return (
		<Container height={height}>
			{paths.map(path => <Wave {...path} />)}
			<ChildrenContainer>
				<div>
					{children}
				</div>
			</ChildrenContainer>
		</Container>
	);
}

export default WaveTitle;

const Container = styled.div<{height: number}>`
	position: relative;
	height: ${props => props.height}px;
`;

const ChildrenContainer = styled.div`
	position: absolute;
	width: 100%;
`;

const StyledWave = styled.div`
	position: absolute;
	width: 100%;
`;

const Wave: React.FC<{path: string, height: number, color: string}> = ({path, height, color}) => {
	const p = normalizePath(path);
	const id = generateRandomString();
	return (
		<StyledWave>
			<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{position: 'absolute'}}>
				<clipPath id={id} clipPathUnits="objectBoundingBox">
					<path d={normalizePath(p)} />
				</clipPath>
			</svg>
			<Column>
				<WavePart color={color} height={height} path={`#${id}`} gradient={true}/>
			</Column>
		</StyledWave>
	)
}