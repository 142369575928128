import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonHeader, 
  IonItem, IonList, IonListHeader, IonModal, IonPage, IonRadioGroup, IonRouterLink,
   IonRow, IonText, IonTitle, IonToolbar,IonLabel,IonSelect,IonSelectOption,IonInput,IonIcon,IonToggle,useIonAlert } from "@ionic/react"
  import ProfileImage from "components/ProfileImage";
  import useAuth from "hooks/useAuth";
  import useToast from "hooks/useToast";
  import React, { ReactNode, useEffect, useState } from "react";
  import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
  import { Challenges, Tournaments,Users,Team } from "services/api";
  import useAsyncEffect from "use-async-effect";
  import styled from 'styled-components';
  import { generateRandomString } from "services/util";
  import CenteredSpinner from "components/CenteredSpinner";
  import { timeAgo, toAppDateFormat, toAppFormat, toAppTimeFormat } from "../services/time";
  import IonTextTip from "components/IonTextTip";
  import useTournaments from "hooks/useTournaments";
  import ReportTournament from "components/ReportTournament";
  import { trash } from 'ionicons/icons';
  
  interface Ratings {
    [id: string]: number
  }

  interface TournamentPageProps extends RouteComponentProps<{
    tournament_view_id?: string;
    type: string;
    id?: string;
  }> {}

  
  const TournamentrankPage: React.FC<TournamentPageProps> = ({ match }) => {
    const { tournament_view_id } = match.params;
    //console.log("Match Params", match.params);

    const {token, userID} = useAuth();
    const [toast] = useToast("");
    const {refreshTournaments} = useTournaments();
    const [participants, setParticipants] = useState<User[]>([]);
    const [tournament, setTournament] = useState<Tournament>();
    const [tournamentrank, setTournamentRank] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();
  
    // form state
    const [winner, setWinner] = useState<string>();
    const [players, setPlayers] = useState<ReactNode[]>([]);
    const [type, setType] = useState<number>(0);
    const [email, setEmail] = useState<string>("");
    const [emailData, setEmailData] = useState<any>([]);
    const [data, setData] = useState<any>([]);

    //const [rankdetails, setrankData] = useState();

    const [presentAlert] = useIonAlert();
    const [handlerMessage, setHandlerMessage] = useState('');
    const [roleMessage, setRoleMessage] = useState('');

    const isOrganizer = tournament?.user_id === userID;
    //console.log("Is OPrganizer", isOrganizer);
    //console.log("Organizer ID", tournament?.user_id);
    //console.log("Login User id", userID);
    
    /**
     * 
     * @param users array of users who participanted in the game
     * @param issuerId the user id of the tournament issuer
     * @returns A react component displaying the users
     */
    const getPlayers = ((users: User[], organizer_id: string, rankdetails:any) => {
      //console.log("Users", users);
      //console.log("Tournament111", rankdetails); 
      return (rankdetails) ? rankdetails.map((user:any, index:any) => {
          return (
            <IonItem key={generateRandomString()} lines="none"  routerDirection="forward">
              <Player 
                className="ion-align-items-center" 
                key={index}
                // isSelected={tournament?.id == winner}
              >
                <ProfileImage
                  icon_type="none" 
                  icon_color="primary"
                  size="75px" 
                  filename={user?.image}
                />
                <IonCol>
                  <IonText>{user?.Name}</IonText>
                </IonCol>
              
                <IonCol>
                  <IonText>{user?.rank_value}/{user?.total_participants}
                  </IonText>
                </IonCol>
                <IonCol>
                     <IonText>{user?.rating_value}</IonText>
                </IonCol>
                
              
              </Player>
            </IonItem>
          )
        //}
      }):[]
    });

    

    const addSingleFriendTournament = async (user_id: any) => {
      try {
          let postData = {
          tournament_id: tournament?.id,
          user_id: user_id
      }
        let response = await Tournaments.addSingleFriendTournament(token ?? "", postData);
        if (response.success == true) {
          getData(tournament?.id);
          toast({ message: response.message });
        } else {
          toast({ message: response.message }, true);
        }
      } catch (error) {
        toast({ message: error }, true);
      }
    }

  
    const getData = async (id: any) => {
      if (!token) return;
      // //Get participant rank
      const getRank  = await Tournaments.viewRank(token ?? "", id);
      //console.log("tournament Rank array", getRank.payload); return;
      //console.log("tournament rank array", getRank.payload.length); return;
      setTournamentRank(getRank.payload);
      
      const result = await Tournaments.view(token ?? "", id);
      if (result.success){
        setIsLoading(false);
        const { users, user_id } = result.payload;
        
        const {ranks} = getRank.payload;

        //console.log('TOURNAMENT PayLoad data', result.payload);
        const participants = users.filter((user: User) => user.id !== userID);
        let ratings: Ratings = {};
        participants.forEach((user: User) => {
          ratings[user.id] = 5;
        });
        setTournament(result.payload);
        //console.log("Result", result.payload);
        setParticipants(participants);
        setPlayers(getPlayers(users, user_id, ranks));
      } else {
        console.log('fail', result);
        console.log('redirect');
        toast({message: "Tournament does not exist"}, true);
        //history.push('/');
      }
      return Challenges.viewCancel;
    }
  
    useAsyncEffect(async () => {
      if (tournament_view_id){
        return getData(tournament_view_id);
      }
    }, [token, userID, tournament_view_id])
  
    // useEffect(() => {
    //   if (tournament){
    //     setPlayers(getPlayers(tournament.users, tournament.user_id));
    //     Tournaments.setTournamentViewed(token ?? "", tournament.id);
    //   }
    // getFriendList();
    // },[tournament]);
  

  return (
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle color="main-text">Tournament Rank</IonTitle>
          
        </IonToolbar>
      </IonHeader>
        <IonContent>
          {(!isLoading) ? (
          <IonList>
            {(tournament) ? 
            (<>
              <TournamentTitle>{tournament?.title}</TournamentTitle>
              <StartsIn>{timeAgo(tournament?.tournament_start).agoString}</StartsIn>
              <IonCard>
                <IonListHeader>
                  <CardHead>Rank Details</CardHead>
                </IonListHeader>

              </IonCard>
            </>
            ):null}
  

          <IonCard>
                {/* <CardHead>
                  <IonRow className="ion-justify-content-between ion-align-items-center" style={{paddingRight: '16px'}}>
                    <IonText>Participants</IonText>
                  </IonRow>
                </CardHead> */}
              
              <IonRow>
                    <div style={{ width: '47%', textAlign: 'right'}}>
                       Rank Value/Total Participant
                    </div>
                    <div style={{ width: '27%', textAlign: 'right'}}>
                        Rating Value
                    </div>
              </IonRow>

              <IonRadioGroup 
                value={winner} 
                onIonChange={e => {
                  setWinner(e.detail.value)
                }}
                name="winner"
              >
                {(tournament?.users && tournament?.user_id) ? getPlayers(tournament?.users ?? [], tournament?.user_id, tournamentrank):null}
              </IonRadioGroup>

          </IonCard>
            
           
          </IonList>
          ):<CenteredSpinner />}
        </IonContent>
      </IonPage>
    )
  }
  
  export default TournamentrankPage;
  
  const Player = styled.div`
    display: flex;
    width:100%;
    cursor: pointer;
    padding: 0 0 10px 0;
    &:first-of-type {
      padding-top: 5px;
    }
  `
  ;
  
  const CardHead = styled.div`
    flex: 1;
    font-size: 20px;
  `;
  
  const Actions = styled.div`
    display: flex;
    justify-content: center;
    padding: var(--horizontal-gutter);
  `;
  
  const Tag = styled.div<{color: string, textColor: string}>`
    background: ${props => `var(--ion-color-${props.color})`};
    color: ${props => `var(--ion-color-${props.textColor})`};
    padding: 2px 15px;
    border-radius: 100px;
    text-align: center;
    width: min-content;
    font-size: 13px;
    margin: 5px 10px 0 0;
  `;
  
  const EventTag = styled.div<{color: string, textColor: string}>`
    background: ${props => `var(--ion-color-${props.color})`};
    color: ${props => `var(--ion-color-${props.textColor})`} !important;
    padding: 7px 15px;
    border-radius: 100px;
    text-align: center;
    width: min-content;
    margin-right: 25px;
  `;
  
  const DetailLabel = styled.div`
    font-size: 20px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `;
  
  const DetailLabelText = styled(IonText)`
  `;
  
  const DetailSubLabel = styled.div`
    font-size: 12px;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between; 
    & > ${DetailLabelText} {
      margin-right: 10px;
    }
    @media (max-width: 275px){
      flex-direction: column;
      padding-bottom: 5px;
    }
  `;
  
  const Note = styled(IonCol)`
    background: #fff;
    border-radius: 5px;
    color: #333;
    width: 90%;
  `;
  
  const NoteMsg = styled.div`
    padding: 10px 10px 5px;
  `;
  
  const NoteDetails = styled(IonRow)`
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 5px;
    margin-top: 5px;
    padding: 3px 10px;
    background: #ddd;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: max-content;
  `;
  
  const NoteAuthor = styled.div`
    font-size: 12px;
  `;
  
  const NoteTime = styled.div`
    font-size: 10px;
  `;
  
  const TournamentTitle = styled.h1`
    padding: 0 20px 0px;
    text-align: center;
    color: var(--ion-color-main-text);
  `;
  
  const StartsIn = styled.div`
    padding: 0 20px 10px;
    font-size: 12px;
    text-align: center;
    color: var(--ion-color-main-text);
  `;
  
  const DetailRow = styled(IonItem)`
    align-items: center;
    --padding-top: 0;
    --padding-bottom: 0;
  `;
  
  const DetailValueColumn = styled.div`
    display: flex;
    flex-direction: column;
  `;
  
  const DetailValue = styled.div`
    text-align: right;
    padding-left: 20px;
  `;
  
  
  const DetailsHeader = styled.div`
    background: var(--ion-background-color);
    padding: 0px 16px;
    color: var(--ion-item-color, var(--ion-text-color, #000));
  `;

  const playerdelete = {
    marginLeft: '110px',
   '@media (max-width: 900px)': {
     marginLeft: '10px',
   }
 };

 const joinTournament = {
    width:'100%',
    
 };
