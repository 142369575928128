import { IonText } from "@ionic/react";
import { ReactNode } from "react";
import styled from "styled-components";
import Card from "./Card";

const UpdateAddressPrompt: React.FC<{msg:ReactNode, buttons?: ReactNode[]}> = ({msg, buttons = []}) => {
	return (
		<Card>
			<Wrapper>
				<Head>
					<IonText>Turn on your location</IonText>
						<Or>Or</Or>
					<IonText>Update your profile<br />with an address</IonText>
				</Head>
				{msg}
			</Wrapper>
			{buttons}
		</Card>
	)
}

export default UpdateAddressPrompt;

const Head = styled.div`
	text-align: center;
	font-size: 18px;
`;

const Wrapper = styled(Card)`
	opacity: 1;
	font-size: 12px;
	padding: 20px 0 10px;
`;

const Or = styled.div`
	padding: 10px;
`;