import { useState } from "react";
import { Tournaments, Users } from "services/api";
import useAsyncEffect from "use-async-effect";
import useAuth from "./useAuth";

function useTournaments(id?: number){
	const {token} = useAuth();
	const [tournamentsLoading, setTournamentsLoading] = useState<boolean>(false);
	const [tournamentsError, setTournamentsError] = useState<string | null>(null);
	const [tournaments, setTournaments] = useState<Tournament[]>([]);
	const [organizingLoading, setOrganizingLoading] = useState<boolean>(false);
	const [organizingError, setOrganizingError] = useState<string | null>(null);
	const [organizing, setOrganizing] = useState<Tournament[]>([]);

	const logIfShould = (...params: any[]) => {
		const shouldLog = false;
		if (shouldLog){
			console.log(...params);
		}
	}

	const refreshTournaments = async () => {
		setTournamentsLoading(true);
		let response;
		try {
			response = await Tournaments.list(token ?? "");
		} catch (e) {
			logIfShould('tournament error', e);
			setTournamentsLoading(false);
			setTournamentsError('Problem Loading Tournaments');
		}
		if (response && response.success){
			if (JSON.stringify(response.payload) !== JSON.stringify(tournaments)){
				logIfShould('setting "participating" tournament list');
				setTournaments(response.payload);
				setTournamentsError(null);
			} else {
				logIfShould('skipping "particpating" tournament list update');
				setTournamentsError(null);
				setTournamentsLoading(false);
			}
		} else {
			setTournamentsError("Problem Loading Tournaments");
		}
		setTournamentsLoading(false);

		setOrganizingLoading(true);
		let response_two;
		try {
			response_two = await Tournaments.organizing(token ?? "");
		} catch (e) {
			logIfShould('organizing error', e);
			setOrganizingLoading(false);
			setOrganizingError('Problem Loading Tournaments');
		}
		if (response_two && response_two.success){
			if (JSON.stringify(response_two.payload) !== JSON.stringify(organizing)){
				logIfShould('setting "organizing" tournament list');
				setOrganizing(response_two.payload);
				setOrganizingError(null);
			} else {
				logIfShould('skipping "organizing" tournament list update');
				setOrganizingError(null);
				setOrganizingLoading(false);
			}
		} else {
			setOrganizingError("Problem Loading Tournaments");
		}
		setOrganizingLoading(false);
	}

	const pollTournaments = async () => {
		let response;
		try {
			response = await Tournaments.list(token ?? "");
		} catch (e) {
			logIfShould('Tournament polling error', e);
			setTournamentsError('Problem Loading Tournaments');
		}
		if (response && response.success){
			if (JSON.stringify(response.payload) !== JSON.stringify(tournaments)){
				logIfShould('setting "participating" tournament list');
				setTournaments(response.payload);
				setTournamentsError(null);
			} else {
				logIfShould('skipping "participating" updating tournaments')
			}
		}

		let response_two;
		try {
			response_two = await Tournaments.organizing(token ?? "");
		} catch (e) {
			console.log('Tournament polling error', e);
			setTournamentsError('Problem Loading Tournaments');
		}
		if (response_two && response_two.success){
			if (JSON.stringify(response_two.payload) !== JSON.stringify(organizing)){
				logIfShould('setting "organizing" tournament list');
				setOrganizing(response_two.payload);
				setOrganizingError(null);
			} else {
				logIfShould('skipping "organizing" updating tournaments');
			}
		}
	}

	useAsyncEffect(() => {
		refreshTournaments();
	},[token])
	return {
		tournaments,
		tournamentsLoading,
		tournamentsError,
		organizing,
		organizingLoading,
		organizingError,
		refreshTournaments,
		pollTournaments
	}
}

export default useTournaments;