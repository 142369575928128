import useDynamicInputs from "hooks/useDynamicInputs";
import { useReducer, useEffect, createContext } from "react";

interface FilterContext {
	dispatch?: any
	state: {
		matchFilters: MatchSearch
		eventFilters: EventSearch
		teamFilters: TeamSearch
		tournamentFilters: TournamentSearch
		leaderboardFilters: LeaderBoardFilters
		searchType: SearchType
		friendFilters: FriendType

	}
}

let initialContext = {
	state: {
		friendFilters: {
			email: "",
			origin: "",
			distance: 100,
			ageRange: {
				lower: 18,
				upper: 65
			},
			genders: [],
			past_matches: false
		},
		matchFilters: {
			sports: [],
			sportsFinal: [],
			origin: "",
			distance: 200,
			ageRange: {
				lower: 18,
				upper: 65
			},
			ranked: false,
			genders: [],
			ratingPlus: "",
			ratingMinus: "",
			endDate: "",
			startDate: ""
		},
		tournamentFilters: {
			sports: [],
			origin: "",
			distance: 200,
			startDate: "",
			endDate: "",
			maxRate:"",
			minRate:""
		},
		
		eventFilters: {
			origin: "",
			distance: 200,
			startDate: "",
			endDate: "",
			eventDetails: []
		},


		teamFilters: {
			teamDetails: []
		},



		leaderboardFilters: {
			time: 'all-time' as LeaderBoardTime,
			origin: "",
			genders: [],
			distance: 200,
			sport: "all",
			ageRange: {
				lower: 18,
				upper: 100
			},
		},
		searchType: 'match' as SearchType,
	}
};

let FilterContext = createContext<FilterContext>(initialContext);

let reducer = (state: any, action: StateAction) => {
	switch (action.type) {

		/* MATCH FILTER CASES */
		case 'match_setSelectedSports':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					sports: action.payload
				}
			}
			case 'match_setSelectedSportsFinal':
				return {
					...state,
					matchFilters: {
						...state.matchFilters,
						sportsFinal: action.payload
					}
				}
		case 'match_setSelectedGenders':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					genders: action.payload
				}
			}
		case 'match_setOrigin':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					origin: action.payload
				}
			}
		case 'match_setDistance':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					distance: action.payload
				}
			}
		case 'match_setAgeRange':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					ageRange: action.payload
				}
			}
	   	case 'match_setRatingPlus':
				return {
					...state,
					matchFilters: {
						...state.matchFilters,
						ratingPlus: action.payload
					}
				}


				case 'match_setRatingMinus':
					return {
						...state,
						matchFilters: {
							...state.matchFilters,
							ratingMinus: action.payload
						}
					}

					case 'match_setEndDate':
						return {
							...state,
							matchFilters: {
								...state.matchFilters,
								endDate: action.payload
							}
						}
						case 'match_setStartDate':
						return {
							...state,
							matchFilters: {
								...state.matchFilters,
								startDate: action.payload
							}
						}

		/* Friend Filter Case */
		case 'friend_setSelectedGenders':
			return {
				...state,
				friendFilters: {
					...state.friendFilters,
					genders: action.payload
				}
			}
		case 'friend_setOrigin':
			return {
				...state,
				friendFilters: {
					...state.friendFilters,
					origin: action.payload
				}
			}
		case 'friend_setDistance':
			return {
				...state,
				friendFilters: {
					...state.friendFilters,
					distance: action.payload
				}
			}
		case 'friend_setAgeRange':
			return {
				...state,
				friendFilters: {
					...state.friendFilters,
					ageRange: action.payload
				}
			}
		case 'friend_setEmail':
			return {
				...state,
				friendFilters: {
					...state.friendFilters,
					email: action.payload
				}
			}
		case 'friend_setPastMatch':
			return {
				...state,
				friendFilters: {
					...state.friendFilters,
					past_matches: action.payload
				}
			}
		/* TOURNAMENT FILTER CASES */
		case 'tournament_setSelectedSports':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					sports: action.payload
				}
			}
		case 'tournament_setOrigin':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					origin: action.payload
				}
			}

		case 'tournament_setDistance':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					distance: action.payload
				}
			}
		case 'tournament_setStartDate':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					startDate: action.payload
				}
			}
			
			case 'tournament_setEndDate':
				return {
					...state,
					tournamentFilters: {
						...state.tournamentFilters,
						endDate: action.payload
					}
				}


				case 'tournament_setMaxRate':
					return {
						...state,
						tournamentFilters: {
							...state.tournamentFilters,
							maxRate: action.payload
						}
					}

					case 'tournament_setMinRate':
					return {
						...state,
						tournamentFilters: {
							...state.tournamentFilters,
							minRate: action.payload
						}
					}


		/* Event FILTER CASES */
		case 'event_setOrigin':
			return {
				...state,
				eventFilters: {
					...state.eventFilters,
					origin: action.payload
				}
			}
		case 'event_setDistance':
			return {
				...state,
				eventFilters: {
					...state.eventFilters,
					distance: action.payload
				}
			}
		case 'event_setStartDate':
			return {
				...state,
				eventFilters: {
					...state.eventFilters,
					startDate: action.payload
				}
			}
		case 'event_setEndDate':
			return {
				...state,
				eventFilters: {
					...state.eventFilters,
					endDate: action.payload
				}
			}
		case 'eventDetails_setEventDetails':
			return {
				...state,
				eventFilters: {
					...state.eventFilters,
					eventDetails: action.payload
				}
			}

		/* TEAM FILTER CASES */
		case 'teamDetails_setTeamDetails':
			return {
				...state,
				teamFilters: {
					...state.teamFilters,
					teamDetails: action.payload
				}
			}
		/* TEAM FILTER CASES */

		/* LEADERBOARD FILTER CASES */
		case 'leaderboard_setGenders':
			console.log('setting state genders for leaderboard', action);
			return {
				...state,
				leaderboardFilters: {
					...state.leaderboardFilters,
					genders: action.payload
				}
			}

		case 'leaderboard_setAgeRange':
			return {
				...state,
				leaderboardFilters: {
					...state.leaderboardFilters,
					ageRange: action.payload
				}
			}

		case 'leaderboard_setTime':
			return {
				...state,
				leaderboardFilters: {
					...state.leaderboardFilters,
					time: action.payload
				}
			}

		case 'leaderboard_setDistance':
			return {
				...state,
				leaderboardFilters: {
					...state.leaderboardFilters,
					distance: action.payload
				}
			}

		case 'leaderboard_setLocation':
			return {
				...state,
				leaderboardFilters: {
					...state.leaderboardFilters,
					location: action.payload
				}
			}

		case 'leaderboard_setSport':
			console.log('leaderboard_setSport', { action });
			return {
				...state,
				leaderboardFilters: {
					...state.leaderboardFilters,
					sport: action.payload
				}
			}

		/* SET SEARCH TYPE */
		case 'setSearchType':
			return {
				...state,
				searchType: action.payload
			}
	}
	return state;
}

function FilterContextProvider(props: any) {
	const initialState = initialContext.state;
	const { sports, genders } = useDynamicInputs();
	let [state, dispatch] = useReducer(reducer, initialState);
	let value = { state, dispatch };

	useEffect(() => {
		if (sports.map) {
			dispatch({ type: 'match_setSelectedSports', payload: sports.map(sport => sport.name) });
			dispatch({ type: 'tournament_setSelectedSports', payload: sports.map(sport => sport.name) });
			dispatch({ type: 'match_setSelectedSportsFinal', payload: sports.map(sport => sport.name) });
		}
	}, [sports]);

	useEffect(() => {
		if (genders.map) {
			dispatch({ type: 'match_setSelectedGenders', payload: genders.map(gender => gender.name) });
			dispatch({ type: 'tournament_setSelectedGenders', payload: genders.map(gender => gender.name) });
		}
	}, [genders]);

	return (
		<FilterContext.Provider value={value}>{props.children}</FilterContext.Provider>
	)
}

let FilterContextConsumer = FilterContext.Consumer;

export {
	FilterContext,
	FilterContextConsumer,
	FilterContextProvider
}