import { Link } from "react-router-dom";
import styled from "styled-components";

interface TextLinkProps {
	href: string
	color?: string
}

const TextLinkStyled = styled(Link)`
	text-align: center;
	display: block;
	font-size: 14px;
	font-family: var(--heading-font);
	text-decoration: none;
	color: ${props => `var(--ion-color-${props.color})`};
`;

const TextLink: React.FC<TextLinkProps> = ({href, children, color = "text-shade"}) => {
	return (
		<TextLinkStyled 
			to={href}
			color={color}
		>
			{children}
		</TextLinkStyled >
	);
}

export default TextLink;