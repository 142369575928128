import { IonButton, IonIcon } from "@ionic/react";
import { search } from "ionicons/icons";

const TournamentPromptButton = () => (
	<IonButton
			color="accent-two"
			expand="block" 
			routerLink="/search/tournament"
		>
		<IonIcon icon={search} style={{marginRight: '5px'}}></IonIcon>Find Open Tournaments
	</IonButton>
)

export default TournamentPromptButton;