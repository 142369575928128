import { IonCheckbox, IonItem, IonLabel, IonList, IonListHeader, IonSelect, IonSelectOption } from "@ionic/react";
import { ReactNode, useEffect, useState } from "react";
import useDynamicInputs from "../hooks/useDynamicInputs";
import { generateRandomString } from "../services/util";

interface GenderCheckboxProps {
  selectedGenders: string[]
  onChange(checked: boolean, gender: string): void
}

const GenderCheckboxes: React.FC<GenderCheckboxProps> = ({selectedGenders, onChange}) => {
  const {genders} = useDynamicInputs();
  const [options, setOptions] = useState<ReactNode[]>([]);
  
  const getGenderCheckboxes = () => {
    let options: React.ReactNode[] = [];
    if (genders && genders.length > 0){
      if (genders.map !== undefined){
        options = genders.map((gender: Gender) => (
          <IonItem key={generateRandomString()}>
            <IonLabel>{gender.name}</IonLabel>
            <IonCheckbox 
              slot="end" 
              onIonChange={e => onChange(e.detail.checked, gender.name)}
              checked={selectedGenders.includes(gender.name)}
            />
          </IonItem>
        ));
      }
    }
    return options;
  }

  useEffect(() => {
    const options = getGenderCheckboxes();
    setOptions(options);
  }, [genders, selectedGenders])

  return (
    <IonList>
      <IonListHeader>
      <div style={{fontSize:"21px", marginLeft:"12px"}}>
        <IonLabel>Gender</IonLabel>
      </div>
      </IonListHeader>
      {options}
    </IonList>
  );
}

export default GenderCheckboxes;