import { IonContent, IonPage, IonHeader,IonToolbar, IonButtons,IonBackButton,IonTitle} from '@ionic/react';
import WaveTabs from '../components/WaveTabs';
import PageTitle from '../components/PageTitle';
import MatchSearch from '../components/MatchSearch';
import TournamentSearch from '../components/TournamentSearch';
import EventSearch from 'components/EventSearch';
import { RouteComponentProps } from 'react-router';
import CommonHeader from 'components/CommonHeader';


const SearchPage: React.FC<RouteComponentProps> = () => {
  return (
    <IonPage>
       <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton style={{display:"block"}} />
          </IonButtons>
          {/* <IonTitle color="main-text">Search</IonTitle> */}
        </IonToolbar>
      </IonHeader>
      {/* <CommonHeader title = {`Search`}></CommonHeader> */}
      <IonContent fullscreen>
        <WaveTabs 
          leftTitle="Match" 
          rightTitle="Tournament"
          centerTitle="Event" 
          leftComponent={<MatchSearch />} 
          rightComponent={<TournamentSearch />}
          centerComponent={<EventSearch />}
          color='accent-two'
        >
          <PageTitle color="main">Search</PageTitle>
        </WaveTabs>
      </IonContent>
    </IonPage>
  );
};

export default SearchPage;
