import { IonButton, IonCol, IonCard, IonContent, IonItem, IonLabel, IonToggle, IonIcon, IonPage, IonRow,IonRadio, IonSpinner, IonText, useIonAlert, IonInput,IonRadioGroup,IonListHeader,IonSelect,IonSelectOption } from '@ionic/react';
import StarRatingComponent from 'react-star-rating-component';
import WaveHeader from '../components/WaveHeader';
import { person, location, star, diamond, starHalf } from 'ionicons/icons';
import useUser from '../hooks/useUser';
import PageTitle from '../components/PageTitle';
import { generateRandomString } from '../services/util';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import ProfileImage from '../components/ProfileImage';
import { useEffect, useState, useContext } from 'react';
import VariableText from 'components/VariableText';
import styled from 'styled-components';
import useToast from "hooks/useToast";
import { Team,Users,DynamicInputs } from "../services/api";
import CenteredSpinner from "components/CenteredSpinner";
import { FilterContext } from "../State/FilterContext";
import { trash } from 'ionicons/icons';


interface TeamDetailsPageProps extends RouteComponentProps<{
	id?: string;
	type: string;
}> { }

const TeamDetailsPage: React.FC<TeamDetailsPageProps> = ({ match }, props) => {
	const dev = false;
	const { id } = match.params;
	const { userID } = useAuth();
	const { token } = useAuth();
	const [toast] = useToast("Hello");
	const { user } = useUser(id);
	const detailsSize = "12px";
	const [teamDetails, setTeam] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const history = useHistory();
	const [gender, setGender] = useState<string>();
	const [total_players, setTotalPlayers] = useState<string>("");
	const [team_name, setTeamName] = useState<string>("");
	const { state, dispatch } = useContext(FilterContext);
	const [userData,setUserData]=useState<any>([]);
	const [data, setData] = useState<any>([]);
	const [emailData, setEmailData] = useState<any>([]);
	const [email, setEmail] = useState<string>("");
	const [type, setType] = useState<number>(0);
	const [location, setLocation] = useState<string>("");
	const [team_id, setTeamId] = useState<any>([]);
	const [sports, setSports] = useState<string>("");
	const [sportslist, setSportsList] = useState<any>([]);
  const [checked, setChecked] = useState(true);
	
  
	var genderList = ['Male', 'Female', 'Mix'];

  
	
	const getData = async () => {
		let result: any = [];
		let sports_list: any = [];
		let teamUserResult: any = [];
		let args = {
			team_id:id
		};
		//sports_list = await DynamicInputs.getSports(token ?? "");
    sports_list = await Users.getTeamSport(token ?? "");
		setSportsList(sports_list.payload);
		if (!token) return;
		result = await Team.teamDetails(token ?? "", args);
		if (result.success) {
			setIsLoading(false);
			setTeam(result.payload);
			setGender(result.payload.gender_id);
			setUserData(result.payload.users);
			setLocation(result.payload.location);
			setTeamName(result.payload.name);
			setTeamId(result.payload.id);
			setTotalPlayers(result.payload.users.length);
			setSports(result.payload.sport_id);
		} 
		else {
			toast({ message: "Team does not exist" }, true);
		}
		// return Event.viewCancel;
	}

	const isUserProfile = () => {
		if (userID === null) return false;
		return (id === undefined || id == userID);
	}

	const validateEmail = (email: any) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};
	const searchUserByEmail = async () => {
		try {
			if (!email) {
				toast({ message: 'Enter Email Id' }, true);
				return;
			}

			if (validateEmail(email) == null) {
				toast({ message: "Invalid Email id" }, true);
				return;
			}

			let postData = {
				email: email
			}
			let response = await Users.searchUserByEmail(token ?? "", postData);
			if (response.success == true) {
				let user = response.payload;
				var result = (
					<IonItem key={generateRandomString()} lines="none">
						<Player
							className="ion-align-items-center"
							key="0"
						>
							<ProfileImage
								icon_type="none"
								icon_color="primary"
								size="75px"
								filename={user.preference.image}
							/>
							<IonCol>
								<IonText>{user.short_name}</IonText>
								<IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
									{user.preference.location}
								</IonRow>
							</IonCol>
							{/* <IonToggle  onIonChange={e => addSingleFriendTeam(user.id)} /> */}
							<IonButton style={{marginLeft: "407px"}} onClick={(e) => { addSingleFriendTeam(user.id) }}>Add</IonButton>
						</Player>
					</IonItem>
				);
				setEmailData(result);
			} else {
				toast({ message: response.message }, true);
				setEmailData([]);
			}

		} catch (error) {
			setEmailData([]);
			toast({ message: error }, true);
		}
	}


	const getPlayers = ((users: User[], organizer_id: string) => {
		return (users.length > 0) ? users.map((user, index) => {
			// check if we have the user info we need
			// setIsLoading(false);
			if (user && user.id && user.preference) {
				return (
					<IonItem key={generateRandomString()} lines="none" routerDirection="forward">
						<Player
							className="ion-align-items-center"
							key={index}
							// onClick={(e) => { clickToProfilePage(user.id) }}
						>
							<ProfileImage
								icon_type="none"
								icon_color="primary"
								size="75px"
								filename={user.preference.image}
							/>
							<IonCol>
								<IonText>{user.short_name}</IonText>
								<IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
									{user.preference.location}
								</IonRow>
							</IonCol>
							
						<IonButton 
							style={{playerdelete}}
							size="default"
							color="danger"
							onClick={(e) => { removeSingleFriendTeam(user.id); }}>
							<IonIcon icon={trash} />
						</IonButton>
						
							<IonToggle checked={user?.active} onIonChange={(e) => { changeStatusTeamPlayers(user.id); }} />
						</Player>
						
					</IonItem> 

				)
			}
		}) : (<IonItem><IonText>No Players found</IonText></IonItem>)
		
	});

	const cancelTeam = async () => {
		try {
			let postData = {
				team_id: id
			}
			let response = await Team.teamDelete(token ?? "", postData);
			if (response.success == true) {
				toast({ message: response.message });
				history.push('/myteam');
			} else {
				toast({ message: response.message }, true);
			}
		} catch (error) {
			console.log('Team Delete Error', error);
			toast({ message: error }, true);
		}
	};

	const getFriendList = async () => {
		let response = await Users.getFriendList(token ?? "");
		let users = response.payload;

		if (response.payload) {
			var data = users.map((user: any, index: any) => (
				<IonItem key={generateRandomString()} lines="none">
					<Player
						className="ion-align-items-center"
						key={index}
					>
						<ProfileImage
							icon_type="none"
							icon_color="primary"
							size="75px"
							filename={user.preference.image}
						/>
						<IonCol>
							<IonText>{user.short_name}</IonText>
							<IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
								{user.preference.location}
							</IonRow>
						</IonCol>
						{/* <IonToggle  onIonChange={e => addSingleFriendTeam(user.id)} /> */}
						<IonButton style={{marginLeft: "100px"}} onClick={(e) => { addSingleFriendTeam(user.id) }}>Add</IonButton>
					</Player>
				</IonItem>
			));
		} else {
			data = (<IonItem><IonText>No friend found</IonText></IonItem>);
		}
		setData(data);
		setEmailData([]);
		setEmail('');
		
	};

	const addSingleFriendTeam = async (user_id: any) => {
		try {
		  	let postData = {
		 		team_id: id,
				user_id: user_id
		}
			let response = await Team.addSingleFriendTeam(token ?? "", postData);
			if (response.success == true) {
				getData();
				toast({ message: response.message });
			} else {
				toast({ message: response.message }, true);
			}

		} catch (error) {
			toast({ message: error }, true);
		}
	}
	const removeSingleFriendTeam = async (user_id: any) => {
		try {
			let postData = {
				team_id: id,
				user_id: user_id
			}
			let response = await Team.removeSingleFriendTeam(token ?? "", postData);
			if (response.success == true) {
				getData();
				toast({ message: response.message });
			} else {
				toast({ message: response.message }, true);
			}

		} catch (error) {
			console.log('Remove Event', error);
			toast({ message: error }, true);
		}
	}

	const changeStatusTeamPlayers = async (user_id: any) => {
		try {
			let postData = {
				team_id: id,
				user_id: user_id
			}
			let response = await Team.changeStatusTeamPlayers(token ?? "", postData);
			if (response.success == true) {
				//getData();
				toast({ message: response.message });
			} else {
				toast({ message: response.message }, true);
			}

		} catch (error) {
			console.log('Status Update', error);
			toast({ message: error }, true);
		}
	}



	useEffect(() => {
		getData();
		setGender(teamDetails.gender_id);
		setSports(teamDetails.sport_id);
		console.log("Location useEffect",location);
		getFriendList();
	}, [id,token]);


  //console.log("Team sport list", sportslist);
	//console.log("Teamdetails", teamDetails);
	//console.log("Team status", teamstatus);

	return (
		<IonPage>
			<IonContent fullscreen>
				{/*
					WAVE HEADER
				*/}
				<WaveHeader color="accent-two"  >
					<IonRow>
						<ProfileDetails>
							<PageTitle
								color="main"
							>
								<VariableText text={(teamDetails && teamDetails.name) ? teamDetails.name : ""} />
							</PageTitle>

							<DetailsPill show={true}>
								{(teamDetails && teamDetails.name) ? (
									<IonRow style={{ fontSize: detailsSize }} className="ion-align-items-center horizontal-gutter">
										<IonIcon style={{ marginRight: "5px" }} color="main" icon={person} />
										<IonText color="main">
										{teamDetails.users.length} * {teamDetails.sport.name}
										</IonText>
									</IonRow>
								) : null}

								{/* Only show this row if the location is set */}
								{(teamDetails && teamDetails.location) ?
									(
										<IonRow style={{ fontSize: detailsSize }} className="ion-align-items-center horizontal-gutter">
											<IonIcon style={{ marginRight: "5px" }} color="main" icon={location} />
											<IonText color="main" >{teamDetails.location}</IonText>
										</IonRow>
									) : null}


							</DetailsPill>
						</ProfileDetails>
			
					</IonRow>
				</WaveHeader>




				{/*
					MAIN PROFILE BODY
				*/}
				{(!isLoading) ? (
				<MainContent show={true}>
					<div className="horizontal-gutter" style={{ marginTop: '25px' }}>
						<IonCol className="ion-justify-content-between">
							<div>
								{/*
								THREE ROWS FOR THINGS THAT AREN'T GOING ON A CARD
							*/}
								<div className="horizontal-gutter">


									{/*
									USER RATING AND CREDITS
								*/}
									<IonRow style={{ fontSize: "15px" }} className="ion-align-items-center ion-justify-content-between">

										<StarRatingComponent
											name="user-rating"
											value={(teamDetails && teamDetails.rating) ?? 0}
											editing={false}
											starColor={"var(--ion-color-text)"}
											emptyStarColor="#777"
											renderStarIcon={() => <IonIcon icon={star} />}
											renderStarIconHalf={() => <IonIcon icon={starHalf} />}
										/>

									</IonRow>

								</div>


							{(!isUserProfile()) ?
									(
										<IonItem className="form-input" data-name="title">
											<IonRow style={{ width: "100%" }}>
												<IonCol size-md="9" size-sm="8">
												<IonLabel>Team Name</IonLabel>
													<IonInput
														name="team_name"
														type="text"
														value={team_name}
														onIonChange={(e) => {
															let temName = e.detail.value!;
															//console.log({temName});
															setTeamName(e.detail.value as string);
														}}
													/>
												</IonCol>
											</IonRow>
											<IonRow style={{ width: "30%" }}>
													<IonCol size-md="7" size-sm="6">
													<IonLabel style={{paddingBottom: "10px"}}>Ranking</IonLabel>
													<IonCol>{teamDetails.rank}</IonCol>
													</IonCol>
											</IonRow>
											
											
											<IonRow style={{ width: "30%" }}>
											<IonCol size-md="9" size-sm="8">
											<IonLabel>Active</IonLabel>
											
											 <IonToggle color="success" checked={checked} onIonChange= {(e) => setChecked(e.detail.checked)}  />
											 </IonCol>
											</IonRow>
											
										</IonItem>
									) : null}

								{(!isUserProfile()) ?
									(
										<IonItem className="form-input" data-name="title">
											<IonLabel position="stacked">Location</IonLabel>
											<IonRow style={{ width: "100%" }}>
												<IonCol size-md="9" size-sm="8">
													<IonInput
														name="location"
														type="text"
														placeholder="City, State, or Zip Code"
														value={location}
														onIonChange={(e) => {
															let localLocation = e.detail.value!;
															console.log({localLocation});
															setLocation(e.detail.value as string);
														}}
													/>
												</IonCol>
											</IonRow>
										</IonItem>
									) : null}
							</div>
						  
							{/* <IonLabel position="stacked">Gender</IonLabel>
							<IonRadioGroup value={gender} name="gender" onIonChange={(e) => {
								        //console.log("Gender value",e.detail.value);
    										setGender(e.detail.value);
											}}>
							{genderList.map((value) => (
								<IonItem>
									<IonLabel>{value}</IonLabel>
									<IonRadio  value={value} name="gender"/>
								</IonItem>

							))}
							</IonRadioGroup> */}
							<IonLabel position='stacked'>Gender</IonLabel>
							<IonItem className="form-input" data-name="title">
                 <IonSelect placeholder="Select gender" value={gender} onIonChange={(e) => {
									    setGender(e.detail.value);
								 }}>

							    {genderList.map((value:any) =>(
										  <IonSelectOption value={value}>{value}</IonSelectOption> 
									))}
								 </IonSelect>
							</IonItem>


							
						
							<IonLabel position="stacked">Sports</IonLabel>
							{/* <IonRadioGroup value={sports} name="sports" onIonChange={(e) => {
    										setSports(e.detail.value);
											}}>
							{sportslist.map((value:any) => (
								<IonItem>
									<IonLabel>{value.name}</IonLabel>
									<IonRadio  value={value.sport_id} name="sports"/>
								</IonItem>

							))}
							</IonRadioGroup> */}

               <IonItem className="form-input" data-name="title">
									<IonSelect value={sports} placeholder="Select Sports" onIonChange={(e) => {
										   setSports(e.detail.value)
									}}>
										{sportslist.map((value:any) => (
										    <IonSelectOption value={value.id}>{value.name}</IonSelectOption>
										))}
									</IonSelect>
								</IonItem>

							<IonCard>
							{
							(teamDetails?.user_id == userID) ?
								<IonItem className="form-input" data-name="title">
									<IonLabel style={{ fontWeight: "bold" }}>
										{(type == 1) ? "Add Players By Email" : ""}
										{(type == 2) ? "Friend List" : ""}
									</IonLabel>
									<IonSelect value={type} placeholder="Select Type" onIonChange={e => setType(e.detail.value)}>
										<IonSelectOption value="1">Players Email</IonSelectOption>
										<IonSelectOption value="2">Friendlist</IonSelectOption>
									</IonSelect>
								</IonItem>
								:
								[]

						}
						{(type == 1) ?
							<div>
								<IonItem>
									<IonInput
										name="title"
										type="text"
										placeholder="Enter Email"
										value={email}
										onIonChange={(e) => {
											setEmail(e.detail.value as string);
										}}
									/>
								</IonItem>
								<IonItem>
									<IonButton
										size="default"
										color="main"
										expand="block"
										style={{ flex: 1 }}
										onClick={searchUserByEmail}>Search</IonButton>
								</IonItem>
								{emailData}
							</div>

							: (type == 2) ?
								data
								: []
						}
						</IonCard>
							<IonCard>
							<IonListHeader>
								<CardHead>
								<IonRow className="ion-justify-content-between ion-align-items-center" style={{ paddingRight: '16px' }}>
								<IonLabel><b>Players</b></IonLabel>
								<IonLabel style={{ textAlign: 'right' }}><b>Active Players</b></IonLabel>

									</IonRow>

								</CardHead>
							</IonListHeader>
							<IonRadioGroup
								name="user"
							>
							{(teamDetails?.users && teamDetails?.user_id) ? getPlayers(teamDetails.users ?? [], teamDetails?.id) : null}
							</IonRadioGroup>
						</IonCard>
						</IonCol>


						<IonRow>
									<IonCol>
										<IonButton
											size="default"
											color="danger"
											expand="block"
											style={{ flex: 1 }}
											onClick={(e) => {
												e.preventDefault();
												if (!location) {
													toast({ message: 'Add a location' }, true);
													return;
												}
												if (!gender) {
													toast({ message: 'Set gender' }, true);
													return;
												}
												// if (!sports) {
												// 	toast({ message: 'Select sports' }, true);
												// 	return;
												// }
												let args = {
													name:team_name,
													location,
													gender,
													sport_id:sports,
													team_id:teamDetails.id,
													status: checked

												};
												//console.log("Team all argument", args);
												//console.log("Team arguments", args);
												let response = Team.teamEdit(token ?? "", args);
												
												if (response) {
													response.then(function (data) {
														if (data && data.success) {
															getData();
															toast({ message: "Team edited successfully." });
															history.push('/team/edit/'+team_id);
															
														} else {
															toast({ message: "Problem editing Team" }, true);
														}
													})
												}
											}}
										>
											Save
										</IonButton>
									</IonCol>
									<IonCol>
										<IonButton
										size="default"
											color="main"
											expand="block"
											style={{ flex: 1 }}
											onClick={cancelTeam}
											
										>
											Delete
										</IonButton>
									</IonCol>
								</IonRow>
					</div>
				</MainContent>
				) : <CenteredSpinner />}
			</IonContent>
		</IonPage>

	);
};

export default TeamDetailsPage
const RankName = styled.div`
	width:53%;
`;
const ProfileDetails = styled(IonCol)`
	flex: 2;
`;

const DetailsPill = styled.div<{ show: boolean }>`
	background: var(--ion-color-accent-one);
	letter-spacing: 0.4px;
	padding: 10px;
	padding-left: 0;
	width: max-content;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	box-shadow: 0 0 4px rgba(13,13,13,0.3);
	transition: margin-left 0.3s;
	margin-left: ${props => `${(props.show) ? "0" : "-100%"}`};
	font-family: var(--heading-font);
	& * {
		font-size: 12px;
	}
`;

const ProfileContainer = styled.div<{ show: boolean }>`
	flex: 1;
	padding-right: 3%;
	transition: margin-right 0.3s;
	margin-right: ${props => `${(props.show) ? "0" : "-100%"}`};
`;

const MainContent = styled.div<{ show: boolean }>`
	transition: margin-top 0.3s;
	margin-top: ${props => `${(props.show) ? "0" : "150%"}`}
`;


const CardHead = styled.div`
	flex: 1;
  font-size: 20px;
`;

const DetailLabel = styled.div`
  font-size: 20px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Player = styled.div`
  display: flex;
	width:100%;
	cursor: pointer;
  padding: 0 0 10px 0;
  &:first-of-type {
    padding-top: 5px;
  }

`;

const playerdelete = {
   marginLeft: '110px',
	'@media (max-width: 900px)': {
		marginLeft: '10px',
	}
};
