import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useHistory } from "react-router-dom";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "CardSection";
import useToast from "hooks/useToast";
import { CONFIG } from '../constants';
import useUser from 'hooks/useUser';
import { Users } from 'services/api'
import { IonLabel, IonSelect, IonSelectOption,IonSpinner } from "@ionic/react";

interface CheckoutProps { stripe: any, elements: any }

const CheckoutForm: React.FC<CheckoutProps> = (props) => {
  const [toast] = useToast("Hello");
  const [amount, setAmount] = useState<any>([]);
  const user = useUser();
  const history = useHistory();
  const [loader,setLoader]=useState<boolean>(false);
  const [creditList,setCreditList]=useState<any>([]);

  useEffect(() => {
    getCreditList();
  }, []);

  
  const getCreditList = async () => {
    let options;
    var creditData = [];
    let response = await Users.getCreditList();
    creditData = response;
    setCreditList(creditData);

    return creditData;
  };

  const stripeTokenHandler = async (token: any) => {
   
    const paymentData = { 
      token: token.id, 
      data: user, 
      amount: amount.amount,
      point:amount.point 
    };
    //console.log(paymentData);return;
    
    const response = await fetch(CONFIG.API_ENDPOINT + '/stripes/payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(paymentData),
    });
    setLoader(false);
    return response;
  }
  const handleSubmit = async (event: any) => {
    
    event.preventDefault();
    setLoader(true);
    
    if (amount.length==0) {
      toast({ message: "Select Price" }, true);
      setLoader(false);
      return;
    }
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      toast({ message: "Api key missing" }, true);
      setLoader(false);
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      toast({ message: "Invalid Card Details" }, true);
      setLoader(false);
    } else {
      const token = result.token;
      try {
        stripeTokenHandler(token).then(res => {
          console.log(res);
          toast({ message: "Payment Succesfully Done" });
          history.goBack();
        })
      } catch (error) {
        console.log(error);
        setLoader(false);
        toast({ message: "Something went wrong" }, true);
      }
    }
  };

  return (
    <div>
      <div className="product-info">
        <IonLabel><b>Select Price</b></IonLabel>
        <IonSelect placeholder="Select Price" onIonChange={e => setAmount(e.detail.value)} value={amount}>
        {
          creditList.map((item:any)=>(
            <IonSelectOption key={item.id} value={item}
            >${item.amount} for {item.point} credits
            </IonSelectOption>
          ))
        }
      </IonSelect>
      </div>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <button className="btn-pay">
          Buy Now{(loader)?(<IonSpinner />):""}
        </button>
        
      </form>
    </div>
  );
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}