import { useState } from "react";
import {
	IonButton,
	IonItem,
	IonLabel,
	IonInput,
	IonPage,
	IonContent,
	IonRow,
	IonProgressBar,
	IonText,
} from "@ionic/react";
import { AuthenticationService } from '../../services/auth';
import PasswordInput from "../../components/PasswordInput";
import useToast from "../../hooks/useToast";
import { validateEmail } from "services/util";
import { useHistory } from "react-router";
import AuthNav from "components/AuthNav";
import TitleCardSimple from "components/TitleCardSimple";
import styled from "styled-components";
import TextLink from "components/TextLink";
import Button from "components/Primitives/Button";

const RegistrationPage = () => {
	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('')
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirm, setConfirm] = useState('');
	const [strengthText, setStrengthText] = useState('');
	const [strengthNum, setStrengthNum] = useState(0);
	const [toast] = useToast();
	const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
	const history = useHistory();

	const showLabels = false;

	const getPassed = (password: string): number => {
		var passed = 0;
		if (password.length >= 8){
			passed++;
		}

		//Regular Expressions.
		var regex = new Array();
		regex.push("[A-Z]"); //Uppercase Alphabet.
		regex.push("[a-z]"); //Lowercase Alphabet.
		regex.push("[0-9]"); //Digit.
		regex.push("[$@$!%*#?&]"); //Special Character.

		//Validate for each Regular Expression.
		for (var i = 0; i < regex.length; i++) {
				if (new RegExp(regex[i]).test(password)) {
						passed++;
				}
		}
		switch (passed) {
			case 0:
				setStrengthText("Very Weak");
				break;
			case 1:
				setStrengthText("Weak");
				break;
			case 2:
				setStrengthText("Okay");
				break;
			case 3:
				setStrengthText("Good");
				break;
			case 4:
				setStrengthText("Strong");
				break;
			case 5:
				setStrengthText("Very Strong");
				break;
		}

		setStrengthNum(passed);

		return passed;
	}



	const handleChange = (event:any)=>  {
		var a = event.target.value;
		console.log("A", a);
		var b = a.replace(/[^a-z]/gi, '');
		console.log("B", b);
    const result = event.target.value.replace(/[^a-z]/gi, '');
    setFirstName(result);

  };

	return (
		<IonPage className="ion-align-items-center">
			<IonContent className="ion-padding basic-bg basic-bg-pad ">
				<div style={{ padding: "50px 0 10px"}}>
					<TitleCardSimple />
				</div>
				<div className="basic-form" style={{maxWidth:"400px", margin:"auto"}}>
					<IonItem>
						{showLabels && <IonLabel position="stacked">First Name</IonLabel>}
						<IonInput
							type="text"
							onKeyUp={(event: any) => {
								let newValue = event.target.value;
								let regExp = new RegExp('^[A-Za-z? ]+$');
								if (! regExp.test(newValue)) {
									event.target.value = newValue.slice(0, -1);
									
								}
                setFirstName(event.target.value);
						 }}

						onKeyPress={(event:any) => {
							    let newValue = event.target.value;
									let regExp = new RegExp('^[A-Za-z? ]+$');
									if (! regExp.test(newValue)) {
										event.target.value = newValue.slice(0, -1);
										
									}
							    setFirstName(event.target.value);
						}}

							placeholder="First Name"
							name="first_name"
							value={first_name}
							autocomplete="given-name"
							//onKeyUp={handleChange}
							
						/>
					</IonItem>
					<IonItem>
						{showLabels && <IonLabel position="stacked">Last Name</IonLabel>}
						<IonInput
							type="text"
							// onIonInput={(e) => {
							// 	let event = e as any; // get's typescript compiler to not complain
							// 	let value = event.target.value;
							// 	setLastName(value);
							// }}
							onKeyUp={(event:any) => {
								  let lastname = event.target.value;
									let regExp = new RegExp('^[A-Za-z? ]+$');
									if(!regExp.test(lastname)){
										   event.target.value = lastname.slice(0, -1);
									}
									setLastName(event.target.value);
							}}
              
							onKeyPress={(event:any) => {
								let lastname = event.target.value;
								let regExp = new RegExp('^[A-Za-z? ]+$');
								if(!regExp.test(lastname)){
										 event.target.value = lastname.slice(0, -1);
								}
								setLastName(event.target.value);
						}}

							placeholder="Last Name"
							name="last_name"
							value={last_name}
							autocomplete="family-name"
						/>
					</IonItem>
					<IonItem>
						{showLabels && <IonLabel position="stacked">Email Address</IonLabel>}
						<IonInput
							type="email"
							onIonInput={(e) => {
								let event = e as any; // get's typescript compiler to not complain
								let value = event.target.value;
								setEmail(value);
							}}
							placeholder="Email"
							name="email"
							value={email}
							autocomplete="email"
						/>
					</IonItem>
					<IonItem>
						{showLabels && <IonLabel position="stacked">Password</IonLabel>}
						<PasswordInput
							onChange={setPassword}
							onInput={(e: any) => {
								getPassed(e.target.value);
								setPassword(e.target.value);
							}}
							placeholder="Password"
							password={password}
							name="password"
						/>
					</IonItem>
					<IonItem>
						{showLabels && <IonLabel position="stacked">Confirm Password</IonLabel>}
						<PasswordInput 
							onChange={setConfirm}
							password={confirm}
							placeholder="Confirm Password"
							name="confirm"
						/>
					</IonItem>
				</div>
				{(strengthText) ? (
				<>
					<StyledProgressBarContainer>
						<div style={{padding: "7px 0", fontSize: '12px'}}>
							<IonText>Password Strength: </IonText><IonText>{strengthText}</IonText>
						</div>
						<IonProgressBar
							color="constant-light"
							value={strengthNum / 5}
						/>
					</StyledProgressBarContainer>
				</>
				):null}
				<StrengthNotes style={{maxWidth:"400px", margin:"auto", fontSize:"13px"}}>
					Password must be between 8 and 20 characters and contain at least one of each:
					<ul>
						<li>- Uppercase Letter (A-Z)</li>
						<li>- Lowercase Letter (a-z)</li>
						<li>- Number (0-9)</li>
						<li>- Special Character ($@$!%*#?&)</li>
					</ul>
				</StrengthNotes>
				<div style={{ padding: 10, paddingTop: 20 , maxWidth:"400px", margin:"auto"}}>
					<Button
						color="accent-two"
						expand="block"
						style={{ margin: 14 }}
						onClick={(e: any) => {
							if (!e.currentTarget) {
								return;
							}
							e.preventDefault();

							//validation
							if (first_name === ""){
								toast({message: "Please enter your first name"}, true);
								return false;
							}

							if (last_name === ""){
								toast({message: "Please enter your last name"}, true);
								return false;
							}

							if (email === ""){
								toast({message: "Please enter an email"}, true);
							}

							if (!validateEmail(email)){
								toast({message: "Please enter a valid email"}, true);
								return false;
							}

							if (password === ""){
								toast({message: "Please enter a password"}, true);
								return false;
							}

							if (password.length > 20 || password.length < 8){
								console.log({password});
								toast({message: "Password must be between 8 and 20 characters"}, true);
								return false;
							}

							let passed = getPassed(password);

							if (passed < 5){
								toast({
									message: "Password must be between 8 and 20 characters and contain at least one of each:\n\n-  Uppercase Letter (A-Z)\n-  Lowercases Letter (a-z)\n-  Number (0-9)\n-  Special Character ($@$!%*#?&)",
									duration: 0
								}, true);
								return false;
							}

							if (password !== confirm){
								toast({message: "Passwords must match"}, true);
								return false;
							}

							let args: RegisterProps = {
								first_name, last_name, email, password, confirm, prefersDark: prefersDark.matches
							}

							let response = AuthenticationService.register(args);
							console.log({response});
							response.then(data => {
								console.log({data})
								if (data !== undefined){
									if (data.success){
										toast({message: data.message});
										history.push('/user/profile/view');
									} else {
										toast({message: data.message}, true);
									}
								}
							});
						}}
					>
						Register
					</Button>
					<IonRow className="ion-justify-content-center ion-align-items-center link-row">
						<Message>Already have an account?</Message>
						<TextLink
							href="/login"
							color="accent-two"
						>
							Sign In
						</TextLink>
					</IonRow>
				</div>
				<AuthNav />
			</IonContent>
		</IonPage>
	);
};

export default RegistrationPage

const Message = styled.div`
	margin-right: 10px;
	color: white;
`;

const StyledProgressBarContainer = styled.div`
	margin-top: 15px;
	color: white;
`;

const StrengthNotes = styled.div`
	color: white;
	font-size: 12px;
	padding: 10px 0 0 0;
	& ul {
		text-indent: 0;
		padding-left: 0;
		& li {
			list-style: none;
		}
	}
`;