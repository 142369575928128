import { IonCol, IonIcon, IonInput, IonItem, IonRow, IonText, IonToggle } from "@ionic/react"
import ProfileImage from "./ProfileImage"
import { location, star, walk, starHalf } from 'ionicons/icons';
import { generateRandomString } from "../services/util";
import { useEffect, useState, useContext } from "react";
import { Friend } from "../services/api"
import useToast from "hooks/useToast";
import useAuth from '../hooks/useAuth';
import { FilterContext } from "../State/FilterContext";
import StarRatingComponent from 'react-star-rating-component';

interface FriendCardProps {
  user: any
  type: string
}

const FriendCard: React.FC<FriendCardProps> = ({ user, type }) => {
  const { token } = useAuth();
  const [toast] = useToast("Hello");
  const { state, dispatch } = useContext(FilterContext);
  const [check, setCheck] = useState<boolean>(
    () => {
      if (type == "friend") {
        if (user.friends.status == 2 || user.friends == "") {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  );

  const friendActiveDeactive = async (userId: any, check: any) => {
    try {
      let post = { friends: [{ id: userId, active: check }] };
      let response = await Friend.sendFriendsRequest(post, token ?? "");
      if (response.success == true) {
        toast({ message: response.message });
      } else {
        toast({ message: response.message }, true);
      }
      setCheck(check);
    }
    catch {
      toast({ message: "Something went wrong" }, true);
    }
  }


  const eventFriendAdd = (userId: any, email: any, check: any) => {
    let data = (type === "event") ? state.eventFilters.eventDetails :
      (type === "team") ? state.teamFilters.teamDetails :
        state.teamFilters.teamDetails;

    let objData = {
      id: userId,
      email: email
    };
    if (type === "event") {
      if (check == true) {
        data.selected.push(objData);
        setCheck(true);
      } else {
        for (var i = 0; i < data.selected.length; i++) {
          if (userId == data.selected[i].id) {
            data.selected.splice(i, 1);
          }
        }
        setCheck(false);
      }

    } else if (type === "team") {
      if (check == true) {
        data.push(objData);
        setCheck(true);
      } else {
        for (var i = 0; i < data.length; i++) {
          if (userId == data[i].id) {
            data.splice(i, 1);
          }
        }
        setCheck(false);
      }
    }

    if (type === 'event') {
      dispatch({ type: 'eventDetails_setEventDetails', payload: data });
    } else if (type === 'team') {
      dispatch({ type: 'teamDetails_setTeamDetails', payload: data });
    }

    return check;
  }

  const getRankRows = () => {
    if (user && user.ranks && Object.keys(user.ranks).map !== undefined) {
      return Object.keys(user.ranks).map((rank: string, index: number, array: any[]) => {
        return (
          <IonRow key={generateRandomString()}>
            <IonIcon style={{ marginRight: "5px" }} color="main-text" icon={star} />
            <IonText>{(array.length > 1) ? rank : null} Rank: {user.ranks[rank]}</IonText>
          </IonRow>
        );
      })
    }
    return null;
  }
  return (
    <IonItem>
      <ProfileImage
        icon_type="none"
        size="75px"
        filename={user?.preference?.image ?? ""}
        style={{ marginRight: '15px' }}
      />
      <IonCol>
        <IonRow>
          <StarRatingComponent
            name="user-rating"
            value={(user && user.rating) ?? 0}
            editing={false}
            starColor={"var(--ion-color-text)"}
            emptyStarColor="#777"
            renderStarIcon={() => <IonIcon icon={star} />}
            renderStarIconHalf={() => <IonIcon icon={starHalf} />}
          />
        </IonRow>
        <IonRow>
          <IonText style={{ fontSize: '18px', paddingBottom: '5px' }}>{user.full_name}</IonText>
        </IonRow>
        {getRankRows()}
        <IonRow className="ion-align-items-center">
          <IonCol style={{ maxWidth: '22px' }}>
            <IonIcon style={{ marginRight: "5px", fontSize: '18px' }} color="main-text" icon={location} />
          </IonCol>
          <IonCol>
            <IonRow>
              <IonText style={{ fontSize: '15px' }}>{user?.preference?.location}</IonText>
            </IonRow>
            {/* {(user && user.preference && user.preference.distance_miles !== null) ?
              (<IonRow style={{ paddingTop: '1px' }}>
                <IonText style={{ fontSize: '11px' }}>{user.preference.distance_miles?.toFixed(1)} miles away</IonText>
              </IonRow>) : null} */}
          </IonCol>
        </IonRow>
      </IonCol>


      <IonCol>
       <IonRow>
        <IonText style={{marginLeft: "auto",fontWeight:"bold"}}>Reject / Send</IonText>
        </IonRow>

          {
            (type == 'friend') ?
              <IonToggle color="success" style={{marginLeft: "auto",display: "block",marginRight: "16px"}} checked={check} onIonChange={e => friendActiveDeactive(user.id, e.detail.checked)} />
              :
              <IonToggle color="success" style={{marginLeft: "auto",display: "block",marginRight: "16px"}} checked={check} onIonChange={e => eventFriendAdd(user.id, user.email, e.detail.checked)
              } />
          }

        </IonCol>
    
    </IonItem>
  )
}

export default FriendCard;