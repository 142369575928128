import { IonItem, IonLabel } from '@ionic/react';
import styled from 'styled-components';


interface LinkProps {
	path: string;
	align?: string;
	classes?: string;
	color?: string;
	textColor?: string;
}

const LinkStyle = styled(IonItem)<{textColor: string}>`
	text-align: center;
	margin: 10px auto;
	border-radius: 100px;
	text-transform: unset !important;
	--min-height: 35px;
	font-size: 20px;
	min-width: 150px;
	border: none;
	& > ion-label {
		padding: 0;
	}
	&::part(native) {
		color: ${props => `var(--ion-color-${props.textColor})`};
	}
`;

const Label = styled(IonLabel)`
	margin: 0;
	font-family: var(--heading-font) !important;
`


const Link: React.FC<LinkProps> = ({children, path, classes, color = 'main', textColor = 'constant-light'}) => {
	return (
		<LinkStyle routerLink={path} color={color} textColor={textColor} className={classes} lines="none">
			<Label>{children}</Label>
		</LinkStyle>
	);
};

export default Link;
