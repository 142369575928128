import { IonContent, IonRow, IonSpinner } from "@ionic/react";

const LoadingContent: React.FC<{useSplashBackground?: boolean}> = ({useSplashBackground = false}) => {
	return (
		<IonContent
			className={`ion-padding ${(useSplashBackground) ? 'splash-content':""}`}
			style={{ filter: 'greyscale(1)'}}
			fullscreen
		>
			<IonRow style={{height: "100%"}} class="ion-justify-content-center ion-align-items-center">
				<div>
				<IonSpinner
					style={{
						width: "50px",
						height: "100px"
					}}
				/>
				</div>
			</IonRow>
		</IonContent>
	);
}

export default LoadingContent;