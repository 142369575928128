export const normalizePath = (path: string) => {
	
	// strip commas
	path = path.split(',').join(' ');
	// add spaces around all letters
	path = path.replace(/[A-Za-z]/g, " $& ").trim();

	let min: number = 0;
	let max: number = 0;
	let firstLoop: boolean = true;
	path.split(/\s+/ig).forEach((each: string | number) => {
		if (each !== " " && !isNaN(Number(each))){
			if (firstLoop) {
				min = Number(each);
				max = Number(each);
				firstLoop = false;
			} else if (each < min) {
				min = Number(each);
			} else if (each > max) {
				max = Number(each);
			}
		}
	});
	function normalize(num: number){
		return (num - min)/(max - min);
	}
	let newPath: Array<number | string>  = [];
	path.split(/\s+/ig).forEach(each => {
		let newEach;
		if (each !== " " && !isNaN(Number(each))) {
			newEach = normalize(Number(each));
		} else {
			newEach = each;
		}
		newPath.push(newEach);
	})

	let newPathString = newPath.join(' ');
	return newPathString;
}

export function getResponseMessages(res: any){
	if (res === undefined) res = { errors: [] };
	if (res.errors === undefined) res.errors = [];
	return res.errors;
}

export function getLinearGradient(color: string){
	if (color === 'accent-two'){
		return `linear-gradient(var(--ion-color-accent-two-light), var(--ion-color-accent-two))`;
	} else {
		return `linear-gradient(rgba(var(--ion-color-${color}-rgb), 0.6), var(--ion-color-${color}))`;
	}
}

export const generateRandomString = (length=20) => Math.random().toString(20).substr(2, length);

export const convertGenderStringToGenderObject = (gender: string, genders: Gender[]): Gender => {
	let default_gender: Gender = { id: "1", name: 'Male' };
	let found_gender = genders.find(g => g.name === gender);
	return (found_gender) ? found_gender:default_gender;
}

export const truncate = (str: string, limit: number = 10) => {
	return str.length > limit ? str.substring(0, limit - 3) + "..." : str;
}

export const validateEmail = (email: string) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export const validateAnyZip = (zip: string) => {
	let zipUs = /^\d{5}$|^\d{5}-\d{4}$/
	let zipCa = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
	let zipUk = /^[A-Z]{1,2}[0-9R][0-9A-Z]?\s*[0-9][A-Z-[CIKMOV]]{2}/
	let zipRo = /^\d{6}$/
	let zipSj = /^\d{4}\s{0,1}[A-Za-z]{2}$/
	let zipNl = /^\d{4}$/
	let zipBe = /^\d{4}$/
	let zipIr = /^[ACDEFHKNPRTVWXY]{1}[0-9]{1}[0-9W]{1}[\ \-]?[0-9ACDEFHKNPRTVWXY]{4}$/

	return (
		zipUs.test(zip) ||
		zipCa.test(zip) ||
		zipUk.test(zip) ||
		zipRo.test(zip) ||
		zipSj.test(zip) ||
		zipNl.test(zip) ||
		zipBe.test(zip) ||
		zipIr.test(zip)
	)
}