import { IonPage, IonContent, IonRow, IonText, IonSpinner, IonCol, IonItem, IonRadio, IonCard, IonList, IonListHeader, IonRadioGroup, IonButton, IonSelect, IonSelectOption } from "@ionic/react"
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Challenges, Tournaments, Users } from "services/api";
import { getTimeParts } from "services/time";
import { generateRandomString } from "services/util";
import styled from "styled-components";
import { disposeEmitNodes } from "typescript";
import CommonHeader from "./CommonHeader"
import ProfileImage from "./ProfileImage";
interface UserRank {
	user: any,
	rank: number,
}

const ReportTournament: React.FC<{
  onCloseClick:  () => void
  tournament: Tournament
}> = ({tournament, onCloseClick}) => {
	const {token, userID} = useAuth();
  const [toast] = useToast("");
	const [submitted, setSubmitted] = useState<boolean>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [playerRadios, setPlayerRadios] = useState<ReactNode[]>([]);
	const [winner, setWinner] = useState<string>();
	const history = useHistory();
	const initialUserRank: UserRank = {
		user: 0,
		rank: 0
	}
	//const [rank, setUserRank] = useState(initialUserRank);
	const [rank, setUserRank] = useState(initialUserRank);
	const [rankArg, setRankArg] = useState([]);
	const [rankvalue, setRankVal] = useState('');

	const totalpart = 0;
	const[totalparticiapnt, setTotalParticipant] = useState(totalpart);
  
	const [acceptParticipant, setAcceptedUser] =  useState();
	//const [acceptParticipant1, setAcceptedUser1] =  useState(2);
	
  let rankarray:any = [];
	const onChangeRankEventHandler = (obj: any) => {
		//console.log("Rank object", obj); 
		if(rankarray.some((rank: { userid: any; }) => rank.userid === obj.userid)){
			const objIndex = rankarray.findIndex(((objj: { userid: any; }) => objj.userid == obj.userid));
			rankarray[objIndex].rank = obj.rank;
		} else{
			rankarray.push(obj);
		}
		setRankArg(rankarray);
		
	}



	const onSubmitRankEventHandler = () => {
		//alert("hello");
	  console.log("Rank Arg", rankArg.length); 
		var valueArr = rankArg.map(function(item){ return item['rank'] });
    console.log("Array Value", valueArr.length); 
		let getParticipants = totalparticiapnt;
    // if(valueArr.length < getParticipants){
		// 	 toast({message: "Please select position from dropdwn before submit."}, true);
		// 	 return true;
		// }
		
  
    if(rankArg.length < 4){
			 toast({message: "Please select at least four participants position from dropdwn before submit."}, true);
			 return true;
		}


    var hasDuplicate = (arrayObj:any, colName:any) => {
				var hash = Object.create(null);
				return arrayObj.some((arr:any) => {
					return arr[colName] && (hash[arr[colName]] || !(hash[arr[colName]] = true));
				});
		};
		var isDuplicate = hasDuplicate(rankArg, "rank");
		//console.log(isDuplicate); return;
		
    if(isDuplicate==true){
			   toast({message: "Duplicate positions have been entered, please correct."}, true);
				 return true;
		}
		else
		{
			  //console.log("Report submit"); return;
				let rankValue = rankArg;
				const response = Tournaments.rankreport(token ?? "", rankArg, tournament.id); 
				if (response){
					response.then(function(data: any){
						if (data.success){
							toast({message: "Report sent successfully"});
							//history.goBack();
						} else {
							toast({message: data.message}, true);
						}
					});
				}
		}


	}

	

  const getPlayerRadios = ((users:any) => {
    //console.log("Array length", users.length);
		//console.log("Player list", users);
    let acceptedUsers:any = [];
    for(let j=0; j<users.length; j++){
			   if(users[j]?._joinData?.invitation_status_id == 2){
				   	acceptedUsers = [...acceptedUsers, users[j]];
				 }
		}
		//console.log("Accepted user", acceptedUsers);
    const numberOfUsers = acceptedUsers.length;
    //console.log("Accept user count", acceptedUsers.length);
		setAcceptedUser(numberOfUsers);

		const arr = [];
		for (let i = 0; i < numberOfUsers; i++) {
			  arr.push(i+1);
	  } 
		const options = arr.map((value, index) =>  <IonSelectOption value={value}>{value}</IonSelectOption>)

    return (acceptedUsers) ? acceptedUsers.map((user:any, index:any) => {
      if (user && user.id && user.preference){
        return (
          <IonItem key={generateRandomString()} lines="none">
            <Player
              className="ion-align-items-center"
              key={index}
              isSelected={user.id == winner}
            >
              <ProfileImage
                icon_type="none"
                icon_color="primary"
                size="75px"
                filename={user.preference.image}
              />
              <IonCol>
                <IonText>{user.full_name}</IonText>
              </IonCol>
            </Player>
            {/* <IonRadio slot="end" value={user.id} /> */}

            <IonSelect value={rank} placeholder="Select" style={{position:'absolute', right:'10px'
						}} onIonChange={
							   e => onChangeRankEventHandler({userid: user.id, rank: e.detail.value, tournamentid: tournament.id})
							}>

							{options}
                
						</IonSelect>

          </IonItem>
        )
      }
    }):[]



  

		// const numberOfUsers = users.length;
		// const arr = [];
		// for (let i = 0; i < numberOfUsers; i++) {
		// 	    arr.push(i+1);
	  // } 
		// const options = arr.map((value, index) =>  <IonSelectOption value={value}>{value}</IonSelectOption>)

    // return (users) ? users.map((user:any, index:any) => {
    //   if (user && user.id && user.preference){
    //     return (
    //       <IonItem key={generateRandomString()} lines="none">
    //         <Player
    //           className="ion-align-items-center"
    //           key={index}
    //           isSelected={user.id == winner}
    //         >
    //           <ProfileImage
    //             icon_type="none"
    //             icon_color="primary"
    //             size="75px"
    //             filename={user.preference.image}
    //           />
    //           <IonCol>
    //             <IonText>{user.full_name}</IonText>
    //           </IonCol>
    //         </Player>
    //         {/* <IonRadio slot="end" value={user.id} /> */}

    //         <IonSelect value={rank} placeholder="Select" style={{position:'absolute', right:'10px'
		// 				}} onIonChange={
		// 					   e => onChangeRankEventHandler({userid: user.id, rank: e.detail.value, tournamentid: tournament.id})
		// 					}>

		// 					{options}
                
		// 				</IonSelect>

    //       </IonItem>
    //     )
    //   }
    // }):[]


		
  });

	useEffect(() => {
		const { users } = tournament;
		console.log('users', users);
		setPlayerRadios(getPlayerRadios(users));
		setTotalParticipant(users.length);
	}, [tournament])


	console.log("Get Accepted", acceptParticipant);
	//console.log("Get Accepted total", acceptParticipant1);


	return (
		<IonPage>
			<CommonHeader title={`Tournament Report`} onClose={onCloseClick} />
			<IonContent>
			{(!isLoading) ? (
        (submitted) ? (
          <IonRow style={{padding: '10px'}}>
            <IonText style={{fontSize: '20px'}}>Thanks for submitting the tournament report</IonText>
          </IonRow>
        ):(
					(acceptParticipant!=0)?
					(<IonList>
						<IonCard>
							<IonListHeader>
								<Question>Who won?</Question>
							</IonListHeader>
							<IonRadioGroup 
								value={winner} 
								onIonChange={e => {
									setWinner(e.detail.value)
								}}
								name="winner"
							>
								{playerRadios}
							</IonRadioGroup>
						</IonCard>

						<IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
							onSubmitRankEventHandler();

            }}
          >
            Submit
          </IonButton>
					</IonList>):<div style={{height: "100%", width: "100%", display: "flex", justifyContent:"center", alignItems:"center", fontSize:"25px", marginTop:"-10%" }}>No Accepted Participants Found.</div>
				)
			):(
					<IonRow style={{height: "100%"}} class="ion-justify-content-center ion-align-items-center">
						<div>
							<IonSpinner />
						</div>
					</IonRow>
				)
			}
			</IonContent>
		</IonPage>
	)
}

export default ReportTournament;

const Player = styled.div<{isSelected: boolean}>`
  display: flex;
`

const Question = styled.div`
  font-size: 20px;
`;