import { IonButton, IonIcon, IonRouterLink,IonModal, IonRow, IonSpinner, IonText} from "@ionic/react";
import useAuth from "hooks/useAuth";
import useDarkTheme from "hooks/useDarkTheme";
import useStateWithPromise from "hooks/useStateWithPromise";
import { idCard, location, map } from "ionicons/icons";
import { ReactNode, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Tournaments } from "services/api";
import styled, { css } from "styled-components";
import useAsyncEffect from "use-async-effect";
import { getTimeParts } from "../services/time";
import { generateRandomString, truncate } from "../services/util";
import useToast from "hooks/useToast";

import ReportTournament from "./ReportTournament";


interface TournamentProps {
	tournament: Tournament
	contextual_user_id: string
	boxType: FillType
	refreshChallenges: () => void
	showActions?: boolean
	isContextUserIssuer: boolean
}

const Tournament: React.FC<TournamentProps> = ({
	tournament,
	contextual_user_id,
	boxType,
	refreshChallenges,
	showActions = true,
	isContextUserIssuer
  }) => {

	let history = useHistory();
	const { token, userID } = useAuth();
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useStateWithPromise<boolean>(true);
	const [actionNodes, setActionNodes] = useState<ReactNode[]>();
	const [tournaments_id, setTournamentsId] = useState<string>("");
	const [toast] = useToast("Hello");
	const [data, setData] = useState<any>([]);
	const [timeObj, setTimeObj] = useState({
		weekday_name: "Day",
		month_name: "Mon",
		month_day: "00",
		time_12: "00:00 AM"
	});

	const { prefersDark } = useDarkTheme();

  //console.log("Tournament data", tournament);
	//console.log("Context ID tournament", contextual_user_id);
	//console.log("Context ID tournament", contextual_user_id);

	const getActionButtons = (): ReactNode[] => {
		//console.log("Tournament data", tournament.status);

		let buttons: ReactNode[] = [];
		if (tournament.status === "Pending") {

			if(!isContextUserIssuer){
				buttons.push(makeActionButton({
					name: "Accept",
					function: async (id) => {
						await Tournaments.updateStatus(token ?? "", id, "accepted");
						//refreshChallenges();
					},
					type: 'outline' as FillType,
					color: (prefersDark) ? "constant-light" : "main",
					textColor: (prefersDark) ? "constant-light" : 'main-text'
				}))
			}
		

			// buttons.push(makeActionButton({
			// 	name: "Modify",
			// 	function: (tournaments_id) => {
			// 		history.push({
			// 			pathname: `/tournament/edit/${tournaments_id}`,
			// 			state: {
			// 				tournaments_id
			// 			}
			// 		});
			// 	},
			// 	type: 'solid' as FillType, color: "main", textColor: 'constant-light'
			// }))

		} else if (tournament.status === 'Upcoming') {

			buttons.push(makeActionButton({
				name: "Cancel",
				function: async (tournaments_id) => {
					let response = await Tournaments.cancelTournament(token ?? "", tournaments_id);
					if(response.success===true){
					toast({ message: "Tournaments cancel successfully." });
          }
					refreshChallenges()
				},
				type: 'solid' as FillType,
				color: "danger" as ColorType,
				textColor: 'constant-light' as ColorType
			}))

		} else if (tournament.status === 'Cancelled') {

			buttons.push(makeActionButton({
				name: "Cancelled",
				type: 'solid' as FillType, color: 'danger', textColor: 'constant-light'
			}))

		} else if (tournament.status === 'Dismissed') {

			buttons.push(makeActionButton({
				name: "Dismissed",
				type: 'solid' as FillType, color: 'danger', textColor: 'constant-light'
			}))

		} else if (tournament.status === 'Reporting') {
			//console.log("Tournament user list", tournament.users);
			let found_user = tournament.users.find(user => user.id === contextual_user_id);
			//console.log("found_user", found_user);
			let already_reported = (found_user && found_user?._joinData?.reported_winner_id !== null);
			//console.log("Already reported", already_reported);
			
			if (!already_reported) {
				buttons.push(makeActionButton({
					name: "Report",
					function: (id) => {
						setModalOpen(true);
          
					},
					
					type: 'solid' as FillType, color: "main", textColor: 'constant-light'
				}))
			} else {
				buttons.push(makeActionButton({
					name: "Reported",
					function: () => { },
					type: 'solid' as FillType, color: "main", textColor: 'constant-light',
					disabled: true
				}))
			}

		} else if (tournament.status === 'Complete') {
			buttons.push(makeActionButton({
				//name: "Complete",
				name: "View Result",
				function: (id) => {
					history.push({
						//pathname: `/tournament/view/${id}`
						pathname: `/tournament/viewrank/${id}`
					});
				},
				type: 'solid' as FillType, color: "success", textColor: 'constant-light'
			}))

		}

		return buttons;
	}

	const makeActionButton = (action: ActionButtonProps) => (
		<ActionButton
			key={generateRandomString()}
			onClick={() => { action.function && action.function(tournament.id) }}
			routerLink={action.href}
			fill={action.type}
			color={action.color}
			size="small"
			disabled={action.disabled || !action.function}
		>
			{action.name}
		</ActionButton>
	);


	const getSport = () => {
		if (tournament && tournament.sport) {
			return tournament.sport.name;
		}
		return "Sport";
	}


	
	useEffect(() => {
		if (JSON.stringify(tournament)) {
			setTimeObj(getTimeParts(tournament.tournament_start));
			setTournamentsId(tournament.id);
			setIsLoading(false);
		
		}
		setActionNodes(getActionButtons());

		
	}, [tournament,token])

	useAsyncEffect(async (isMounted) => {

	}, [tournament,token])

	return (isLoading) ? (
		<IonRow style={{ padding: '15px', justifyContent: 'center' }}>
			<IonSpinner name="dots" color="accent-two" />
		</IonRow>
	) : (

		<Col style={{ padding: '10px 0 0' }}>
				<Row style={{ fontSize: '15px' }}>
					<Col>
						<Dot
							size={7}
							//color={"danger"}
							color=""
							style={{ backgroundColor: "#67BDFD" }} />
					</Col>

					<Col width="65px" align="center" style={{ padding: "0 6px 0 0", fontSize: "12px", textAlign: "center", letterSpacing: "0.2px" }}>
						<Row justify="center"><strong>{timeObj.weekday_name}</strong></Row>
						<Row justify="center">{timeObj.month_name}<span style={{ width: "5px" }}></span><strong>{timeObj.month_day}</strong></Row>
						<Row justify="center" style={{ "whiteSpace": "nowrap" }}>{timeObj.time_12}</Row>
					</Col>

					<Col flex={1}>
						<ColoredBox
							type={boxType}
							color=""
							style={{ background: "#67BDFD" }}
							textColor={"black"}
							onClick={() => {
								if (tournament && tournament.id) {
									history.push({
										pathname: `/tournament/view/${tournament?.id}`
									});
								}
							} }
						>


							<Row style={{ paddingTop: "21px" }}>
								<Col>
									<Row>
										<IonText style={{ marginLeft: "10px" }}><strong>{tournament?.title ?? ""}</strong></IonText>
									</Row>
									<Row>
										<IonIcon style={{ marginRight: "5px", marginLeft: "10px" }} color="boxTextColor" icon={location} />
										<IonText style={{ fontSize: "0.8rem" }}>{tournament.venue}</IonText>
									</Row>
									{(tournament.venue) ?
										<Row>
											<IonIcon style={{ marginRight: "5px", marginLeft: "10px" }} color="boxTextColor" icon={map} />
											<IonText><b>Venue:</b></IonText>
											<IonText style={{ fontSize: "0.8rem" }}>{tournament.venue}</IonText>
										</Row>
										: null}

								</Col>

								<AbsoluteBox>
									{(tournament.ranked) ? (
										<Pill>Ranked</Pill>
									) : null}
									<Pill>{getSport()}</Pill>
								</AbsoluteBox>

							</Row>
						</ColoredBox>
					</Col>
				</Row>
				<Row justify="flex-end">
					{(showActions) ? actionNodes : null}

				</Row>
				
				<IonModal isOpen={modalOpen} cssClass="reportpop">
					<ReportTournament onCloseClick={() => setModalOpen(false)} tournament={tournament} />
				</IonModal>
			</Col>

	)
}




export default Tournament;

const Row = styled.div<{ justify?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;

const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
	width: ${(props: any) => (props.width) ? props.width : 'auto'};
	flex: ${(props: any) => (props.flex) ? props.flex : 'unset'};
	align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;

const Dot = styled.div<{ size: number, color: string | undefined }>`
	height: ${(props: any) => `${props.size}px`};
	width: ${(props: any) => `${props.size}px`};
	background: var(--ion-color-${props => props.color});
	border-radius: 50px;
	border: 2px solid var(--ion-color-${props => props.color});
	margin: 5px;
`;

const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: ${props => `var(--ion-color-${props.textColor})`};
	${props => (props.type === 'outline') ? css` 
		border: 2.5px solid var(--ion-color-${props.color});` : css`
		background: var(--ion-color-${props.color});
	`}
`;

const ActionButton = styled(IonButton)`
	--padding-top: 0;
	--padding-right: 0;
	--padding-bottom: 0;
	--padding-left: 0;
`;

const AbsoluteBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 2px;
`;

const Pill = styled.div`
	background: white;
	color: black;
	box-shadow: 0 0 0 1px #333;
	border-radius: 10px;
	padding: 1px 10px 1px;
	margin: 4px 2px;
	font-size: 11px;
	text-align: center;
	width: min-content;
`;


