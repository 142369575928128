import { IonItem, IonLabel, IonInput, IonSelectOption } from "@ionic/react";
import { STATES } from "../constants";
import { generateRandomString } from "../services/util";
import StateSelect from "./StateSelect";
import styled from "styled-components";
import { options } from "ionicons/icons";

interface Setter {
	(prop: string): void
}

interface NewAddressInputsProps {
	lineOne: string
	lineTwo: string
	city: string
	state: string
	zipCode: string
	setLineOne: Setter
	setLineTwo: Setter
	setCity: Setter
	setState: Setter
	setZipCode: Setter
}

const NewAddressInputs: React.FC<NewAddressInputsProps> = ({
	lineOne, lineTwo, city, state, zipCode,
	setLineOne, setLineTwo, setCity, setState, setZipCode
}) => {
	
	return (
		<div>
			<IonItem>
			<div className="emaillab">
				<IonLabel position="stacked">Address Line 1</IonLabel>
			</div>
			
				<IonInput
						type="text"
						onIonInput={(e: any) => {
							setLineOne(e.target.value as string);
						}}
						onIonChange={(e) => {
							setLineOne(e.detail.value as string);
						}}
						name="address-line-1"
						placeholder="Address Line 1"
						autocomplete="address-line1"
						value={lineOne}
				/>
			</IonItem>
			<IonItem>
			<div className="emaillab">
				<IonLabel position="stacked">Address Line 2</IonLabel>
			 </div>
				<IonInput
						type="text"
						onIonInput={((e: any) => {
							setLineTwo(e.target.value as string);
						})}
						onIonChange={(e) => {
							setLineTwo(e.detail.value as string);
						}}
						name="address-line-2"
						placeholder="Address Line 2"
						autocomplete="address-line2"
						value={lineTwo}
				/>
			</IonItem>
			<IonItem>
			<div className="emaillab">
				<IonLabel position="stacked">City</IonLabel>
			</div>
				<IonInput
						type="text"
						onIonChange={(e) => {
								setCity(e.detail.value as string);
						}}
						onIonInput={(e: any) => {
							setCity(e.target.value as string);
						}}
						name="city"
						placeholder="City"
						value={city}
				/>
			</IonItem>
			<IonItem>
			<div className="emaillab">
					<IonLabel position="stacked">State</IonLabel>
			</div>
					<StateSelect
						onChange={(option: DropdownOption) => {
							console.log('SELECTED STATE', option);
							setState(option.value);
							//console.log("State value", state);
							//console.log("Option value", option.value);
						}}
						initialValue={state}
						
					   
					/>
			</IonItem>
			<IonItem>
			<div className="emaillab">
				<IonLabel position="stacked">Zip Code</IonLabel>
			</div>
				<IonInput
						type="text"
						onIonInput={(e) => {
							let event = e as any; // get's typescript compiler to not complain
							let value = event.target.value;
							setZipCode(value);
						}}
						onIonChange={(e) => {
							setZipCode(e.detail.value as string);
						}}
						name="zip"
						placeholder="Zip Code"
						autocomplete="postal-code"
						value={zipCode}
				/>
			</IonItem>
		</div>
	)
}

export default NewAddressInputs;