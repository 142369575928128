
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

import "../../src/product.css";

////Rakesh Stripe Key////////
// const stripePromise = loadStripe("pk_test_9X1Edj5lTitMBPOAzC1Q2xcV");

/// CLient test stripr key///////
const stripePromise = loadStripe("pk_test_51KWV4hCctzss0Obg3lMiQbTqYGbJxlVZe00fX0n30jrozZE9W7VREHpn9g0EmSaS34nG2WjeDaccrJNfYnDckMiK00WzPde5fG");

const PaymentForm = () => {
  return (
    <div className="PaymentForm">
      <div className="product">
        <div>
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;