import {
	IonPage,
	IonContent,
	IonList,
	IonItem,
	IonButton,
	IonLabel,
	IonInput,
	IonTextarea,
	IonRow,
	IonIcon,
	IonToggle,
	IonCol,
	useIonAlert,
} from "@ionic/react";
import CommonHeader from "../components/CommonHeader";
import SportsDropdown from "../components/SportsDropdown";
import { useContext, useEffect, useState } from "react";
import GenderRadio from "../components/GenderRadio";
import useUser from "../hooks/useUser";
import ProfileImage from "../components/ProfileImage";
import { Users } from "../services/api";
import useAuth from "../hooks/useAuth";
import { convertGenderStringToGenderObject, validateAnyZip, validateEmail } from "../services/util";
import useDynamicInputs from "../hooks/useDynamicInputs";
import AddressDropdown from "../components/AddressDropdown";
import NewAddressInputs from "../components/NewAddressInputs";
import useToast from "../hooks/useToast";
import useDarkTheme from "../hooks/useDarkTheme";
import { moon, sunny } from "ionicons/icons";
import { RouteComponentProps } from "react-router-dom";
import { AuthenticationService } from "services/auth";
import { UserContext } from "State/UserContext";
import styled from "styled-components";

import { useParams } from "react-router";
import useAsyncEffect from "use-async-effect";




const FriendProfileEditPage: React.FC<RouteComponentProps> = (props) => {
	let { id } = useParams<Params>();
  //console.log("Profile ID value", id);
	const { token, userID } = useAuth();
	//console.log("Login id", userID);
	const { genders, sports } = useDynamicInputs();
	const { userRefresh } = useUser();

	//const { state: { user, userLoading } } = useContext(UserContext);
	const [user, setUserList] = useState<any>([]);

	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [age, setAge] = useState<number | null>(18);
	const [gender, setGender] = useState<string>("");
	const [bio, setBio] = useState<string>("");
	const [selectedSports, setSelectedSports] = useState<string[]>([]);
	const [primaryAddress, setPrimaryAddress] = useState<any>();
	const [lineOne, setLineOne] = useState<string>("");
	const [lineTwo, setLineTwo] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [state, setState] = useState<string>("");
	const [zipCode, setZipCode] = useState<string>("");
	const [toast] = useToast("Hello");
	const { prefersDark } = useDarkTheme();
	const [present] = useIonAlert();

	const sportList=()=>{

	}
	const deleteAccount = async () => {
		if (userID) {
			const response = await Users.delete(token ?? "", userID);
			if (response) {
				AuthenticationService.logout();
			}
			console.log("DELETE USER RESPONSE", response);
		}
	}

  
	
  useAsyncEffect(async () => {
    if (id){
		  const userlist = await Users.getUser(token ?? "", id);
      if (userlist.success){
        console.log('Friend view response', userlist.payload);
        // const { message, start_date, end_date, location, event_name, slots, open_invite,venue,openSlots } = result.payload;
        // setTitle(event_name);
        setUserList(userlist.payload);
        
      }
    }
  }, [token])




	useEffect(() => {
		setFirstName((user) ? user.first_name : "");
		setLastName((user) ? user.last_name : "");
		setEmail((user) ? user.email : "");
		setAge((user && user.preference && user.preference.age) ? user.preference?.age : null);
		setGender((user && user.preference && user.preference.gender) ? user?.preference?.gender?.name : "");
		setBio((user && user.preference && user.preference.bio) ? user.preference?.bio : "");

		// setSelectedSports(() => {
		// 	return (user && user.sports) ? user.sports
		// 		.filter(sp => sp._joinData?.active)
		// 		.map(sp => sp.name || "") : []
		// });
		
		//setPrimaryAddress((user) ? user.preference?.addresses?.find((address: { _joinData: { primary_address: any }; }) => address?._joinData?.primary_address)?.id : undefined);
    
		var addr = user.preference?.primary_address?.address_line_1;
		var city = user.preference?.primary_address?.city;
		var state = user.preference?.primary_address?.state;
		var fullAddress = addr + ' ' + city + ' ' + state;

		setPrimaryAddress((user) ? fullAddress:null);

	}, [user])

	return (
		<IonPage>
			{(userID==id)?(
			<CommonHeader title="View Profile" defaultHref="/user/profile/view" logout={true} />
			):<CommonHeader title="View Profile" /> }

			<IonContent className="ion-padding light-bg">
				<IonList>
					<IonRow>
						<IonCol>
							<IonItem>
							<div className="emaillab">
								<IonLabel position="stacked">First Name</IonLabel>
								</div>
								<IonInput
									type="email"
									onIonChange={(e) => {
										setFirstName(e.detail.value as string);
									}}
									name="text"
									style={{fontSize:"18px;"}}
									placeholder="First Name"
									value={firstName}
									readonly
								/>
							</IonItem>
							<IonItem>
							<div className="emaillab">
								<IonLabel position="stacked">Last Name</IonLabel>
							</div>
								<IonInput
									type="email"
									onIonChange={(e) => {
										setLastName(e.detail.value as string);
									}}
									name="text"
									placeholder="Last Name"
									value={lastName}
									readonly
								/>
							</IonItem>
							<IonItem>
							<div className="emaillab">
								<IonLabel position="stacked">Age</IonLabel>
							</div>
								<IonInput type="number" readonly value={age} placeholder="Enter Age" onIonChange={e => setAge(parseInt(e.detail.value!, 10))}></IonInput>
							</IonItem>
						</IonCol>
						<IonCol>
							<IonRow
								className="ion-justify-content-center"

							>
								{
									(user) ? (
										<ProfileImage
											icon_color="accent-two"
											//icon_type="change"
											filename={user?.preference?.image ?? ""}
											size="50%"
											maxSize="300px"
											minSize="0px"
											icon_offset="0"
											refresh={userRefresh}
										/>) : null
								}
							</IonRow>
						</IonCol>
					</IonRow>

					{/* <IonItem
						style={{paddingTop: "20px"}}
					>
						<IonLabel>Toggle Theme</IonLabel>
						<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonIcon size="medium" icon={sunny} />
						<IonToggle checked={prefersDark} onIonChange={e => {
							saveToDatabase(e.detail.checked);
							toggleDarkTheme(e.detail.checked);
						}} />
						<IonIcon size="small" icon={moon} />
						</IonRow>
					</IonItem> */}
					<IonItem>
					<div className="emaillab">
						<IonLabel position="stacked">Email Address</IonLabel>
					</div>
						<IonInput
							type="email"
							onIonChange={(e) => {
								setEmail(e.detail.value as string);
							}}
							name="email"
							placeholder="Email"
							value={email}
							readonly
						/>
					</IonItem>
					<div style={{paddingLeft:"15px"}} >
				   	<GenderRadio selectedGender={gender} onChange={setGender}   />
					</div>
					{/* <SportsDropdown multiple={true} onChange={setSelectedSports} selectedSports={selectedSports} /> */}
					


					<IonItem>
					<div className="emaillab">
						<IonLabel position="stacked">Primary Address</IonLabel>
					</div>
						<IonInput
							type="email"
							onIonChange={(e) => {
								setPrimaryAddress(e.detail.value as string);
							}}
							name="address"
							placeholder="Primary Address"
							value={(primaryAddress) ? primaryAddress: ''}
							readonly
						/>
					</IonItem>

					{/* {(user && user.preference && user.preference.addresses && user.preference.addresses.length >= 1) ? (
						<AddressDropdown
							onChange={setPrimaryAddress}
							selectedAddress={primaryAddress}
							preferenceID={user?.preference?.id}
							refreshUser={userRefresh}
							
						/>
					) : (
						<NewAddressInputs
							lineOne={lineOne}
							lineTwo={lineTwo}
							city={city}
							state={state}
							zipCode={zipCode}
							setLineOne={setLineOne}
							setLineTwo={setLineTwo}
							setCity={setCity}
							setState={setState}
							setZipCode={setZipCode}
						/>
					)} */}


					<IonItem>
					<div className="emaillab">
						<IonLabel position="stacked">Biography</IonLabel>
					</div>
            <IonTextarea 
							placeholder="I love playing sports..." 
							value={bio} 
							onIonChange={e => setBio(e.detail.value!)}
							auto-grow={false}
							rows={6}
							spellCheck={true}
							readonly
						></IonTextarea>
          </IonItem>

					
				</IonList>
			</IonContent>
		</IonPage>
	);
};


export default FriendProfileEditPage
const DangerZone = styled.div`
	margin-top: 50px;
	padding: 20px;
	border-radius: 15px;
	border: 5px solid var(--ion-color-danger);
`;

const DangerZoneHeader = styled.div`
	padding-bottom: 50px;
`;

const DangerZoneTitle = styled.h1`
	font-family: var(--heading-font);
`;

const DangerZoneMessage = styled.div`
	font-size: 13px;
`;

const DangerZoneContent = styled.div`

`;
