import { IonButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonCol, IonContent, IonIcon, IonInput, IonItem, IonPage, IonRow, IonText, IonToggle } from "@ionic/react"
import { location, star, walk } from 'ionicons/icons';
import { useEffect, useState, useContext } from "react";
import { Event } from "services/api";
import useToast from "hooks/useToast";
import useAuth from '../hooks/useAuth';
import { FilterContext } from "../State/FilterContext";
import FriendCard from "components/FriendCard";
import { Users } from 'services/api';
import { arrowBack, person } from 'ionicons/icons';
import { useHistory } from "react-router-dom";
import CenteredSpinner from 'components/CenteredSpinner';

interface AddFriendPageProps {
	user: any
}

const AddFriendPage: React.FC<AddFriendPageProps> = ({ user }) => {
	const { state, dispatch } = useContext(FilterContext);
	const { token } = useAuth();
	const [toast] = useToast("Hello");
	const [userData, setUserData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const history = useHistory();
	//console.log('Event data',token);

	const friendList = async () => {
		setIsLoading(true);
		let response: any = [];
		let userList: any = [];
		try {
			response = await Users.getFriendList(token ?? "");
			//console.log("Friend list data",response);
			userList = response.payload.map((item: any) => (
				<FriendCard user={item} type='event' />
			));
			setUserData(userList);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setUserData([]);
			setIsLoading(false);
		}
		return userList;
	}

	useEffect(() => {
		friendList();
	}, [token]);

	const addEvent = () => {
		let post = state.eventFilters.eventDetails;
		let getTotalSlots = post.slots;
		let getTotalSelected = post.selected.length;
		if(getTotalSlots < getTotalSelected){
			toast({ message: "You can not select friends more than total available slots." }, true);
			return;
		}
		else
		{
			try{
				let response = Event.create(token ?? "",post);
				if (response) {
					response.then(function (data) {
						if (data.success==true) {
							toast({ message: data.message });
							dispatch({ type: 'eventDetails_setEventDetails', payload: [] })
							history.push('eventlist');
						} else {
							toast({ message: "Problem Creating Event" }, true);
						}
					})
				}
			}
			catch(error){
				console.log(error);
				toast({message: "Problem creating event"});
			}
	
		}

	};


	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton
							routerLink='/addevent'
						>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
					</IonButtons>
					<IonTitle color="main-text">
						Add Friend
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{(userData.length > 0) ? userData : <CenteredSpinner />}
				<IonButton onClick={addEvent} color="main"
					expand="block"
					style={{ margin: 14 }}>Submit</IonButton>
			</IonContent>
		</IonPage>
	)
}

export default AddFriendPage;