import { IonContent, IonPage,IonRow } from '@ionic/react';
import WaveTabsFriend from '../components/WaveTabsFriend';
import PageTitle from '../components/PageTitle';
import AddChallengeSearch from 'components/AddChallengeSearch';
import ChallengelistCard from 'components/ChallengelistCard';
import { RouteComponentProps } from 'react-router';
import { Challenges } from 'services/api';
import useAuth from 'hooks/useAuth';
import { ReactNode, useEffect, useState } from 'react';
import CenteredSpinner from 'components/CenteredSpinner';
import { generateRandomString, truncate } from "../services/util";
import ChallengesList from 'components/ChallengesList';
import CommonHeader from 'components/CommonHeader';


const CreateChallengeSearchPage: React.FC<RouteComponentProps> = () => {
	const { token } = useAuth();
	const [eventData, setUserData] = useState<ReactNode[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [event, setEvent] = useState<Event>();
	

	const isUserProfile = () => {
		return false;
		// if (userID === null) return false;
		// return (id === undefined || id == userID);
	}
	
	const eventList = async () => {
		let response: any = [];
		let ChallengesList: any = [];
		let getData: any = [];
		let result: any = [];
		try {
			response = await Challenges.mymatches(token ?? "");
			if(response.success==true){
				ChallengesList = response.payload.map((item: any) => (
					<ChallengelistCard user={item} type="" key={generateRandomString()} />
				));
			}
			else{
				ChallengesList = (
					<IonRow className="ion-justify-content-center">
						<h1>No Challenges Found</h1>
					</IonRow>
				);
			}
			setUserData(ChallengesList);
		} catch (error) {
			console.log(error);
			setUserData([]);
		}
		return ChallengesList;	
	}


	useEffect(() => {
		eventList();
		setTimeout(() => {setIsLoading(false)}, 1000);
	}, [token]);



	return (
		<IonPage>
			<CommonHeader title={`Match`}/>
			<IonContent fullscreen>
				<WaveTabsFriend
					leftTitle="Create"
					rightTitle="My Matches"
					leftComponent={<AddChallengeSearch />}
					rightComponent = {(isLoading==true) ? <CenteredSpinner/> : eventData}
					color='accent-two'
					leftRoute='addchallenge'
					rightRoute='mychallenge'
				>
					<PageTitle color="main">Match</PageTitle>
				</WaveTabsFriend>
			</IonContent>
		</IonPage>

	);
}
export default CreateChallengeSearchPage;