import { useState, useRef, useCallback, useEffect } from "react";
import { Type } from "typescript";

function useStateCallback<Type>(initialState: Type): [Type, (state: Type, cb: (state: Type) => void) => void] {
  const [state, setState] = useState<Type>(initialState);
  const cbRef = useRef((state: Type) => {}); // mutable ref to store current callback

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = (state: Type) => {}; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

export default useStateCallback;