import { IonCol, IonIcon, IonItem, IonRow, IonText,IonSpinner,IonContent,IonGrid } from "@ionic/react"
import { person,star,starHalf, location, alertCircleOutline, alertCircle, constructOutline, calendar, map } from "ionicons/icons";
import {ReactNode,useEffect,useState } from "react";
import useAuth from "hooks/useAuth";
import useStateWithPromise from "hooks/useStateWithPromise";
import styled, { css } from "styled-components";
import { getTimeParts } from "../services/time";
import { Link } from "react-router-dom";
import ProfileImage from "./ProfileImage";
import StarRatingComponent from 'react-star-rating-component';
import { generateRandomString, truncate } from "../services/util";
import { RouteComponentProps, useHistory } from "react-router";

interface ChallengelistCard {
  user: any,
	type: string

}

const ChallengelistCard: React.FC<ChallengelistCard> = ({
	user,type
  }) => {
  
	//console.log("User data", user);
	const [isLoading, setIsLoading] = useStateWithPromise<boolean>(true);
	const { token, userID } = useAuth();
	const [displayUser, setDisplayUser] = useStateWithPromise<User | undefined>();
	const [sportsdata, setSportsData] = useState<any>([]);
	const [timeObj, setTimeObj] = useState({
		weekday_name: "Day",
		month_name: "Mon",
		month_day: "00",
		time_12: "00:00 AM"
	});
	let history = useHistory();

	const getLocation = () => {
		if (user && user.location) {
			return user.location;
		}
		return "Location";
	}


	const checkUrl = () => {
    if (type== "match_create") {
      return "challenge/create/";
    }else {
      return "challenge/issue/";
    } 
  }

	useEffect(() => {
		if (JSON.stringify(user)) {
			setTimeObj(getTimeParts(user?.challenge_start));
			setIsLoading(false);
		}
		
	}, [user,token])

	console.log("Serach data", user?.issuer?.full_name);
	
	return (isLoading) ? (
		<IonRow style={{ padding: '15px', justifyContent: 'center' }}>
			<IonSpinner name="dots" color="accent-two" />
		</IonRow>
	) : (
		 <Col style={{ padding: '10px 0 0' }}>
		 <Row style={{ fontSize: '15px' }}>
		 {(user.open_challenge==false) ?
		 <Col>
            <Dot
              size={7}
              color=""
							style={{backgroundColor:"#caee6f"}}
            />
      </Col>
  	: <Col>
		<Dot
			size={7}
			color=""
			style={{backgroundColor:"#caee6f"}}
		/>
     </Col>}

			 <Col width="65px" align="center" style={{ padding: "0 6px 0 0", fontSize: "12px", textAlign: "center", letterSpacing: "0.2px" }}>
				 <Row justify="center"><strong>{timeObj.weekday_name}</strong></Row>
				 <Row justify="center">{timeObj.month_name}<span style={{ width: "5px" }}></span><strong>{timeObj.month_day}</strong></Row>
				 <Row justify="center" style={{ "whiteSpace": "nowrap" }}>{timeObj.time_12}</Row>
			 </Col>

			 <Col flex={1} style={{background: '#caee6f'}}>
				 <ColoredBox
					type={'outline'}
					color={"#caee6f"}
					textColor={"dark"}
					 onClick={() => {
						if (user && user.id) {
							 history.push({
								 pathname: `${checkUrl()}${user.id}`
							 });
						 }
					 }}
				 >
					 <Dot
						size={7}
						color="Green"
					/>
					{(user.opponent) ?
          <ProfileImage
          icon_type="none"
          size="75px"
          filename={user?.opponent[0]?.preference?.image ?? ""}
          style={{ marginRight: '15px' }}
          />
					:null
					}

					<Row style={{ paddingTop: "11px" }}>
						 <Col>
						 {(user.open_challenge==true) ?
							 <Row>
								 <IonText><strong>Open</strong></IonText>
							 </Row>
							 
							 :null
							}
						 {(user.open_challenge==false) ?
							 <Row>
								 <StarRatingComponent
									 name="user-rating"
									 value={(displayUser && displayUser?.rating) ?? 0}
									 editing={false}
									 starColor={"var(--ion-color-text)"}
									 emptyStarColor="#777"
									 renderStarIcon={() => <IonIcon icon={star} />}
									 renderStarIconHalf={() => <IonIcon icon={starHalf} />}
								 />
							 </Row>
							 :<Row style={{height:'10px'}}></Row>
							}

              {/* {(user.open_challenge==true && user.status!='Dismissed') ? 
							  <Row>
								   	<IonIcon style={{marginRight:"5px"}} color="boxTextColor" icon={person} />
										<IonText><strong>{user?.request_user?.first_name} {user?.request_user?.last_name}</strong></IonText>
								</Row>
								:<Row style={{height:'10px'}}></Row>
						  } */}

              {/* {(user.open_challenge==true) ? 
							  <Row>
								   	<IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={location} />
								    <IonText style={{ fontSize: "0.8rem" }}>{truncate(getLocation(), 18)}</IonText>
								</Row>
								:<Row style={{height:'5px'}}></Row>
						  } */}

              {/* {(user.open_challenge==true) ? 
							  <Row>
								   	<IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={calendar} />
							      <IonText >{user.status}</IonText>
								</Row>
								:<Row style={{height:'10px'}}></Row>
						  } */}



							{(user.open_challenge==false) ?
							 (<Row>
								 <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={person} />
								 {(user.opponent)?
								 <IonText><strong>{user?.opponent[0]?.full_name ?? ""}</strong></IonText>
								 :<IonText><strong>{user?.issuer?.full_name ?? ""}</strong></IonText>
	               }
							 </Row>)
							 :<Row style={{height:'5px'}}>
								<IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={person} />
								<IonText><strong>{user?.issuer?.full_name ?? ""}</strong></IonText>
							 </Row>
							}
							
						 {(user.open_challenge==false) ?
							 <Row>
								 <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={location} />
								 <IonText style={{ fontSize: "0.8rem" }}>{truncate(getLocation(), 18)}</IonText>
							 </Row>
							 :<Row style={{marginTop:"10px"}}>
							 <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={location} />
							 <IonText style={{ fontSize: "0.8rem" }}>{truncate(getLocation(), 18)}</IonText>
						 </Row>
							}
							
							 
							{/* {(user.open_challenge==false) ?
								 <Row>
									 <IonText><b>Venue :</b></IonText>
									 <IonText style={{ fontSize: "0.8rem" }}>&nbsp;{user?.venue}</IonText>
								 </Row>
								 : <Row style={{ paddingBottom: "21px" }}>
								 <IonText><b>Venue :</b></IonText>
								 <IonText>&nbsp;&nbsp;{user?.venue}</IonText>
							 </Row>
							 } */}

                <Row>
									 <IonText><b>Venue :</b></IonText>
									 <IonText style={{ fontSize: "0.8rem" }}>&nbsp;{user?.venue}</IonText>
								 </Row>
								 
							<Row>
							 <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={calendar} />
							 <IonText >{user.status}</IonText>
					    </Row>


							 {/* {(user.open_challenge==false) ?
							 <Row>
							 <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={calendar} />
							 <IonText >{user.status}</IonText>
					     </Row>
							 :null
							} */}
						 </Col>
						 {(user.open_challenge==true && user.user_count) ?
						 <IonText style={{textAlign: "right", background: "red", margin:"-6px 0 0 0px",  position: "absolute", height: "24px", top: "7px",
						  width: "24px !important", left:"0", padding: "3px 8px",
						  borderRadius: "30px", color: "#fff"}}>{user.user_count}
							</IonText>
							:null
					  	}
						  <AbsoluteBox>
                 <Pill>{user?.sport?.name}</Pill>
								 <Row>
								<RankBox>
								{/* {user?.issuer?.sports[0]?._joinData.rank} */}
								{user?.rank ?? ""}

								</RankBox>
							</Row>
              </AbsoluteBox>
							
					 </Row>
					 <Row>
					 </Row>
				 </ColoredBox>
			 </Col>
		 </Row>

	 </Col>
	 )


}

export default ChallengelistCard;

const Row = styled.div<{ justify?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;

const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
	width: ${(props: any) => (props.width) ? props.width : 'auto'};
	flex: ${(props: any) => (props.flex) ? props.flex : 'unset'};
	align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;
const Dot = styled.div<{ size: number, color: string | undefined }>`
	height: ${(props: any) => `${props.size}px`};
	width: ${(props: any) => `${props.size}px`};
	background: var(--ion-color-${props => props.color});
	border-radius: 50px;
	border: 2px solid var(--ion-color-${props => props.color});
	margin: 5px;
`;
const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: ${props => `var(--ion-color-${props.textColor})`};
	${props => (props.type === 'outline') ? css` 
		border: 2.5px solid var(--ion-color-${props.color});` : css`
		background: var(--ion-color-${props.color});
	`}
`;

const AbsoluteBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 2px;
	width:100px;
`;

const RankBox = styled.div`
	position: absolute;
	background:#fff;
	left: auto;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	font-weight:700;
	right:35px;
	bottom:-26px;
`;
const Pill = styled.div`
	background: white;
	color: black;
	box-shadow: 0 0 0 1px #333;
	border-radius: 10px;
	padding: 1px 10px 1px;
	margin: 4px 2px;
	font-size: 11px;
	text-align: center;
	width:100px;
`;