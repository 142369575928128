import { useState } from "react";
import {
	IonButton,
	IonItem,
	IonLabel,
	IonInput,
	IonPage,
	IonContent,
	IonCardTitle,
	IonCol,
	IonRow,
} from "@ionic/react";
import { AuthenticationService } from '../../services/auth';
import Waves from "../../components/Waves";
import MessageList from '../../components/MessageList';
import SmallTitleCard from "../../components/SmallTitleCard";
import SmallTitleText from "../../components/SmallTitleText";
import TextLink from "../../components/TextLink";
import useToast from "../../hooks/useToast";
import TitleCardSimple from "components/TitleCardSimple";
import Input from "components/Primitives/Input";
import Button from "components/Primitives/Button";
import AuthNav from "components/AuthNav";

const ForgotPage = () => {
	const [email, setEmail] = useState("");
	const [toast] = useToast();

	return (
		<IonPage className="ion-align-items-center">
			<IonContent className="ion-padding basic-bg basic-bg-pad content-width">
				<div style={{ padding: "50px 0 30px"}}>
					<TitleCardSimple />
				</div>
				<div className="bubble-form">
				<IonItem lines="none">
					<Input
						type="email"
						onIonChange={(e: any) => {
							setEmail(e.detail.value as string);
						}}
						name="email"
						placeholder="Email"
						value={email}
					/>
				</IonItem>
				</div>
				<IonCol>
					<Button
						color="accent-two"
						expand="block"
						style={{ margin: 14 }}
						onClick={(e: any) => {
							if (!e.currentTarget) {
								return;
							}
							e.preventDefault();
							let response = AuthenticationService.forgot(email);
							response.then(response => {
								if (response !== undefined){
									if (response.success){
										toast({message: response.message});
									} else {
										toast({message: response.message}, true);
									}
								}
							});
						}}
					>
						Request Reset
					</Button>
					
					<IonRow className="ion-justify-content-evenly link-row">
						<TextLink href="/login" color="accent-two">Login</TextLink>
						<TextLink href="/register" color="accent-two">Sign Up</TextLink>
					</IonRow>
					<AuthNav />
				</IonCol>
			</IonContent>
		</IonPage>
	);
};

export default ForgotPage
