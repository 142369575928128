import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import useDynamicInputs from "../hooks/useDynamicInputs";
import { generateRandomString } from "../services/util";

const myDefault = 'ber';

interface SportsPrepend {
  name: string
  value?: string
}

interface SportsDropdownProps {
  selectedSports: string[]
  onChange(value: string[]): void
  multiple?: boolean
  prepend?: SportsPrepend[]
}

const SportsDropdown: React.FC<SportsDropdownProps> = ({prepend = [], selectedSports, multiple, onChange}) => {
  const {sports} = useDynamicInputs();
  const getSportsOptions = () => {
    let options: React.ReactNode[] = [];
    let sports_prepended: any[] = [];
    sports_prepended = [...prepend.map((each: SportsPrepend) => {
      return each;
    }), ...sports];
    if (sports_prepended && sports_prepended.length > 0){
      if (sports_prepended.map !== undefined){
        options = sports_prepended.map((sport: SportsPrepend) => {
          return (
            <IonSelectOption
              key={generateRandomString()}
              value={sport.value ?? sport.name}
              
            >
                {sport.name}
            </IonSelectOption>
          )
        });
      } else {
        return [];
      }
    }

    return (
      <IonSelect 
        multiple={multiple}
        //value={selectedSports}
        placeholder="Select Sports"
        onIonChange={e => onChange(e.detail.value)}
        interface="alert"
      >
        {options}
      </IonSelect>
    );
  }
  return (
    <IonItem>
      <div className="emaillab">
        <IonLabel position="stacked" style={{fontSize: '16px !important'}}>{(multiple) ? "Sports":"Sport"}</IonLabel>
      </div>
      {getSportsOptions()}
    </IonItem>
  );
}

export default SportsDropdown;