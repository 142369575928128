import { IonButton, IonCard, IonCol, IonContent, IonIcon, IonItem, IonList, IonListHeader, IonPage, IonRadio, IonRadioGroup, IonRow, IonSpinner, IonText } from "@ionic/react"
import CommonHeader from "components/CommonHeader"
import ProfileImage from "components/ProfileImage";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { ReactNode, useEffect, useState } from "react";
import { Challenges } from "services/api";
import styled from 'styled-components';
import { generateRandomString } from "services/util";
import StarRatingComponent from 'react-star-rating-component';
import useStateCallback from "hooks/useStateCallback";
import { returnUpForwardOutline, star, starHalf } from "ionicons/icons";
import useChallenges from "hooks/useChallenges";
import useDarkTheme from "hooks/useDarkTheme";
import { Rating } from 'react-simple-star-rating'
import { useHistory } from 'react-router'; 


interface Ratings {
  [id: string]: number 
}

const ReportChallenge: React.FC<{
  onCloseClick:  () => void
  challenge: any
}> = ({onCloseClick, challenge}) => {

  let history = useHistory();
	const {token, userID} = useAuth();
	const {refreshChallenges} = useChallenges();
  const [toast] = useToast("");
  const [participants, setParticipants] = useState<User[]>([]);
  const [submitted, setSubmitted] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {prefersDark} = useDarkTheme();
  // form state
  const [winner, setWinner] = useState<any>("");
  const [checkwinner, setcheckWinner] = useState<any>("");
  const [ratings, setRatings] = useStateCallback<Ratings>({})
  const [ratingRows, setRatingRows] = useState<ReactNode[]>([]);
  const [rating, setRating] = useState(0) // initial rating value

  const checkReject = challenge.reject;

  //console.log("challenge value", challenge);
  //console.log("Reject value", checkReject);
  //console.log("Check winner", checkwinner);
  

   // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate)
    // other logic
  }
  
  const getRatingRows = ((users: User[]) => {
    return (users) ? users.map((user: User) => {
      if (
        user &&
        user.preference &&
        user.id &&
        ratings[user.id] != undefined
      ){
        return (
          <IonItem key={generateRandomString()} lines="none">
            <Player 
              className="ion-align-items-center"
              isSelected={false}
            >
              <ProfileImage
                icon_type="none" 
                icon_color="primary"
                size="75px"
                filename={user.preference.image}
              />
              <IonCol>
                <IonText>{user.short_name}</IonText>
              </IonCol>
            </Player>
            <div slot="end" style={{fontSize: "15px"}}>
              {/* <StarRatingComponent
                name="user-rating"
                value={ratings[user.id]}
                editing={true}
                emptyStarColor="#777"
                onStarClick={(e) => handleRating(user.id, e)}
                renderStarIcon={() => <IonIcon icon={star} />}
                renderStarIconHalf={() => <IonIcon icon={starHalf} />}
              /> */}
              
              {/* <Rating onClick={handleRating} ratingValue={rating}  /> */}
              <Rating onClick={handleRating} initialValue={rating} />
            </div>
          </IonItem>
        )
      }
    }):[];
  })
  
  const [playerRadios, setPlayerRadios] = useState<ReactNode[]>([]);
  const getPlayerRadios = ((users: User[]) => {
    console.log("User list", users);
    return (users) ? users.map((user, index) => {
      if (user && user.id && user.preference){
         //console.log("Winner ID", user._joinData?.reported_winner_id);
        if(user._joinData?.reported_winner_id!==null){
            setWinner(user._joinData?.reported_winner_id);
            setcheckWinner(user._joinData?.reported_winner_id);
        }
        
        
        return (
          <IonItem key={generateRandomString()} lines="none">
            <Player
              className="ion-align-items-center"
              key={index}
              isSelected={user.id == winner}
            >
              <ProfileImage
                icon_type="none"
                icon_color="primary"
                size="75px"
                filename={user.preference.image}
              />
              <IonCol>
                <IonText>{user.full_name}</IonText>
              </IonCol>
            </Player>
            {/* <IonRadio slot="end" value={user.id} /> */}
            <IonRadio slot="end" value={user.id} name="winner"></IonRadio>
          </IonItem>
        )
      }
    }):[]
  });

  // Catch Rating value
  // const handleRating = (id: string, rate: number) => {
  //   setRatings({
  //     [id]: rate
  //   }, () => {});
  // }

  useEffect(() => {
    const { users, id } = challenge;
    // prevent report form showing up when it's already been reported
    let found_user = users.find((user: User) => user.id === userID);
    if (found_user && found_user?._joinData?.reported_winner_id !== null){
      setSubmitted(true);
      onCloseClick();
    }

    const participants = users.filter((user: User) => user.id !== userID);
    let ratings: Ratings = {};
    participants.forEach((user: User) => {
      ratings[user.id] = 5;
    });
    setRatings(ratings, () => {
      setParticipants(participants);
      //console.log('setRatings callback users', users);
      setPlayerRadios(getPlayerRadios(users));
      setIsLoading(false);
    });
  }, [token, userID, challenge])

  useEffect(() => {
    setRatingRows(getRatingRows(participants));
  }, [participants, ratings])

  
  //console.log("Winner id check", winner);
  ///console.log("check winner", checkwinner);
  //console.log("Challenge props", challenge.reject);
  //console.log("challenge all data", challenge);
	return (
		<IonPage>
			<CommonHeader title={`Match Report`} onClose={onCloseClick} />
			<IonContent>
        {(!isLoading) ? (
        (submitted) ? (
          <IonRow style={{padding: '10px'}}>
            <IonText style={{fontSize: '20px'}}>Thanks for submitting a challenge report</IonText>
          </IonRow>
        ):(
			  <IonList>
          <IonCard>
            <IonListHeader>
              <Question>Confirm Winner</Question>
            </IonListHeader>
          
            
            <IonRadioGroup 
              value={winner} 
              name="winner"
              onIonChange={(e) => {
                //setWinner(e.detail.value as string);
                if(checkwinner==''){
                  setWinner(e.detail.value as string);
                }else{
                  setcheckWinner(e.detail.value as string);
                }
                
              }}
            >
              {playerRadios}
            </IonRadioGroup>
          </IonCard>
          <IonCard>
          <IonListHeader>
            <Question>Rate Players</Question>
          </IonListHeader>
          <IonCol>
            {ratingRows}
          </IonCol>
          </IonCard>

          {(checkwinner=='')?(<IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (!e.currentTarget) return;
              e.preventDefault();
              let args = {
                ratings, 
                winner
              };
              //console.log("Winner check after submit", args); return;


              if(args.winner === undefined || args.winner==''){
                  toast({message: "Please select a winner"}, true);
              } else {
                const { id } = challenge;
                if (id){
                  const response = Challenges.report(token ?? "", args, id);
                  if (response){
                    response.then(function(data: any){
                      if (data.success){
                        toast({message: "Report sent successfully"});
												refreshChallenges()
                        onCloseClick();
                      } else {
                        toast({message: data.message}, true);
                      }
                    });
                  }
                }
              }
            }}
          >
            Submit
          </IonButton>):null}

           

  {(checkwinner!=='')?(<div>
    {(checkReject==0 || undefined)?(<IonButton expand="block" color="green" className="gbtn testbtn" onClick={(e) => {
              if (!e.currentTarget) return;
              e.preventDefault();
              let args = {
                ratings, 
                winner
              };
              //console.log("Args", args); return;

              if (args.winner === undefined){
                    toast({message: "Please select a winner"}, true);
              } else {
                const { id } = challenge;
                if (id){
                  const response = Challenges.report(token ?? "", args, id);
                  if (response){
                    response.then(function(data: any){
                      if (data.success){
                        toast({message: "Report accepted successfully"});
												refreshChallenges()
                        onCloseClick();
                      } else {
                        toast({message: data.message}, true);
                      }
                    });
                  }
                }
              }
            }}>Confirm</IonButton>):<IonButton disabled expand="block" color="green" className="gbtn testbtn">Confirm</IonButton>}

          <div style={{display:"flex", justifyContent:"center"}}>
            {(checkReject==0 || undefined)?(<IonButton className="ion-color-dark-black whtext" onClick={(e) => {
                 e.preventDefault();
                 history.push('contact');

                 ///Reject challenge on Dispute
                //  const {id} = challenge;
                //  if(id) {
                //      const rejectresponse = Challenges.rejectreport(token ?? "",id);
                //      if(rejectresponse){
                //       rejectresponse.then(function(data:any) { 
                //           //console.log("data.succ");
                //           if(data.success){
                //               toast({message: "Challenge rejected successfully."});
                //               refreshChallenges()
                //               onCloseClick();
                            
                //           }else{
                //                toast({message: data.message});
                //           }    
                         
                //       })
                //      }
                //  }
             }}
            >Dispute</IonButton>):<IonButton className="ion-color-dark-black whtext" disabled>Dispute</IonButton>}

            </div>
            </div>):null}



        </IonList>
      )):(
        <IonRow style={{height: "100%"}} class="ion-justify-content-center ion-align-items-center">
          <div>
            <IonSpinner />
          </div>
        </IonRow>
      )}
			</IonContent>
		</IonPage>
	)
}

export default ReportChallenge;

const Player = styled.div<{isSelected: boolean}>`
  display: flex;
`

const Question = styled.div`
  font-size: 20px;
`;

const activeButton = {
  margin:14, 
  backgroundColor:"#00ff00", 
  color:"black", fontWeight:"bold", 
  textTransform:"capitialize", 
  display:"flex", 
  justifyContent:"center",
  
}


