import { IonCol, IonContent, IonGrid, IonIcon, IonItem, IonRow, IonText } from "@ionic/react"
import styled, { css } from "styled-components";
import { ReactNode, useEffect, useState } from "react";
import useStateWithPromise from "hooks/useStateWithPromise";
import { getTimeParts } from "../services/time";

interface EventCardProps {
  event: any
}

const EventCard: React.FC<EventCardProps> = ({ event }) => {
	const [boxColor, setBoxColor] = useStateWithPromise<string>("accent-three-event");
	const [boxTextColor, setBoxTextColor] = useStateWithPromise<string>("main-text");
  const [timeObj, setTimeObj] = useState({
		weekday_name: "Day",
		month_name: "Mon",
		month_day: "00",
		time_12: "00:00 AM"
	});

  useEffect(() => {
			setTimeObj(getTimeParts(event.start_date));
		
	}, [event])

  return (
    <IonItem routerLink={`/event/view/${event.id}`} detail={true}>
      <Col style={{ padding: '10px 0 0' }}>
        <Row style={{ fontSize: '15px' }}>
          <Col>
            <Dot
              size={7}
              color=""
              style={{backgroundColor:'#FCB83E'}}
            />
          </Col>
          <Col width="65px" align="center" style={{ padding: "0 6px 0 0", fontSize: "12px", textAlign: "center", letterSpacing: "0.2px" }}>
            <Row justify="center"><strong>{timeObj.weekday_name}</strong></Row>
            <Row justify="center">{timeObj.month_name}<span style={{ width: "5px" }}></span><strong>{timeObj.month_day}</strong></Row>
            <Row justify="center" style={{ "whiteSpace": "nowrap" }}>{timeObj.time_12}</Row>
          </Col>
          <Col flex={1} style={{width:"100vw"}}>
            <ColoredBox
              type="solid"
              color=""
              textColor="black"
              style={{backgroundColor:'#FCB83E'}}
            >
              <Row style={{ padding: "10px" }}>
                <Col>
                  <Row>
                    <IonText style={{ fontSize: "0.8rem",fontWeight:"bold" }}>{event.openSlots} Open / {event.slots} Total</IonText>
                  </Row>
                  <Row>
                    <IonText style={{ fontSize: "0.8rem",fontWeight:"bold" }}>{event.event_name}</IonText>
                  </Row>
                  {(event.venue) ?
                    <Row>
                      <IonText style={{ fontSize: "0.8rem",fontWeight:"bold" }}>Venue:</IonText>
                      <IonText style={{ fontSize: "0.8rem",fontWeight:"bold" }}>{event.venue}</IonText>
                    </Row>
                    : null
                  }
                </Col>
              </Row>
            </ColoredBox>
          </Col>
        </Row>
      </Col>
    </IonItem>
  )
}

export default EventCard;
const Row = styled.div<{ justify?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;

const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
	width: ${(props: any) => (props.width) ? props.width : 'auto'};
	align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;

const Dot = styled.div<{ size: number, color: string | undefined }>`
	height: ${(props: any) => `${props.size}px`};
	width: ${(props: any) => `${props.size}px`};
	background: var(--ion-color-${props => props.color});
	border-radius: 50px;
	border: 2px solid var(--ion-color-${props => props.color});
	margin: 5px;
`;

const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius:  0px;
	color: ${props => `var(--ion-color-${props.textColor})`};
	${props => (props.type === 'outline') ? css` 
		border: 2.5px solid var(--ion-color-${props.color});` : css`
		background: var(--ion-color-${props.color});
	`}
`;
