import { IonPage, IonContent } from "@ionic/react";
import CommonHeader from "components/CommonHeader";
import { RouteComponentProps } from "react-router";
import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from 'components/CheckoutForm';
import "../../src/product.css";

const stripePromise = loadStripe("pk_test_9X1Edj5lTitMBPOAzC1Q2xcV");

const StorePage: React.FC<RouteComponentProps> = ({ match }, props) => {
  

  return (
    <IonPage>
      <CommonHeader title="Buy Credits" defaultHref="/user/profile" />
      <IonContent>
        <div className="PaymentForm">
          <div className="product">
            <div>
              <Elements stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default StorePage;