import { IonInput } from "@ionic/react"
import { forwardRef } from "react";
import styled from 'styled-components';

const Input: React.FC<any> = forwardRef(({...platformProps}, ref) => {
	return <StyledIonInput ref={ref} {...platformProps} />
})

export default Input;

let vertical_padding = "8px";
const StyledIonInput = styled(IonInput)`
	border-radius: 100px;
	--background: var(--ion-color-light-grey);
	--padding-bottom: ${vertical_padding};
  --padding-top: ${vertical_padding};
	--padding-start: 20px !important;
	--padding-end: 30px;
	--placeholder-color: #626262;
	--placeholder-opacity: 1;
	--color: var(--ion-color-constant-dark) !important;
	&::part(native){
		font-size: 20px;
	}
	&::part(native):-webkit-autofill {
		color: var(--ion-color-constant-dark) !important;
	}
`;