import { IonCol, IonContent, IonGrid, IonIcon, IonItem, IonRow, IonText } from "@ionic/react"
import { clipboardOutline, location, calendar, map, radioButtonOff } from 'ionicons/icons';
import { timeAgo, toAppDateFormat, toAppFormat, toAppTimeFormat } from "../services/time";
import { truncate } from "../services/util";
import styled from 'styled-components';
import StarRatingComponent from 'react-star-rating-component';
import {star, walk, starHalf } from 'ionicons/icons';

interface TeamCardProps {
  team: any
}

const TeamCard: React.FC<TeamCardProps> = ({ team }) => {

  return (
    <IonItem routerLink={`/team/edit/${team.id}`} detail={true}>
      <IonGrid>
        <IonRow style={{ overflow: 'hidden',borderRadius: '10px',width:'100%'}}>
          <IonCol size-md="16" style={{ background: '#cf9',padding: "0px 0px 0 8px",width:'100%'}}>
            <IonRow>
              <IonCol size="6" style={{ fontSize: '15px',padding: "0px 0px 0px 5px", width: '0%' }}>
                <StarRatingComponent
                  value={team.rating}
                  name="user-rating"
                  editing={false}
                  starColor={'var(--ion-color-text)'}
                  emptyStarColor="#777"
                  renderStarIcon={() => <IonIcon style={{ background: '#fff' }} icon={star} />}
                  renderStarIconHalf={() => <IonIcon icon={starHalf} />}
                />
              </IonCol>
              <IonCol size="6">
                <IonText
                  style={{
                    width: '100%',
                    fontSize: '13px',
                    padding: '0px 0px 0 74px',
                    fontWeight: 'bold',
                  }}
                >
                 {team.users.length} * {team.sport.name}
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonText
                  style={{
                    width: '100%',
                    fontSize: '13px',
                    padding: '0px 0px 0 5px',
                    fontWeight: 'bold',
                  }}
                >
                {team.name}
                </IonText>
              </IonCol>
              <IonCol size="6"  style={{ fontSize: "12px", padding: "1px 0px 0px 75px" }}>
              <IonText style={{ width: "100%", fontSize: "14px", padding: "0px 0px 0px 0px", fontWeight:"bold",background:"#fff" }}>{team.rank}</IonText>
              </IonCol>
              <IonCol size="12">
                <IonText
                  style={{
                    width: '100%',
                    fontSize: '13px',
                    padding: '0px 0px 0 0px',
                    fontWeight: 'bold',
                  }}
                ></IonText>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>

    </IonItem>
  )
}

export default TeamCard;

