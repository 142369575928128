import styled, { css } from "styled-components";

interface TagProps {
	textColor: string
	color: string;
	type?: FillType
	value?: string
	onClick?: (val: string) => void
}

const TagStyle = styled.div<TagProps>`
	border-radius: 50px;
	padding: 3px 12px;
	font-size: 14px;
	margin: 2px 5px 2px 0;
	width: min-content;
	font-weight: normal;
	color: ${props => `var(--ion-color-${props.textColor})`};
  ${props => (props.type === 'outline') ? css` 
		border: 1px solid var(--ion-color-${props.color});`:css`
		background: var(--ion-color-${props.color});
	`}
`;

const Tag: React.FC<TagProps> = ({children, textColor, color, type = 'outline', value = "", onClick = (val: string) => {}}) => {
	return (
		<TagStyle
			color={color}
			textColor={textColor}
			type={type}
			onClick={() => {
				onClick(value);
			}}
		>
			{children}
		</TagStyle>
	)
}

export default Tag;