import { IonContent, IonPage,IonRow } from '@ionic/react';
import WaveTabsFriend from '../components/WaveTabsFriend';
import PageTitle from '../components/PageTitle';
import AddEvent from 'components/AddEvent';
import EventCard from 'components/EventCard';
import { RouteComponentProps } from 'react-router';
import { Users } from 'services/api';
import useAuth from 'hooks/useAuth';
import { ReactNode, useEffect, useState } from 'react';
import CenteredSpinner from 'components/CenteredSpinner';
import { Event } from "services/api";
import CommonHeader from 'components/CommonHeader';



const CreateEventPage: React.FC<RouteComponentProps> = () => {
	const { token } = useAuth();
	// const [eventData, setUserData] = useState<any>([]);
	const [eventData, setUserData] = useState<ReactNode[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [event, setEvent] = useState<Event>();
	
	const eventList = async () => {
		let response: any = [];
		let eventList: any = [];
		let getData: any = [];
		let result: any = [];
		try {
			response = await Users.getEventList(token ?? "");
			
			if(response.success===true){
				eventList = response.payload.map((item: any) => (
					<EventCard event={item} />
				));
			}else{
				eventList = (
					<IonRow className="ion-justify-content-center">
						<h1>No Event Found</h1>
					</IonRow>
				);
			}
			setUserData(eventList);
		} catch (error) {
			console.log(error);
			setUserData([]);
		}
		return eventList;
	}

	useEffect(() => {
		eventList();
		setTimeout(() => {setIsLoading(false)}, 1000);
	}, [token]);


	return (
		<IonPage>
			<CommonHeader title={`Event`} />
			<IonContent fullscreen>
				<WaveTabsFriend
					leftTitle="Create"
					rightTitle="My Events"
					leftComponent={<AddEvent />}
					rightComponent = {(isLoading==true) ? <CenteredSpinner/> : eventData}
					color='accent-two'
					leftRoute='addevent'
					rightRoute='myevent'
				>
					<PageTitle color="main">Events</PageTitle>
				</WaveTabsFriend>
			</IonContent>
		</IonPage>

	);
}
export default CreateEventPage;