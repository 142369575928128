import styled from "styled-components";
import { useSpring, animated } from 'react-spring';
import { ReactNode, useContext, useState } from "react";
import { getLinearGradient } from "../services/util";
import { useHistory, useParams } from "react-router";
import { FilterContext } from "State/FilterContext";
import { isPlatform } from '@ionic/react';

interface WaveTabProps {
	leftTitle: string
	rightTitle: string
	leftComponent: React.ReactNode
	rightComponent: React.ReactNode
	color: string
	leftRoute:string
	rightRoute:string
}

interface TabsProps {
	path: string
	color: string
	zIndex?: number
}

interface TargetProps {
	top: string
	left?: string
	right?: string
	colorActive?: boolean
}

const WaveTabsTeam: React.FC<WaveTabProps> = ({ children, leftTitle, rightTitle, leftComponent, rightComponent, color, leftRoute, rightRoute }) => {
	let { tab } = useParams<SearchTypeParams>();
	const { state, dispatch } = useContext(FilterContext);
	const [leftActive, setLeftActive] = useState(() => {
		return (tab !== 'tournament');
	});

	const { x } = useSpring({ config: { duration: 300 }, x: leftActive ? 0 : 1 });

	const provideFallback = (path: ReactNode, fallback_paths: string[], leftActive: boolean) => {
		if (isPlatform('ios')) {
			return <path d={(leftActive) ? fallback_paths[1] : fallback_paths[0]} />
		} else {
			return path;
		}
	}

	console.log("left active", leftActive);

	return (
		<div>
			<Container>
				<ChildrenContainer>
					{children}
				</ChildrenContainer>
				<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{ position: 'absolute' }}>
					<clipPath id="path" clipPathUnits="objectBoundingBox">
						{
							provideFallback(<animated.path
								d={x.to({
									range: [0, 1],
									output: [
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.15 0.5 0.5 T 0 0.5",
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.85 0.5 0.5 T 0 0.5",
									],
								})}
								style={{
									fill: x.to({
										range: [0, 1],
										output: ["#fdeae7", "#eae7fd"],
									}),
								}}
							/>, [
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.15 0.5 0.5 T 0 0.5",
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.85 0.5 0.5 T 0 0.5"
							], leftActive)
						}
					</clipPath >
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{ position: 'absolute' }}>
					<clipPath id="pathBack" clipPathUnits="objectBoundingBox">
						{
							provideFallback(<animated.path
								d={x.to({
									range: [0, 1],
									output: [
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.07 0.5 0.5 T 0 0.5",
										"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.93 0.5 0.5 T 0 0.5",
									],
								})}
								style={{
									fill: x.to({
										range: [0, 1],
										output: ["#fdeae7", "#eae7fd"],
									}),
								}}
							/>, [
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.07 0.5 0.5 T 0 0.5",
								"M 0 0.5 L 0 0 L 1 0 L 1 0.5 Q 0.75 0.93 0.5 0.5 T 0 0.5"
							], leftActive)
						}
					</clipPath >
				</svg>
				<Tabs path="#pathBack" color="bg-shadow" />
				<Tabs path="#path" color={color} />
				<Target
					className="color-text-transition"
					top="50%"
					left="0px"
					colorActive={leftActive}
					onClick={() => {
						window.history.pushState({}, `${leftTitle}`, "/"+leftRoute);
						// dispatch({type: 'setSearchType', payload: 'match'});
						//setLeftActive(true)
						setLeftActive(false)
						
					}}
				>{leftTitle}
				</Target>


				<Target
					className={(isPlatform('ios')) ? "" : "color-text-transition"}
					top="50%"
					right="0px"
					colorActive={!leftActive}
					onClick={() => {
						window.history.pushState({}, `${rightTitle}`, "/"+rightRoute);
						//dispatch({type: 'setSearchType', payload: 'tournament'});
						//setLeftActive(false)
						setLeftActive(true)
					}}
				>{rightTitle}</Target>
			</Container>
			{/* {(leftActive) ? leftComponent : rightComponent} */}

			{(leftActive) ? rightComponent : leftComponent}
		</div>
	)
}

export default WaveTabsTeam;

const Target = styled.div<TargetProps>`
  position: absolute;
  width: 50%;
  height: 50px;
  top: ${props => `${props.top}`};
  left: ${props => `${props.left}`};
  right: ${props => `${props.right}`};
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${({ colorActive }) => {
		if (colorActive) {
			return 'var(--ion-color-text)';
		} else {
			return `var(--ion-color-main)`;
		}
	}};
`;

const ChildrenContainer = styled.div`
	background: transparent;
	position: absolute;
	z-index: 1;
`;

const Container = styled.div`
	color: white;
	font-size: 30px;
	height: 155px;
	width: 100%;
	position: relative;
`;

const Tabs = styled.div<TabsProps>`
  width: 100%;
  height: 200px;
  background: ${props => `var(--ion-color-${props.color})`};
	background: ${props => getLinearGradient(props.color)};
  clip-path: ${props => `url(${props.path})`};
  position: absolute;
  z-index: ${props => `${(props.zIndex !== undefined) ? props.zIndex : 0}`};
`;