import { IonText } from "@ionic/react";
import useLongPress from "hooks/useLongPress";
import useToast from "hooks/useToast";

const IonTextTip: React.FC<{title: string}> = ({children, title}) => {
	const onLongPress = () => {
		console.log('longpress is triggered');
		toast({message: title});
	};

	const onClick = () => {
		console.log('click is triggered')
	}

	const defaultOptions = {
		shouldPreventDefault: true,
		delay: 500,
	};
	const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
	const [toast] = useToast("");
	return (
		<IonText {...longPressEvent} title={title}>
			{children}
		</IonText>
	)
}

export default IonTextTip;