import { IonCol, IonHeader, IonIcon, IonItem, IonLabel, IonRow, IonText } from "@ionic/react"
import ProfileImage from "./ProfileImage"
import { location, star, walk, starHalf } from 'ionicons/icons';
import { generateRandomString } from "../services/util";
import { useEffect } from "react";
import StarRatingComponent from 'react-star-rating-component';
import { useState } from 'react';

import { Users } from "services/api";
import useAuth from "hooks/useAuth";

interface UserCardProps {
  user: any,
  type: string
  //resultsList:any
}



const UserCard: React.FC<UserCardProps> = ({ user,type }) => {
  const { userID, token } = useAuth();
  const [sportRank, setSportRank] = useState<any>("");

  console.log("User list", user);
  let user_id = user.id;
  
  const getSportRankvalue = async (user_id: any) => {
    let sportname = localStorage.getItem('sportsintiallySel');
    let userID = user_id;
    var response = await Users.getUserSportRank(token ?? "",sportname,userID);
    //console.log("Sport Rank", response);
    //console.log(response.payload[0].rank);
    //console.log(response.payload[0].id);
    //console.log(response.payload[0].user_id);

    let getrankval = response.payload[0].rank;
    //console.log("Show rank value", getrankval);
    setSportRank(getrankval);
    

   
  }



  // const getRankRows = () => {
  //   if (user && user.ranks && Object.keys(user.ranks).map !== undefined) {
  //     return Object.keys(user.ranks).map((rank: string, index: number, array: any[]) => {
  //       return (
  //         <IonRow key={generateRandomString()}>
  //           <IonIcon style={{ marginRight: "5px" }} color="main-text" icon={star} />
  //           <IonText>{(array.length > 1) ? rank : null} Rank: {user.ranks[rank]}</IonText>
  //         </IonRow>
  //       );
  //     })
  //   }
  //   return null;
  // }



  const getRankRows = () => {
    if (user?.sports) {
      return user?.sports.map((sport:any,index: number) => {
        
        return (
          <IonRow key={generateRandomString()}>
            <IonIcon style={{ marginRight: "5px" }} color="main-text" icon={star} />
            {/* <IonText>{(array.length > 1) ? rank : null} Rank: {user.ranks[rank]}</IonText> */}
            <IonText>{localStorage.getItem('sportsintiallySel')}</IonText>
          </IonRow>
        );
      })
    }
    return null;
  }



  const checkUrl = () => {
    if (type== "match_create") {
      return "challenge/create/";
    }else {
      return "/user/friendlist/view/";
    } 
  }



  useEffect(() => {
    getSportRankvalue(user_id)
  }, [token]);


  //console.log("User data", user?.sports[0]?._joinData.rank);
  //console.log("User data", user.id);
  //console.log("selected sports", localStorage.getItem('sportsintiallySel'));
  return (
    <IonItem routerLink={`${checkUrl()}${user.id}`} detail={true}>
      <ProfileImage
        icon_type="none"
        size="75px"
        filename={user?.preference?.image ?? ""}
        style={{ marginRight: '15px' }}
      />
      <IonCol>
        <IonRow>
          <StarRatingComponent
            name="user-rating"
            value={(user && user.rating) ?? 0}
            editing={false}
            starColor={"var(--ion-color-text)"}
            emptyStarColor="#777"
            renderStarIcon={() => <IonIcon icon={star} />}
            renderStarIconHalf={() => <IonIcon icon={starHalf} />}
          />
        </IonRow>
        <IonRow>
          <IonText style={{ fontSize: '18px', paddingBottom: '5px' }}>{user.full_name}</IonText>
        </IonRow>
        {/* {getRankRows()} */}

        <IonRow className="ion-align-items-center">
          <IonCol style={{ maxWidth: '22px' }}>
            <IonIcon style={{ marginRight: "5px", fontSize: '18px' }} color="main-text" icon={location} />
          </IonCol>
          <IonCol>
            <IonRow>
              <IonText style={{ fontSize: '15px' }}>{user?.preference?.location}</IonText>
            </IonRow>
            {/* {(user && user?.preference && user?.preference?.distance_miles !== '') ?
              (<IonRow style={{ paddingTop: '1px' }}>
                <IonText style={{ fontSize: '11px' }}>{user?.preference?.distance_miles?.toFixed(1)} miles away</IonText>
              </IonRow>) : null} */}
          </IonCol>
        </IonRow>
      </IonCol>

  
       <IonCol>
           <IonRow>
             <IonText>{localStorage.getItem('sportsintiallySel')}</IonText>&nbsp;&nbsp;
             <IonText>{sportRank}</IonText>
           </IonRow>
       </IonCol>
      

    </IonItem>
  )
}

export default UserCard;