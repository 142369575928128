import {
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonRow,
  IonText,
  IonToggle,
  IonTextarea,
  IonDatetime,
  IonCard,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonContent,
  IonPage

} from "@ionic/react";
import styled from "styled-components";
import ProfileImage from "components/ProfileImage";
import { FilterContext } from "../State/FilterContext";
import { Event, Users } from "../services/api";
import { Team } from "../services/api";
import { DynamicInputs } from "../services/api";
import useToast from "hooks/useToast";
import useAuth from "hooks/useAuth";
import { UserContext } from "State/UserContext";
import useUser from "../hooks/useUser";
import GenderRadio from "../components/GenderRadio";
import SportsRadio from "../components/SportsRadio";
import SportsDropdown from "./SportsDropdown";
import React, { ReactNode, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { generateRandomString } from "../services/util";
import FriendCard from "./FriendCard";
import { usePosition } from "../hooks/usePosition";
import { Genders } from "../constants";
import CenteredSpinner from 'components/CenteredSpinner';
import useAsyncEffect from "use-async-effect";
import { list, star, walk, starHalf, location } from "ionicons/icons";
import StarRatingComponent from 'react-star-rating-component';
import { Any } from "@react-spring/types";
import CommonHeader from "components/CommonHeader";


const AddTeam: React.FC = () => {
  const { token, userID } = useAuth();
  const history = useHistory();
  const [gender, setGender] = useState<string>("");
  const [coords, setCords] = useState<any>([]);
  const [sports, setSports] = useState<string>("");
  //const [selectedSports, setSelectedSports] = useState<string>("");
  const [selectedSports] = useState<string>("");

  const { state, dispatch } = useContext(FilterContext);
  const [userData, setUserData] = useState<any>([]);
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { position, prompt } = usePosition();
  const [event, setEvent] = useState<Event>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [type, setType] = useState<number>(0);
  const [data, setData] = useState<any>([]);
  const [emailData, setEmailData] = useState<any>([]);
  const [email, setEmail] = useState<string>("");
  const [oldArray, setMyArray] = useState<any>([]);
  const [playerData, setplayerData] = useState<any>([]);
  const [isShown, setIsShown] = useState<boolean>(true);
  const [selfdata, setSelfData] = useState<any>([]);
  const [temlistdata, setTeamList] = useState<any>([]);
  

  const [check, setCheck] = useState<boolean>(
    () => {
      return false;
    }
  );

  
  var genderList = ['Male', 'Female', 'Mix'];

  var lat = coords.lat;
  var lon = coords.lon;

  const {
    state: { user, userLoading }
  } = useContext(UserContext);

  const { userRefresh } = useUser();
  const [toast] = useToast("Hello");

  const getLocation = async () => {
    var response = await prompt();
    if (response !== null) {
      setCords(response.coords);
    }
  }
  useEffect(() => {
    getLocation();
  }, [token]);
  
  useAsyncEffect(async () => {
    getFriendList()
  }, [token, userID])

  useEffect(() => {
    getFriendList();
  }, [token,oldArray]);

  //Add self to team
  useEffect(() => {
    getSelfList()
  }, [token])


  useEffect(() => {
    teamSport()
  }, [token])


  const addSinglePlayerTeam = async (user_id: any, user: any) => {
    //console.log("User ID", user_id);
    //console.log("User array", user); return;

    var checkPlayer =  oldArray.filter(function(key:any) {
      return key.id == user.id;
    });

    //console.log("check Player", checkPlayer); return;
    
    if(checkPlayer.length > 0){
      toast({ message: 'Players already added.' },true);
    }else{
      oldArray.push(user);
      setMyArray(oldArray);
    }
    
    //console.log("Old Array", oldArray); return;


    if (oldArray) {
      var resultpdata = oldArray.map((user: any, index: any) => (
        <IonItem>
          <ProfileImage
            icon_type="none"
            size="75px"
            filename={user?.preference?.image ?? ""}
            style={{ marginRight: '15px' }}
          />
          <IonCol>
            <IonRow>
              <StarRatingComponent
                name="user-rating"
                value={(user && user.rating) ?? 0}
                editing={false}
                starColor={"var(--ion-color-text)"}
                emptyStarColor="#777"
                renderStarIcon={() => <IonIcon icon={star} />}
                renderStarIconHalf={() => <IonIcon icon={starHalf} />}
              />
            </IonRow>
            <IonRow>
              <IonText style={{ fontSize: '18px', paddingBottom: '5px' }}>{user.full_name}</IonText>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol style={{ maxWidth: '22px' }}>
                <IonIcon style={{ marginRight: "5px", fontSize: '18px' }} color="main-text" icon={location} />
              </IonCol>
              <IonCol>
                <IonRow>
                  <IonText style={{ fontSize: '15px' }}>{user?.preference?.location}</IonText>
                </IonRow>
                {(user && user.preference && user.preference.distance_miles !== null) ?
                  (<IonRow style={{ paddingTop: '1px' }}>
                    <IonText style={{ fontSize: '11px' }}>{user.preference.distance_miles?.toFixed(1)} miles away</IonText>
                  </IonRow>) : null}
              </IonCol>
            </IonRow>
          </IonCol>
          {/* <IonToggle onIonChange={e => teamFriendAdd(user.id, user.email, e.detail.checked)} /> */}
        </IonItem>
      ));
      setplayerData(resultpdata);
    }
  }


  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
 

  const teamFriendAdd = (userId: any, email: any, check: any) => {
    var type = 'team';
    // let data = (type === "event") ? state.eventFilters.eventDetails :
    //   (type === "team") ? state.teamFilters.teamDetails :
    //     state.teamFilters.teamDetails;

    let data = (type === "team") ? state.teamFilters.teamDetails :
          state.teamFilters.teamDetails;

    let objData = {
      id: userId,
      email: email
    };


   if (type === "team") {
      //if (check == true) {
        data.push(objData);
        setCheck(true);
      // } else {
      //   for (var i = 0; i < data.length; i++) {
      //     if (userId == data[i].id) {
      //       data.splice(i, 1);
      //     }
      //   }
      //   setCheck(false);
      // }
    }

    //console.log("Team added data", data);

    if (type === 'team') {
      dispatch({ type: 'teamDetails_setTeamDetails', payload: data });
    }


    return check;
  }



 //Invite player using Email Rakesh on 26.10.2022
 const sentInvitePlayer = async(email:any) =>{
      //toggle visibility
      //let inviteBtn = false;
      //setIsShown(false);
      
      if(email==''){
          toast({message: 'Please enter person email id.'}, true);
          return;
      }

      if(validateEmail(email)==null){
          toast({message: 'Please enter valid email id.'}, true);
          return;
      }

      let postdata = {email:email};
      let response = await Team.invitePLayerByEmail(token?? "", postdata);
      if(response.success==true){
          toast({message: 'Invitation to player sent successfully.'}, true);
          const element = document.getElementById('myDIV');
          element!.style.display = (element!.style.display != 'none' ? 'none' : 'block' );
          
      }

   
 }


  const searchUserByEmail = async () => {
    try {
      if (!email) {
        toast({ message: 'Enter Email Id' }, true);
        return;
      }

      if (validateEmail(email) == null) {
        toast({ message: "Invalid Email id" }, true);
        return;
      }

      let postData = {
        email: email
      }
      let response = await Users.searchUserByEmail(token ?? "", postData);
      if (response.success == true) {
        let user = response.payload;
        var result = (<IonItem>
          <ProfileImage
            icon_type="none"
            size="75px"
            filename={user?.preference?.image ?? ""}
            style={{ marginRight: '15px' }}
          />
          <IonCol>
            <IonRow>
              <StarRatingComponent
                name="user-rating"
                value={(user && user.rating) ?? 0}
                editing={false}
                starColor={"var(--ion-color-text)"}
                emptyStarColor="#777"
                renderStarIcon={() => <IonIcon icon={star} />}
                renderStarIconHalf={() => <IonIcon icon={starHalf} />}
              />
            </IonRow>
            <IonRow>
              <IonText style={{ fontSize: '18px', paddingBottom: '5px' }}>{user.full_name}</IonText>
            </IonRow>
            {/* {getRankRows()} */}
            <IonRow className="ion-align-items-center">
              <IonCol style={{ maxWidth: '22px' }}>
                <IonIcon style={{ marginRight: "5px", fontSize: '18px' }} color="main-text" icon={location} />
              </IonCol>
              <IonCol>
                <IonRow>
                  <IonText style={{ fontSize: '15px' }}>{user?.preference?.location}</IonText>
                </IonRow>
                {(user && user.preference && user.preference.distance_miles !== null) ?
                  (<IonRow style={{ paddingTop: '1px' }}>
                    <IonText style={{ fontSize: '11px' }}>{user.preference.distance_miles?.toFixed(1)} miles away</IonText>
                  </IonRow>) : null}
              </IonCol>
            </IonRow>
          </IonCol>
          <IonButton onClick={(e) => { addSinglePlayerTeam(user.id, user) }}>Add</IonButton>
        </IonItem>);

        setEmailData(result);
      } else {
        
        toast({ message: response.message }, true);
        //setEmailData([]);
        var result = (<IonItem>
          <div id="myDIV">
          <IonButton
            onClick={(e) => {sentInvitePlayer(email)}}
            size="default"
            color="main"
            expand="block"
            style={{ flex: 1 }}
            //style={{flex: 1, display: invit ? 'block' : 'none'}}
            >Invite Player</IonButton></div>
        
        </IonItem>);
        setEmailData(result);
      }

    } catch (error) {
      setEmailData([]);
      toast({ message: error }, true);
    }
  }



  const getFriendList = async () => {
    let response = await Users.getFriendList(token ?? "");
    let users = response.payload;

    if (response.payload) {
      var data = users.map((user: any, index: any) => (
        <IonItem key={generateRandomString()} lines="none">
          <Player
            className="ion-align-items-center"
            key={index}
          >
            <ProfileImage
              icon_type="none"
              icon_color="primary"
              size="75px"
              filename={user.preference.image}
            />
            <IonCol>
              <IonText>{user.short_name}</IonText>
              <IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
                {user.preference.location}
              </IonRow>
            </IonCol>
            <IonButton onClick={(e) => { addSinglePlayerTeam(user.id, user) }}>Add</IonButton>
          </Player>
        </IonItem>
      ));
    } else {
      data = (<IonItem><IonText>No friend found</IonText></IonItem>);
    }
    setData(data);
    setEmailData([]);
    setEmail('');
  };



  const getSelfList = async() => {
    let currentuser = await Users.getCurrentUser(token ?? "")
    let users = currentuser.payload;
    console.log("current user", users);
    var selfdata = (<IonItem key={generateRandomString()} lines="none">
                    <Player
                      className="ion-align-items-center"
                    >
                      <ProfileImage
                        icon_type="none"
                        icon_color="primary"
                        size="75px"
                        filename={users.preference.image}
                      />
                      <IonCol>
                        <IonText>{users.short_name}</IonText>
                        <IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
                          {users.preference.location}
                        </IonRow>
                      </IonCol>
                      <IonButton onClick={(e) => { addSinglePlayerTeam(users.id, user) }}>Add</IonButton>
                    </Player>
            </IonItem>);
    setSelfData(selfdata);

  }


  const teamSport = (async () => {
		let response = await Users.getTeamSport(token ?? "");
		if (response){
			  //setIsLoading(false);
		    setTeamList(response.payload);
		}
	})




  // event handler for new sports selectiong
	const setSelectedSports = (selectedSports:any) => { 
    //alert(selectedSports);
    //console.log("selected sports",selectedSports);
    setSports(selectedSports);
    if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sports)){
      dispatch({type: "match_setSelectedSports", payload: selectedSports});
    }
  }

//console.log("Sports on select", sports);
//console.log("Team data List", temlistdata);
//console.log("gender list", genderList);
//console.log("Type selected", type);
 
  return (
    // <IonPage>
		// 	<IonContent fullscreen>
    <IonList lines="none">
      {/* <CommonHeader title={`Create Team`}/> */}
      <IonItem className="form-input" data-name="title">
        <IonLabel position="stacked">Team Name</IonLabel>
        <IonRow style={{ width: "100%" }}>
          <IonCol size-md="9" size-sm="8">
            <IonInput
              name="name"
              type="text"
              placeholder="Team Name"
              value={name}
              onIonChange={(e) => {
                setName(e.detail.value as string);
              }}
            />
          </IonCol>
        </IonRow>
      </IonItem>

    {/* <IonItem>
        <IonLabel position="stacked">Gender</IonLabel>
        <IonRadioGroup name="gender" onIonChange={(e) => {
          setGender(e.detail.value as string);
        }}>
          {genderList.map((value) => (
            <IonItem key={generateRandomString()}>
              <IonLabel>{value}</IonLabel>
              <IonRadio slot="end" value={value} name="gender" />
            </IonItem>

          ))}
        </IonRadioGroup>
    </IonItem> */}

   
      <IonItem>
        <IonLabel position="stacked">Gender</IonLabel>
        <IonItem className="form-input" data-name="title">
            <IonSelect  placeholder="Select Gender" onIonChange={(e) => {
              setGender(e.detail.value as string);
              }}>
              {genderList.map((value:any) => (
                  <IonSelectOption value={value}>{value}</IonSelectOption>
              ))}
            </IonSelect>
				</IonItem>
      </IonItem> 

    {/* <IonItem>
      <SportsRadio
        selectedSports={sports}
        onChange={setSports}
      />
    </IonItem> */}
      
    	{/* <SportsDropdown
				multiple={false}
				onChange={(sports) => {
					setSelectedSports(sports);
					
				}}
				selectedSports={state.matchFilters.sports}
				
			/> */}

      <IonItem>
          <IonLabel position="stacked">Sports</IonLabel>
          <IonItem className="form-input" data-name="title">
          <IonSelect  placeholder="Select Sports" onIonChange={(e) => {
              console.log("selected sport", e.detail.value);
              setSports(e.detail.value as string);
              }}>
              {temlistdata?.map((team:any) => (
                  <IonSelectOption value={team.name}>{team.name}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
      </IonItem>

     

      <IonItem className="form-input" data-name="address">
        <IonLabel position="stacked">Location</IonLabel>
        <IonRow style={{ width: "100%" }}>
          <IonCol size-md="9" size-sm="8">
            <IonInput
              name="address"
              type="text"
              placeholder="City, State, or Zip Code"
              value={address}
              onIonChange={(e) => {
                setAddress(e.detail.value as string);
              }}
            />
          </IonCol>
        </IonRow>
      </IonItem>


      <IonItem className="form-input" data-name="title">
        <IonLabel style={{ fontWeight: "bold" }}>
          {(type == 1) ? "Add Players by Email" : ""}
          {(type == 2) ? "Friend List" : ""}
          {(type == 3) ? "Add Self To Team" : ""}
        </IonLabel>
        <IonSelect value={type} placeholder="Select Type" onIonChange={
          e => setType(e.detail.value)
        }>
          <IonSelectOption value="3">Add Myself</IonSelectOption>
          <IonSelectOption value="2">Friendlist</IonSelectOption>
          <IonSelectOption value="1">Player E-mail</IonSelectOption>
          
          
        </IonSelect>
      </IonItem>

      {/* {(type == 1) ?
        <div>
          <IonItem>
            <IonInput
              name="title"
              type="text"
              placeholder="Enter Email"
              value={email}
              onIonChange={(e) => {
                setEmail(e.detail.value as string);
              }}
            />
          </IonItem>
          <IonItem>
            <IonButton
              size="default"
              color="main"
              expand="block"
              style={{ flex: 1 }}
              onClick={searchUserByEmail}>Search</IonButton>
          </IonItem>

          {emailData}
        </div>

        : (type == 2) ?
          data
          : []
      } */}


        {(type == 1) ?
        <div>
          <IonItem>
            <IonInput
              name="title"
              type="text"
              placeholder="Enter Email"
              value={email}
              onIonChange={(e) => {
                setEmail(e.detail.value as string);
              }}
            />
          </IonItem>
          <IonItem>
            <IonButton
              size="default"
              color="main"
              expand="block"
              style={{ flex: 1 }}
              onClick={searchUserByEmail}>Search</IonButton>
          </IonItem>

          {emailData}
        </div>

        : []
      }


      {(type == 2) ? data: []}

      {(type==3) ? selfdata : []}  
      
      <IonItem>
        <IonLabel style={{ textAlign: 'right' }}><b>Active Players</b></IonLabel>
      </IonItem>
      {playerData}

      <IonButton
        color="main"
        expand="block"
        style={{ margin: 14 }}
        onClick={(e) => {
          e.preventDefault();
          //let sports:any
          //const sports = state.matchFilters.sports;
          //alert(sports);
          //console.log("All sports", sports);

          if (!name) {
            toast({ message: 'Add team name' }, true);
            return;
          }
          if (!gender) {
            toast({ message: 'Set gender' }, true);
            return;
          }

          if (!sports) {
            toast({ message: 'Select sports' }, true);
            return;
          }

          if (!address) {
            toast({ message: 'Set location' }, true);
            return;
          }
          let args = {
            name,
            gender,
            sports,
            address,
            //lat,
            //lon,
            //selected: state.teamFilters.teamDetails
            selected:oldArray
          };

          //console.log("team create page", args); return;
          //console.log("Team data", args); return;
          //console.log('Team selected', state.teamFilters.teamDetails); return;
         
          try {
            let response = Team.createTeam(token ?? "", args);
            if (response) {
              response.then(function (data) {
                console.log("Response data", data);
                if (data && data.success) {
                  toast({ message: data.message });
                  dispatch({ type: 'teamDetails_setTeamDetails', payload: [] }); 
                  history.push('dashboard');
                  history.replace('/teams');
                  //window.location.href = "/teams";
                  //history.replace('/teams')
                 
                } else {
                  toast({ message: data.message }, true);
                }
              })
            }
          }
          
          catch (error) {
            console.log(error);
            toast({ message: "Problem creating team" });
          }
        }}
      >
        Create Team
      </IonButton>
    </IonList>

    // </IonContent>
    // </IonPage>

  );
};

export default AddTeam;

const RowLabel = styled(IonLabel)`
  display: flex !important;
  justify-content: space-between;
  --width: 100% !important;
`;

const LabelValue = styled(IonText)`
  margin-left: 10px;
  font-size: 12px;
`;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;

const Player = styled.div`
  display: flex;
	width:100%;
	cursor: pointer;
  padding: 0 0 10px 0;
  &:first-of-type {
    padding-top: 5px;
  }
`;
