import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonHeader, 
  IonItem, IonList, IonListHeader, IonModal, IonPage, IonRadioGroup, IonRouterLink,
   IonRow, IonText, IonTitle, IonToolbar,IonLabel,IonSelect,IonSelectOption,IonInput,IonIcon,IonToggle,useIonAlert } from "@ionic/react"
  import ProfileImage from "components/ProfileImage";
  import useAuth from "hooks/useAuth";
  import useToast from "hooks/useToast";
  import React, { ReactNode, useEffect, useState } from "react";
  import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
  import { Challenges, Tournaments,Users,Team } from "services/api";
  import useAsyncEffect from "use-async-effect";
  import styled from 'styled-components';
  import { generateRandomString } from "services/util";
  import CenteredSpinner from "components/CenteredSpinner";
  import { timeAgo, toAppDateFormat, toAppFormat, toAppTimeFormat } from "../services/time";
  import IonTextTip from "components/IonTextTip";
  import useTournaments from "hooks/useTournaments";
  import ReportTournament from "components/ReportTournament";
  import { trash } from 'ionicons/icons';
  
  interface Ratings {
    [id: string]: number
  }

  interface TournamentReportPageProps extends RouteComponentProps<{
    tournaments_id:any;
   
  }> {}

  
  const TournamentReportPage: React.FC<TournamentReportPageProps> = ({ match }) => {
    const [organization, setOrganization] = useState(null);
    const { tournaments_id } = match.params;
    const {token, userID} = useAuth();
    const [toast] = useToast("");
   
    let tournamentID = match.params;
    console.log("All parameter", tournaments_id);
	  //let response = Tournaments.view(token ?? "",tournament_view_id);
    //console.log(response);
		
    return (
         <IonPage>Hello</IonPage>
    )
  }
  
  export default TournamentReportPage;
  
    // const Player = styled.div<{isSelected: boolean}>`
  //   display: flex;
  //   padding: 0 0 10px 0;
  //   &:first-of-type {
  //     padding-top: 5px;
  //   }
  // `
  const Player = styled.div`
    display: flex;
    width:100%;
    cursor: pointer;
    padding: 0 0 10px 0;
    &:first-of-type {
      padding-top: 5px;
    }
  `
  ;
  
  const CardHead = styled.div`
    flex: 1;
    font-size: 20px;
  `;
  
  const Actions = styled.div`
    display: flex;
    justify-content: center;
    padding: var(--horizontal-gutter);
  `;
  
  const Tag = styled.div<{color: string, textColor: string}>`
    background: ${props => `var(--ion-color-${props.color})`};
    color: ${props => `var(--ion-color-${props.textColor})`};
    padding: 2px 15px;
    border-radius: 100px;
    text-align: center;
    width: min-content;
    font-size: 13px;
    margin: 5px 10px 0 0;
  `;
  
  const EventTag = styled.div<{color: string, textColor: string}>`
    background: ${props => `var(--ion-color-${props.color})`};
    color: ${props => `var(--ion-color-${props.textColor})`} !important;
    padding: 7px 15px;
    border-radius: 100px;
    text-align: center;
    width: min-content;
    margin-right: 25px;
  `;
  
  const DetailLabel = styled.div`
    font-size: 20px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `;
  
  const DetailLabelText = styled(IonText)`
  `;
  
  const DetailSubLabel = styled.div`
    font-size: 12px;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between; 
    & > ${DetailLabelText} {
      margin-right: 10px;
    }
    @media (max-width: 275px){
      flex-direction: column;
      padding-bottom: 5px;
    }
  `;
  
  const Note = styled(IonCol)`
    background: #fff;
    border-radius: 5px;
    color: #333;
    width: 90%;
  `;
  
  const NoteMsg = styled.div`
    padding: 10px 10px 5px;
  `;
  
  const NoteDetails = styled(IonRow)`
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 5px;
    margin-top: 5px;
    padding: 3px 10px;
    background: #ddd;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: max-content;
  `;
  
  const NoteAuthor = styled.div`
    font-size: 12px;
  `;
  
  const NoteTime = styled.div`
    font-size: 10px;
  `;
  
  const TournamentTitle = styled.h1`
    padding: 0 20px 0px;
    text-align: center;
    color: var(--ion-color-main-text);
  `;
  
  const StartsIn = styled.div`
    padding: 0 20px 10px;
    font-size: 12px;
    text-align: center;
    color: var(--ion-color-main-text);
  `;
  
  const DetailRow = styled(IonItem)`
    align-items: center;
    --padding-top: 0;
    --padding-bottom: 0;
  `;
  
  const DetailValueColumn = styled.div`
    display: flex;
    flex-direction: column;
  `;
  
  const DetailValue = styled.div`
    text-align: right;
    padding-left: 20px;
  `;
  
  
  const DetailsHeader = styled.div`
    background: var(--ion-background-color);
    padding: 0px 16px;
    color: var(--ion-item-color, var(--ion-text-color, #000));
  `;

  const playerdelete = {
    marginLeft: '110px',
   '@media (max-width: 900px)': {
     marginLeft: '10px',
   }
 };

 const joinTournament = {
    width:'100%',
    
 };
