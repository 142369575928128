import { Redirect, Route, Switch } from 'react-router-dom';
import {
	IonApp,
	IonIcon,
	IonLabel,
	IonPage,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { person, search, home,book,call,chatboxEllipsesOutline, mail, chatbox,documentText,helpSharp} from 'ionicons/icons';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import SearchPage from './pages/SearchPage';
import FriendPage from './pages/FriendPage';
import SplashPage from './pages/auth/SplashPage';
import LoginPage from './pages/auth/LoginPage';
import AboutPage from './pages/auth/About';

import FaqPage from 'pages/auth/Faq';

import ForgotPage from './pages/auth/ForgotPage';
import ChallengePage from 'pages/ChallengePage';
import RegistrationPage from './pages/auth/RegistrationPage';
import ResultsPage from './pages/ResultsPage';
import useAuth from './hooks/useAuth';
import ResultsPageChallenge from './pages/ResultsPageChallenge';

import AuthNav from 'components/AuthNav';
import Link from "components/Link";
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './global.css';
import styled from 'styled-components';
import LeaderboardPage from './pages/LeaderboardPage';
import useDarkTheme from './hooks/useDarkTheme';
import StorePage from 'pages/StorePage';
import ProfileImage from 'components/ProfileImage';
import React, { FunctionComponent, ReactNode, useContext, useEffect, useRef } from 'react';
import useUser from 'hooks/useUser';
import TournamentPage from 'pages/TournamentPage';
import TournamentrankPage from 'pages/TournamentrankPage';

import EventPage from 'pages/EventPage';
import TeamDetailsPage from 'pages/TeamDetailsPage';
import LoadingPage from 'pages/LoadingPage';
import ProfileEditPage from 'pages/ProfileEditPage';

import FriendProfileEditPage from 'pages/FriendProfileEditPage';

import ModifyChallengePage from 'pages/ModifyChallengePage';
import ModifyOpenChallengePage from 'pages/ModifyOpenChallengePage';
import { AppContextProvider } from './State/AppContext';
import SetupPage from 'pages/SetupPage';
import { UserContext } from 'State/UserContext';
import ContactPage from 'pages/auth/ContactPage';
import ChallengesPage from 'pages/ChallengesPage';
import IssueChallenges from 'pages/IssueChallengePage';
import TournamentsPage from 'pages/TournamentsPage';
import TeamsPage from 'pages/TeamsPage';
import AccountSettingsPage from 'pages/AccountSettingsPage';
import ModifyTournamentPage from 'pages/ModifyTournamentPage';
import PaymentForm from "./components/PaymentForm";
import ModifyEventPage from 'pages/ModifyEventPage';
import CreateEventPage from 'pages/CreateEventPage';
import CreateTournament from 'pages/CreateTournament';
import AddFriendPage from 'pages/AddFriendPage';

import CreateChallengeSearchPage from 'pages/CreateChallengeSearchPage';
import ChallengeCreateSearch from 'pages/ChallengeCreateSearch';
import AddChallengePage from 'pages/CreateChallengePage';
import AddTeam from 'components/AddTeam';
import ReportTournament from 'components/ReportTournament';

import TournamentReportPage from 'pages/TournamentReportPage';

const SetupRoutes = () => {
	return (
		<IonReactRouter>
			<IonRouterOutlet>
				<Route exact path="/setup" component={SetupPage} />
				<Route exact path="/" render={() => <Redirect to='/setup' />} />
				<Route render={() => <Redirect to="/setup" />} />
			</IonRouterOutlet>
		</IonReactRouter>
	)
}

const PublicRoutes = () => {
	return (
		<IonReactRouter>
			<IonRouterOutlet>
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/register" component={RegistrationPage} />
				<Route exact path="/forgot" component={ForgotPage} />
				<Route exact path="/about" component={AboutPage} />
				<Route exact path ='/faq' component={FaqPage} />
				<Route exact path="/contact" component={ContactPage} />
				<Route exact path="/leaderboard" component={LeaderboardPage} />
				<Route exact path="/payment-form" component={PaymentForm} />
				<Route exact path="/event/view/:event_view_id" component={EventPage} />
				
				{/* <Route exact path="/tournament/view/:tournament_view_id" component={TournamentPage} /> */}

{/*
				<Route path="/search/:tab(match)" render={(props) => <SearchPage {...props} />} />
				<Route path="/search/:tab(tournament)" render={(props) => <SearchPage {...props} />} />
				<Route path="/search/:tab(event)" render={(props) => <SearchPage {...props} />} />
				<Route exact path="/results" render={(props) => <ResultsPage {...props} />} />
*/}

				<Route exact path="/" component={SplashPage} />
				<Route render={() => <Redirect to="/" />} />
			</IonRouterOutlet>
		</IonReactRouter>
	);
};

class DebugRouter extends IonReactRouter {
  constructor(props: any){
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null,2))
    this.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

const PrivateRoutes: React.FC<{user: User}> = ({user}) => {
	return (
		<IonReactRouter>
			<IonPage>
				<IonTabs>
					{/*
							Actual Authenticated Routes
					*/}
					<IonRouterOutlet>
						<Switch>
							<Route exact path="/dashboard" component={DashboardPage} />
							<Route exact path="/about" component={AboutPage} />
							<Route exact path="/contact" component={ContactPage} />
							<Route exact path ='/faq' component={FaqPage} />

							<Route path="/search/:tab(match)" render={(props) => <SearchPage {...props} />} />
							<Route path="/search/:tab(tournament)" render={(props) => <SearchPage {...props} />} />
							<Route path="/search/:tab(event)" render={(props) => <SearchPage {...props} />} />
							<Route exact path="/results" render={(props) => <ResultsPage {...props} />} />
							<Route exact path="/challenge/results" render={(props) => <ResultsPageChallenge {...props} />} />

							{/* Friends */}
							<Route path="/search/:tab(friend)" render={(props) => <FriendPage {...props} />} />
							<Route path="/search/:tab(myfriend)" render={(props) => <FriendPage {...props} />} />
              
							{/* Create event by Rakesh */}
              <Route exact path="/eventlist" component={CreateEventPage} />
							<Route path="/addevent" render={(props) => <CreateEventPage {...props} />} />
							<Route path="/myevent" render={(props) => <CreateEventPage {...props} />} /> 
							{/* Create event by Anurag */} 
              <Route exact path="/tournamentlist" component={CreateTournament} />
							<Route path="/addtournament" render={(props) => <CreateTournament {...props} />} />
							<Route path="/mytournament" render={(props) => <CreateTournament {...props} />} />
              <Route exact path ="/addfriend" component = {AddFriendPage} />


								{/* Create Challenge by Anurag */}
							<Route exact path="/challengesearch" component={CreateChallengeSearchPage} />
              <Route path="/addchallenge" render={(props) => <CreateChallengeSearchPage {...props} />} />
              <Route path="/mychallenge" render={(props) => <CreateChallengeSearchPage {...props} />} />
              <Route exact path="/challenge/create/:id" render={(props) => <ChallengeCreateSearch {...props} />} />
							<Route path="/addchallenge" render={(props) => <CreateChallengeSearchPage {...props} />} />
							<Route path="/mychallenge" render={(props) => <CreateChallengeSearchPage {...props} />} />
							<Route exact path="/challengelist/:id" render={(props) => <AddChallengePage {...props} />}  />
							<Route path="/createchallenge/:id" render={(props) => <AddChallengePage {...props} />} />
						
							<Route path="/createopenchallenge" render={(props) => <AddChallengePage {...props} />} />
              {/* <Route exact path="/createopenchallenge" component={AddChallengePage} /> */}

							<Route exact path="/account-settings" component={AccountSettingsPage} />
							<Route exact path="/user/profile/edit" component={ProfileEditPage} />
							<Route exact path="/user/profile/view" component={ProfilePage} />
							<Route exact path="/user/view/:id" render={(props) => <ProfileEditPage {...props} />} />

							{/*** Added by Rakesh on 30.08.2022 */}
							<Route exact path="/user/friendlist/view/:id" render={(props) => <FriendProfileEditPage {...props} />}  />
	
							<Route exact path="/challenge/issue/:id" component={IssueChallenges} />
							<Route exact path="/challenge/edit/:id" component={ModifyChallengePage} />
              <Route exact path="/challenge/edit_open_match/:id" component={ModifyOpenChallengePage}></Route>

							<Route exact path="/tournament/view/:tournament_view_id" component={TournamentPage} />

              <Route exact path="/tournament/viewrank/:tournament_view_id" component={TournamentrankPage} />

							<Route exact path="/event/view/:event_view_id" component={EventPage} />
							<Route exact path="/event/edit/:id" component={ModifyEventPage}/>
							<Route exact path="/team/edit/:id" component={TeamDetailsPage} />
							<Route exact path="/tournament/edit/:id" component={ModifyTournamentPage} />
							<Route exact path="/leaderboard" component={LeaderboardPage} />
							<Route exact path="/store" component={StorePage} />
					  	<Route exact path="/challenges" component={ChallengesPage} />
							<Route exact path="/tournaments" component={TournamentsPage} />
							<Route exact path="/teams" component={TeamsPage} />
							<Route exact path="/myteam" component={TeamsPage} />
              <Route exact path='/createteam' component ={AddTeam} />

              <Route exact path="/tournament/report/:tid" component={TournamentReportPage} />
              <Route exact path ='/reporttournament' component={TournamentReportPage} />



							<Route exact path="/payment-form" component={PaymentForm} />
							<Route exact path="/" render={() => <Redirect to='/dashboard' />} />
							<Route render={() => <Redirect to="/dashboard" />} />
              

							
						</Switch>
					</IonRouterOutlet>

					{/*
							Tabs that show at bottom when we're in this section of pages
					*/}
					<IonTabBar slot="bottom">
						<IonTabButton tab="dashboard" href="/dashboard">
							<IonIcon icon={home} />
							{/* <IonBadge>6</IonBadge> */}
						</IonTabButton>
						<IonTabButton tab="search" href="/search/match">
							<IonIcon icon={search} />
						</IonTabButton>
						
						<IonTabButton tab="My Profile" href="/user/profile/view">
							{
								(user?.id && user?.preference?.image) ?
								(<ProfileImage
									icon_color="accent-two"
									icon_type={"none"}
									filename={user.preference.image}
									size="50px"
									icon_offset="-10px"
									title="My Profile"
								/>):(<IonIcon icon={person} />)
							}
						</IonTabButton>

						<IonTabButton tab="about"  href="/about">
								{/* <IonIcon icon={book}  /> */}
								<IonIcon icon={documentText} />
								
						</IonTabButton>

            <IonTabButton tab="contact"  href="/contact">
									{/* <IonIcon icon ={call} /> */}
									<IonIcon icon={mail} />
						</IonTabButton>
					   
						<IonTabButton tab="faq"  href="/faq">
							<IonIcon icon ={helpSharp} />
						</IonTabButton>
					
					</IonTabBar>
				</IonTabs>
			</IonPage>
		</IonReactRouter>
	);
};

const AppInner: React.FC = () => {
	const {loggedIn, loading, token} = useAuth();
	const {userRefresh} = useUser();
	const {state: { user, userLoading, userError }} = useContext(UserContext);
	useDarkTheme();


	// setup polling for verified email check
	const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);
	//console.log("Interval Reference", intervalRef);
	
	useEffect(() => {
		if (user?.preference?.verified_email){
			if (intervalRef.current){
				clearInterval(intervalRef.current);
			}
		} else {
			intervalRef.current = setInterval(() => {
				userRefresh();
			}, 1000);
		}
		return () => {
			if (intervalRef.current){
				clearInterval(intervalRef.current);
			}
		}
	}, [user]);

	if (loading || userLoading && userError === null){
		//console.log("Loading page");
		//console.log("Loading page error");
		return <LoadingPage />
	}
	else 
	{
		///console.log("Page loaded"); 
		//console.log("Page loaded");
		if (loggedIn && user && token){
			if (!user?.preference?.verified_email){
				return <SetupRoutes />
			} else {
				return <PrivateRoutes user={user} />
			}
		}

		return <PublicRoutes />
	}
}

const App: React.FC = () => (
	<AppContextProvider>
		<IonApp>
			<AppInner />
		</IonApp>
	</AppContextProvider>
)

export default App;


