import { IonContent, IonPage,IonRow } from '@ionic/react';
import WaveTabsFriend from '../components/WaveTabsFriend';
import PageTitle from '../components/PageTitle';
import AddTournament from 'components/AddTournament';
import { RouteComponentProps } from 'react-router';
import useAuth from 'hooks/useAuth';
import { ReactNode, useEffect, useState } from 'react';
import CenteredSpinner from 'components/CenteredSpinner';
import { Tournaments } from "services/api";
import TournamentsList from "components/TournamentsList";
import CommonHeader from 'components/CommonHeader';

const CreateTournament: React.FC<RouteComponentProps> = () => {
	const {userID} = useAuth();
	const { token } = useAuth();
	// const [eventData, setUserData] = useState<any>([]);
	const [tournaments, setUserData] = useState<ReactNode[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);


	const eventList = async (token:any) => {
		let response_organizing: any = [];
		let response_participating: any = [];
		let tournamentList: any = [];

		try {
			response_organizing = await Tournaments.organizing(token ?? "");
			response_participating = await Tournaments.list(token ?? "");
			// if(response_organizing.success==true && response_participating.success===true){
			// 	tournamentList = () => (
			// 		<div>
			// 		<PageTitle size="15px">Organizing</PageTitle>
			// 		<TournamentsList tournaments={response_organizing.payload} user_id={userID} organizing_status={"organizing"} />
			// 		<PageTitle size="15px">Participating</PageTitle>
			// 		<TournamentsList tournaments={response_participating.payload} user_id={userID} organizing_status={"organizing"} />
			// 		</div>
			// 	);
			// }
			// else if(response_organizing.success===true && response_participating.success===false){
			// 	tournamentList = () => (
			// 		<div>
			// 		<PageTitle size="15px">Organizing</PageTitle>
			// 		<TournamentsList tournaments={response_organizing.payload} user_id={userID} organizing_status={"organizing"} />
			// 		</div>
			// 	);
			// }
			// else if(response_organizing.success===false && response_participating.success===true){
			// 	tournamentList = () => (
			// 		<div>
			// 		<PageTitle size="15px">Participating</PageTitle>
			// 		<TournamentsList tournaments={response_participating.payload} user_id={userID} organizing_status={"organizing"} />
			// 		</div>
			// 	);
			// }

      if(response_organizing.payload.length > 0 && response_participating.payload.length > 0){
					tournamentList = () => (
						<div>
						<PageTitle size="15px">Organizing</PageTitle>
						<TournamentsList tournaments={response_organizing.payload} user_id={userID} organizing_status={"organizing"} />
						<PageTitle size="15px">Participating</PageTitle>
						<TournamentsList tournaments={response_participating.payload} user_id={userID} organizing_status={"organizing"} />
						</div>
					);
				}
			else if(response_organizing.payload.length > 0 || response_participating.payload.length <1){
					tournamentList = () => (
						<div>
						<PageTitle size="15px">Organizing</PageTitle>
						<TournamentsList tournaments={response_organizing.payload} user_id={userID} organizing_status={"organizing"} />
						<PageTitle size="15px">Participating</PageTitle>
						<IonRow className="ion-justify-content-center">
							<h1>No data found.</h1>
					  </IonRow>
						</div>
					);
				}
				else if(response_organizing.payload.length<1 || response_participating.payload.length >0){
					tournamentList = () => (
						<div>
						<PageTitle size="15px">Organizing</PageTitle>
						<IonRow className="ion-justify-content-center">
							<h1>No data found.</h1>
					  </IonRow>
						<PageTitle size="15px">Participating</PageTitle>
						<TournamentsList tournaments={response_participating.payload} user_id={userID} organizing_status={"organizing"} />
						</div>
					);
				}
			else{
				tournamentList = (
					<IonRow className="ion-justify-content-center">
						<h1>No data found.</h1>
					</IonRow>
				);
			}
			setUserData(tournamentList);
		} catch (error) {
			console.log(error);
			setUserData([]);
		}
		return eventList;
	}

	useEffect(() => {
		eventList(token);
		setTimeout(() => {setIsLoading(false)}, 1000);
	}, [token]);


	return (
		<IonPage>
			<CommonHeader title={`Tournament`} />
			<IonContent fullscreen>
				<WaveTabsFriend
					leftTitle="Create"
					rightTitle="My Tournaments"
					leftComponent={<AddTournament />}
					rightComponent = {(isLoading==true) ? <CenteredSpinner/> : tournaments}
					color='accent-two'
					leftRoute='addtournament'
					rightRoute='mytournament'
				>
					<PageTitle color="main">Tournaments</PageTitle>
				</WaveTabsFriend>
			</IonContent>
		</IonPage>

	);
}
export default CreateTournament;
