
import { CONFIG } from "../constants";
import axios from "axios";
import { makeGetRequest, makePostRequest } from './fetching'
import { resultingClientExists } from "workbox-core/_private";
import { async } from "rxjs";
import { useResize } from "react-spring";

const TOKEN_ERROR = {
	success: false,
	message: ["No token supplied"]
};

const makeAxioPost = (url: string, token: string, formData: FormData): Promise<any> => {
	let config = {
		headers: {
			"Authorization": `Bearer ${token}`,
			"Accept": 'application/json',
			"Content-Type": "multipart/form-data"
		}
	}
	return axios.post(url, formData, config)
}

export const Users = {
	getCurrentUser: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/self';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getCurrentUserCancel = controller.abort;
		return result;
	},
	getCurrentUserCancel: () => { },

	getUsers: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getUsersCancel = controller.abort;
		return result;
	},
	getUsersCancel: () => { },

	getUser: async (token: string, userID: string | number) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/view/' + userID;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getUserCancel = controller.abort;
		return result;
	},
	getUserCancel: () => { },

	updateProfileImage: async (token: string, formData: FormData) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/update-profile-image';
		const result = await makeAxioPost(endpoint, token, formData);
		return result;
	},

	updateProfile: async (token: string, data: ProfileUpdatePayload) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/update-profile';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal);
		return result;
	},

	getInfo: async () => {
		const endpoint = CONFIG.API_ENDPOINT + '/users/info';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal);
		Users.getInfoCancel = controller.abort;
		return result;
	},
	getInfoCancel: () => { },

	getLeaders: async (token: string, args: LeaderBoardFilters) => {
		const endpoint = CONFIG.API_ENDPOINT + '/users/leaders';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, args, signal, true);
		Users.getLeadersCancel = controller.abort;
		return result;
	},
	getLeadersCancel: () => { },
	
	getSportsLeaders: async (token: string, args: LeaderBoardFilters) => {
		const endpoint = CONFIG.API_ENDPOINT + '/users/sports-leaders';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, args, signal, true);
		Users.getSportsLeadersCancel = controller.abort;
		return result;
	},
	getSportsLeadersCancel: () => {},

	getMyLeaders: async (token: string, args: LeaderBoardFilters) => {
		const endpoint = CONFIG.API_ENDPOINT + '/users/my-leaders';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, args, signal, true);
		Users.getLeadersCancel = controller.abort;
		return result;
	},
	getMyLeadersCancel: () => {},

	updatePassword: async (token: string, args: any) => {
		const endpoint = CONFIG.API_ENDPOINT + '/users/update-password';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, args, signal, true);
		Users.updatePasswordCancel = controller.abort;
		return result;
	},
	updatePasswordCancel: () => { },

	getPrefersDark: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/prefers-dark';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getPrefersDarkCancel = controller.abort;
		return result;
	},
	getPrefersDarkCancel: () => { },

	updateThemePreference: async (token: string, prefersDark?: boolean) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/users/update-theme-preference';
		if (prefersDark != undefined) {
			endpoint += '/' + prefersDark;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.updateThemePreferenceCancel = controller.abort;
		return result;
	},
	updateThemePreferenceCancel: () => { },

	requestVerificationEmail: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/send-verification-email';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.requestVerificationEmailCancel = controller.abort;
		return result;
	},
	requestVerificationEmailCancel: () => { },

	search: async (token: string, term: string, selected_emails: string[]) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/search?term=' + term;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, selected_emails, signal, true);
		Users.searchCancel = controller.abort;
		return result;
	},
	searchCancel: () => { },

	delete: async (token: string, user_id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/users/delete/' + user_id
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},
	updateSportsActiveDeactive: async (token:string,data:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/users/update-sports-status';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		Users.updateSportsActiveDeactiveCancel = controller.abort;
		return result;
	},
	updateSportsActiveDeactiveCancel: () => { },

	addSports: async (token:string,data:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/users/add-sports';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		Users.addSportsCancel = controller.abort;
		return result;
	},
	addSportsCancel: () => { },


	///Add 10 credits by Rakesh on 07.11.2023
	addcredit: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/addplayertencredit/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
    const result = await makeGetRequest(endpoint, signal, token);
		Challenges.cancelCancel = controller.abort;
		return result;
	},
	addcredit1Cancel: () => { },



	getCreditList: async () => {
		// if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/credits/allcredits';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal);
		Users.getCreditListCancel = controller.abort;
		return result;
	},
	getCreditListCancel: () => { },

	getAllSportList: async (token:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/sports/sportsList';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal,token);
		Users.getAllSportListCancel = controller.abort;
		return result;
	},
	getAllSportListCancel: () => { },
	



  ///Single sports list
	getSingleAllSportList: async (token:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/sports/sportsListSingle';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal,token);
		Users.getSingleAllSportListCancel = controller.abort;
		return result;
	},
	getSingleAllSportListCancel: () => { },


  //get team sport list by Rakesh Kumar
	getTeamSport: async(token:any) => {
		  if(!token) return TOKEN_ERROR;
			let end_point = CONFIG.API_ENDPOINT + '/sports/teamSports';
			const controller = new AbortController();
			const signal = controller.signal;
			const result = await makeGetRequest(end_point, signal,token);
			return result;
	},



	getAllUserSportList: async (token:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/sports/userSports';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint,signal,token);
		Users.getAllUserSportListCancel = controller.abort;
		return result;
	},
	getAllUserSportListCancel: () => { },

	getFriendList:async (token:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/UsersFriends/friendsList';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint,signal,token);
		Users.getFriendListCancel = controller.abort;
		return result;
	},
	getFriendListCancel: () => { },

  getEventList:async (token:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/events';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint,signal,token);
		Users.getEventListCancel = controller.abort;
		return result;
	},
	getEventListCancel: () => { },

	searchUserByEmail: async (token:string,data:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/users/searchUserByEmail';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		Users.searchUserByEmailCancel = controller.abort;
		return result;
	},
	searchUserByEmailCancel: () => { },

	searchUserById: async (token:string,data:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/teams/searchbyId';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		Users.searchUserByEmailCancel = controller.abort;
		return result;
	},
	searchUserByIdCancel: () => { },


	getSportRank: async (token: string, sport_id: any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/sportrank/' + sport_id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getSportRankCancel = controller.abort;
		return result;
	},
	getSportRankCancel: () => { },


  getUserSportRank: async (token: string, sportname: any, userID:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/usersportrank/' + sportname +'/' + userID;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getSportRankCancel = controller.abort;
		return result;
	},
	getUserSportRankCancel: () => { },


}

export const Addresses = {
	add: async (token: string, data: Address) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/addresses/add';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	update: async (token: string, address: Address) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/addresses/update/' + address.id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, address, signal, true);
		return result;
	},
	delete: async (token: string, address: Address) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/addresses/delete/' + address.id;
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	}
}

export const Challenges = {

	view: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/view';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.viewCancel = controller.abort;
		return result;
	},
	viewCancel: () => { },

	edit: async (token: string, args: any, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/edit/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = makePostRequest(endpoint, token, args, signal, true);
		Challenges.editCancel = controller.abort;
		return result;
	},
	editCancel: () => { },

	getPending: async (token: string, id: string | null) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/pending';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.getPendingCancel = controller.abort;
		return result;
	},
	getPendingCancel: () => { },

	getPast: async (token: string, id: string | null) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/past';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.getPastCancel = controller.abort;
		return result;
	},
	getPastCancel: () => { },

	getUpcoming: async (token: string, id: string | null) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/upcoming';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.getUpcomingCancel = controller.abort;
		return result;
	},
	getUpcomingCancel: () => { },

	getNumModified: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/challenges/getNumModified';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	issue: async (token: string, args: any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/issue';
		const controller = new AbortController();
		const signal = controller.signal;
		return makePostRequest(endpoint, token, args, signal, true);
	},

	updateStatus: async (token: string, id: string | undefined, status: InvitationStatus) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/updateStatus/' + id + '/' + status;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.updateStatusCancel = controller.abort;
		return result;
	},
	updateStatusCancel: () => { },

	setChallengeViewed: async (token: string, challenge_id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/setViewed/' + challenge_id;
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	cancel: async (token: string, id: string | undefined) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/cancel/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.cancelCancel = controller.abort;
		return result;
	},
	cancelCancel: () => { },

	report: async (token: string, args: any, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/report/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		Challenges.reportCancel = controller.abort;
		return makePostRequest(endpoint, token, args, signal, true);
	},
	reportCancel: () => { },


	rejectreport: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/reject/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
    const result = await makeGetRequest(endpoint, signal, token);
		Challenges.cancelCancel = controller.abort;
		return result;
	},
	rejectreportCancel: () => { },


  mymatches: async (token: string) => {
    if (!token) return TOKEN_ERROR;
    const endpoint = CONFIG.API_ENDPOINT + '/challenges/mymatches';
    const controller = new AbortController();
    const signal = controller.signal;
    Challenges.mymatchesCancel = controller.abort;
    const result = await makeGetRequest(endpoint, signal, token);
    return result;
  },
  mymatchesCancel: () => { },
	
  viewMatches: async (token: string,id: string) => {
    if (!token) return TOKEN_ERROR;
    const endpoint = CONFIG.API_ENDPOINT + '/challenges/viewMatches/'+ id;
    const controller = new AbortController();
    const signal = controller.signal;
    Challenges.viewMatchesCancel = controller.abort;
    const result = await makeGetRequest(endpoint, signal, token);
    return result;
  },
  viewMatchesCancel: () => { },

	search: async (token: string, args: any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/search';
		const controller = new AbortController();
		const signal = controller.signal;
		Challenges.searchCancel = controller.abort;
		const result = await  makePostRequest(endpoint, token, args, signal, true);
		return result;
		
	},
	searchCancel: () => { },
	addFriendChallenge: async (token: string,id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/addfriend/'+ id;
		const controller = new AbortController();
		const signal = controller.signal;
		Challenges.addFriendChallengeCancel = controller.abort;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},
	addFriendChallengeCancel: () => { },


	challengejoin:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/challengeJoin';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	challengejoinCancel: () => { },



	challengeUnjoin:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/challengeUnJoin';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	challengeUnjoinCancel: () => { },




	challengeStatusUpate:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/challengeStatusUpate';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	challengeStatusUpateCancel: () => { },

	totalMatchesRequest: async (token: string) => {
    if (!token) return TOKEN_ERROR;
    const endpoint = CONFIG.API_ENDPOINT + '/challenges/totalMatchesRequest';
    const controller = new AbortController();
    const signal = controller.signal;
    Challenges.totalMatchesRequestCancel = controller.abort;
    const result = await makeGetRequest(endpoint, signal, token);
    return result;
  },
  totalMatchesRequestCancel: () => { },

}

export const DynamicInputs = {
	getSports: async (token: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/sports/activeSports';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		DynamicInputs.getSportsCancel = controller.abort;
		return result;
	},
	getSportsCancel: () => { },

	getGenders: async (token: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/genders';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		DynamicInputs.getGendersCancel = controller.abort;
		return result;
	},
	getGendersCancel: () => { },

	getUserAddresses: async (token: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/users/addresses';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		DynamicInputs.getUsersAddressesCancel = controller.abort;
		return result;
	},
	getUsersAddressesCancel: () => { }
}

export const Search = {
	match: async (token: string, args: MatchSearch): Promise<any> => {
		if (!token) return false;
		//--------Match serch API Changed by anurag as per requirements--------------//
		const endpoint = CONFIG.API_ENDPOINT + '/users';
		//const endpoint = CONFIG.API_ENDPOINT + '/challenges/search';
		const controller = new AbortController();
		const signal = controller.signal;
		Search.matchCancel = controller.abort;
		return makePostRequest(endpoint, token, args, signal);
	},
	matchCancel: () => { },

	friend: async (token: string, args: FriendType): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/UsersFriends/searchFriends';
		const controller = new AbortController();
		const signal = controller.signal;
		Search.friendCancel = controller.abort;
		return makePostRequest(endpoint, token, args, signal);
	},
	friendCancel: () => { },

	tournament: async (token: string, args: TournamentSearch): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments';
		const controller = new AbortController();
		const signal = controller.signal;
		Search.matchCancel = controller.abort;
		return makePostRequest(endpoint, token, args, signal);
	},
	tournamentCancel: () => { },
	
	event: async (token: string, args: EventSearch): Promise<any> => {
		if (!token) return false;
		delete args['eventDetails'];
		const endpoint = CONFIG.API_ENDPOINT + '/Events/searchEvntsBydate';
		const controller = new AbortController();
		const signal = controller.signal;
		Search.eventCancel = controller.abort;
		return makePostRequest(endpoint, token, args, signal);
	},
	eventCancel: () => { }
}

export const Tournaments = {

	list: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/user';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.listCancel = controller.abort;
		return result;
	},
	listCancel: () => { },

	organizing: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/organizing';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.organizingCancel = controller.abort;
		return result;
	},
	organizingCancel: () => { },

	create: async (token: string, args: any): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/create';
		const controller = new AbortController();
		const signal = controller.signal;
		Tournaments.createCancel = controller.abort;
		const result = makePostRequest(endpoint, token, args, signal, true);
		return result;
	},
	createCancel: () => { },

	report: async (token: string, args: any, tournament_id: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/report/' + tournament_id;
		const controller = new AbortController();
		const signal = controller.signal;
		Tournaments.reportCancel = controller.abort;
		const result = makePostRequest(endpoint, token, args, signal, true);
		return result;
	},

	rankreport: async (token: string, args: any, tournament_id: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/rankreport/'+ tournament_id;;
		const controller = new AbortController();
		const signal = controller.signal;
		Tournaments.reportCancel = controller.abort;
		const result = makePostRequest(endpoint, token, args, signal, true);
		return result;
	},
	reportCancel: () => { },

	edit: async (token: string, args: any, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/edit/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = makePostRequest(endpoint, token, args, signal, true);
		Tournaments.editCancel = controller.abort;
		return result;
	},
	editCancel: () => { },

	getNumModified: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/tournaments/getNumModified';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	view: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/view';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.viewCancel = controller.abort;
		return result;
	},
	viewCancel: () => { },


	viewRank: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/details';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.viewRankCancel = controller.abort;
		return result;
	},
	viewRankCancel: () => { },
  
	setTournamentViewed: async (token: string, tournament_id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/setViewed/' + tournament_id;
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	join: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/join';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.joinCancel = controller.abort;
		return result;
	},
	joinCancel: () => { },

	leave: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/leave';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.joinCancel = controller.abort;
		return result;
	},
	leaveCancel: () => { },

	updateStatus: async (token: string, id: string | undefined, status: InvitationStatus) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/updateStatus/' + id + '/' + status;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.updateStatusCancel = controller.abort;
		return result;
	},
	updateStatusCancel: () => { },

	cancelTournament: async (token: string,id: string | undefined) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/cancelTournament/'+ id;
		const controller = new AbortController();
		const signal = controller.signal;
		Tournaments.cancelTournamentCancel = controller.abort;
		const result = makeGetRequest(endpoint, signal, token);
		return result;
	},
	cancelTournamentCancel: () => { },

  removeSingleFriendTournaments:async (token:any,data:any) => {
    if (!token) return TOKEN_ERROR;
    const endpoint = CONFIG.API_ENDPOINT + '/Tournaments/tournamentPlayersRemove';
    const controller = new AbortController();
    const signal = controller.signal;
    const result = await makePostRequest(endpoint, token, data, signal, true);
    return result;
      },
   removeSingleFriendTournamentsCancel: () => { },

	 cancelTournamentParticipants:async (token:any,data:any) => {
    if (!token) return TOKEN_ERROR;
    const endpoint = CONFIG.API_ENDPOINT + '/Tournaments/cancel';
    const controller = new AbortController();
    const signal = controller.signal;
    const result = await makePostRequest(endpoint, token, data, signal, true);
    return result;
      },
		cancelTournamentParticipantsCancel: () => { },

	 addSingleFriendTournament:async (token:any,data:any) => {
    if (!token) return TOKEN_ERROR;
    const endpoint = CONFIG.API_ENDPOINT + '/tournaments/addUserToTournaments';
    const controller = new AbortController();
    const signal = controller.signal;
    const result = await makePostRequest(endpoint, token, data, signal, true);
    return result;
    },
    addSingleFriendTournamentsCancel: () => { },


		joinMyselfTournament:async (token:any,data:any) => {
			if (!token) return TOKEN_ERROR;
			const endpoint = CONFIG.API_ENDPOINT + '/tournaments/joinMyselfToTournaments';
			const controller = new AbortController();
			const signal = controller.signal;
			const result = await makePostRequest(endpoint, token, data, signal, true);
			return result;
			},
			joinMyselfTournamentCancel: () => { },

}

export const Cms = {

	getCmsContent: async (data: any) => {
		const endpoint = CONFIG.API_ENDPOINT + '/cms/view';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, "", data, signal);
		Cms.getCmsContentCancel = controller.abort;
		return result;
	},
	getCmsContentCancel: () => { },
}

export const Contact = {
	contactDataPost:async(data:any) => {
		const endpoint = CONFIG.API_ENDPOINT + '/contact/sendcontact';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, "", data, signal);
		Contact.contactDataPostCancel = controller.abort;
		return result;

	},
	contactDataPostCancel: () => { },
}

export const Friend ={
	sendFriendsRequest:async(data:any,token:any) => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/UsersFriends/sendFriendsRequest';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal);
		Friend.sendFriendsRequestCancel = controller.abort;
		return result;

	},
	sendFriendsRequestCancel: () => { },
}




export const Event ={
	sendFriendsRequest:async(data:any,token:any) => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/UsersFriends/sendFriendsRequest';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal);
		Friend.sendFriendsRequestCancel = controller.abort;
		return result;

	},
	sendFriendsRequestCancel: () => { },

  create: async (token: string, args: any): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/events/add';
		const controller = new AbortController();
		const signal = controller.signal;
		Event.createCancel = controller.abort;
		const result = makePostRequest(endpoint, token, args, signal, true);
		return result;
	},
	createCancel: () => { },

	view: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/events/view';
		if (id) {
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.viewCancel = controller.abort;
		return result;
	},
	viewCancel: () => { },

	edit: async (token: string, args: any, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/events/edit/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = makePostRequest(endpoint, token, args, signal, true);
		Tournaments.editCancel = controller.abort;
		return result;
	},
	editCancel: () => { },

	setEventViewed: async (token: string, tournament_id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/events/setViewed/' + tournament_id;
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	
	joinEvent:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/events/join';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	joinEventCancel: () => { },

	cancelEvent:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/events/cancelEvents';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	cancelEventCancel: () => { },

	addSingleFriendEvent:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/events/addUserToEvents';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	addSingleFriendEventCancel: () => { },

	removeSingleFriendEvent:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/events/eventsParticipateRemove';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	removeSingleFriendEventCancel: () => { },
}

export const Team = {
	createTeam: async (token: string, args: any): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/teams/add';
		const controller = new AbortController();
		const signal = controller.signal;
		Team.createTeamCancel = controller.abort;
		const result = makePostRequest(endpoint, token, args, signal, true);
		return result;
	},
	createTeamCancel: () => { },
	
	teamList: async (token: any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/teams/list';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Team.teamListCancel = controller.abort;
		return result;
	},
	teamListCancel: () => { },

	teamDetails: async (token:string,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/Teams/view';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		Team.teamDetailsCancel = controller.abort;
		return result;
	},
	teamDetailsCancel: () => { },

	teamEdit: async (token:string,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/Teams/edit';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		Team.teamEditCancel = controller.abort;
		return result;
	},
	teamEditCancel: () => { },

	teamDelete: async (token:string,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/Teams/delete';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		Team.teamDeleteCancel = controller.abort;
		return result;
	},
	teamDeleteCancel: () => { },

	removeSingleFriendTeam:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/Teams/teamPlayersRemove';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	removeSingleFriendTeamCancel: () => { },

	addSingleFriendTeam:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/Teams/addUserToTeam';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	addSingleFriendTeamCancel: () => { },

	changeStatusTeamPlayers:async (token:any,data:any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/Teams/changeStatusTeamPlayers';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	changeStatusTeamPlayersCancel: () => { },

	invitePLayerByEmail: async (token:string,data:any) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/teams/invite';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		//Team.invitePLayerByEmailCancel = controller.abort;
		return result;
	},
	sinvitePLayerByEmailCancel: () => { },

	

}

