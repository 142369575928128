import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonHeader, 
  IonItem, IonList, IonListHeader, IonModal, IonPage, IonRadioGroup, IonRouterLink,
   IonRow, IonText, IonTitle, IonToolbar,IonLabel,IonSelect,IonSelectOption,IonInput,IonIcon,IonRadio,IonCheckbox,useIonAlert } from "@ionic/react"
  import ProfileImage from "components/ProfileImage";
  import useAuth from "hooks/useAuth";
  import useToast from "hooks/useToast";
  import React, { ReactNode, useEffect, useState } from "react";
  import { RouteComponentProps, useHistory } from "react-router-dom";
  import { Challenges } from "services/api";
  import styled, { css } from "styled-components";
  import CenteredSpinner from "components/CenteredSpinner";
  //import { toAppFormat } from "../services/time";
  import IonTextTip from "components/IonTextTip";
  import StarRatingComponent from 'react-star-rating-component';
  import {star,starHalf, location,person, returnUpBackOutline } from "ionicons/icons";
  import { getTimeParts } from "../services/time";
  import { timeAgo, toAppDateFormat, toAppFormat, toAppTimeFormat } from "../services/time";
  import { generateRandomString } from "services/util";
  import { trash } from 'ionicons/icons';
  
  interface IssueChallengePageProps extends RouteComponentProps<{
    id?: string;
    type: string;
  }> {}
  
  const IssueChallengePage: React.FC<IssueChallengePageProps> = ({ match }) => {
    const { id } = match.params;
    const {token, userID} = useAuth();
    const [toast] = useToast("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    // form state
    const [type, setType] = useState<number>(0);
    const [challengedata, setChallengeData] = useState<any>([]);
    const [sportsdata, setSportsData] = useState<any>([]);
    const [rankstaus, setRankStatus] = useState<string>("No");
    const [rank, setRank] = useState<Number>();
    const [timeObj, setTimeObj] = useState({
      weekday_name: "Day",
      month_name: "Mon",
      month_day: "00",
      time_12: "00:00 AM"
    });
    let history = useHistory();
    const [presentAlert] = useIonAlert();
    const [handlerMessage, setHandlerMessage] = useState('');
    const [roleMessage, setRoleMessage] = useState('');
    const [show, setShow]= useState<string>("block");

    
    /**
     * 
     * @param users array of users who participanted in the game
     * @param issuerId the user id of the tournament issuer
     * @returns A react component displaying the users
     */

    const onChangeValue =async (event:any)=> {
      setType(event.target.value);
    }

    

		const getdata = async () => {
        let response = await Challenges.viewMatches(token ?? "", id ??"");
				if (response.success == true) {
					setChallengeData(response?.payload);
				}
        if (response?.payload?.ranked == true) {
					setRankStatus("Yes");
				}
        let spotsdata = response?.payload?.opponents?.sports?.map((item: any) => (
          <Pill>{item.name}</Pill>
          
        ));
        setSportsData(spotsdata);
        // let rankdata = response.payload.opponents.sports.map((item: any) => (
        //   if(item.name==response.payload.sport.name){

        //   }
          
        // ));
        
				
				}
      /**
     * Add opponent as a friends
     */
    const addAsFriends = async () => {
      let response = await Challenges.addFriendChallenge(token ?? "", challengedata?.opponents?.id ??"");
      if (response.success == true) {
        toast({ message: response.message });
        getdata();
      }
      else {
				toast({ message: response.message }, true);
			}
    }

    const challengeJoin = async () => {
      try {
        let postData = {
          challenge_id: id
        }
        let response = await Challenges.challengejoin(token ?? "", postData);
        if (response.success == true) {
          getdata();
          toast({ message: response.message });
        } else {
          toast({ message: response.message }, true);
        }
      } catch (error) {
        console.log('Challenge Join Error', error);
        toast({ message: error }, true);
      }
    };

  

    const challengeUnJoin = async () => {
      presentAlert({
        header: "Are you sure want to Unjoin this challenge?",
        buttons: [
          {
            text: "Yes",
            role: "confirm",
            handler: () => {
              //let response = Challenges.cancel(token ?? "", challengedata?.id);   
              let postData = {
                    challenge_id: id
                  }
              let response = Challenges.challengeUnjoin(token ?? "", postData);           
              if (response) {
                response.then(function (data) {
                  if (data && data.success) {
                    toast({ message: "Challenge unjoin successfully." });
                  } else {
                    toast({ message: "User already unjoined." }, true);
                  }
                })
              }


            },
          },
          {
            text: "No",
            role: "cancel",
            handler: () => {
              setHandlerMessage("Alert canceled");
            },
          },
        ],
        onDidDismiss: (e: CustomEvent) =>
          setRoleMessage(`Dismissed with role: ${e.detail.role}`),
      })


      // try {
      //   let postData = {
      //     challenge_id: id
      //   }
      //   let response = await Challenges.challengeUnjoin(token ?? "", postData);
      //   if (response.success == true) {
      //     getdata();
      //     toast({ message: response.message });
      //   } else {
      //     toast({ message: response.message }, true);
      //   }
      // } catch (error) {
      //   console.log('Challenge Join Error', error);
      //   toast({ message: error }, true);
      // }
    };




    const challengeStatusUpate = async (user_challenge_id:string) => {
      try {
        let postData = {
          user_challenge_id: user_challenge_id
        }
        let response = await Challenges.challengeStatusUpate(token ?? "", postData);
        if (response.success == true) {
          getdata();
          toast({ message: response.message });
        } else {
          toast({ message: response.message }, true);
        }
      } catch (error) {
        console.log('Accept Challenge Error', error);
        toast({ message: error }, true);
      }
    };

    const getPlayers = ((users: User[], organizer_id: string) => {
      return (users.length > 0) ? users.map((user, index) => {
        // check if we have the user info we need
        if (user && user.id && user.preference) {
          return (
            <IonItem key={generateRandomString()} lines="none" routerDirection="forward">
              <Player
                className="ion-align-items-center"
                key={index}
                // onClick={(e) => { clickToProfilePage(user.id) }}
              >
                <ProfileImage
                  icon_type="none"
                  icon_color="primary"
                  size="75px"
                  filename={user.preference.image}
                />
                <IonCol>
                  <IonText>{user.short_name}</IonText>
                  <IonRow style={{ fontSize: "12px", paddingTop: "3px" }}>
                    {user.preference.location}
                  </IonRow>
                </IonCol>
  
              </Player>


              {/* {(user.user_challenges.invitation_status_id==1) ?	
              <IonButton
                size="default"
                color="success" 
                onClick={(e) => { challengeStatusUpate(user?.user_challenges?.id); }}
                >
                Accept
              </IonButton> 
              :  <IonButton 
              size="default"
              color="danger"
              onClick={(e) => { challengeStatusUpate(user?.user_challenges?.id); }}
              >
              Reject
            </IonButton> 
              } */}


            </IonItem>
          )
        }
      }) : (<IonItem><IonText>No Participants found</IonText></IonItem>)
    });
  
    useEffect(() => {
      getdata();
      //setIsLoading(getTimeParts(challengedata?.challenge_start));
      setTimeObj(getTimeParts(challengedata.challenge_start));
    },[id,token]);

    //console.log("open challenge", challengedata?.open_challenge);
    //console.log("Challenge data", challengedata); 
    console.log("All challenge data", challengedata);
    console.log("Frozen Time", challengedata);
  
    return (
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle color="main-text">Challenge</IonTitle>
        </IonToolbar>
      </IonHeader>
        <IonContent>
          {(!isLoading) ? (
          <IonList>
            {(challengedata) ? 
            (<>
              <IonCard>
                <DetailsHeader>
                <IonItem  routerDirection="forward" lines="none">
                  <div style={{paddingTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: "1"}}>
                    <ProfileImage
                      icon_type="none" 
                      icon_color="primary"
                      size="90px"
                      filename={challengedata?.issuer?.preference?.image ?? ""}  
                      style={{margin: "15px 0", padding: "0", minWidth: "90px"}}
                    />
                    <DetailLabel style={{marginLeft: 'auto', marginRight: "0", padding: "0 0 0 25px"}}>
                      <div style={{paddingBottom: "10px"}}>
                        <div><IonText style={{fontSize: "13px"}}><strong>Issuer:</strong></IonText></div>
                        <div><IonText style={{fontSize: "18px"}}>{challengedata?.issuer?.full_name}</IonText></div>
                        <div><IonText style={{fontSize: "18px"}}>{challengedata?.sport?.name}</IonText></div>
                      </div>
										

                      <DetailSubLabel>
                        <DetailLabelText>Created:</DetailLabelText> 
                        <IonTextTip
                          title={toAppFormat(challengedata.created)}
                        >
                         {toAppDateFormat(challengedata.created)}
                        </IonTextTip>
                      </DetailSubLabel>

                    </DetailLabel>
                  </div>
                </IonItem>
                </DetailsHeader>
                <DetailRow lines="none">
                  <DetailLabel>Start Date</DetailLabel>
                  <DetailValueColumn>
                    <DetailValue>{toAppDateFormat(challengedata.challenge_start)}, {toAppTimeFormat(challengedata.challenge_start)}</DetailValue>
                  </DetailValueColumn>
                </DetailRow>
                <DetailRow lines="none">
                  <DetailLabel> City</DetailLabel>
                  <DetailValueColumn>
                    <DetailValue>{challengedata.location ?? ""}</DetailValue>
                  </DetailValueColumn>
                </DetailRow>

                <DetailRow lines="none">
                  <DetailLabel> State</DetailLabel>
                  <DetailValueColumn>
                    <DetailValue>{challengedata.state ?? ""}</DetailValue>
                  </DetailValueColumn>
                </DetailRow>

                <DetailRow lines="none">
                  <DetailLabel> Zip</DetailLabel>
                  <DetailValueColumn>
                    <DetailValue>{challengedata.zip ?? ""}</DetailValue>
                  </DetailValueColumn>
                </DetailRow>

								<DetailRow lines="none">
                  <DetailLabel> Venue</DetailLabel>
                  <DetailValueColumn>
                    <DetailValue>{challengedata.venue ?? ""}</DetailValue>
                  </DetailValueColumn>
                </DetailRow>
							
                <DetailRow lines="none">
                  <DetailLabel> Ranked</DetailLabel>
                  <DetailValueColumn>
                    <DetailValue>{rankstaus}</DetailValue>
                  </DetailValueColumn>
                </DetailRow>

                <DetailRow lines="none">
                  <DetailLabel>Rules</DetailLabel>
                  <DetailValueColumn>
                    <DetailValue>{challengedata.rules ?? ""}</DetailValue>
                  </DetailValueColumn>
                  </DetailRow>
                 
                  <DetailRow lines="none">
                  <DetailLabel>Message</DetailLabel>
                  <DetailValueColumn>
                    <DetailValue>{challengedata.message ?? ""}</DetailValue>
                  </DetailValueColumn>
                  </DetailRow>

              </IonCard>
            </>
            ):null}

            {(challengedata?.friend_status != 'true' && challengedata?.open_challenge==false ) ? (
            <IonCard>
            <IonCard></IonCard>
              <IonListHeader>
                <CardHead>
                  <IonRow className="ion-justify-content-between ion-align-items-center" style={{paddingRight: '16px'}} >
                    <IonText>Opponent</IonText>
                    <IonText >
                    <IonText>Add as Friends  </IonText>
                    <IonCheckbox   onClick={(e) => { addAsFriends() }}/>
                    </IonText>

                  </IonRow>
                </CardHead>
              </IonListHeader>

            </IonCard>
          ):null } 


            
            <Col style={{ padding: '10px 0 0' }}>
                {(challengedata?.open_challenge==false ) ? (
                  <Row style={{ fontSize: '15px' }}>
                    <Col flex={1} style={{background: '#caee6f',borderRadius: '12px', margin:'0 14px'}}>
                      <ColoredBox
                        type={'outline'}
                        color={"#caee6f"}
                        textColor={"dark"}
                        // onClick={() => {
                        //   if (challengedata && challengedata?.opponents?.id) {
                        //     history.push({
                        //       pathname: `/user/view/${challengedata?.opponents?.id}`
                        //     });
                        //   }
                        // }}
                      >
                        <ProfileImage
                        icon_type="none"
                        size="75px"
                        filename={challengedata?.opponents[0]?.preference?.image ?? ""}
                        style={{ marginRight: '15px' }}
                        />
                        <Row style={{ paddingTop: "21px" }}>
                          <Col>
                            <Row>
                              <StarRatingComponent
                                name="user-rating"
                                value={challengedata?.opponents[0]?.rating ?? ""}
                                editing={false}
                                starColor={"var(--ion-color-text)"}
                                emptyStarColor="#777"
                                renderStarIcon={() => <IonIcon icon={star} />}
                                renderStarIconHalf={() => <IonIcon icon={starHalf} />}
                              />
                            </Row>
                            <Row>
                              <IonIcon style={{ marginRight: "5px" }} color="boxTextColor" icon={person} />
                              <IonText><strong>{challengedata?.opponents[0]?.full_name ?? ""}</strong></IonText>
                            </Row>

                            

                          </Col>
                          <AbsoluteBox>
                          {/* {sportsdata} */}
                          <Pill>{challengedata?.sport?.name}</Pill>
                          </AbsoluteBox>
                          <Row>
                          <RankBox>
                          {challengedata?.rank ?? ""}
                          </RankBox>
                        </Row>
                        </Row>
                        
                      </ColoredBox>
                      
                    </Col>
                    
                  </Row>
                ):null } 
              
                
                {/* Any user can join if the challenge is open
                 (challengedata?.open_challenge == true && challengedata?.user_id == userID) ? */
                }
                
                {
                  (challengedata?.open_challenge == true && challengedata?.user_id != userID && challengedata?.matchSet==0) ?
                    <IonButton
                      color="main"
                      expand="block"
                      style={{ margin: 14 }}
                      onClick={challengeJoin}
                    >
                      Join
                    </IonButton>
                    : null
                }


                {
                  (challengedata?.open_challenge==true && challengedata?.user_id!=userID && challengedata?.matchSet==1) ?
                     <IonButton
                     color="main"
                     expand="block"
                     style={{ margin: 14 }}
                     onClick={challengeUnJoin}
                     >Unjoin
                     </IonButton>: null
                }


               {
                (challengedata?.open_challenge == true && challengedata?.user_id == userID && challengedata?.request_user?.length >0) ?
             <IonCard>
							<IonListHeader>
								<CardHead>
									<IonRow className="ion-justify-content-between ion-align-items-center" style={{ paddingRight: '16px' }}>
										<IonText>Participants</IonText>
									</IonRow>
								</CardHead>
							</IonListHeader>
							<IonRadioGroup
								name="user"
							>
							{(challengedata?.request_user) ? getPlayers(challengedata?.request_user ?? [], challengedata?.user_id) : null}
							</IonRadioGroup>
						</IonCard>
              : null
            }
                  
            {/********Match result start here added by Rakesh on 05.01.2023*/}
              {(challengedata?.winnername!='')?(
              <div>
              <DetailRow>
              <DetailLabel><strong>Winner:</strong></DetailLabel>
              <DetailValue>{challengedata?.winnername}</DetailValue>
            </DetailRow>
          
            <DetailRow lines="none"></DetailRow></div>):null}

            {/*******Match result end here *******/}

          </Col>

           {/* {(challengedata.status!='Complete' && challengedata?.user_id == userID)?(
           <IonButton 
              size="default"
              color="danger"
              style={{ flex: 1 ,width:"49%"}}
              onClick={async () => {
              presentAlert({
                header: "Are you sure want to cancel this challenge?",
                buttons: [
                  {
                    text: "Yes",
                    role: "confirm",
                    handler: () => {
                      let response = Challenges.cancel(token ?? "", challengedata?.id);              
                      if (response) {
                        response.then(function (data) {
                          if (data && data.success) {
                            toast({ message: "Challenge cancel successfully." });
                          } else {
                            toast({ message: "Problem cancel challenge" }, true);
                          }
                        })
                      }


                    },
                  },
                  {
                    text: "No",
                    role: "cancel",
                    handler: () => {
                      setHandlerMessage("Alert canceled");
                    },
                  },
                ],
                onDidDismiss: (e: CustomEvent) =>
                  setRoleMessage(`Dismissed with role: ${e.detail.role}`),
              })

                
          }}
        >
            Cancel
          </IonButton>):null} */}

           <IonButton 
              size="default"
              color="danger"
              style={{ flex: 1 ,width:"49%"}}
              onClick={async () => {
              presentAlert({
                header: "Are you sure want to cancel this challenge?",
                buttons: [
                  {
                    text: "Yes",
                    role: "confirm",
                    handler: () => {
                      let response = Challenges.cancel(token ?? "", challengedata?.id);              
                      if (response) {
                        response.then(function (data) {
                          if (data && data.success) {
                            toast({ message: "Challenge cancel successfully." });
                          } else {
                            toast({ message: "Problem cancel challenge" }, true);
                          }
                        })
                      }


                    },
                  },
                  {
                    text: "No",
                    role: "cancel",
                    handler: () => {
                      setHandlerMessage("Alert canceled");
                    },
                  },
                ],
                onDidDismiss: (e: CustomEvent) =>
                  setRoleMessage(`Dismissed with role: ${e.detail.role}`),
              })

                
          }}
        >
            Cancel
          </IonButton>

            {(challengedata && !challengedata.frozen && challengedata.open_challenge==0) ? (
              <IonButton
                size="default"
                color="main"
                style={{ flex: 1 ,width:"49%"}}
                routerLink={`/challenge/edit/${challengedata?.id}`}
              >
                Modify
              </IonButton>
            ) : null }

          
          {(challengedata && !challengedata.frozen && challengedata.open_challenge==1) ? (
                        <IonButton
                          size="default"
                          color="main"
                          style={{ flex: 1 ,width:"49%"}}
                          routerLink={`/challenge/edit_open_match/${challengedata?.id}`}
                        >
                          Modify
                        </IonButton>
                      ) : null }
           


            {/* {(challengedata && !challengedata.frozen && challengedata.status!='Cancelled' && challengedata?.user_id==userID && challengedata?.matchSet==0) ? (
              <IonButton
                size="default"
                color="main"
                style={{ flex: 1 ,width:"49%"}}
                routerLink={`/challenge/edit/${challengedata?.id}`}
              >
                Modify
              </IonButton>
            ) : null } */}
           
          
          {(challengedata && challengedata.status=='Pending' && challengedata?.user_id != userID) ? (
      
          <IonButton 
              size="default"
              color="main"
              //expand="block"
              // style={{ flex: 1 ,width:"98.40%", display:show}}
              expand="block"
              style={{ margin: 14 }}

              //style = {{display:"{block}"}}
              id="acceptBtn"
              onClick={async () => {
              presentAlert({
                header: "Are you sure want to accept this challenge?",
                buttons: [
                  {
                    text: "Yes",
                    role: "confirm",
                    handler: () => {
                      let response = Challenges.updateStatus(token ?? "", challengedata?.id, "accepted");              
                      if (response) {
                        response.then(function (data) {
                          if (data && data.success) {
                            // history.push({
                            //         pathname: `challenge/issue/${challengedata?.id}`
                            //      });
                            setShow("none");
                            toast({ message: "Challenge accepted successfully." });
                            

                          } else {
                            toast({ message: "Please try again." }, true);
                          }
                        })
                      }


                    },
                  },
                  {
                    text: "No",
                    role: "cancel",
                    handler: () => {
                      setHandlerMessage("Alert acnceled");
                    },
                  },
                ],
                onDidDismiss: (e: CustomEvent) =>
                  setRoleMessage(`Dismissed with role: ${e.detail.role}`),
              })

                
          }}
        >
          Accept
          </IonButton>

          ):null}

          </IonList>
          ):<CenteredSpinner />}
        </IonContent>
       
      </IonPage>
    )
  }
  
  export default IssueChallengePage;
  
  // const Player = styled.div<{isSelected: boolean}>`
  //   display: flex;
  //   padding: 0 0 10px 0;
  //   &:first-of-type {
  //     padding-top: 5px;
  //   }
  // `
  const Player = styled.div`
    display: flex;
    width:100%;
    cursor: pointer;
    padding: 0 0 10px 0;
    &:first-of-type {
      padding-top: 5px;
    }
  `
  ;
  
  const CardHead = styled.div`
    flex: 1;
    font-size: 20px;
  `;
  
  const Actions = styled.div`
    display: flex;
    justify-content: center;
    padding: var(--horizontal-gutter);
  `;
  
  const Tag = styled.div<{color: string, textColor: string}>`
    background: ${props => `var(--ion-color-${props.color})`};
    color: ${props => `var(--ion-color-${props.textColor})`};
    padding: 2px 15px;
    border-radius: 100px;
    text-align: center;
    width: min-content;
    font-size: 13px;
    margin: 5px 10px 0 0;
  `;
  
  const EventTag = styled.div<{color: string, textColor: string}>`
    background: ${props => `var(--ion-color-${props.color})`};
    color: ${props => `var(--ion-color-${props.textColor})`} !important;
    padding: 7px 15px;
    border-radius: 100px;
    text-align: center;
    width: min-content;
    margin-right: 25px;
  `;
  
  const DetailLabel = styled.div`
    font-size: 20px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `;
  
  const DetailLabelText = styled(IonText)`
  `;
  
  const DetailSubLabel = styled.div`
    font-size: 12px;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between; 
    & > ${DetailLabelText} {
      margin-right: 10px;
    }
    @media (max-width: 275px){
      flex-direction: column;
      padding-bottom: 5px;
    }
  `;
  
  const Note = styled(IonCol)`
    background: #fff;
    border-radius: 5px;
    color: #333;
    width: 90%;
  `;
  
  const NoteMsg = styled.div`
    padding: 10px 10px 5px;
  `;
  
  const NoteDetails = styled(IonRow)`
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 5px;
    margin-top: 5px;
    padding: 3px 10px;
    background: #ddd;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: max-content;
  `;
  
  const NoteAuthor = styled.div`
    font-size: 12px;
  `;
  
  const NoteTime = styled.div`
    font-size: 10px;
  `;
  
  const TournamentTitle = styled.h1`
    padding: 0 20px 0px;
    text-align: center;
    color: var(--ion-color-main-text);
  `;
  
  const StartsIn = styled.div`
    padding: 0 20px 10px;
    font-size: 12px;
    text-align: center;
    color: var(--ion-color-main-text);
  `;
  
  const DetailRow = styled(IonItem)`
    align-items: center;
    --padding-top: 0;
    --padding-bottom: 0;
  `;
  
  const DetailValueColumn = styled.div`
    display: flex;
    flex-direction: column;
  `;
  
  const DetailValue = styled.div`
    text-align: right;
    padding-left: 20px;
  `;
  
  
  const DetailsHeader = styled.div`
    background: var(--ion-background-color);
    padding: 0px 16px;
    color: var(--ion-item-color, var(--ion-text-color, #000));
  `;

  const Col = styled.div<{ width?: string, flex?: number, align?: string }>`
	width: ${(props: any) => (props.width) ? props.width : 'auto'};
	flex: ${(props: any) => (props.flex) ? props.flex : 'unset'};
	align-items: ${(props: any) => (props.align) ? props.align : "flex-start"};
`;

const Row = styled.div<{ justify?: string }>`
	display: flex;
	align-items: center;
	justify-content: ${(props: any) => (props.justify) ? props.justify : "flex-start"};
`;
  
const ColoredBox = styled.div<{ type: FillType, color: string | undefined, textColor: string | undefined }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: ${props => `var(--ion-color-${props.textColor})`};
	${props => (props.type === 'outline') ? css` 
		border: 2.5px solid var(--ion-color-${props.color});` : css`
		background: var(--ion-color-${props.color});
	`}
`;

const AbsoluteBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 2px;
`;

const Pill = styled.div`
	background: white;
	color: black;
	box-shadow: 0 0 0 1px #333;
	border-radius: 10px;
	padding: 1px 10px 1px;
	margin: 4px 2px;
	font-size: 11px;
	text-align: center;
	width: min-content;
`;

const RankBox = styled.div`
	position: absolute;
	background:#fff;
	top: 28px;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 5px;
	font-weight:700;
`;
  