import { IonList, IonItem, IonLabel, IonInput, IonRange, IonText, IonButton, IonDatetime, IonModal } from "@ionic/react";
import TournamentCreate from "pages/TournamentCreate";
import { useState, useContext } from "react";
import SportsDropdown from "./SportsDropdown";
import styled from 'styled-components';
import { FilterContext } from '../State/FilterContext';
import useAuth from "hooks/useAuth";
import { Users } from "services/api";

  const newDate = new Date()
  const date = newDate.getDate();
  let dateValue:any;
  if(date < 10){
    dateValue= '0'+date;
  }else{
    dateValue = date;
  }
  const month = newDate.getMonth() + 1;
  let monthValue:any;
  if(month<10){
    monthValue = '0'+month;
  }else{
    monthValue = month;
  }
  let year = newDate.getFullYear();
  let getCurrentDate = year+'-'+monthValue+'-'+dateValue;

const TournamentSearch: React.FC = () => {
	const { state, dispatch } = useContext(FilterContext);
	const [modalOpen, setModalOpen] = useState(false);
	//const [min, setMin] = useState(500);
	const [max, setMax] = useState(500)

	const[maxRate, setMaxrate] = useState("500");
  const[minRate, setMinrate] = useState("500");


	const [ratingMinus, setMin] = useState(100);
  const { token, userID } = useAuth();
	const [rankvalue, setRankvalue] = useState("");


	// event handler for new sports selectiong
	const setSelectedSports = (selectedSports: string[]) => {
		if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sports)) {
			dispatch({ type: "tournament_setSelectedSports", payload: selectedSports });

			let sport_id = selectedSports;
			try {
				let response = Users.getSportRank(token ?? "", sport_id);
				if (response) {
					response.then(function (data) {
						console.log("Response data", data.payload[0].rank);
						console.log("Rating Minus", ratingMinus);
						if (data && data.success) {
							setRankvalue(data.payload[0].rank);
							dispatch({type: "tournament_setMaxRate", payload:data.payload[0].rank});
							dispatch({ type: 'tournament_setMinRate', payload: ratingMinus })
						} 
					})
				}
			}
			catch (error) {
				console.log(error);
				
			}
 


		}
	}

	
	// Function is called everytime increment button is clicked
	const increment = () => {
		// Counter state is incremented
		setMax(max + 10)
	}

	// Function is called everytime decrement button is clicked
	const decrement = () => {
		// Counter state is decremented
		setMin(ratingMinus - 10)
	}

	const spreadIncr = () =>{
		//console.log("Rating MInus Value", ratingMinus);
		setMin(ratingMinus + 10);
  }

 
	return (
		<IonList lines="none">
			{/* <IonButton
				color="main"
				expand="block"
				style={{ margin: 14 }}
				onClick={(e) => {
					if (!e.currentTarget) {
						return;
					}
					e.preventDefault();
					setModalOpen(true);
				}}
			>
				Create Tournament
			</IonButton> */}
			<IonModal isOpen={modalOpen}>
				<TournamentCreate onCloseClick={() => setModalOpen(false)} />
			</IonModal>
			<SportsDropdown
				//multiple={true}
				onChange={setSelectedSports}
				selectedSports={state.tournamentFilters.sports} />
			<IonItem>
			<div className="emaillab">
				<IonLabel position="stacked">Location</IonLabel>
			</div>
				<IonInput
					type="text"
					onIonChange={(e) => {
						dispatch({ type: 'tournament_setOrigin', payload: e.detail.value })
					}}
					name="text"
					placeholder="(City, State) or Zip Code"
					value={(typeof state.tournamentFilters.origin === 'string') ? state.tournamentFilters.origin : ""}
				/>
			</IonItem>
			<IonItem>
				<RowLabel position="floating">
					<IonText style={{fontSize:"20px"}}>Distance</IonText>
					<LabelValue>({state.tournamentFilters.distance} miles)</LabelValue>
				</RowLabel>
				<IonRange
					pin={true}
					dualKnobs={false}
					min={0}
					max={300}
					name="distance"
					value={state.tournamentFilters.distance}
					onIonChange={(e) => {
						dispatch({ type: 'tournament_setDistance', payload: e.detail.value })
					}}
				>
					<IonText slot="start">0</IonText>
					<IonText slot="end">300</IonText>
				</IonRange>
			</IonItem>

			{/* <IonItem>
				<IonLabel style={{fontSize:"18px"}}>Start Date</IonLabel>
				<IonDatetime
					displayFormat="MM DD YY"
					min={getCurrentDate}
					placeholder="Select Date"
					value={state.tournamentFilters.startDate}
					onIonChange={e => {
						dispatch({ type: 'tournament_setStartDate', payload: e.detail.value })
					}}

					max={(() => {
						let d = new Date();
						let year = d.getFullYear();
						let next_year = year + 1;
						return String(next_year);
					})()}
				></IonDatetime>
			</IonItem> */}


<IonItem className="form-input">
				<div className="emaillab">
          <IonLabel position="stacked">Start Date</IonLabel>
				 </div>
          <IonDatetime
                name="date"
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                min={getCurrentDate}
                value={state.tournamentFilters.startDate}
								onIonChange={e => {
									dispatch({ type: 'tournament_setStartDate', payload: e.detail.value })
								}}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
        </IonItem>
      
			{/* <IonItem>
				<IonLabel style={{fontSize:"18px"}}>End Date</IonLabel>
				<IonDatetime
					displayFormat="MM DD YY"
					min={getCurrentDate}
					placeholder="Select Date"
					value={state.tournamentFilters.endDate}
					onIonChange={e => {
						dispatch({ type: 'tournament_setEndDate', payload: e.detail.value })
					}}

					max={(() => {
						let d = new Date();
						let year = d.getFullYear();
						let next_year = year + 1;
						return String(next_year);
					})()}
				></IonDatetime>
			</IonItem> */}


			<IonItem className="form-input" >
				<div className="emaillab">
          <IonLabel position="stacked">End Date</IonLabel>
				</div>
          <IonDatetime
            name="challenge_end_date"
            min={getCurrentDate}
            displayFormat="MMM DD YYYY"
            placeholder="Select End Date"
            value={state.tournamentFilters.endDate}  
            onIonChange={e => {
							dispatch({type: 'tournament_setEndDate', payload: e.detail.value})
						}}
            max={(() => {
              let d = new Date();
              let year = d.getFullYear();
              let next_year = year + 1;
              return String(next_year);
            })()}
          />
        </IonItem>

			
		  	{/* <IonItem>
          <IonLabel>Rating</IonLabel>
          <IonText style={{marginLeft:"300px"}}>{max}</IonText>
          <IonButton color="main" fill="clear" onClick={() => { increment() }}>+</IonButton>/
          <IonButton color="main" fill="clear" onClick={() => { decrement() }}>-</IonButton>
          <IonText>{min}</IonText>
        </IonItem> */}


      <IonItem>
				<IonLabel style={{fontSize:"20px"}}>Rating</IonLabel>
				<IonText>{rankvalue}</IonText>
			</IonItem>

			
			<IonItem>
				<IonLabel style={{fontSize:"20px"}}>Spread</IonLabel>
				<IonButton color="main" fill="clear" onClick={() => { spreadIncr() }}>+</IonButton>/
				<IonButton color="main" fill="clear" onClick={() => { decrement() }}>-</IonButton>
				<IonText>{ratingMinus}</IonText>
			</IonItem>

        
				<IonItem style={{display:'none'}}>
					<IonInput onIonChange={e => {
							dispatch({ type: 'tournament_setMaxRate', payload: e.detail.value })
				}} value={max} hidden></IonInput>
				</IonItem>

				<IonItem style={{display:'none'}}>
					<IonInput onIonChange={e => {
							dispatch({ type: 'tournament_setMinRate', payload: e.detail.value })
				}} value={ratingMinus} hidden></IonInput>
				</IonItem>

		
			<IonButton
				color="main"
				expand="block"
				style={{ margin: 14 }}
				routerLink="/results"
				onClick={() => {
					dispatch({ type: 'setSearchType', payload: 'tournament' });
				}}
			>
				Search
			</IonButton>
		</IonList>
	)
}

export default TournamentSearch;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	width: 100%;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;