import { IonContent, IonPage,IonRow } from '@ionic/react';
import WaveTabsFriend from '../components/WaveTabsFriend';
import PageTitle from '../components/PageTitle';
import AddChallenge from 'components/AddChallenge';
import ChallengelistCard from 'components/ChallengelistCard';
import { RouteComponentProps } from 'react-router';
import { Challenges } from 'services/api';
import useAuth from 'hooks/useAuth';
import { ReactNode, useEffect, useState,useContext } from 'react';
import CenteredSpinner from 'components/CenteredSpinner';
import { generateRandomString, truncate } from "../services/util";
import { FilterContext } from "../State/FilterContext";

  const CreateChallengePage: React.FC<RouteComponentProps> = (id:any) => {
	const { token } = useAuth();
	const [eventData, setUserData] = useState<ReactNode[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);


	const eventList = async () => {
		let response: any = [];
		let eventList: any = [];
		try {
			response = await Challenges.mymatches(token ?? "");
			if(response.success===true){
				eventList = response.payload.map((item: any) => (
					<ChallengelistCard user={item} type="" key={generateRandomString()} />
				));
			}else{
				eventList = (
					<IonRow className="ion-justify-content-center">
						<h1>No Challenges Found</h1>
					</IonRow>
				);
			}
			setUserData(eventList);
		} catch (error) {
			console.log(error);
			setUserData([]);
		}
		return eventList;	
	}

 
	
	useEffect(() => {
		eventList();
		setTimeout(() => {setIsLoading(false)}, 1000);
	}, [token]);

	const challenge_id = id.match.params.id;
	return (

		<IonPage>
			<IonContent fullscreen>
				<WaveTabsFriend
					leftTitle="Create"
					rightTitle="My Challenges"
					leftComponent={<AddChallenge id={challenge_id}/>}
					rightComponent = {(isLoading==true) ? <CenteredSpinner/> : eventData}
					color='accent-two'
					leftRoute='addchallenge'
					rightRoute='mychallenge'
				>
					<PageTitle color="main">Challenge</PageTitle>
				</WaveTabsFriend>
			</IonContent>
		</IonPage>

	);
}
export default CreateChallengePage;