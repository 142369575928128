import { IonButton, IonContent, IonDatetime, IonInput, IonItem, IonLabel, IonList, IonPage, IonTextarea, IonToggle, LocationHistory } from "@ionic/react"
import Challenge from "components/Challenge";
import CommonHeader from "components/CommonHeader"
import PillRadios from "components/PillRadios";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import { Challenges } from "services/api";
import { joinISODateAndTime } from "services/time";
import styled from "styled-components";
import useAsyncEffect from "use-async-effect";

const ModifyChallengePage: React.FC<RouteComponentProps> = () => {

  let { id } = useParams<Params>();
	const {token, userID} = useAuth();
  const [sport, setSport] = useState<string>("");
  const [availableSports, setAvailableSports] = useState<Sport[]>([])
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [challengeLocation, setChallengeLocation] = useState<string>("");
  const [challengeLocationState, setChallengeLocationState] = useState<string>("");
  const [challengeLocationZip, setChallengeLocationZip] = useState<string>("");
  const [venue, setVenue] = useState<string>("");
  const [ranked, setRanked] = useState<boolean>(false);
  const [rules, setRules] = useState<string>("");
  const [toast] = useToast("Hello");

  const [playedsportvalue, setplayedsport] = useState<string>("");
  const [matchset, setMatch] = useState<string>("");
  const [opponentID, setOpponentID]  =  useState<string>("");

  useAsyncEffect(async () => {
    if (id){
      const result = await Challenges.view(token ?? "", id);
      console.log("payload data", result.payload);
      //console.log("Modify data", result.payload);
      if (result.success){
        const { message, sport, challenge_start, location, venue,ranked,rules,recipients,playedsport } = result.payload;
        const showsportsValue = result.payload.sport.name;
        const stateVal = result.payload.state;
        const zip = result.payload.zip;
        const opponenetIDVal =  result.payload.recipients[0]?.id;
        
        const openchallengeVal = result.payload.open_challenge;
        console.log("Openmatch Value", openchallengeVal);

        console.log("Match set", result.payload.event_status_id);
        setMatch(result.payload.event_status_id);
        console.log("Match set value", matchset);
        
        setOpponentID(opponenetIDVal);
       
        const availableSports = recipients.map((recipient: User) => {
          return recipient.sports;
        }).flat();
        setAvailableSports(availableSports);
        setMessage(message);
        setSport(sport.name);
        setChallengeLocation(location);
        setChallengeLocationState(stateVal);
        setChallengeLocationZip(zip);
        setDate(challenge_start);
        setTime(challenge_start);
        setRanked(ranked);
        setplayedsport(showsportsValue);
        setRules(rules);
        setVenue(venue);

      }
    }
  }, [token])

   console.log("Login id", userID);
   console.log("Opponent id",opponentID);
   //console.log("Open challenge check", result.payload.open_challenge);

	return (
		<IonPage>
			<CommonHeader title={`Modify Challenge`}/>
			<IonContent>
			  <IonList>
          <IonItem className="form-input" data-name="sport">
            <IonLabel style={{fontSize:"18px"}}>Sport</IonLabel>
            {/* <PillRadios
              options={availableSports.map((sport: Sport) => sport.name)}
              value={sport}
              onChange={(selectedSport:string) => setSport(selectedSport)}
            /> */}
            
            <AbsoluteBox style={{backgroundColor:'#CAEE6F', borderRadius:'10px', marginRight:'15px', width:'150px'}}>           
               <Pill>{playedsportvalue}</Pill>
            </AbsoluteBox>

          </IonItem>
         
          <TimeRow>
            <IonItem className="form-input" data-name="date">
              <IonLabel position="stacked" style={{fontSize:"23px"}}>Start Date</IonLabel>
              {(matchset=='1' && userID != opponentID)?(
              <IonDatetime
                name="date"
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                value={date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({localDate});
                  setDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />): <IonDatetime
              name="date"
              displayFormat="MMM DD YYYY"
              placeholder="Select Start Date"
              readonly = {true}
              value={date}
              onIonChange={e => {
                let localDate = e.detail.value!;
                console.log({localDate});
                setDate(localDate);
              }}
              max={(() => {
                let d = new Date();
                let year = d.getFullYear();
                let next_year = year + 1;
                return String(next_year);
              })()}
            />}

            </IonItem>

            <IonItem className="form-input" data-name="time">
                <IonLabel position="stacked" style={{fontSize:"23px"}}>Start Time</IonLabel>
                {(matchset=='1' && userID != opponentID)?(
                <IonDatetime
                  name="time"
                  minuteValues="0,10,20,30,40,50"
                  displayFormat="h:mm A"
                  placeholder="Select Start Time"
                  value={time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setTime(localTime);
                  }}
                  ></IonDatetime>):<IonDatetime
                  name="time"
                  readonly = {true}
                  minuteValues="0,10,20,30,40,50"
                  displayFormat="h:mm A"
                  placeholder="Select Start Time"
                  value={time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setTime(localTime);
                  }}
                  ></IonDatetime>}
            </IonItem>
          </TimeRow>
         
          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>City</IonLabel>
            {(matchset=='1' && userID != opponentID)?(
            <IonInput
              name="location"
              type="text"
              placeholder="City"
              value={challengeLocation}
              onIonChange={(e) => {
                  setChallengeLocation(e.detail.value as string);
              }}
            />): <IonInput
            name="location"
            readonly= {true}
            type="text"
            placeholder="City"
            value={challengeLocation}
            onIonChange={(e) => {
                setChallengeLocation(e.detail.value as string);
            }}
          />}
          </IonItem>
  
          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>State</IonLabel>
            {(matchset=='1' && userID != opponentID)?(
            <IonInput
              name="locstate"
              type="text"
              placeholder="State"
              value={challengeLocationState}
              onIonChange={(e) => {
                  setChallengeLocationState(e.detail.value as string);
              }}
            />): <IonInput
            name="locstate"
            readonly= {true}
            type="text"
            placeholder="State"
            value={challengeLocationState}
            onIonChange={(e) => {
                setChallengeLocationState(e.detail.value as string);
            }}
          />}
          </IonItem>

         
          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>Zip</IonLabel>
            {(matchset=='1' && userID != opponentID)?(
            <IonInput
              name="location"
              type="text"
              placeholder="City"
              value={challengeLocationZip}
              onIonChange={(e) => {
                  setChallengeLocationZip(e.detail.value as string);
              }}
            />): <IonInput
            name="location"
            readonly={true}
            type="text"
            placeholder="City"
            value={challengeLocationZip}
            onIonChange={(e) => {
                setChallengeLocationZip(e.detail.value as string);
            }}
          />}
          </IonItem>


           
            <IonItem className="form-input" data-name="title">
            <IonLabel position="stacked" style={{fontSize:"22px"}}>Venue</IonLabel>
            {(matchset=='1' && userID != opponentID)?(
              <IonInput
                name="venue"
                type="text"
                placeholder="Venue"
                value={venue}
                onIonChange={(e) => {
                  setVenue(e.detail.value as string);
                }}

              />): <IonInput
              name="venue"
              readonly={true}
              type="text"
              placeholder="Venue"
              value={venue}
              onIonChange={(e) => {
                setVenue(e.detail.value as string);
              }}

            />}
            </IonItem>

          <IonItem>
              <IonLabel style={{fontSize:"18px"}}>Ranked</IonLabel>
              {(matchset=='1' && userID != opponentID)?(
              <IonToggle color="success" 
                 checked={ranked} 
                 onIonChange={e => 
                  setRanked(e.detail.checked)} 
                />):<IonToggle color="success" 
                checked={ranked} 
                disabled={true}
                onIonChange={e => 
                 setRanked(e.detail.checked)} 
               />}

          </IonItem>

          <IonItem className="form-input" data-name="location">
          <IonLabel position="stacked" style={{fontSize:"22px"}}>Rules</IonLabel>
          {(matchset=='1' && userID != opponentID)?(
            <IonInput
              name="location"
              type="text"
              value={rules}
              onIonChange={(e) => {
                setRules(e.detail.value as string);
              }}
            />):<IonInput
            name="location"
            readonly={true}
            type="text"
            value={rules}
            onIonChange={(e) => {
              setRules(e.detail.value as string);
            }}

          />}
          </IonItem>

          <IonItem className="form-input" data-name="message">
            <IonLabel position="stacked" style={{fontSize:"23px"}}>Message</IonLabel>
            {(matchset=='1' || matchset=='2')?(
            <IonTextarea
              name="message"
              placeholder="Message"
              value={message}
              auto-grow={false}
              rows={6}
              spellCheck={true}
              onIonChange={e => setMessage(e.detail.value!)}
            ></IonTextarea>): <IonTextarea
            name="message"
            readonly = {true} 
            placeholder="Message"
            value={message}
            auto-grow={false}
            rows={6}
            spellCheck={true}
            onIonChange={e => setMessage(e.detail.value!)}
          ></IonTextarea>}
          </IonItem>

          <IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (id){
                if (!e.currentTarget) return;
                e.preventDefault();

                if (!sport){
                  toast({message: 'Select a sport'}, true);
                  return;
                }

                if (!date || !time){
                  toast({message: 'Please set a date and time'}, true);
                  return;
                }
  
                if (!challengeLocation){
                  toast({message: 'Please set city'}, true);
                  return;
                }

                if (!challengeLocationState){
                  toast({message: 'Please set state'}, true);
                  return;
                }
                
                if (!challengeLocationZip){
                  toast({message: 'Please set zip'}, true);
                  return;
                }

                if(!venue){
                    toast({message: 'Please set venue'});
                    return;
                }
               

                let args = {
                  sport,
                  date: joinISODateAndTime(date, time),
                  location: challengeLocation,
                  challengeLocationState: challengeLocationState,
                  challengeLocationZip : challengeLocationZip,
                  venue: venue,
                  ranked: (ranked) ? 1:0,
                  rules: rules,
                  message
                  
                }

                //console.log("Modify data", args); return;
                //console.log("Edit data", args) return;
                const response = Challenges.edit(token ?? "", args, id);
                if (response){
                  response.then(function(data){
                    if (data.success){
                      toast({message: "Challenge Updated"});
                    } else {
                      toast({message: "Error Updating Challenge"}, true);
                    }
                  });
                }
              } else {
                toast({message: "Error Updating Challenge"}, true);
              }
            }}
          >
            Submit
          </IonButton>
        </IonList>
			</IonContent>
		</IonPage>
	)
}

export default ModifyChallengePage;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;

const AbsoluteBox = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 2px;
`;

const Pill = styled.div`
	color: black;
	border-radius: 10px;
	padding: 1px 10px 1px;
	margin: 4px 2px;
	font-size: 11px;
	text-align: center;
	display: flex;
  justify-content: center;
`;