import { IonButton, IonList, IonRow, IonSpinner } from "@ionic/react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { FilterContext } from '../State/FilterContext'
import { generateRandomString } from "../services/util";
import RankCard from "./RankCard";
import TournamentCard from "./TournamentCard";
import UserCard from "./UserCard";
import GroupCard from "./GroupCard";
import { useHistory } from "react-router-dom";

const GroupedSearchResults: React.FC<SearchResultsProps> = ({results, filters, searchType = "match" }) => {
  const [resultsList, setResultsList] = useState<ReactNode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {state, dispatch} = useContext(FilterContext);
  const history = useHistory();

  const listResults = () => {
    let list = [];
    for (const [name, group] of Object.entries(results)) {
      let items = [];
      if (group.map !== undefined){
        if (searchType === "match"){
          items = group.map((item: any) => (
            <UserCard user={item} type={searchType} key={generateRandomString()}/>
          ));
        } else if (searchType === "tournament"){
          items = group.map((item: any) => (
            <TournamentCard tournament={item} key={generateRandomString()} />
          ));
        } else if (searchType === "leaderboard"){
          items = group.map((item: any) => (
            <RankCard user={item} filters={filters} key={generateRandomString()} />
          ));
        }
      }
      
      let card = (
        <GroupCard title={name} key={generateRandomString()}>
          {items}
          <IonButton
            expand="full"
            onClick={() => {
							dispatch({type: 'leaderboard_setSport', payload: name});
						}}
          >
            View All
          </IonButton>
        </GroupCard>
      )
      list.push(card);
    }
    setIsLoading(false);
    return list;
  }

  useEffect(() => {
    setResultsList(listResults());
  }, [results])

  return (
    (isLoading) ? (
    <IonRow className="ion-justify-content-center ion-align-items-center" style={{padding: '15px'}}>
      <IonSpinner
        style={{
          width: "100%",
          height: `30px`
        }}
        name="dots"
      />
    </IonRow>
  ):(
      <IonList>
        {resultsList}
      </IonList>
    )
  )
}

export default GroupedSearchResults