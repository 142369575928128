import useAuth from "hooks/useAuth";
import { createContext, useEffect, useReducer } from "react";
import { Users } from "services/api";
import { useAsyncEffect } from 'use-async-effect';

interface UserContext {
  dispatch?: any
  state: {
    user: User | null
		userLoading: boolean
		userError: string | null
  }
}

let initialContext = { 
	state: {
		user: null,
		userLoading: true,
		userError: null,
	}
};

let UserContext = createContext<UserContext>(initialContext);

let reducer = (state: any, action: StateAction) => {
	console.log(action);
	switch(action.type){
		case 'setUser':
			return {
				user: action.payload,
				userLoading: false,
				userError: false,
			}
		case 'setError':
			return {
				...state,
				userError: action.payload
			}
	}
	return state;
}

function UserContextProvider(props: any){
	const initialState = initialContext.state;
	const {token} = useAuth();
	let [state, dispatch] = useReducer(reducer, initialState);
	let value = { state, dispatch };

	useAsyncEffect(async () => {
		let response = await Users.getCurrentUser(token ?? "");
		if (response && response.success){
			dispatch({type: 'setUser', payload: response.payload});
		} else {
			dispatch({type: 'setError', payload: "Failed to get user"});
		}
	},[token]);


	return (
		<UserContext.Provider value={value}>{props.children}</UserContext.Provider>
	)
}

let UserContextConsumer = UserContext.Consumer;

export {
	UserContext,
	UserContextConsumer,
	UserContextProvider
}