import styled from "styled-components";
import { generateRandomString, getLinearGradient, normalizePath } from '../services/util'
import './Waves.css';

interface WaveProps {
	color: string;
	stretch: number;
	path: string;
	top?: boolean;
	gradient?: boolean;
}

interface ContainerProps {
	top: boolean;
}

const Container = styled.div<ContainerProps>`
	width: 100%;
	position: absolute;
	top: ${props => `${(props.top) ?'unset':0}`};
	bottom: ${props => `${(props.top) ? 0:'unset'}`};
	z-index: -1;
`;

interface WavePartProps {
	color: string;
	path: string;
	height: number;
	gradient?: boolean;
}

const WavePart = styled.div<WavePartProps>`
	transition: height 0.5s;
	background: ${props => (props.gradient) ? getLinearGradient(props.color):`var(--ion-color-${props.color})`};
	clip-path: ${props => `url(${props.path})`};
	-webkit-clip-path: ${props => `url(${props.path})`};
	height: ${props => `${props.height}px`};
`

interface ColumnProps {
	top: boolean
}

const Column = styled.div<ColumnProps>`
	display: flex;
	flex-direction: ${props => ((props.top) ? 'column':'column-reverse')};
`;

const Wave: React.FC<WaveProps> = ({path, color, stretch, top = true, gradient = false}) => {
	const svg_id = generateRandomString();
	return (
		<Container className={`wave-container ${color}`} top={top}>
			<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{position: 'absolute'}}>
				<clipPath id={svg_id} clipPathUnits="objectBoundingBox">
					<path d={normalizePath(path)} />
				</clipPath>
			</svg>
			<Column top={top}>
				<WavePart color={color} height={stretch} path={`#${svg_id}`} gradient={gradient}/>
			</Column>
		</Container>
	);
};

export default Wave;