import { IonSpinner } from "@ionic/react";
import styled from "styled-components";

const AbsoluteSpinner = () => {
	return (
		<StyledAbsoluteSpinner>
			<IonSpinner 
				name="circles"
				style={{
					height: `50px`
				}}
			/>
		</StyledAbsoluteSpinner>
	);
}

export default AbsoluteSpinner

const StyledAbsoluteSpinner = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 0);
`;