import { IonButton } from '@ionic/react'
import styled from 'styled-components';

const Button: React.FC<any> = ({children, ...platformProps}) => {
	return (
		<StyledIonButton {...platformProps}>
			{children}
		</StyledIonButton>
	)
}
export default Button;

const StyledIonButton = styled(IonButton)`
	--border-radius: 100px;
	text-transform: none;
	font-size: 20px;
	&::part(native) {
		font-family: 'Montserrat'
	}
`;