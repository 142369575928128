import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton, IonIcon, IonText } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useContext } from "react";
import { FilterContext } from '../State/FilterContext'

const NoResults: React.FC<{link: string}> = ({link}) => {
	const {state} = useContext(FilterContext);
	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>No Results</IonCardTitle>
				<IonCardSubtitle>Try changing the search filters</IonCardSubtitle>
			</IonCardHeader>
			<IonCardContent>
				<IonButton
					color="main"
					expand="block"
					style={{ margin: 14 }}
					routerLink={link}
				>
					<IonIcon style={{marginRight: '15px'}} icon={arrowBack}></IonIcon>
					<IonText>Return to Search</IonText>
				</IonButton>
			</IonCardContent>
		</IonCard>
	)
}

export default NoResults;