import { IonCheckbox, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRow, IonSkeletonText, IonText, IonThumbnail } from "@ionic/react"
import useAuth from "hooks/useAuth";
import { add, close } from "ionicons/icons";
import { ReactNode, useEffect, useRef, useState } from "react"
import { Users } from "services/api";
import { generateRandomString } from "services/util";
import styled from "styled-components";
import useAsyncEffect from "use-async-effect";
import CenteredSpinner from "./CenteredSpinner";
import ProfileImage from "./ProfileImage";
import SkeletonList from "./SkeletonList";

const UsersSelect: React.FC<{
	selected: User[],
	setSelected: (selected: User[]) => void,
	max: number | null
}> = ({ selected, setSelected, max = null }) => {
	const {token, userID} = useAuth();
	const [term, setTerm] = useState<string>("");
	const [users, setUsers] = useState<User[]>([]);
	const [showPlus, setShowPlus] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const timeoutRef = useRef<number | null>(null);

	useAsyncEffect(async () => {
		setIsLoading(true);
		const response = await Users.search(token ?? "", term, selected.map(user => user.email));
		if (response.success){	
			let matching_users = response.payload;
			// Filter out current user
			let users = matching_users.filter((user: User) => user.id !== userID)
			setUsers(users);
		}
		setIsLoading(false);
	}, [token, term, selected]);

	const isEmailSelected = (email: string) => {
		return selected.find(user => email === user.email);
	}

	useEffect(() => {
		setShowPlus(() => {
			return validateEmail(term) && !isEmailSelected(term);
		});
	},[term])

	const onSelect = (user: User) => {
		let index = selected.findIndex(u => u.email === user.email);
		if (index >= 0){
			selected.splice(index, 1);
			let newSelected = [...selected];
			setSelected(newSelected);
		} else {
			if (max === null || selected.length < max){
				setSelected([...selected, user]);
			}
		}
	}

	const addEmail = () => {
		let user = { email: term } as User;
		let index = selected.findIndex(u => u.email === user.email);
		if (index < 0){
			if (max === null || selected.length < max){
				setSelected([...selected, user]);
			}
		}
	}
	
	const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
	}

	return (
		<div>
			<IonItem lines="none">
				<IonLabel position="stacked">
					<IonText style={{fontSize:"25px"}}>Invite Participants</IonText> 
					<IonText
						style={{ marginLeft: "10px" }}
					>
						{(max !== null) ? `(${selected.length}/${max})`:null}
					</IonText>
				</IonLabel>
				<IonRow className="ion-align-items-center" style={{width: "100%"}}>
					<IonInput
						type="text"
						value={term}
						placeholder="Search Participants"
						onIonInput={(e: Event) => {
							if (timeoutRef.current){
								clearTimeout(timeoutRef.current);
							}
							timeoutRef.current = window.setTimeout(() => {
								setTerm((e.target as HTMLInputElement).value);
							}, 700);
						}}
					/>
					<IonIcon icon={add} hidden={!showPlus} onClick={() => addEmail()}/>
				</IonRow>
			</IonItem>
			<SelectedUsers selected={selected} onDismiss={onSelect} term={term} />
			<AvailableUsers users={users} onSelect={onSelect} selected={selected} disabled={!(max === null || selected.length < max)} loading={isLoading}/>
		</div>
	)
}

const AvailableUsers: React.FC<{ 
	users: User[], 
	selected: User[]
	onSelect: (user: User) => void
	disabled: boolean
	loading: boolean
}> = ({
	users = [],
	onSelect,
	selected,
	disabled = false,
	loading = false
}) => {
	const [cache, setCache] = useState<User[]>([])
	const [nodes, setNodes] = useState<ReactNode[]>([]);

	const getNodes = (users: User[]) => {
		return users.map((user: User) => {
			let index = selected.findIndex(u => u.email === user.email);
			let isSelected = (index >= 0);
			return <AvailableUser key={generateRandomString()} onSelect={onSelect} isSelected={isSelected} user={user} disabled={disabled}/>
		})
	}

	useEffect(() => {
		if (JSON.stringify(users) !== JSON.stringify(cache)){
			setNodes(getNodes(users));
		}
	}, [users])

	return (
		<>
			{(loading) ? (
				<SkeletonList num={(users && users.length) || 3} />
			):nodes}
		</> 
	)
}

const AvailableUser: React.FC<{
	user: User,
	onSelect: (user: User) => void, 
	isSelected: boolean
	disabled: boolean
}> = ({
	user,
	onSelect,
	isSelected,
	disabled = false
}) => {
	return (
		<StyledUser lines="none" disabled={disabled}>
			<IonRow className="ion-align-items-center">
				<ProfileImage 
					icon_type="none"
					size="50px"
					filename={user?.preference?.image ?? ""}
					style={{marginRight: "15px"}}
				/>
				<IonText>{user.full_name}</IonText>
			</IonRow>
			<IonCheckbox slot="end" checked={isSelected} onIonChange={() => onSelect(user)} disabled={disabled}/>
		</StyledUser>
	)
}

const SelectedUsers: React.FC<{
	selected: User[],
	onDismiss: (user: User) => void
	term: string
}> = ({selected, onDismiss, term}) => {
	return (
		<StyledSelectedUsers>
			{selected.map((user: User) => <SelectedUser key={generateRandomString()} user={user} onDismiss={onDismiss} term={term}/>)}
		</StyledSelectedUsers>
	)
}

const SelectedUser: React.FC<{
	user: User
	onDismiss: (user: User) => void,
	term: string
}> = ({user, onDismiss, term}) => {
	const display = (user.full_name) ? user.full_name:user.email;
	return (
		<StyledSelectedUser matchesTerm={(
			user.email === term || 
			user.full_name === term
		)}>
			{display}
			<IonIcon icon={close} onClick={() => onDismiss(user)}/>
		</StyledSelectedUser>
	)
}

export default UsersSelect;

const StyledSelectedUsers = styled.div`
	padding: 10px 10px 20px 10px;
	display: flex;
	flex-wrap: wrap;
`;

const StyledUser = styled(IonItem)`
	background: var(--ion-color-shade);
`;

const StyledSelectedUser = styled(IonRow)<{matchesTerm: boolean}>`
	align-items: center;
	margin: 2px 5px 0 0;
	background: ${props => props.matchesTerm ? `var(--ion-color-danger)`:`var(--ion-color-main)`};
	color: var(--ion-color-constant-light);
	padding: 5px 10px 5px 15px;
	width: max-content;
	font-size: 13px;
	border-radius: 100px;
	& ion-icon {
		font-size: 15px;
		margin-left: 10px;
	}
`;