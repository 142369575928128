import { useEffect, useRef, useState } from "react"

const usePromptPWA = () => {
	const deferredPromptRef = useRef<any>();
	const [showPrompt, setShowPrompt] = useState<boolean>(false);

	const prompt = () => {
		if (deferredPromptRef.current){
			deferredPromptRef.current.prompt();
		}
	}

	useEffect(() => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			deferredPromptRef.current = e;
			setShowPrompt(true)
		});
	})

	return {
		prompt,
		showPrompt
	}
}

export default usePromptPWA