import { IonButton, IonCol, IonInput, IonItem, IonLabel, IonList, IonRange, IonRow, IonText } from "@ionic/react";
import { useContext,useEffect } from "react";
import GenderCheckboxes from "./GenderCheckboxes";
//import styled from 'styled-components';
import styled, { css } from "styled-components";
import { FilterContext } from "../State/FilterContext";
import useToast from "hooks/useToast";
import { useHistory } from "react-router-dom";


const FindFriend: React.FC = () => {
	const { state, dispatch } = useContext(FilterContext);
	const [toast] = useToast("Hello");
	const history = useHistory();

	// event handler for new gender selection
	const updateGenderSelection = (checked: boolean, gender_change: string) => {
		let selectedGenders = state.friendFilters.genders;
		const newGenderArr: string[] = selectedGenders;
		if (checked) {
			if (newGenderArr.indexOf(gender_change) < 0) {
				newGenderArr.push(gender_change);
			}
		} else {
			const index = newGenderArr.indexOf(gender_change);
			if (index > -1) {
				newGenderArr.splice(index, 1);
			}
		}
		if (JSON.stringify(selectedGenders) !== JSON.stringify(state.friendFilters.genders)) {
			dispatch({ type: 'friend_setGenders', payload: selectedGenders });
		}
	}


	const updateAge = (e: any) => {
		dispatch({ type: 'match_setAgeRange', payload: e.target.value });
	}

	const searchEmail=()=>{
		if(state.friendFilters.email==""){
			toast({ message: "Email id required" }, true);
			return;
		}
		if(validateEmail(state.friendFilters.email)==null){
			toast({ message: "Invalid Email id" }, true);
			return;
		}
		history.push("/results");
		dispatch({ type: 'setSearchType', payload: 'friend' });
	}

	const validateEmail = (email:any) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	useEffect(() => {
		dispatch({ type: 'setSearchType', payload: 'friend' });
		dispatch({ type: 'friend_setPastMatch', payload:false })
		dispatch({ type: 'friend_setEmail', payload: "" })
	}, []);

	return (
		<IonList lines="none">
			<IonItem>
				   <div className="emaillab">
					    <IonLabel  position="stacked" class="email">Email Address</IonLabel>
					 </div>
				  
				<IonRow style={{width:"100%"}}>
					<IonCol size-md="9" size-sm="8">
						<IonInput
							type="text"
							style={{fontSize:"18px;"}}
							name="text"
							placeholder="Email"
							value={(typeof state.friendFilters.email === 'string') ? state.friendFilters.email : ""}
							onIonChange={(e) => {
								dispatch({ type: 'friend_setEmail', payload: e.detail.value })
							}}
						/>
					</IonCol>
					<IonCol size-md="3" size-sm="4">
						<IonButton 
						style={{float:"right"}}
						onClick={searchEmail}
							>
							Search
						</IonButton>
					</IonCol>
				</IonRow>
			</IonItem>
			<IonItem>
				<IonRow style={{width:"100%"}}>
					<IonCol size-md="9" size-sm="8">
						<IonText style={{fontSize:"20px"}}>Past Match</IonText>
					</IonCol>
					<IonCol size-md="3" size-sm="4">
						<IonButton 
						style={{float:"right"}}
						routerLink="/results"
						onClick={() => {
							dispatch({ type: 'setSearchType', payload: 'friend' });
							dispatch({ type: 'friend_setPastMatch', payload:true })
						}}
						>Search
						</IonButton>
					</IonCol>
				</IonRow>
			</IonItem>
			<IonItem>
			  <div className="emaillab">
				  <IonLabel position="stacked">Location</IonLabel>
				</div>
				<IonInput
					type="text"
					onIonChange={(e) => {
						dispatch({ type: 'friend_setOrigin', payload: e.detail.value })
					}}
					name="text"
					placeholder="(City, State) or Zip Code"
					value={(typeof state.friendFilters.origin === 'string') ? state.friendFilters.origin : ""}
				/>
			</IonItem>
			<IonItem>
				<RowLabel position="floating">
					<IonText style={{fontSize:"20px"}}>Distance</IonText>
					<LabelValue>({state.friendFilters.distance} miles)</LabelValue>
				</RowLabel>
				<IonRange
					pin={true}
					dualKnobs={false}
					min={0}
					max={300}
					name="distance"
					value={state.friendFilters.distance}
					onIonChange={(e) => {
						dispatch({ type: 'friend_setDistance', payload: e.detail.value })
					}}
				>
					<IonText slot="start">0</IonText>
					<IonText slot="end">300</IonText>
				</IonRange>
			</IonItem>
			<IonItem>
				<RowLabel position="floating">
					<IonText style={{fontSize:"20px"}}>Age</IonText>
					<LabelValue>{(
						state.friendFilters.ageRange &&
						state.friendFilters.ageRange.lower &&
						state.friendFilters.ageRange.upper) ?
						`(${state.friendFilters.ageRange.lower} - ${state.friendFilters.ageRange.upper})` : null}</LabelValue>
				</RowLabel>
				<IonRange pin={true} dualKnobs={true} min={18} max={100} name="age" value={state.matchFilters.ageRange} onIonChange={updateAge}>
					<IonText slot="start">18</IonText>
					<IonText slot="end">100</IonText>
				</IonRange>
			</IonItem>
			<div style={{paddingLeft:"15px"}}>
				<GenderCheckboxes
					selectedGenders={state.friendFilters.genders}
					onChange={updateGenderSelection}
				/>
			</div>
			
			<IonButton
				color="main"
				expand="block"
				style={{ margin: 14 }}
				routerLink="/results"
				onClick={() => {
					dispatch({ type: 'setSearchType', payload: 'friend' });
				}}
			>
				Search
			</IonButton>
		</IonList>
	)
}

export default FindFriend;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	--width: 100% !important;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;


