import { IonContent, IonPage, IonRow, IonText } from '@ionic/react';
import WaveTabsFriend from '../components/WaveTabsFriend';
import PageTitle from '../components/PageTitle';
import FindFriend from 'components/FindFriend';
import UserCard from 'components/UserCard';
import { RouteComponentProps } from 'react-router';
import { Users } from 'services/api';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';

import CommonHeader from "components/CommonHeader";


const FriendPage: React.FC<RouteComponentProps> = () => {
	const { token } = useAuth();
	const [userData, setUserData] = useState<any>([]);


	const friendList = async () => {
		let response: any = [];
		let userList: any = [];
		try {
			response = await Users.getFriendList(token ?? "");
			if (response.success == true) {
				userList = response.payload.map((item: any) => (
					<UserCard user={item} type="" />
				));
			} else {
				userList = (
					<IonRow className="ion-justify-content-center">
						<h1>No Friend Found</h1>
					</IonRow>
				);
			}

			setUserData(userList);
		} catch (error) {
			console.log(error);
			setUserData([]);
		}
		return userList;
	}


	useEffect(() => {
		friendList();
	}, [token]);


	return (
		<IonPage>
			<CommonHeader title={`Friends`}/>
			<IonContent fullscreen>
				<WaveTabsFriend
					leftTitle="Find"
					rightTitle="My Friends"
					leftComponent={<FindFriend />}
					rightComponent={userData}
					color='accent-two'
					leftRoute='friend'
					rightRoute='myfriend'
				>
					<PageTitle color="main">Friends</PageTitle>
				</WaveTabsFriend>
			</IonContent>
		</IonPage>
	);
};

export default FriendPage;
