import { IonIcon, IonInput, IonRow } from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import { useRef, useState, Ref, FormEventHandler } from "react";
import {AutocompleteTypes} from '@ionic/core';
import React from "react";
import styled from 'styled-components';

interface PasswordInputProps {
	onChange: Function,
	placeholder: string,
	onInput?: FormEventHandler<HTMLIonInputElement>,
	password: string
	name: string
	autofill?: string
	dark?: boolean
}

const PasswordInput = React.forwardRef(({
	onChange,
	placeholder,
	onInput,
	password,
	name,
	autofill,
	dark = false
}: PasswordInputProps, ref: Ref<HTMLIonInputElement>) => {
	const [showPassword, setShowPassword] = useState(false);
	return (
		<IonRow className="ion-justify-content-between ion-align-items-center full-width">
			<IonInput
				ref={ref}
				color={(dark) ? 'main':'constant-light'}
				type={(showPassword) ? 'text':'password'}
				onInput={onInput}
				onIonChange={(e) => {
					onChange(e.detail.value as string);
				}}
				placeholder={placeholder}
				name={name}
				value={password}
				autocomplete={autofill as AutocompleteTypes}
			/>
			<IonIcon
				color={(dark) ? 'main':'constant-light'}
				style={{fontSize: '23px'}}
				icon={(showPassword) ? eyeOff:eye}
				onClick={() => setShowPassword(!showPassword)}
			></IonIcon>
		</IonRow>
	);
});

export default PasswordInput;

const StyledIonIcon = styled(IonIcon)`
	font-size: 25px;
	position: absolute;
	right: 10px;
	z-index: 999;
`;