import { IonCol, IonRow, IonText,IonItem,IonIcon,IonModal } from "@ionic/react";
import { ReactNode, useEffect, useState } from "react";
import { generateRandomString } from "../services/util";
import Card from "./Card";
import CenteredSpinner from "./CenteredSpinner";
import Challenge from "./Challenge";
import Tournament from "./Tournament";
import Events from "./Events";  
import ChallengePrompt from "./ChallengePrompt";
import styled, { css } from "styled-components";	
import { truncate } from "../services/util";	
import { clipboardOutline, location,calendar,map } from 'ionicons/icons';	
import { toAppDateFormat} from "../services/time";

interface ChallengesListProps {
	title: string
	contextual_user_id: string
	challenges: Challenge[]
	tournament: Tournament[]
	event: Event[] 
	searchPrompt?: boolean
	hideTitleOnNoResults?: boolean
	allowViewChallenge?: boolean
	showActions?: boolean
	refreshChallenges: () => void
}

const ChallengesList: React.FC<ChallengesListProps> = ({
	title,
	contextual_user_id,
	challenges,
	tournament,
	event,
	searchPrompt = false,
	hideTitleOnNoResults = true,
	allowViewChallenge = true,
	showActions = true,
	refreshChallenges
}, props) => {
	const [nodes, setNodes] = useState<ReactNode[]>();
	const [nodesTournament, setNodesTournament] = useState<ReactNode[]>();
	const [nodesEvent, setNodesEvent] = useState<ReactNode[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [totalCount, setTotalCount] = useState<number>(0);
  const mapChallenges = () => {
		if (challenges && challenges.map !== undefined){
			const newNodes = challenges.map((challenge) => {
				let passProps = {
					
					challenge,
					issuer: challenge.issuer,
					recipients: challenge.recipients,
					contextual_user_id,
					boxType: 'solid',
					isContextUserIssuer: challenge.issuer?.id === contextual_user_id,
					allowViewChallenge,
					showActions,
					refreshChallenges,
					
				}
				return (
					<Challenge key={generateRandomString()} {...passProps} {...props} />
				)
			})
			setNodes(newNodes);
			setTimeout(() => {setIsLoading(false)}, 0);
		} else {
			setNodes([]);
		}
	}

	
	const mapTournament = () => {	
		
		if (tournament && tournament.map !== undefined){	
			const newnodesTournament = tournament.map((tournament) => {	
					let passProps  = {	
					tournament,	
					boxType: 'solid',	
					allowViewChallenge,	
					showActions,	
					refreshChallenges,
					contextual_user_id
				}	
        
				return (	
		   <Tournament key={generateRandomString()} {...passProps} {...props} />	
						
				)	
			})	
			setNodesTournament(newnodesTournament);	
			setTimeout(() => {setIsLoading(false)}, 0);	
		} else {	
			setNodesTournament([]);	
		}	
	}	
	const mapEvent = () => {	
		if (event && event.map !== undefined){	
			const newnodesEvent = event.map((event) => {	
					let passProps  = {	
					event,	
					boxType: 'solid',	
					allowViewChallenge,	
					showActions,	
					refreshChallenges	
				}	
				return (	
		   <Events key={generateRandomString()} {...passProps} {...props} />	
						
				)	
			})	
			setNodesEvent(newnodesEvent);	
			setTimeout(() => {setIsLoading(false)}, 0);	
		} else {	
			setNodesEvent([]);	
		}	
	}	
	useEffect(() => {	
		mapChallenges();	
		mapTournament();	
		 mapEvent();
		 setTotalCount(challenges?.length+tournament?.length+event?.length)

			
	}, [challenges,tournament,event])

	//console.log("Title", title);

	return (
		(isLoading) ? (<CenteredSpinner />):(
			<IonCol>
				<Card title={
						(totalCount && totalCount !== undefined && totalCount === 0) ? 
							((hideTitleOnNoResults) ? null:`${title} Challenges`)
							:
						(`${title} (${(totalCount && totalCount !== undefined) ? totalCount:0})`)
					}
				>   
				    <SectionTitle>All Challenges/Tournaments/Events</SectionTitle>
					{(challenges && challenges.length === 0) ? (
						(searchPrompt) ? <ChallengePrompt msg={`No ${title} Challenges`} />:(
							<IonRow className="ion-justify-content-center">
								<IonText>No Challenges found.</IonText>
							</IonRow>
						)):nodes}
						
					{/* <SectionTitle>Tournament List</SectionTitle>
					<ChallengePrompt msg={`No ${title} Tournaments`} />	 */}
					{(tournament && tournament.length === 0) ? (	
						(searchPrompt) ? 	<ChallengePrompt msg={`No ${title} Tournaments`} />	 :(	
							<IonRow className="ion-justify-content-center">	
								<IonText>No Tournaments found.</IonText>	
							</IonRow>	
						)):nodesTournament}
						{(event && event.length === 0) ? (	
						(searchPrompt) ? 	<ChallengePrompt msg={`No ${title} Event`} />	 :(	
							<IonRow className="ion-justify-content-center">	
								<IonText>No Event found.</IonText>	
							</IonRow>	
						)):nodesEvent}

						
				</Card>
			</IonCol>

			
		)
  );
}

export default ChallengesList;

	const SectionTitle = styled.div`	
	padding: var(--horizontal-gutter);	
	font-size: 25px;	
	font-family: var(--heading-font);	
`;	


